import React from 'react';
import Overlay from './Overlay';

class OverlayTabs extends React.Component
{
    getClasses(data)
    {
        let classes = 'overlay-tab ';
        if (data.isActive === true) {
            classes += 'overlay-tab--active';
        }
        return classes;
    }

    render()
    {
        return (
            <nav className="overlay-tabs">
                {this.props.tabData.map(function (data) {
                    return (
                        <a href="#" className={this.getClasses(data)} onClick={() => this.props.chooseTab(data.name)} key={"tab_" + data.name}>
                            <h3 className="overlay-tab__title">{data.title}</h3>
                        </a>
                    );
                }.bind(this))}
            </nav>
        );
    }
}

export default OverlayTabs;
