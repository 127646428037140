import React from 'react';

import Card from './Card';

class ShopCards extends React.Component
{
    performAction(card) {
        this.props.encounterAction('buyCard', this.getGoldCost(card), card.uid);
    }

    canPurchase(card)
    {
        return this.props.gold >= this.getGoldCost(card);
    }

    isPurchased(card)
    {
        return this.props.cardsPurchased.filter(purchasedCard => {
            return purchasedCard.uid === card.uid;
        }).length > 0;
    }

    getGoldCost(card)
    {
        return Math.ceil(card.goldCost * ((100 - this.props.discountPercentage) / 100));
    }

    getGoldCostOriginal(card)
    {
        return card.goldCost;
    }

    render() {
        return (
            <div class="cards cards--shop">
                {this.props.cardsForPurchase.map((card, handPos) => {
                    let buttonLabel = <span>Buy for {this.getGoldCost(card)} gold</span>;
                    if (this.getGoldCost(card) !== this.getGoldCostOriginal(card)) {
                        buttonLabel = <span>Buy for <span className="shop-discount-original-price">{this.getGoldCostOriginal(card)}</span> {this.getGoldCost(card)} gold</span>;
                    }
                    return (
                        <div className={"card-with-cta " + (!this.canPurchase(card) ? "card-with-cta--unbuyable " : "") + (this.isPurchased(card) ? "card-with-cta--purchased " : "")}>
                            <Card
                                id={card.id}
                                key={card.uid}
                                uid={card.uid}
                                name={card.name}
                                image={card.image}
                                description={card.description}
                                tier={card.tier}
                                cost={card.cost}
                                goldCost={this.getGoldCost(card)}
                                goldCostOriginal={this.getGoldCostOriginal(card)}
                                playCard={() => {}}
                                addUnplayableClass={false}
                            />
                            <button class="card-cta" onClick={() => this.canPurchase(card) && !this.isPurchased(card) ? this.performAction(card) : {}}>{buttonLabel}</button>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default ShopCards;
