import React from 'react';
import TopBar from './TopBar';

class TopBarBattle extends React.Component
{
    render()
    {
        return (
            <TopBar
                openOverlay={this.props.openOverlay}
                classes="topbar--battle"
                >
                <div className="topbar-enemy">
                    <h1 className="topbar-enemy__title">{this.props.enemyName}</h1>
                    <div className="topbar-deck">
                        <div className="icon icon--topbar">
                            <a href="#" onClick={() => this.props.openOverlay('enemyDeck')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#000" strokeWidth="15.605" strokeLinecap="round" strokeLinejoin="round" stroke-miterlimit="22.9"><path d="M8.32 195.1l96.948 61.257 150.94-103.596v0"/><path d="M8.518 154.112l96.947 61.257 150.94-103.597v0"/><path d="M8.518 112.673l96.947 61.257 150.94-103.596v0M256.405 70.334L159.457 9.076 8.517 112.673v0"/></g></svg>
                            </a>
                            <span className="icon__label">{this.props.enemyDeckCount}</span>
                        </div>
                    </div>
                    <div className="topbar-discard">
                        <div className="icon icon--topbar">
                            <a href="#" onClick={() => this.props.openOverlay('enemyDiscard')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><path fill="none" stroke="#000" strokeWidth="14.552" strokeLinejoin="round" stroke-opacity=".996" d="M19.418 72.917L118.619 9.85l115.586 181.815-99.201 63.066z"/><path fill="#fff" stroke="#000" strokeWidth="14.552" strokeLinejoin="round" stroke-opacity=".996" d="M218.422 48.289l32.017 113.107-207.302 58.679-32.016-113.107z"/><path fill="#fff" stroke="#000" strokeWidth="14.552" strokeLinejoin="round" stroke-opacity=".996" d="M89.86 14.69l116.51 15.618-28.623 213.536-116.51-15.617z"/><path d="M71.485 68.886l-5.387 39.121M78.666 16.725L73.28 55.847M55.232 189.838l-5.387 39.122M217.384 31.466l-5.387 39.122M200.375 158.844l-5.387 39.122M194.988 197.966l-5.386 39.121M78.619 241.527l118.685 16.253v0M50.647 84.658L12.593 95.213M242.411 175.017l-38.054 10.555" fill="none" stroke="#fff" strokeWidth="7.5"/></svg>
                            </a>
                            <span className="icon__label">{this.props.enemyDiscardCount}</span>
                        </div>
                    </div>
                    <div className="topbar-exile">
                        <div className="icon icon--topbar">
                            <a href="#" onClick={() => this.props.openOverlay('enemyExile')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#000" strokeWidth="15" strokeLinejoin="round"><path d="M207.417 25.107l49.318 7.553-33.79 223.528-99.77-15.384 74.707-81.029-36.306-57.564z"/><path d="M123.176 240.804L75.562 255.71 7.596 40.101l96.368-30.063L72.51 115.666l57.923 35.73z"/></g></svg>
                            </a>
                            <span className="icon__label">{this.props.enemyExileCount}</span>
                        </div>
                    </div>
                </div>
                <div className="topbar-gold">
                    <div className="icon icon--topbar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#000" strokeWidth="14.552"><path d="M61.023 21.023l31.064 47.495s-178.49 175.26 40.016 169.56M203.183 21.023l-31.065 47.495s178.49 175.26-40.015 169.56"/><path d="M67.341 40.496s15.796-12.824 25.273-7.6c9.477 5.225 11.057 12.35 25.8 4.275 14.742-8.074 14.742-14.724 27.378-3.325 12.637 11.4 4.739 14.25 20.008 8.075 15.269-6.175 13.163-9.974 28.432-5.7"/></g></svg>
                        <span className="icon__label">{this.props.gold}</span>
                    </div>
                </div>
            </TopBar>
        );
    }
}

export default TopBarBattle;
