import React from 'react';

class ShopSectionArtifact extends React.Component {

    getTabClasses() {
        let classes = "shop-tab";
        if (this.props.isActiveTab === true) {
            classes += " shop-tab--active";
        }
        return classes;
    }

    performAction(id) {
        this.props.encounterAction('buyArtifact', 50, id);
    }

    isPurchased(artifact)
    {
        return this.props.artifactsPurchased.filter(purchasedArtifact => {
            return purchasedArtifact.uid === artifact.uid;
        }).length > 0;
    }

    render() {
        return (
            <div className={this.getTabClasses()}>
                {this.props.artifactsForPurchase.map((artifact, handPos) => {
                    return (
                        <div className={"shop-artifact " + (this.isPurchased(artifact) ? 'shop-artifact--purchased' : '')} onClick={() => !this.isPurchased(artifact) ? this.performAction(artifact.uid) : {}}>
                            <div className="shop-artifact-inner">
                                <h3>{artifact.name}</h3>
                                <img src={artifact.image} title={artifact.description} />
                            </div>
                            <div className={"shop-artifact-cost " + (this.props.gold < 50 ? 'shop-artifact-cost--unbuyable' : '')}>
                                <img src="/images/roguequest/money.jpeg" />
                                50
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default ShopSectionArtifact;
