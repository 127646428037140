import AbstractEncounter from "./AbstractEncounter";
import Card from '../Card';

import {shuffle} from '../Helper';

import {cardsData} from '../data/cards-new';
import {artifactsData} from '../data/artifacts';
import Artifact from "../Artifacts/Artifact";

class ShopEncounter extends AbstractEncounter
{
    constructor()
    {
        super();
        this.name = 'Shop';
        this.screen = 'play';
        this.tab = 'general';
        this.image = '/images/new/encounters/shop.png';
        this.cardsForPurchase = [];
        this.cardsPurchased = [];
        this.artifactsForPurchase = [];
        this.artifactsPurchased = [];
        this.servicesPurchased = [];
        this.cardsSeenAndCouldHavePurchased = [];
        this.artifactsSeenAndCouldHavePurchased = [];
        this.cardsDeleted = [];
        this.ctas = [
            'browse',
            'leave'
        ];
        this.generalWareCost = 50;
    }

    startEncounter(game)
    {
        super.startEncounter(game);
        this.generateCardsForPurchase();
        this.generateArtifactsForPurchase();
        game.triggerSoundEffect('/sounds/effects/enter_shop.mp3');
    }

    setShopTab(tab)
    {
        this.tab = tab;

        if (tab === 'cards') {
            let cardsSeenAndCouldHavePurchased = this.cardsForPurchase.filter(card => this.game.gold >= card.goldCost);
            cardsSeenAndCouldHavePurchased
                .filter(card => this.cardsSeenAndCouldHavePurchased.indexOf(card) === -1)
                .forEach(card => this.cardsSeenAndCouldHavePurchased.push(card));
        }

        if (tab === 'artifacts') {
            let artifactsSeenAndCouldHavePurchased = this.artifactsForPurchase.filter(artifact => this.game.gold >= artifact.goldCost);
            artifactsSeenAndCouldHavePurchased
                .filter(artifact => this.artifactsSeenAndCouldHavePurchased.indexOf(artifact) === -1)
                .forEach(artifact => this.artifactsSeenAndCouldHavePurchased.push(artifact));
        }
    }

    generateCardsForPurchase()
    {
        if (this.cardsForPurchase.length > 0) {
            return;
        }
        let cardIds = {...cardsData};
        let possibleCardIds = Object.keys(cardIds)
            .filter(function (cardId) {
                if (cardIds[cardId].modes === undefined) {
                    return false;
                }
                return cardIds[cardId].tier > 0 && cardIds[cardId].modes.includes(this.game.modeId) && cardIds[cardId].unlockLevel <= this.game.experience.level;
            }.bind(this));

        if (this.game.artifacts.filter(artifact => artifact.hasPassiveEffect('noDuplicates')).length > 0) {
            possibleCardIds = possibleCardIds
                .filter(function (card) {
                    let cardNamesInGame = this.game.deck.deck.map(card => card.name);
                    return !cardNamesInGame.includes(card.name);
                }.bind(this));
        }

        let possibleCards = possibleCardIds.map((cardId, pos) => Card.createFromCardId(cardId));

        let cardsForPurchase = shuffle(possibleCards).slice(0, 3);

        this.cardsForPurchase = cardsForPurchase;
    }

    generateArtifactsForPurchase()
    {
        if (this.artifactsForPurchase.length > 0) {
            return;
        }
        let artifactIds = {...artifactsData};
        let possibleArtifacts = Object.keys(artifactIds).filter(artifactId => !this.game.hasArtifact(artifactIds[artifactId].name) && artifactIds[artifactId].pandora !== true).map((artifactId, pos) => Artifact.createFromId(artifactId));

        let artifactsForPurchase = shuffle(possibleArtifacts).slice(0, 3);

        this.artifactsForPurchase = artifactsForPurchase;
    }

    getEncounterState()
    {
        let state = super.getEncounterState();

        state.cardsForPurchase = this.cardsForPurchase.map(card => card.getCardState());
        state.cardsPurchased = this.cardsPurchased.map(card => card.getCardState());
        state.artifactsForPurchase = this.artifactsForPurchase.filter(artifact => artifact.expired === false).map(artifact => artifact.getArtifactState());
        state.artifactsPurchased = this.artifactsPurchased.filter(artifact => artifact.expired === false).map(artifact => artifact.getArtifactState());
        state.servicesPurchased = this.servicesPurchased;

        state.generalWareCost = this.generalWareCost;
        state.discountPercentage = 0;

        if (this.game && this.game.artifacts.filter(artifact => artifact.hasPassiveEffect('itemsHalfOff')).length > 0) {
            state.discountPercentage = 50;
        }

        return state;
    }

	increaseMaxHealth(gold)
	{
		if (gold <= this.game.gold) {
            this.spendGold(gold);
			this.game.maxHp += 10;
			this.game.hp += 10;
            this.servicesPurchased.push('maxHp');
		}
	}

	increaseHealth(gold)
	{
		if (gold <= this.game.gold) {
            this.spendGold(gold);
			let possibleHealing = this.game.maxHp - this.game.hp;
			let health = Math.min(possibleHealing, 30);
			this.game.hp += health;
            this.servicesPurchased.push('heal');
		}
	}

	deleteCard(gold, cardUid)
	{
		if (gold <= this.game.gold) {
            this.spendGold(gold);
            let card = this.game.deck.deck.filter(reward => reward.uid === cardUid)[0];
            this.game.deck.removeFromDeck(card);
            this.servicesPurchased.push('delete');
            this.cardsDeleted.push(card);
		}
	}

	upgradeCard(gold, id)
	{
        console.log('ddd');

	}

	buyCard(gold, cardUid)
	{
        let card = this.cardsForPurchase.filter(reward => reward.uid === cardUid)[0];
        // let gold = card.goldCost;
		if (gold <= this.game.gold) {
            this.spendGold(gold);
            this.game.deck.addToDeck(card);
            this.cardsPurchased.push(card);
		}
	}

	buyArtifact(gold, artifactUid)
	{
        let artifact = this.artifactsForPurchase.filter(reward => reward.uid === artifactUid)[0];
        // let gold = artifact.goldCost;
		if (gold <= this.game.gold) {
            this.spendGold(gold);
            this.game.addArtifact(artifact);
            this.artifactsPurchased.push(artifact);
		}
	}

    spendGold(gold)
    {
        this.game.gold -= gold;
        this.game.trigger('afterSpendGold', {
            goldSpent: gold,
            game: this.game
        });
        this.game.triggerSoundEffect('/sounds/effects/purchase.wav');
    }

    beforeLeaveEncounter()
    {
        this.cardsPurchased.forEach(function(card) {
            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(card.id),
                        name: card.name,
                        columnName: 'boughtFromShop'
                    }
                )
            };
            fetch('/.netlify/functions/increase-card-pickrate-count', requestOptions);
        }.bind(this));

        let cardsDeclined = this.cardsSeenAndCouldHavePurchased.filter(card => this.cardsPurchased.indexOf(card) === -1);

        cardsDeclined.forEach(function(card) {
            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(card.id),
                        name: card.name,
                        columnName: 'declinedFromShop'
                    }
                )
            };
            fetch('/.netlify/functions/increase-card-pickrate-count', requestOptions);
        }.bind(this));

        this.artifactsPurchased.forEach(function(artifact) {
            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(artifact.id),
                        name: artifact.name,
                        columnName: 'boughtFromShop'
                    }
                )
            };
            fetch('/.netlify/functions/increase-artifact-pickrate-count', requestOptions);
        }.bind(this));

        let artifactsDeclined = this.artifactsSeenAndCouldHavePurchased.filter(artifact => this.artifactsPurchased.indexOf(artifact) === -1);

        artifactsDeclined.forEach(function(artifact) {
            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(artifact.id),
                        name: artifact.name,
                        columnName: 'declinedFromShop'
                    }
                )
            };
            fetch('/.netlify/functions/increase-artifact-pickrate-count', requestOptions);
        }.bind(this));

        if (this.cardsDeleted.length > 0) {
            let cardsNotDeleted = {};
            this.game.deck.deck.forEach(function (card) {
                let isDeleted = false;
                this.cardsDeleted.forEach(function (cardDeleted) {
                    if (cardDeleted.id === card.id) {
                        isDeleted = true;
                    }
                });

                if (!isDeleted) {
                    cardsNotDeleted[card.id] = card;
                }
            }.bind(this));

            this.cardsDeleted.forEach(function(card) {
                // Simple POST request with a JSON body using fetch
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            version: this.game.version,
                            internalId: parseInt(card.id),
                            name: card.name,
                            columnName: 'chosenToDelete'
                        }
                    )
                };
                fetch('/.netlify/functions/increase-card-pickrate-count', requestOptions);
            }.bind(this));

            Object.values(cardsNotDeleted).forEach(function(card) {
                // Simple POST request with a JSON body using fetch
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            version: this.game.version,
                            internalId: parseInt(card.id),
                            name: card.name,
                            columnName: 'declinedToDelete'
                        }
                    )
                };
                fetch('/.netlify/functions/increase-card-pickrate-count', requestOptions);
            }.bind(this));
        }
    }
}

export default ShopEncounter;
