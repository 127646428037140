import AbstractEncounter from "./AbstractEncounter";
import Artifact from "../Artifacts/Artifact";
import EncounterFactory from "./EncounterFactory";

import {shuffle} from '../Helper';

import {artifactsData} from '../data/artifacts';
import {cardsData} from '../data/cards-new';
import Card from "../Card";

class PandoraEncounter extends AbstractEncounter
{
    constructor()
    {
        super();
        this.name = 'Grimoire';
        this.screen = 'play';
        this.image = '/images/new/encounters/grimoire.png';
        this.ctas = [
            'open-book',
            'leave'
        ];
        this.choices = [];
        this.postIncantationMessage = null;
        this.chosenChoice = null;
    }

    populateChoices()
    {
        if (this.choices.length > 0) {
            return;
        }

        let possibleBlessings = [
            'gainHealthAndLoseMaxHealth',
            'gainMaxHealthAndLoseHealth',
            'gainPandoraArtifact',
            'removeRandomCardFromDeck',
            'addRandomCardToDeck',
            'addMimic',
            'randomEffect'
        ];

        let choices = [];

        if (this.game.hasArtifact("Translation Guide")) {
            choices = shuffle(possibleBlessings);
        } else {
            choices = shuffle(possibleBlessings).slice(0, 2);
        }

        choices = choices.forEach(function(choiceName, index) {
            let choiceData = {
                'name': choiceName,
                'id': index
            }
            let rand1;
            let rand2;

            switch (choiceName) {
                case 'gainHealthAndLoseMaxHealth':
                    rand1 = this.getRandomNumber(30, 50);
                    rand2 = this.getRandomNumber(5, 10);
                    choiceData.chapterName = 'Cervisia';
                    choiceData.description = 'Gain ' + rand1 + ' health, but lose ' + rand2 + ' maximum health.';
                    choiceData.healthGain = rand1;
                    choiceData.maxHealthLoss = rand2;
                    break;

                case 'gainMaxHealthAndLoseHealth':
                    rand1 = this.getRandomNumber(5, 15);
                    rand2 = this.getRandomNumber(20, 40);
                    choiceData.chapterName = 'Victu';
                    choiceData.description = 'Gain ' + rand1 + ' maximum health, but lose ' + rand2 + ' health.';
                    choiceData.maxHealthGain = rand1;
                    choiceData.healthLoss = rand2;
                    break;

                case 'gainPandoraArtifact':
                    let artifactIds = {...artifactsData};
                    let possibleArtifacts = Object.keys(artifactIds).filter(artifactId => !this.game.hasArtifact(artifactIds[artifactId].name) && artifactIds[artifactId].pandora === true && artifactIds[artifactId].unlockLevel <= this.game.experience.level).map((artifactId, pos) => Artifact.createFromId(artifactId));
                    if (possibleArtifacts) {
                        let chosenArtifact = shuffle(possibleArtifacts)[0];

                        choiceData.chapterName = 'Deprehendere';
                        choiceData.description = 'Gain the ' + chosenArtifact.name + ' artifact (' + chosenArtifact.description + ').';
                        choiceData.artifactId = chosenArtifact.id;
                    } else {
                        choiceData.chapterName = 'Ho...us...por...bies';
                        choiceData.description = 'This chapter is too faded to read.';
                    }
                    break;

                case 'removeRandomCardFromDeck':
                    choiceData.chapterName = 'Extermina';
                    choiceData.description = 'Remove a random card from the deck.';
                    break;

                case 'addRandomCardToDeck':
                    choiceData.chapterName = 'Incrementum';
                    choiceData.description = 'Add a random card to the deck.';
                    break;

                case 'addMimic':
                    if (this.game.locations.length > 0) {
                        choiceData.chapterName = 'Dissimulo';
                        choiceData.description = 'Summons a treasure-hoarding shapeshifting creature...';
                    } else {
                        choiceData.chapterName = 'Ho...us...por...bies';
                        choiceData.description = 'This chapter is too faded to read.';
                    }
                    break;

                case 'randomEffect':
                    let randomChoices = [
                        'gainHealth',
                        'loseHealth',
                        'gainMaxHealth',
                        'loseMaxHealth',
                        'gainGold',
                        'loseGold',
                        'gainRandomArtifact'
                    ];
                    let choice = shuffle(randomChoices)[0];
                    choiceData.chapterName = 'Ignotus';
                    choiceData.description = 'I can\'t make out what this spell does...';
                    choiceData.choice = choice;
                    break;
            }

            this.choices[index] = choiceData;
        }.bind(this));
    }

    getRandomNumber(min, max)
    {
        max += 1;
        return Math.floor((Math.random() * (max - min)) + min);
    }

    readIncantation(id)
    {
        let choice = this.choices[id];
        this.chosenChoice = choice;
        let card;
        let rand;
        let oldHp;
        let possibleArtifacts;

        switch (choice.name) {
            case 'gainHealthAndLoseMaxHealth':
                this.game.maxHp -= choice.maxHealthLoss;
                this.game.hp = Math.min(this.game.maxHp, this.game.hp + choice.healthGain);
                this.game.triggerSoundEffect('/sounds/effects/heal.wav');
                break;

            case 'gainMaxHealthAndLoseHealth':
                this.game.maxHp += choice.maxHealthGain;
                this.game.hp = Math.max(1, this.game.hp - choice.healthLoss);
                this.game.triggerSoundEffect('/sounds/effects/poison.wav');
                break;

            case 'gainPandoraArtifact':
                if (choice.artifactId === undefined) {
                    break;
                }
                let artifact = Artifact.createFromId(choice.artifactId);
                this.game.addArtifact(artifact);
                this.game.triggerSoundEffect('/sounds/effects/purchase2.wav');
                break;

            case 'removeRandomCardFromDeck':
                this.game.deck.reset();
                card = this.game.deck.deck[0];
                this.game.deck.removeFromDeck(card);
                this.game.triggerSoundEffect('/sounds/effects/draw2.wav');
                this.postIncantationMessage = card.name + ' was removed from your deck.';
                break;

            case 'addRandomCardToDeck':
                let cardIds = {...cardsData};
                let possibleCardIds = Object.keys(cardIds)
                    .filter(function (cardId) {
                        if (cardIds[cardId].modes === undefined) {
                            return false;
                        }
                        return cardIds[cardId].tier > 0 && cardIds[cardId].modes.includes(this.game.modeId) && cardIds[cardId].unlockLevel <= this.game.experience.level;
                    }.bind(this));

                if (this.game.artifacts.filter(artifact => artifact.hasPassiveEffect('noDuplicates')).length > 0) {
                    possibleCardIds = possibleCardIds
                        .filter(function (card) {
                            let cardNamesInGame = this.game.deck.deck.map(card => card.name);
                            return !cardNamesInGame.includes(card.name);
                        }.bind(this));
                }

                let possibleCards = possibleCardIds.map((cardId, pos) => Card.createFromCardId(cardId));
                card = shuffle(possibleCards)[0];
                this.game.deck.addToDeck(card);
                this.game.triggerSoundEffect('/sounds/effects/draw2.wav');
                this.postIncantationMessage = card.name + ' was added to your deck.';
                break;

            case 'addMimic':
                if (this.game.locations.length > 0) {
                    let highestEncounterId = Math.max(
                        this.game.locations.reduce(function (accum, location) {
                            return Math.max(accum, location.encounters.reduce(function (accum2, encounter) {
                                return Math.max(accum2, encounter.encounterId);
                            }, 0), 0);
                        }, 0),
                        this.game.currentLocation.encounters.reduce(function (accum3, encounter) {
                            return Math.max(accum3, encounter.encounterId);
                        }, 0)
                    );
                    let encounter = EncounterFactory.createMimicEncounter();
                    encounter.encounterId = highestEncounterId + 1;
                    this.game.locations[0].totalEncounters++;
                    this.game.locations[0].encounters.push(encounter);
                    this.game.triggerSoundEffect('/sounds/effects/growl3.mp3');
                    this.postIncantationMessage = 'You speak the incantation... but nothing appears to have happened? You\'re sure you spoke the word correctly...';
                    break;
                }

            case 'randomEffect':
                switch (choice.choice) {
                    case 'gainHealth':
                        rand = this.getRandomNumber(1, 50);
                        oldHp = this.game.hp;
                        this.game.hp = Math.min(this.game.maxHp, this.game.hp + rand);
                        this.postIncantationMessage = 'You heal for ' + (this.game.hp - oldHp) + ' health.';
                        break;

                    case 'loseHealth':
                        rand = this.getRandomNumber(1, 25);
                        oldHp = this.game.hp;
                        this.game.hp = Math.max(1, this.game.hp - rand);
                        this.postIncantationMessage = 'You lose ' + (oldHp - this.game.hp) + ' health.';
                        break;
                        break;

                    case 'gainMaxHealth':
                        rand = this.getRandomNumber(1, 10);
                        this.game.maxHp += rand;
                        this.postIncantationMessage = 'You gain ' + rand + ' maximum health.';
                        break;

                    case 'loseMaxHealth':
                        rand = this.getRandomNumber(1, 10);
                        this.game.maxHp -= rand;
                        this.game.hp = Math.min(this.game.maxHp, this.game.hp);
                        this.postIncantationMessage = 'You lose ' + rand + ' maximum health.';
                        break;

                    case 'gainGold':
                        rand = this.getRandomNumber(1, 100);
                        this.game.gold += rand;
                        this.postIncantationMessage = 'You gain ' + rand + ' gold.';
                        break;

                    case 'loseGold':
                        rand = this.getRandomNumber(1, this.game.gold);
                        this.game.gold -= rand;
                        this.postIncantationMessage = 'You lose ' + rand + ' gold.';
                        break;

                    case 'gainRandomArtifact':
                        let artifactIds = {...artifactsData};
                        possibleArtifacts = Object.keys(artifactIds).filter(artifactId => !this.game.hasArtifact(artifactIds[artifactId].name) && artifactIds[artifactId].unlockLevel <= this.game.experience.level).map((artifactId, pos) => Artifact.createFromId(artifactId));
                        if (possibleArtifacts) {
                            let chosenArtifact = shuffle(possibleArtifacts)[0];
                            this.game.addArtifact(chosenArtifact);
                            this.game.triggerSoundEffect('/sounds/effects/purchase2.wav');
                            this.postIncantationMessage = 'You gain the ' + chosenArtifact.name + '(' + chosenArtifact.description + ')';
                        } else {
                            this.postIncantationMessage = 'You wait for a while... but nothing happens.';
                        }
                        break;
                }
                break;
        }

        if (this.postIncantationMessage === null) {
            this.game.endEncounter(this.encounterId);
        }
    }

    beforeEndEncounter()
    {

    }

    beforeLeaveEncounter()
    {
        let hasPandoraChoice = this.choices.filter(choice => choice.name === 'gainPandoraArtifact').length > 0;
        if (!hasPandoraChoice) {
            return;
        }

        if (this.chosenChoice.name === 'gainPandoraArtifact') {
            let artifact = Artifact.createFromId(this.chosenChoice.artifactId);

            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(artifact.id),
                        name: artifact.name,
                        columnName: 'chosenFromGrimoire'
                    }
                )
            };
            fetch('/.netlify/functions/increase-artifact-pickrate-count', requestOptions);
        } else {
            let unchosenChoice = this.choices.filter(choice => choice.name === 'gainPandoraArtifact')[0];

            let artifact = Artifact.createFromId(unchosenChoice.artifactId);

            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(artifact.id),
                        name: artifact.name,
                        columnName: 'declinedFromGrimoire'
                    }
                )
            };
            fetch('/.netlify/functions/increase-artifact-pickrate-count', requestOptions);
        }
    }

    startEncounter(game)
    {
        super.startEncounter(game);
        this.populateChoices();
        game.triggerSoundEffect('/sounds/effects/book.mp3');
    }

    getEncounterState()
    {
        let state = super.getEncounterState();
        state.pandoraChoices = this.choices;
        state.postChoiceMessage = this.postIncantationMessage;

        return state;
    }
}

export default PandoraEncounter;
