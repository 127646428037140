import AttackEffect from "../Effects/AttackEffect";
import CritAttackEffect from "../Effects/CritAttackEffect";
import AbstractStatusEffect from "./AbstractStatusEffect";

class MusterStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Muster';
        this.image = '/images/new/status/muster.png';
        this.isBuff = true;
    }

    triggerBeforeTakeDamage(context)
    {
        if (context.enemy === this.player &&
            context.source.effect !== undefined &&
            (
                (context.source.effect instanceof AttackEffect)
                || (context.source.effect instanceof CritAttackEffect)
            )
        ) {
            context.damageVal += this.val;
        }
    }

    generateTooltip()
    {
        return 'Whenever player would deal damage, deal ' + this.val + ' more.';
    }
}

export default MusterStatusEffect;
