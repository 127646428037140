import Artifact from "./Artifacts/Artifact";
import Card from "./Card";
import { artifactsData } from "./data/artifacts";
import { cardsData } from "./data/cards-new";
import { enemiesData } from "./data/enemies-new";
import { modeData } from "./data/startingModes";
import Player from "./Player";

class Experience
{
    constructor()
    {
        this.xp = 0;
        this.xpProgress = 0;
        this.percentProgress = 0;
        this.level = 1;
        this.xpRemaining = 0;

        this.levelBoundries = {
            1: 0,
            2: 100,
            3: 220,
            4: 360,
            5: 560,
            6: 850,
            7: 1250,
            8: 1750,
        };

        this.levelDifference = 500;
        this.calculateLevels();
    }

    static loadFromSession(sessionData)
    {
        let experience = new Experience();
        experience.setXp(sessionData.xp);
        return experience;
    }

    getSessionData()
    {
        return {
            xp: this.xp,
        };
    }

    getState()
    {
        return {
            xp: this.xp,
            xpProgress: this.xpProgress,
            percentProgress: this.percentProgress,
            level: this.level,
            xpRemaining: this.xpRemaining
        };
    }

    setXp(xp)
    {
        this.xp = xp;
        this.calculateLevels();
    }

    gainXp(xp)
    {
        this.xp += xp;
        this.calculateLevels();
    }

    calculateLevels()
    {
        let extraLevels = 0;
        let currentXp = this.xp;

        while (Math.max(Object.values(this.levelBoundries)) > currentXp) {
            currentXp -= this.levelDifference;
            extraLevels++;
        }

        let currentBoundry = Math.max(...Object.values(this.levelBoundries).map(b => parseInt(b)).filter(boundry => boundry <= currentXp));
        let currentLevel = parseInt(Object.keys(this.levelBoundries).find(key => this.levelBoundries[key] === currentBoundry)) + extraLevels;

        this.level = currentLevel;

        if (this.level >= Math.max(...Object.keys(this.levelBoundries).map(key => parseInt(key)))) {
            this.xpProgress = Math.max(...Object.values(this.levelBoundries)) % this.levelDifference;
            this.xpRemaining = this.levelDifference - this.xpProgress;
            this.percentProgress = (this.xpProgress * 100) / this.levelDifference;
        } else {
            this.xpProgress = this.xp - this.levelBoundries[this.level];
            this.xpRemaining = this.levelBoundries[this.level + 1] - this.xp;
            this.percentProgress = (this.xpProgress * 100) / (this.levelBoundries[this.level + 1] - this.levelBoundries[this.level]);
        }
    }

    getUnlocksBetweenLevels(oldLevel, newLevel)
    {
        let returnData = {
            cards: [],
            artifacts: [],
            enemies: [],
            modes: []
        };

        let cardsDataUnlocked = {...cardsData};
        returnData.cards = returnData.cards.concat(Object.values(cardsDataUnlocked).filter(cardData => cardData.unlockLevel > oldLevel && cardData.unlockLevel <= newLevel).map(cardData => Card.createFromCardId(cardData.id)));

        let artifactsDataUnlocked = {...artifactsData};
        returnData.artifacts = returnData.artifacts.concat(Object.values(artifactsDataUnlocked).filter(artifactData => artifactData.unlockLevel > oldLevel && artifactData.unlockLevel <= newLevel).map(artifactData => Artifact.createFromId(artifactData.id)));

        let enemiesDataUnlocked = {...enemiesData};
        returnData.enemies = returnData.enemies.concat(Object.values(enemiesDataUnlocked).filter(enemyData => enemyData.unlockLevel > oldLevel && enemyData.unlockLevel <= newLevel).map(enemyData => Player.createEnemyFromId(enemyData.id)));

        let modesDataUnlocked = {...modeData};
        returnData.modes = returnData.modes.concat(Object.values(modesDataUnlocked).filter(modeData => modeData.unlockLevel > oldLevel && modeData.unlockLevel <= newLevel));

        return returnData;
    }
}

export default Experience;
