import AbstractEffect from "./AbstractEffect";

class HealEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let maxHp = context.player.maxHp;
            let currentHp = context.player.hp;

            context.healVal = this.getVal(context);
            let newContext = {...context};
            newContext.player = context.enemy;
            newContext.enemy = context.player;

            context.battle.game.trigger('beforePlayerHeal', context);
            newContext.healVal = context.healVal;
            context.battle.game.trigger('beforeEnemyHeal', newContext);
            context.healVal = newContext.healVal;

            let newHp = Math.min(maxHp, currentHp + context.healVal);
            context.player.hp = newHp;

            context.healVal = newHp - currentHp;
            newContext.healVal = context.healVal;

            if (context.healVal > 0) {
                context.battle.game.triggerSoundEffect('/sounds/effects/heal.wav');
            } else {
                context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
            }

            context.player.addNumber(context.healVal, 'heal');

            context.battle.game.trigger('afterPlayerHeal', context);
            newContext.healVal = context.healVal;
            context.battle.game.trigger('afterEnemyHeal', newContext);
            context.healVal = newContext.healVal;

            context.battle.history.write(context.player.name + ' heals for ' + (newHp - currentHp), context.historySourceImage);
        }
    }

    generateDescription()
    {
        return 'Heal for ' + parseInt(this.data) + '.';
    }
}

export default HealEffect;
