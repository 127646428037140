import AbstractCondition from "./AbstractCondition";

class GreaterThanCondition extends AbstractCondition
{
    isMet(context)
    {
        let vals = [...this.vals];
        let firstValue = vals.shift().calculate(context);
        return vals.every((value) => firstValue > value.calculate(context));
    }
}

export default GreaterThanCondition;
