import React from 'react';
import Artifact from '../Artifacts/Artifact';

class Cheats extends React.Component
{
    constructor(props) {
      super(props);

      this.state = {
        history: [],
        code: ''
      };

      this.history = [];

      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(event)
    {
        event.preventDefault();
        this.submitCheat(this.state.code);
        this.setState({
            code: '',
            history: this.history
        });
    }

    handleChange(event)
    {
        event.preventDefault();
        this.setState({
            code: event.target.value
        });
    }

    submitCheat(code)
    {
        this.writeToOutput(code);
        let codeParts = code.split('=');
        let key = codeParts[0];
        let value = undefined;
        if (codeParts.length > 1) {
            value = codeParts[1];
        }

        switch (key) {
            case 'setHp':
                if (value === undefined) {
                    this.writeToOutput('Needs value.');
                    break;
                }
                this.props.game.hp = parseInt(value);
                if (this.props.game.battle !== undefined) {
                    this.props.game.battle.player.hp = parseInt(value);
                }
                this.writeToOutput('Set HP to ' + value);
                break;

            case 'setMaxHp':
                if (value === undefined) {
                    this.writeToOutput('Needs value.');
                    break;
                }
                this.props.game.maxHp = parseInt(value);
                if (this.props.game.battle !== undefined) {
                    this.props.game.battle.player.maxHp = parseInt(value);
                }
                this.writeToOutput('Set Max HP to ' + value);
                break;

            case 'gainGold':
                if (value === undefined) {
                    this.writeToOutput('Needs value.');
                    break;
                }
                this.props.game.gold += parseInt(value);
                this.writeToOutput('Added ' + value + ' gold');
                break;

            case 'maxMana':
                if (this.props.game.battle === undefined) {
                    this.writeToOutput('Cheat only accessible during battle');
                    break;
                }
                this.props.game.battle.player.mana = {
                    red: 999999,
                    green: 999999,
                    blue: 999999
                };
                this.writeToOutput('Set mana to 999999');
                break;

            case 'addArtifact':
                if (value === undefined) {
                    this.writeToOutput('Needs value.');
                    break;
                }
                let artifact = Artifact.createFromId(value);
                this.props.game.addArtifact(artifact);
                this.writeToOutput('Added artifact ' + artifact.name);
                break;

            case 'addShop':
                if (value === undefined) {
                    value = 1;
                }
                for (let i = 0; i < value; i++) {
                    this.props.game.addEncounterType('shop');
                }
                this.writeToOutput('Added ' + value + ' shop encounter to current location.');
                break;

            case 'addBlessing':
                if (value === undefined) {
                    value = 1;
                }
                for (let i = 0; i < value; i++) {
                    console.log('adding blessing');
                    this.props.game.addEncounterType('blessing');
                }
                this.writeToOutput('Added ' + value + ' blessing encounter to current location.');
                break;

            case 'addPandora':
                if (value === undefined) {
                    value = 1;
                }
                for (let i = 0; i < value; i++) {
                    this.props.game.addEncounterType('pandora');
                }
                this.writeToOutput('Added ' + value + ' pandora encounter to current location.');
                break;

            case 'addChest':
                if (value === undefined) {
                    value = 1;
                }
                for (let i = 0; i < value; i++) {
                    this.props.game.addEncounterType('chest');
                }
                this.writeToOutput('Added ' + value + ' chest encounter to current location.');
                break;

            case 'addEnemy':
                //todo
                break;

            case 'shuffleEncounters':
                this.props.game.shuffleCurrentLocationEncounters();
                this.writeToOutput('Shuffled encounters');
                break;

            case 'removeArtifact':
                //todo
                break;

            case 'addCard':
                //todo
                break;

            case 'nextLocation':
                //todo
                break;

            case 'winBattle':
                if (this.props.game.battle === undefined) {
                    this.writeToOutput('Cheat only accessible during battle');
                }
                this.props.game.battle.enemySurrender();
                this.writeToOutput('Battle won');
                break;

            case 'loseBattle':
                if (this.props.game.battle === undefined) {
                    this.writeToOutput('Cheat only accessible during battle');
                }
                this.props.game.battle.surrender();
                this.writeToOutput('Battle lost');
                break;

            case 'gainXp':
                if (value === undefined) {
                    this.writeToOutput('Needs value.');
                    break;
                }
                this.props.game.experience.gainXp(value);
                this.writeToOutput(value + ' XP gained.');
                break;

            case 'setXp':
                if (value === undefined) {
                    this.writeToOutput('Needs value.');
                    break;
                }
                this.props.game.experience.setXp(value);
                this.writeToOutput('XP reset to ' + value);
                break;

            default:
                this.writeToOutput('No cheats found.');
                break;
        }

        this.props.updateState();
    }

    writeToOutput(msg)
    {
        let history = this.history;
        history.push(msg);
    }

    render()
    {
        return (
            <div class="cheats">
                <div class="cheats-output">
                    {this.state.history.map((historyLine, pos) => {
                        return <p key={pos}>{historyLine}</p>;
                    })}
                </div>
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <input class="cheats-input" name="cheat" value={this.state.code} autoFocus autoComplete="off" onChange={this.handleChange}/>
                </form>
            </div>
        );
    }
}

export default Cheats;
