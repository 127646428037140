import { v4 as uuidv4 } from 'uuid';
import ConditionFactory from './Artifacts/Conditions/ConditionFactory';
import AbstractValue from './Artifacts/Values/AbstractValue';
import ValueFactory from './Artifacts/Values/ValueFactory';

import {achievementsData} from './data/achievements';
import EffectFactory from './Effects/EffectFactory';

class Achievement
{
    constructor()
    {
        this.id = null;
        this.image = null;
        this.description = null;
        this.name = null;
        this.triggers = [];
        this.conditions = [];
        this.effects = [];
        this.xp = 50;
        this.specialUnlocks = [];

        this.unlocked = false;
        this.pending = false;
        this.game = null;
        this.triggerPriorities = [];
        this.internalCounter = 0;
    }

    static createFromAchievementId(achievementId)
    {
        let achievement = new Achievement();
        let achievements = {...achievementsData};
        let data = achievements[achievementId];

        achievement.id = parseInt(achievementId);
        achievement.image = data.image;
        achievement.description = data.description;
        if (data.effects !== undefined) {
            achievement.effects = achievement.parseEffects(JSON.parse(JSON.stringify(data.effects)));
        }
        achievement.name = data.name;
        achievement.xp = data.xp;
        achievement.specialUnlocks = data.specialUnlocks;
        if (data.triggers !== undefined) {
            achievement.triggers = data.triggers;
        }
        if (data.conditions !== undefined) {
            achievement.conditions = achievement.parseConditions(data.conditions);
        }

        return achievement;
    }

    getState()
    {
        return {
            id: this.id,
            description: this.description,
            image: this.image,
            effects: this.effects,
            name: this.name,
            xp: this.xp,
            unlockLevel: this.unlockLevel,
            unlocked: this.unlocked
        }
    }

    trigger(triggerName, context)
    {
        let effects = this.effects.filter(effect => effect.trigger === triggerName);
        effects.forEach(effect => this.handleEffect(effect, {...context}));

        if (this.triggers.includes(triggerName)) {
            context.internalCounter = this.internalCounter;
            if (this.areConditionsMet(this.conditions, context)) {
                this.pending = true;
            }
        }
    }

    registerTriggers(triggerName, triggerManager)
    {
        let priority = 100;
        if (this.triggerPriorities[triggerName] !== undefined) {
            priority = this.triggerPriorities[triggerName];
        }
        triggerManager.registerListener(triggerName, priority, this.trigger.bind(this));
    }

    handleEffect(effectData, context)
    {
        context.internalCounter = this.internalCounter;

        if (!this.areConditionsMet(effectData.conditions, context)) {
            return;
        }
    }

    areConditionsMet(conditions, context)
    {
        if (conditions !== undefined) {
            return conditions.reduce((accum, condition) => accum && condition.isMet(context), true);
        }
        return true;
    }

    parseEffects(effects)
    {
        effects = effects.map(effect => {
            if (effect.conditions !== undefined) {
                effect.conditions = effect.conditions.map(conditionData => {
                    return ConditionFactory.createConditionFromJson(conditionData);
                });
            }
            return effect;
        });

        return effects;
    }

    parseConditions(conditions)
    {
        conditions = conditions.map(conditionData => {
            return ConditionFactory.createConditionFromJson(conditionData);
        });

        return conditions;
    }

    claim(experience)
    {
        experience.gainXp(this.xp);
        this.unlocked = true;
        this.pending = false;
        this.achievements.unlock(this.id);
    }
}

export default Achievement;
