import AbstractStatusEffect from "./AbstractStatusEffect";

class ShieldStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Shield';
        this.image = '/images/new/status/shield.png';
    }

    triggerStartPlayerTurn(context)
    {
        if (context.player === this.player && context.game.battle.turnCount > 1) {
            let reinforceStatusEffect = context.player.getStatusEffect('reinforce');
            if (reinforceStatusEffect.val <= 0) {
                this.changeVal(0, context);
            }
        }
    }

    generateTooltip()
    {
        return 'Blocks ' + this.val + ' damage. Removed at the start of player\'s next turn.';
    }
}

export default ShieldStatusEffect;
