import React from 'react';
import EnemyPlayArea from './EnemyPlayArea';
import PlayArea from './PlayArea';
import History from './History';
import WinScreen from './WinScreen';
import LoseScreen from './LoseScreen';

class PlayScreen extends React.Component
{
    render() {
        return (
            <div className="playscreen">
                <EnemyPlayArea
                    hp={this.props.enemyHp}
                    maxHp={this.props.enemyMaxHp}
                    mana={this.props.enemyMana}
                    manaRegen={this.props.enemyManaRegen}
                    statusEffects={this.props.enemyStatusEffects}
                    artifacts={this.props.enemyArtifacts}
                    name={this.props.enemyName}
                    image={this.props.enemyImage}
                    surrender={this.props.surrender}
                    endTurn={this.props.endTurn}
                    hand={this.props.enemyHand}
                />
                <PlayArea
                    playCard={this.props.playCard}
                    hand={this.props.hand}
                    hp={this.props.hp}
                    maxHp={this.props.maxHp}
                    mana={this.props.mana}
                    manaRegen={this.props.manaRegen}
                    statusEffects={this.props.statusEffects}
                    artifacts={this.props.artifacts}
                    name={this.props.name}
                    image={this.props.image}
                    surrender={this.props.surrender}
                    endTurn={this.props.endTurn}
                />
                <History
                    history={this.props.history}
                    playerName={this.props.name}
                    enemyName={this.props.enemyName}
                />
                <WinScreen
                    cardRewards={this.props.cardRewards}
                    chooseCard={this.props.chooseCard}
                    canDisplay={this.props.isGameWon}
                />
                <LoseScreen
                    retire={this.props.retire}
                    canDisplay={this.props.isGameLost}
                    stats={this.props.stats}
                />
            </div>
        );
    }
}

export default PlayScreen;
