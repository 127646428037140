import React from 'react';
import Card from './Card';

class Hand extends React.Component
{
    isPlayable(card)
    {
        if (this.props.type === 'enemy') {
            if (card.unplayable !== null) {
                return false;
            }
            let mana = this.props.mana;
            if (card.cost.blue !== undefined && mana.blue < card.cost.blue) {
                return false;
            }
            if (card.cost.green !== undefined && mana.green < card.cost.green) {
                return false;
            }
            if (card.cost.red !== undefined && mana.red < card.cost.red) {
                return false;
            }
            return true;
        }
    }

    render() {
        let startingDeg = 0;
        let i = 0;
        let length = this.props.hand.length;
        if (length < 6) {
            i = 15;
            startingDeg = (length - 1) * 7.5 * (-1);
        } else {
            startingDeg = -30;
            i = 60 / (length - 1);
        }
        return (
            <div className="hand-area">
                <div className={"hand " + (this.props.type === 'enemy' ? 'hand--enemy' : '')}>
                    {this.props.hand.map((card, handPos) => {
                        let canPlay = this.isPlayable(card);
                        let transform = {
                            transform: "translateX(-50%) rotate(" + (startingDeg) + "deg) translateY(-50px)"
                        };
                        if (this.props.type === 'enemy') {
                            transform.transform = "translateX(-50%) rotate(" + (startingDeg) + "deg) translateY(10%) scale(0.5) rotateY(180deg)";
                        }
                        if (card.aboutToPlay === true) {
                            transform.transform = "translateY(100px) translateX(-50%) rotateY(0)";
                            transform.animation = "playCard 0.5s ease-out 0s 1, fade 0.2s ease-out 0.8s 1";
                        }
                        startingDeg += i;
                        return (
                            <Card
                                styles={transform}
                                id={card.id}
                                key={card.uid}
                                uid={card.uid}
                                name={card.name}
                                image={card.image}
                                description={card.description}
                                tier={card.tier}
                                cost={card.cost}
                                goldCost={card.goldCost}
                                playCard={this.props.playCard}
                                addUnplayableClass={!canPlay}
                                noBorder={this.props.type === 'enemy'}
                                aboutToPlay={card.aboutToPlay}
                                showBackFace={this.props.type === 'enemy'}
                            />
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default Hand;
