import React from 'react';
import Card from './Card';
import Overlay from './Overlay';

class Decklist extends React.Component
{
    getOverlayClasses()
    {
        let className = "cards-overlay";

        if (this.props.overlayClasses !== undefined) {
            className += (" " + this.props.overlayClasses);
        }

        return className;
    }

    render()
    {
        return (
            <Overlay
                overlayClasses={this.getOverlayClasses()}
                title={this.props.title}
                display={this.props.display}
                closeOverlay={this.props.closeOverlay}
                name={this.props.name ?? 'decklist'}
                >
                <div className="cards">
                    {this.props.deck.map((card, handPos) => {
                        let cardJsx =
                            <Card
                                id={card.id}
                                key={card.uid}
                                uid={card.uid}
                                name={card.name}
                                image={card.image}
                                description={card.description}
                                tier={card.tier}
                                cost={card.cost}
                                goldCost={card.goldCost}
                                playCard={() => {}}
                                addUnplayableClass={false} />

                        if (this.props.cta && this.props.action) {
                            return (
                                <div class="card-with-cta">
                                    {cardJsx}
                                    <button class="card-cta" onClick={() => this.props.action(card.uid)}>{this.props.cta}</button>
                                </div>
                            );
                        }
                        return cardJsx;
                    })}
                </div>
            </Overlay>
        );
    }
}

export default Decklist;
