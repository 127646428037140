import React from 'react';
import Overlay from './Overlay';

class BookChoices extends React.Component
{
    constructor(props) {
        super();
        this.state = {
            page: 0
        };
    }

    setPage(pageNum)
    {
        this.setState({
            page: pageNum
        });
    }

    getTotalPages()
    {
        return Math.ceil(this.props.choices.length / 2);
    }

    render()
    {
        let choices = this.props.choices.slice(this.state.page * 2, (this.state.page * 2) + 2);

        let choicesJsx = choices.map(function (choiceData) {
            return <div className="l-one-half book__chapter">
                <h3 className="book__chapter-title">{choiceData.chapterName}</h3>
                <p className="book__chapter-description">{choiceData.description}</p>
                <button className="book__choose" onClick={() => this.props.encounterAction(this.props.encounterActionName, choiceData.id)}>Read</button>
            </div>;
        }.bind(this));

        let backButton = this.state.page === 0
            ? <></>
            : <a onClick={() => this.setPage(this.state.page - 1)} className="book__page-back"></a>

        let forwardButton = this.state.page === this.getTotalPages() - 1
            ? <></>
            : <a onClick={() => this.setPage(this.state.page + 1)} className="book__page-forward"></a>

        return (
            <div>
                <Overlay
                    overlayClasses="book-overlay overlay--small"
                    title={this.props.title}
                    display={this.props.display}
                    closeOverlay={this.props.closeOverlay}
                    name='book'
                    >
                        <div className="book">
                            <p className="book__intro">{this.props.bookIntro}</p>
                            <div className="grid grid--flex grid--justify-start book__book">
                                {choicesJsx}
                            </div>
                            {backButton}
                            {forwardButton}
                        </div>

                        {(this.props.postChoiceMessage !== null && this.props.postChoiceMessage !== undefined) ?
                            <Overlay
                                overlayClasses="message-overlay overlay--small"
                                title={this.props.title}
                                display={true}
                                text={this.props.postChoiceMessage}
                                name='bookMessage'
                                actions={
                                    [
                                        {
                                            "action": this.props.endEncounter,
                                            "text": "Leave"
                                        }
                                    ]
                                }
                                showClose={false}
                            />
                            : ''
                        }
                </Overlay>
            </div>
        );
    }
}

export default BookChoices;
