import React from 'react';
import Card from './Card';

class ChestScreen extends React.Component
{
    constructor(props)
    {
        super();
        this.chooseCard = this.chooseCard.bind(this);
    }

    chooseCard(uid)
    {
        this.props.encounterAction('chooseCard', uid);
    }

    render() {
        return (
            <div className="chest-screen">
                <h1 className="results__title">Treasure Chest!</h1>
                <p className="results__text">Please choose a card to add to your deck.</p>
                <div className="results__cards">
                    {this.props.cardChoices.map((card, handPos) => {
                        return (
                            <Card
                                id={card.id}
                                key={card.uid}
                                uid={card.uid}
                                name={card.name}
                                image={card.image}
                                description={card.description}
                                tier={card.tier}
                                cost={card.cost}
                                goldCost={card.goldCost}
                                playCard={this.chooseCard}
                                addUnplayableClass={false}
                            />
                        )
                    })}
                </div>
                <button className="action action--secondary" onClick={() => this.props.endEncounter()}>
                    <span>No thanks</span>
                </button>
            </div>
        );
    }
}

export default ChestScreen;
