import AbstractStatusEffect from "./AbstractStatusEffect";

class BleedStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Bleed';
        this.image = '/images/new/status/bleed.png';
        this.soundEffect = '/sounds/effects/bleed.mp3';
        this.isDebuff = true;
    }

    triggerStartPlayerTurn(context)
    {
        this.activate(context);
    }

    activate(context)
    {
        if (context.player === this.player) {
            if (this.val > 0) {
                let invulnerableStatusEffect = context.player.getStatusEffect('invulnerable');
                if (invulnerableStatusEffect.val > 0) {
                    invulnerableStatusEffect.pulse();
                    return;
                }

                let currentHp = context.player.hp;

                let newHp = Math.max(0, currentHp - this.val);
                context.player.hp = newHp;
                this.changeVal(this.val - 1, context);
                this.pulse();
                this.playSoundEffect(context);
                context.game.battle.history.write(this.player.name + ' bleeds out for ' + (this.val + 1) + ' damage', this.image);
            } else {
                this.changeVal(0, context);
            }
        }
    }

    generateTooltip()
    {
        return 'At the start of player\'s next turn, take ' + this.val + ' damage. Value decreases by 1 each turn.';
    }
}

export default BleedStatusEffect;
