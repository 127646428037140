import AbstractEffect from "./AbstractEffect";
import EffectFactory from "./EffectFactory";

class FutureEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let futureStatusEffect = context.player.getStatusEffect('future', {});
            let data = [...this.data];
            data.forEach(futureEffect => {
                futureEffect.historySourceImage = context.historySourceImage;
                if (futureEffect.name === undefined) {
                    if (context.card !== undefined) {
                        futureEffect.name = context.card.name;
                    } else {
                        futureEffect.name = 'Future Effect';
                    }
                }
            });
            futureStatusEffect.addEffects(data);

            console.log(futureStatusEffect);

            context.battle.history.write(context.player.name + ' sets up an effect for the future', context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription()
    {
        let description = [];
        let data = [...this.data];

        data.forEach(function(futureEffect) {
            if (futureEffect.descriptionOverride !== undefined) {
                description.push(futureEffect.descriptionOverride);
                return;
            }
            let innerDescription = 'In ' + futureEffect.turns + ' ' + this.pluralise('turn', futureEffect.turns) + ', ';
            let innerDescriptionSentences = [];
            futureEffect.effects = Object.keys(futureEffect.effects).forEach((key, pos) => {
                let effect = EffectFactory.createEffect(key, futureEffect.effects[key]);
                let effectDescription = effect.generateDescriptionFromTemplate();
                if (effectDescription.charAt(effectDescription.length - 1) === '.') {
                    effectDescription = effectDescription.slice(0, effectDescription.length - 1);
                }
                innerDescriptionSentences.push(effectDescription.charAt(0).toLowerCase() + effectDescription.slice(1));
            });
            description.push(innerDescription + this.joinAsList(innerDescriptionSentences) + '.');
        }.bind(this));

        return description.join(' ');
    }
}

export default FutureEffect;
