import AbstractEffect from "./AbstractEffect";

class WeakenEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let weakenStatusEffect = context.enemy.getStatusEffect('weaken');
            let immuneStatusEffect = context.enemy.getStatusEffect('immune');
            if (immuneStatusEffect.val > 0) {
                immuneStatusEffect.pulse();
                return;
            }
            weakenStatusEffect.changeVal(Math.max(0, this.getVal(context) + weakenStatusEffect.val), context);
            context.weakenVal = this.getVal(context);

            context.battle.history.write(context.enemy.name + ' is now weakened for ' + weakenStatusEffect.val + ' turns', context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/weaken.wav');
        }
    }

    generateDescription()
    {
        return 'Weaken opponent for ' + parseInt(this.data) + ' ' + this.pluralise('turn', parseInt(this.data)) + '.';
    }
}

export default WeakenEffect;
