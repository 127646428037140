import React from 'react';

class LoseScreen extends React.Component
{
    getFavouriteCardName()
    {
        let data = this.props.getDeckData()[this.props.stats.favouriteCard];
        if (data === undefined) {
            return '';
        }
        return data.name;
    }

    getClassNames() {
        let classNames = "results results--lose";
        if (this.props.canDisplay) {
            classNames += ' results--display';
        }
        return classNames
    }

    render() {
        return (
            <div className={this.getClassNames()}>
                <h2 className="results__title">You Lost...</h2>
                <p className="results__text">Click here to return to the main screen and start your journey again.</p>
                <ul className="results__stats">
                    <li>Total Score: {this.props.stats.totalScore}</li>
                    <li>Total Red Mana Spent: {this.props.stats.totalRedManaSpent}</li>
                    <li>Total Green Mana Spent: {this.props.stats.totalGreenManaSpent}</li>
                    <li>Total Blue Mana Spent: {this.props.stats.totalBlueManaSpent}</li>
                    <li>Total Damage Done: {this.props.stats.totalDamageDone}</li>
                    <li>Total Damage Received: {this.props.stats.totalDamageReceived}</li>
                    <li>Total Shields Added: {this.props.stats.totalShieldsAdded}</li>
                    <li>Total Damage Mitigated with Shields: {this.props.stats.totalDamageMitigated}</li>
                    <li>Shield Effectiveness: {this.props.stats.shieldEffectiveness}</li>
                    <li>Total Healing Done: {this.props.stats.totalHealing}</li>
                    <li>Total Enemies Killed: {this.props.stats.enemiesDefeated}</li>
                    <li>Average Turns Taken to defeat Enemy: {this.props.stats.averageTurnsForKill}</li>
                    <li>Highest Damage Done in a single attack: {this.props.stats.highestDamageDealt}</li>
                    <li>Total Gold Spent: {this.props.stats.goldSpent}</li>
                    <li>Favourite Card: {this.props.stats.favouriteCardName}</li>
                </ul>
                <button onClick={() => this.props.retire()}>Back to Main Menu</button>
            </div>
        );
    }
}

export default LoseScreen;
