import AbstractEncounter from "./AbstractEncounter";

class TurnstileEncounter extends AbstractEncounter
{
    constructor()
    {
        super();
        this.name = 'Map';
        this.image = '/images/new/encounters/map.png';
        this.ctas = [
            'follow-sign',
            'leave'
        ];
    }

    startEncounter(game)
    {
        game.shuffleCurrentLocationEncounters();
        game.endEncounter(this.encounterId);
        game.triggerSoundEffect('/sounds/effects/map.wav');
    }

    beforeEndEncounter()
    {

    }
}

export default TurnstileEncounter;
