import AbstractValue from "./AbstractValue";
import ValueFactory from "./ValueFactory";

class ArrayValue extends AbstractValue
{
    constructor(data)
    {
        super(data);
        this.val = data.val.map(value => ValueFactory.createValueFromJson(value));
    }

    calculate(context)
    {
        return this.val.map(v => v.calculate(context));
    }
}

export default ArrayValue;
