import AbstractEffect from "./AbstractEffect";

class DoubleNextRedEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let doubleNextRedStatusEffect = context.player.getStatusEffect('doubleNextRed');
            doubleNextRedStatusEffect.changeVal(this.getVal() + doubleNextRedStatusEffect.val, context);
            context.doubleNextRedVal = this.getVal();

            context.battle.history.write(context.player.name + ' plays the next red card ' + this.getVal() + ' times', context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription()
    {
        return 'Double the effect of the next red card you play this turn.';
    }
}

export default DoubleNextRedEffect;
