import React from 'react';
import Overlay from './Overlay';

class Changelog extends React.Component
{
    render()
    {
        return (
            <Overlay
                overlayClasses="changelog-overlay"
                title="Changelog"
                display={this.props.display}
                closeOverlay={this.props.closeOverlay}
                name='changelog'
                >
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.7 (23 Jul 2023)</h3>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Fixed issue where ending a battle wouldn't remove it from the list of available encounters.</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.6 (23 Jul 2023)</h3>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Fixed issue with pickrate analytics on prod.</li>
                        <li>Moved to stricter semver protocol as I have a dependency which throws a tantrum otherwise</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.5.1 (23 Jul 2023)</h3>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Fixed issue with claiming cards after defeating enemy</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.5 (23 Jul 2023)</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>Pickrate analytics now collected to help with balance changes</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.4 (20 Jul 2023)</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>My Data link on the homepage
                            <ul>
                                <li>Ability to export save data</li>
                                <li>Ability to import save data</li>
                                <li>Ability to reset all data</li>
                            </ul>
                        </li>
                        <li>Roadmap screen (underneath the changelog)</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Changed</h4>
                    <ul className="changelog__list changelog__list--changed">
                        <li>Made some improvements to the data storage so that save files between different versions should be a lot more compatible.</li>
                        <li>Encrypted the save files so you can't just edit the data however you want.</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.3.2 (14 Jul 2023)</h3>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Fixed issue where having Bear Claw and Unstable Bomb would deal 6x the amount of damage intended.</li>
                        <li>Fixed issue where Bezerker's Spear was giving 3 less armour at the start of a battle than intended.</li>
                        <li>'Highest Damage Dealt' stat now only includes damage the player does to an enemy (and not what an enemy deals to themselves)</li>
                        <li>'Total Damage Dealt' was sometimes concatenating damage instead of adding.</li>
                        <li>Fixed issue where Bezerker's Spear was sometimes triggering Blue Dragon's Egg</li>
                        <li>Fix History Log entry when Blue Dragon's Egg activates</li>
                        <li>Fixed some small issues in the History log where the image wasn't correct.</li>
                        <li>Being invulnerable will now stop bleed and poison effects from triggering, and any artifacts which modify damage.</li>
                        <li>History no longer records players as bleeding every turn.</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.3.1 (7 Jul 2023)</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>Extra Cheats for gaining/setting XP</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Card rewards for defeating enemies are now properly shuffled</li>
                        <li>Fixed an issue where card rewards might have only generated 2 cards.</li>
                        <li>Fixed an issue where achievements could be gained after they'd already been gained.</li>
                        <li>Fixed an issue where achievements could be gained irrelevant to their triggers.</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.3 (23 May 2023)</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>New XP Progression System
                            <ul>
                                <li>Certain cards will now be unlocked until you increase in levels. Increase your XP by killing monsters, unlocking achievements and winning games.</li>
                                <li>XP will be recorded offline in a cookie</li>
                            </ul>
                        </li>
                        <li>Compendium
                            <ul>
                                <li>Cards section</li>
                                <li>Enemies section</li>
                                <li>Artifacts section</li>
                                <li>Locked and unlocked are displayed accordingly.</li>
                            </ul>
                        </li>
                        <li>Achievements
                            <ul>
                                <li>You can now earn achievements to progress your XP</li>
                                <li>New Achievement: Knight in Shining Armour</li>
                                <li>New Achievement: Refined Engine</li>
                                <li>New Achievement: Common as Muck</li>
                                <li>New Achievement: Collector</li>
                                <li>New Achievement: Hoarder</li>
                                <li>New Achievement: Skin of your Teeth</li>
                                <li>New Achievement: Not What it Seems</li>
                                <li>New Achievement: Quick Finish</li>
                                <li>New Achievement: Vampiric Energy</li>
                                <li>New Achievement: Thesaurophobia</li>
                                <li>New Achievement: Untouchable</li>
                            </ul>
                        </li>
                        <li>Card Rarities
                            <ul>
                                <li>Card Rarities added. Rare cards will be more likely to be handed out during boss fights. They also cost more at shops.</li>
                                <li>New Rarity: Common</li>
                                <li>New Rarity: Uncommon</li>
                                <li>New Rarity: Rare</li>
                            </ul>
                        </li>
                        <li>Stats
                            <ul>
                                <li>Historic stats tracked in Achievements page</li>
                                <li>New Stat: Total Blue Mana Spent</li>
                                <li>New Stat: Total Green Mana Spent</li>
                                <li>New Stat: Total Red Mana Spent</li>
                                <li>New Stat: Total Damage Done</li>
                                <li>New Stat: Total Damage Received</li>
                                <li>New Stat: Total Damage Mitigated</li>
                                <li>New Stat: Total Shields Added</li>
                                <li>New Stat: Shield Effectiveness</li>
                                <li>New Stat: Highest Damage Dealt</li>
                                <li>New Stat: Average Turns for Kill</li>
                                <li>New Stat: Gold Spent</li>
                                <li>New Stat: Enemies Defeated</li>
                                <li>New Stat: Total Healing</li>
                                <li>New Stat: Favourite Card Name</li>
                            </ul>
                        </li>
                        <li>Error logging - causing the game to crash should send the developer a detailed error report</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Changed</h4>
                    <ul className="changelog__list changelog__list--changed">
                        <li>UI of tabs (in the Shop and Compendium) slightly improved.</li>
                        <li>Tooltips of enemy effects and artifacts greatly improved.</li>
                        <li>Green Mana Boost cost decreased from 4 to 2 blue mana.</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Blue Elemental now plays nice with Green Eye.</li>
                        <li>Fixed Shield of Hope.</li>
                        <li>Invulnerable now prevents Bleed.</li>
                        <li>Fixed several React JS warnings.</li>
                        <li>Faith Healer now stops Regen from having any effect.</li>
                        <li>Phoenix Feather's healing now rounds to an integer value.</li>
                        <li>Decaying Skeleton's effect now rounds to an integer value.</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.2 (22 Feb 2023)</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>New Artifacts:
                            <ul>
                                <li>{"Bezerker's Spear"}</li>
                                <li>{"Blue Dragon's Egg"}</li>
                                <li>Buzzsaw</li>
                                <li>Chainbreaker</li>
                                <li>Cursed Statue</li>
                                <li>Faulty Watch</li>
                                <li>Fertilizer</li>
                                <li>Flickering Candle</li>
                                <li>Green Eye</li>
                                <li>Hearty Booze</li>
                                <li>{"Hunter's Heart"}</li>
                                <li>Library Card</li>
                                <li>Love Letter</li>
                                <li>{"Lucky Rabbit's Foot"}</li>
                                <li>Magic Toadstool</li>
                                <li>Multi-coloured Spices</li>
                                <li>Mystery Bag</li>
                                <li>Odd Trinket</li>
                                <li>Premium Bond</li>
                                <li>Skull Ring</li>
                                <li>Small Mana Potion</li>
                                <li>Small Trophy</li>
                                <li>Snail Armour</li>
                                <li>Tough Nut</li>
                                <li>Translation Guide</li>
                                <li>Unstable Bomb</li>
                                <li>Wonderous Box</li>
                            </ul>
                        </li>
                        <li>New Cards:
                            <ul>
                                <li>Armour Caddy</li>
                                <li>Backpack Hurl</li>
                                <li>Brittle Shield</li>
                                <li>Emerald Staff</li>
                                <li>Energy Storage</li>
                                <li>Fairy Armour</li>
                                <li>Finishing Blow</li>
                                <li>Heavy Bandages</li>
                                <li>Hemorrhage</li>
                                <li>Laser Rifle</li>
                                <li>Lightning Prediction</li>
                                <li>Magical Chest</li>
                                <li>Metal Splinters</li>
                                <li>Raining Fire</li>
                                <li>Reserve Squad</li>
                                <li>Rune of Damage</li>
                                <li>Rune of Healing</li>
                                <li>Rune of Power</li>
                                <li>Rune of Protection</li>
                                <li>Sentient Dagger</li>
                                <li>Shield Cleaver</li>
                                <li>Smooth Sides</li>
                                <li>Soul Snatcher</li>
                                <li>Spectral Blade</li>
                                <li>Tap Mana Vein</li>
                            </ul>
                        </li>
                        <li>New Effects:
                            <ul>
                                <li>Mana Smoothing - This will redistribute your mana evenly across all colours.</li>
                                <li>Permanent effects - cards like Soul Snatcher and Sentient Dagger will alter themsevles permanently for the remainder of the run</li>
                                <li>On-kill effects - cards like Spectral Blade and Emerald Staff will grant additional boons if used to deliver the final blow to an enemy</li>
                                <li>Temporary cards - cards which are removed completely from the deck once played or discarded.</li>
                                <li>Click-effect artifacts - artifacts like Premium Bond have an activatable effect when clicked.</li>
                            </ul>
                        </li>
                        <li>Cheats Bar (but how to open it???)</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Changed</h4>
                    <ul className="changelog__list changelog__list--changed">
                        <li>History Panel slightly improved with added imagery of what caused an effect - with indentations to help show how different effects trigger off of each other. Also, lots more effects are now recorded - and anything to do with mana will now show a visual instead of just text.</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Fixed Goblin King tooltip to reflect he deals bleed with every attack.</li>
                        <li>Updated Health Stone tooltip to reflect that you will gain both max health and health.</li>
                        <li>Added description popovers to explain the following keywords: Temporary, Burn, Immune, Negate</li>
                        <li>Many more effects are now able to happen multiple times.</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.1</h3>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Fix game errors when playing a Future Effect card would cause the game to freeze.</li>
                        <li>Stop players from purchasing "General Ware" items from the same shop multiple times (even though it's greyed out).</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.3.0</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>New Artifacts:
                            <ul>
                                <li>Chalice of Blood</li>
                                <li>{"Magician's Hat"}</li>
                                <li>Shield of Hope</li>
                                <li>Leather Purse</li>
                                <li>Poisoned Apple</li>
                                <li>Pink Lotus</li>
                                <li>Supression Net</li>
                                <li>Polished Sword</li>
                                <li>Friendship Bracelet</li>
                                <li>Antidote</li>
                                <li>Serrated Dagger</li>
                                <li>Spiked Mace</li>
                                <li>Rock Pile</li>
                                <li>Vampire Fang</li>
                                <li>Trident</li>
                                <li>Bounty Poster</li>
                                <li>Double Edged Sword</li>
                                <li>Cloak of Concealment</li>
                                <li>{"Witch Doctor's Staff"}</li>
                                <li>Short Fuse</li>
                                <li>Anti-Magic Bracers</li>
                            </ul>
                        </li>
                        <li>New Cards:
                            <ul>
                                <li>Aurora</li>
                                <li>Insult to Injury</li>
                                <li>Nourishing Greens</li>
                                <li>Stoke the Flames</li>
                            </ul>
                        </li>
                        <li>New Effects:
                            <ul>
                                <li>Burn - when a player suffers burn, the next card they play causes them to discard a card at random from their hand (and the burn amount decreases).</li>
                                <li>Propogate - each debuff that the enemy suffers is increased by one.</li>
                            </ul>
                        </li>
                        <li>New Enemies:
                            <ul>
                                <li>Mimic</li>
                                <li>Ratling</li>
                                <li>Medusa</li>
                                <li>Banshee</li>
                                <li>Goblin Bomber</li>
                                <li>Witch</li>
                                <li>Earth Elemental</li>
                                <li>Air Elemental</li>
                                <li>Fire Elemental</li>
                                <li>Gorgoth</li>
                            </ul>
                        </li>
                        <li>New Encounters:
                            <ul>
                                <li>Grimoire - choose from a few options - each with a benefit and a curse...</li>
                                <li>Ancient Tome - choose a power-up</li>
                            </ul>
                        </li>
                        <li>Starting Modes! You can now choose the class of hero you want to play as (although only one is available right now).</li>
                        <li>New Starting Modes for the future:
                            <ul>
                                <li>Priest</li>
                                <li>Mage</li>
                                <li>Huntress</li>
                                <li>Warlock</li>
                                <li>Paladin</li>
                                <li>Tinker</li>
                                <li>Thief</li>
                                <li>Monk</li>
                                <li>Gambler</li>
                            </ul>
                        </li>
                        <li>Expired or disabled artifacts will now be grayed out.</li>
                        <li>"Activated" artifacts can show a bright glow around them.</li>
                        <li>Added a History/Turnlog Pane! This can be opened by the tab on the far right hand side when battling an enemy.</li>
                        <li>Beating the game gives a nice overlay.</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Changed</h4>
                    <ul className="changelog__list changelog__list--changed">
                        <li>Balance Changes - Cards
                            <ul>
                                <li>Most cards have an updated description of their effects.</li>
                                <li>Several cards purchase cost have been changed.</li>
                                <li>Relapse: Mana cost changed from 9 red to 2 red.</li>
                                <li>Mana Loan: Mana cost changed from 8 blue to 5 blue.</li>
                                <li>{"Devil's Bargain: Mana cost changed from 5 red, 3 green and 3 blue to 4 red, 2 green and 1 blue."}</li>
                                <li>Energize: Player draw now increased to 2 cards from 1 card</li>
                            </ul>
                        </li>
                        <li>Balance Changes - Enemies
                            <ul>
                                <li>{"Frogling now has 3 Infects in it's deck."}</li>
                                <li>Treefolk hp decreased from 40 to 30. Reward decreased from 50 gold to 20 gold.</li>
                                <li>{"Doppelganger has a new effect - it copies all cards in the player's deck, and adds it to their own. It's image is now a copy of the player's image (with different colouring). It starts with several new cards in it's deck. Reward cost decreased from 30 to 25. Health increased from 30 to 40."}</li>
                                <li>Ooze - Reward decreased from 25 to 15.</li>
                                <li>Spider Hive - Reward decreased from 40 to 30.</li>
                                <li>Goblin Big Boss - Reward increased from 40 to 50.</li>
                                <li>Crystal Golem - Reward decreased from 40 to 25. Health decreased from 50 to 40.</li>
                                <li>Mage in Training - Reward decreased from 40 to 25. Health decreased from 50 to 40.</li>
                                <li>Wight - Reward decreased from 40 to 25. Health decreased from 50 to 40.</li>
                                <li>Water Elemental - Reward decreased from 40 to 30.</li>
                                <li>Demon Bard - Reward decreased from 40 to 30.</li>
                                <li>Vampire - Reward decreased from 40 to 30.</li>
                                <li>Demon Warlock - Reward decreased from 40 to 30.</li>
                                <li>Farsight Wizard - Reward decreased from 40 to 35. Health increased from 50 to 60.</li>
                                <li>Dragon - Reward decreased from 40 to 35. Health increased from 50 to 60.</li>
                                <li>Master Manipulator - Reward decreased from 40 to 35. Health increased from 50 to 60.</li>
                                <li>Master Sorcerer - Reward increased from 40 to 75. Health increased from 50 to 100.</li>
                                <li>Imp - Health increased from 50 to 75.</li>
                                <li>Plague Zombie - Health increased from 50 to 75.</li>
                                <li>Arms Dealer - Health increased from 50 to 75.</li>
                                <li>Faith Healer - Health increased from 50 to 85.</li>
                                <li>Giant - Health increased from 50 to 85.</li>
                                <li>Arms Dealer - Health increased from 50 to 85.</li>
                                <li>Goblin Warrior - Health increased from 50 to 85.</li>
                                <li>Elf Assassin - Health increased from 50 to 100. Reward increased from 40 to 50.</li>
                                <li>Dragon Knight - Health increased from 50 to 100. Reward increased from 40 to 50.</li>
                                <li>Minotaur - Health increased from 50 to 100. Reward increased from 40 to 50.</li>
                                <li>Demon Warrior - Health increased from 50 to 100. Reward increased from 40 to 50.</li>
                                <li>Void Monster - Health increased from 50 to 200. Reward increased from 40 to 200. Added several new cards to the deck. Card draw per turn increased from 3 to 5. Mana regen increased from 3 of each colour to 5 of each colour.</li>
                            </ul>
                        </li>
                        <li>Shop "Max Health increase" option increased from 5 to 10 max health.</li>
                        <li>Two more enemies per location are added to the list of encounters.</li>
                        <li>One fewer chest encounter per location.</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Card descriptions should now accurately portray the effect of the card (even for cards which have been temporarily altered by other cards).</li>
                        <li>Cards with more than one mana colour in their cost now have a gradient background.</li>
                        <li>{"Devil's Bargain now heals you if you finish the battle whilst it's effect is still active."}</li>
                        <li>Goblin King now has correct title.</li>
                        <li>Demon Bard now visibly gains Invulnerability and Future Effect</li>
                        <li>Farsight Wizard now visibly gives you Negate</li>
                        <li>Fixed typo on Master Sorcerer</li>
                        <li>Fixed Arms Dealer effect tooltip</li>
                        <li>Fixed Void Monster effect tooltip</li>
                        <li>{"Fixed issue where clicking 'No Thanks' on a chest would break."}</li>
                        <li>Background state now saved correctly in-browser. This means permanent effects like animation speed or sound volume.</li>
                        <li>Improved the visibility of the decklist when looking at the deck overlay</li>
                        <li>{"Beating the game will now wipe the game storage (so you can't continue the game)."}</li>
                        <li>Changelog UI now indents for better visibility.</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Removed</h4>
                    <ul className="changelog__list changelog__list--removed">
                        <li>{"Removed 'card level' ui at the bottom of cards."}</li>
                        <li>{"Properly removed cards from the deck for the 'Knight' starting mode (including Minor Repairs, and any Module cards)."}</li>
                        <li>{"Removed 'Upgrade card' option from Shop."}</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.2.0</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>{"The 'Lose Game' overlay has been updated."}</li>
                        <li>Ability to alter the animation speed in the Settings bar</li>
                        <li>{"Added a 'Help Sheet' popup in the top bar."}</li>
                        <li>Numerous UI updates, especially to modals/popup screens</li>
                        <li>Added numerous sound effects to the game - including clicks, playing cards, battle won, battle lost, and lots of different status effects</li>
                        <li>New cards:
                            <ul>
                                <li>Nature Attack</li>
                                <li>Small mana potion</li>
                                <li>Fortify</li>
                                <li>Gaping Wound</li>
                                <li>Infect</li>
                                <li>Ambush</li>
                                <li>Venomous Spike</li>
                                <li>Meditation</li>
                                <li>Energize</li>
                                <li>Invigorate</li>
                                <li>Mana Sap</li>
                                <li>Full Focus</li>
                                <li>It Comes</li>
                            </ul>
                        </li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Changed</h4>
                    <ul className="changelog__list changelog__list--changed">
                        <li>Demon Warlock now gains the Invulnerable status effect, instead of 100% dodging damage</li>
                        <li>Balance Changes - Monsters (mostly removing shield cards):
                            <ul>
                                <li>Goblin Hermit now has one big shield card instead of two</li>
                                <li>Goblin Scout now has one big shield card instead of two</li>
                                <li>Treefolk now has one big shield card instead of two</li>
                                <li>Doppelganger now has one big shield card instead of two</li>
                                <li>Goblin now has two big shield cards instead of three</li>
                                <li>Mage In Training now has one big shield card instead of three</li>
                                <li>Wight now has one big shield card instead of two</li>
                                <li>Vampire now has two big shield cards instead of three</li>
                                <li>Demon Warlock now has two big shield cards instead of three</li>
                                <li>Farsight Wizard now has three big shield cards instead of four</li>
                                <li>Master Manipulator now has two big shield cards instead of three</li>
                                <li>Master Sorceror now has two big shield cards instead of three</li>
                                <li>Imp now has one big shield card instead of two</li>
                                <li>Faith Healer now has two big shield cards instead of three</li>
                                <li>Goblin Warrior now has two big shield cards instead of three</li>
                                <li>Elf Assassin now has one big shield card instead of two</li>
                                <li>Demon Warrior now has two big shield cards instead of three</li>
                                <li>Void Monster now has two big shield cards instead of three</li>
                            </ul>
                        </li>
                        <li>Balance Changes - Cards
                            <ul>
                                <li>Explosion: Damage increased from 10 to 15. Cost reduced from 6 red to 5 red.</li>
                                <li>Brass Knuckles: Damage increased from 8 to 10.</li>
                                <li>Quick Strikes: Cost increased from 5 red to 6 red.</li>
                                <li>Painful Tuition: Damage decreased from 5 to 3.</li>
                                <li>Cruel Tutor: Muster amount decreased from 3 to 2.</li>
                                <li>War Front: Cost decreased from 5 red to 4 red.</li>
                                <li>Reinforced Shielding: Reinforce amount increased from 1 to 2.</li>
                                <li>Weaken: Weaken amount increased from 1 to 2. Cost increased from 1 red to 1 red and 1 blue.</li>
                                <li>Minor Repairs: Heal amount decreased from 3 to 2.</li>
                                <li>Experimentation: Self-damage amount increased from 4 to 6.</li>
                                <li>Blacksmith: Cost increased from 4 red and 4 green to 5 red and 5 green.</li>
                                <li>Energy Shield: Shield amount decreased from 4 to 3. Cost reduced from 4 green to 3 green.</li>
                                <li>Killer Robot Chassis: Removed from the deck for now.</li>
                                <li>Heal Module: Removed from the deck for now.</li>
                                <li>Damage Module: Removed from the deck for now.</li>
                                <li>Crit Damage Module: Removed from the deck for now.</li>
                                <li>Recycle Module: Removed from the deck for now.</li>
                                <li>Armour Module: Removed from the deck for now.</li>
                                <li>Kyanite Steel: Reinforce amount increased from 4 to 6. Cost increased from 9 green to 10 green.</li>
                                <li>Neutralise: Cost decreased from 10 blue to 4 blue.</li>
                                <li>Focus Red: Cost decreased from 4 blue and 4 red to 3 blue and 3 red.</li>
                                <li>Focus Green: Cost decreased from 4 blue and 4 green to 3 blue and 3 green.</li>
                                <li>Focus Blue: Cost decreased from 8 blue to 6 blue.</li>
                                <li>Minor Upgrades: Removed from the deck for now.</li>
                                <li>Layered Defense: Shield gain decreased from 3 to 2.</li>
                                <li>Finesse: Multi-attack decreased from 3 times to 2 times.</li>
                                <li>Charge Cannon: Damage decreased from 30 to 25.</li>
                            </ul>
                        </li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Music will now reset whenever it changes (i.e. you don't go into a battle halfway through the battle music)</li>
                        <li>Savefiles store the game version on them</li>
                        <li>Balgruf Font properly bundled in the codebase</li>
                        <li>Demon Warlock text now references Demon Warlock</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.1.2</h3>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>The game state will now properly save and won't freeze up the next time you try to reload the page (you'll need to clear your local storage first though)</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.1.1</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>New enemy card: Small Shield</li>
                        <li>New enemy card: Mega Smash</li>
                        <li>Small animations:
                            <ul>
                                <li>Artifacts, Status Effects and Enemy Passive Effects will now pulse when activated</li>
                                <li>When a player is hurt, there is a hurt animation</li>
                                <li>Damage and healing numbers will now appear on the screen</li>
                            </ul>
                        </li>
                        <li>New logo on homepage</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Changed</h4>
                    <ul className="changelog__list changelog__list--changed">
                        <li>Many enemies will now shield a lot less than they used to, making them easier to kill</li>
                        <li>All tier 3 enemies have an improved deck and a mana adjustment</li>
                        <li>Goblin Big Boss red mana regen decreased from 5 to 3</li>
                        <li>Goblin Big Boss card draw decreased from 3 to 2</li>
                        <li>Keep mechanic has changed: instead of being able to use it multiple times a turn, instead it doesn't get discarded at the end of the turn.</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>The Giant will now use Mega Smash instead of Basic Attack</li>
                        <li>The Arms Dealer, Goblin Warrior and Void Monster now have usable decks</li>
                        <li>The Minotaur tooltip was referencing the Legion Commander, an old enemy</li>
                        <li>The Goblin Big Boss has 50 HP now, instead of 5</li>
                        <li>Fixed Crystal Golem tooltip and image</li>
                        <li>Non-enemy encounters for zones 2 and 3 properly shuffled in</li>
                        <li>Stop sometimes going to zone 3 after zone 1</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.1.0</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>Large amount of new cards</li>
                        <li>New card effects and statuses</li>
                        <li>New enemies</li>
                        <li>Enemy's turns are animated</li>
                        <li>Added music</li>
                        <li>Added saved games</li>
                        <li>Added Compendium (placeholder)</li>
                        <li>Added Donation button</li>
                        <li>Added Decklist Viewer for discard, exile and enemy deck</li>
                        <li>Added Artifact bar</li>
                        <li>Added Location Progress bar</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Changed</h4>
                    <ul className="changelog__list changelog__list--changed">
                        <li>Ui: Complete and total overhaul of graphics, artwork and ui.</li>
                        <li>Player discards their hand after their turn.</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Only tier 1 or greater cards show in the shop</li>
                        <li>Thorn damage now hits shield first</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Removed</h4>
                    <ul className="changelog__list changelog__list--removed">
                        <li>Several cards removed from the game</li>
                        <li>Turn History Screen</li>
                        <li>Surrender button (though I should probably add this back in)</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.0.3</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>Added artifacts</li>
                        <li>New Artifact: Health Stone</li>
                        <li>New Artifact: Nature Stone</li>
                        <li>New Artifact: Lightning Rod</li>
                        <li>New Artifact: Nanobot Medics</li>
                        <li>New Artifact: Phoenix Ashes</li>
                        <li>New Artifact: Mana Machine</li>
                        <li>New Artifact: Amulet of Fury</li>
                        <li>New Artifact: Lucky Coin</li>
                        <li>New Artifact: Warrior's Gauntlet</li>
                        <li>Added artifacts to the shop</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Changed</h4>
                    <ul className="changelog__list changelog__list--changed">
                        <li>Code: HUGE overhaul of code. Complete refactor to use ES6 classes. This will help to be a lot more flexible when adding in new features.</li>
                        <li>Code: Improved trigger handling to allow multiple triggers to be registered for the same event with different priorities.</li>
                        <li>UI: Cards (and artifacts) too expensive for purchase will show in red.</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Fixed: No longer able to buy multiple of the same card (and artifact) in the shop.</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.0.2</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>ChangeLog screen</li>
                        <li>New Encounter: Shop - with the ability to spend gold to buy cards, delete cards, heal, increase maximum health.</li>
                        <li>Added stats tracking throughout the game</li>
                        <li>New Stat: Total Damage Done</li>
                        <li>New Stat: Total Damage Received</li>
                        <li>New Stat: Total Shields Added</li>
                        <li>New Stat: Total Damage Mitigated Through Shields</li>
                        <li>New Stat: Shield Effectiveness (Damage Mitigated / Shields Added)</li>
                        <li>New Stat: Total Healing Done</li>
                        <li>New Stat: Total Enemies Killed</li>
                        <li>New Stat: Average Number of Turns to kill an enemy</li>
                        <li>New Stat: Total Red Mana Spent</li>
                        <li>New Stat: Total Green Mana Spent</li>
                        <li>New Stat: Total Blue Mana Spent</li>
                        <li>New Stat: Favourite Card (most played card)</li>
                        <li>New Stat: Highest Damage done in a single attack</li>
                        <li>New Stat: Gold Spend</li>
                        <li>New Stat: Total Score (based on enemies defeated, shield effectiveness, avg turns for a kill and highest damage in a turn)</li>
                        <li>Deck Viewer in Top Bar</li>
                        <li>UI: New Shield UI for increased visibility of shields</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Changed</h4>
                    <ul className="changelog__list changelog__list--changed">
                        <li>UI: Improved positioning of status effects icons</li>
                    </ul>
                    <h4 className="changelog__release-subtitle">Fixed</h4>
                    <ul className="changelog__list changelog__list--fixed">
                        <li>Fixed: Starting a new game will correctly reset the deck.</li>
                    </ul>
                </div>
                <div className="changelog__release">
                    <h3 className="changelog__release-version">v0.0.1</h3>
                    <h4 className="changelog__release-subtitle">Added</h4>
                    <ul className="changelog__list changelog__list--added">
                        <li>Initial release!</li>
                        <li>Base play game mode</li>
                        <li>New Location: Enchanted Forest</li>
                        <li>New Enemy: Village Guard</li>
                        <li>New Enemy: Barbarian</li>
                        <li>New Enemy: Medic</li>
                        <li>New Enemy: Venomous Snake</li>
                        <li>New Enemy: Powerful Mage</li>
                        <li>New Enemy: Warrior</li>
                        <li>New Enemy: Leaking Fuse</li>
                        <li>New Enemy: Dryad</li>
                        <li>New Enemy: Army General</li>
                        <li>New Enemy: Blue Mage</li>
                        <li>New Encounter: Turnstile</li>
                        <li>New Encounter: Mysterious Chest</li>
                        <li>Credits Screen</li>
                        <li>Top Bar for stats</li>
                    </ul>
                </div>
            </Overlay>
        );
    }
}

export default Changelog;
