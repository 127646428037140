import AbstractEffect from "./AbstractEffect";

class MusterEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let musterStatusEffect = context.player.getStatusEffect('muster');
            musterStatusEffect.changeVal(this.getVal(context) + musterStatusEffect.val, context);
            context.musterVal = this.getVal(context);

            context.game?.battle?.history.write(context.player.name + ' musters ' + this.getVal(context), context.historySourceImage);

            context.game.triggerSoundEffect('/sounds/effects/muster.mp3');
        }
    }

    generateDescription()
    {
        return 'Muster ' + parseInt(this.data) + '.';
    }
}

export default MusterEffect;
