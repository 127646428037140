export const locationsData = {
    "1": {
        "name": "The Enchanted Forest",
        "id": 1,
        "backgroundMusicPlay": "/sounds/music/play-forest.mp3",
        "backgroundMusicBattle": "/sounds/music/battle-forest.mp3",
        "backgroundImage": "/images/new/locations/forest.png",
        "encounterData": {
            "enemies": {
                "1": 3,
                "2": 4,
                "3": 3,
                "boss": 3,
            },
            "turnstiles": 2,
            "shops": 2,
            "chests": 2,
            "blessings": 1,
            "pandoras": 1
        }
    },
    "2": {
        "id": 2,
        "name": "Scorched Wastes",
        "backgroundMusicPlay": "/sounds/music/play-plains.mp3",
        "backgroundMusicBattle": "/sounds/music/battle-plains.wav",
        "backgroundImage": "/images/new/locations/plains.png",
        "encounterData": {
            "enemies": {
                "4": 3,
                "5": 4,
                "6": 3,
                "boss": 6,
            },
            "turnstiles": 2,
            "shops": 2,
            "chests": 2,
            "blessings": 1,
            "pandoras": 1
        }
    },
    "3": {
        "id": 3,
        "name": "The War Zone",
        "backgroundMusicPlay": "/sounds/music/play-warship.mp3",
        "backgroundMusicBattle": "/sounds/music/battle-warship.mp3",
        "backgroundImage": "/images/new/locations/battlefield.png",
        "encounterData": {
            "enemies": {
                "7": 3,
                "8": 4,
                "9": 3,
                "boss": 9
            },
            "turnstiles": 2,
            "shops": 2,
            "chests": 2,
            "blessings": 1,
            "pandoras": 1
        }
    }
}
