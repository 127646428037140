import React from 'react';

class OverlayTabContent extends React.Component
{
    getContentClasses() {
        let classes = "overlay-tab__content";
        if (this.props.isActive === true) {
            classes += " overlay-tab__content--show";
        }
        if (this.props.withBorder === true) {
            classes += " overlay-tab__content--with-border";
        }
        return classes;
    }

    render() {
        return (
            <div className={this.getContentClasses()}>
                {this.props.children}
            </div>
        );
    }
}

export default OverlayTabContent;
