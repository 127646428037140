import AbstractEffect from "./AbstractEffect";

class OppDiscardEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let immuneStatusEffect = context.enemy.getStatusEffect('immune');
            if (immuneStatusEffect.val > 0) {
                immuneStatusEffect.pulse();
                return;
            }
            context.enemy.discard(this.getVal(context));

            context.battle.history.write(context.enemy.name + ' discards ' + this.getVal(context), context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription()
    {
        return 'The enemy discards ' + parseInt(this.data) + ' ' + this.pluralise('card', parseInt(this.data)) + '.';
    }
}

export default OppDiscardEffect;
