import AbstractEffect from "./AbstractEffect";

class CritAttackEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            context.originalDamageVal = this.getVal(context);
            context.damageVal = this.getVal(context);
            context.source = {
                effect: this,
                card: context.card,
                player: context.player
            }

            let newContext = {...context};
            newContext.enemy = context.player;
            if (context.enemy === undefined) {
                context.enemy = context.player.isPlayer() ? context.battle.enemy : context.battle.player;
            }
            newContext.player = context.enemy;

            context.player.takeDamage(newContext, true);
            if (context.damageReceived < 1) {
                context.battle.game.triggerSoundEffect('/sounds/effects/hit_shield.mp3');
            } else if (context.damageReceived < 6) {
                context.battle.game.triggerSoundEffect('/sounds/effects/light_impact.mp3');
            } else if (context.damageReceived < 11) {
                context.battle.game.triggerSoundEffect('/sounds/effects/medium_impact.mp3');
            } else {
                context.battle.game.triggerSoundEffect('/sounds/effects/heavy_impact.mp3');
            }
        }
    }

    generateDescription(context = {})
    {
        let descriptionEnding = '.';
        if (this.data?.context?.multiEffect !== undefined && this.data.context.multiEffect > 1) {
            descriptionEnding = ', ' + this.data.context.multiEffect + ' times.';
        } else if (context.multiEffect !== undefined && context.multiEffect > 1) {
            descriptionEnding = ', ' + context.multiEffect + ' times.';
        }
        return 'Attack for ' + parseInt(this.getVal()) + ' critical damage' + descriptionEnding;
    }
}

export default CritAttackEffect;
