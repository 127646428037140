import {parse, stringify, toJSON, fromJSON} from 'flatted';
import { compare, compareVersions, satisfies } from 'compare-versions';
import Artifact from './Artifacts/Artifact';
import AndCondition from './Artifacts/Conditions/AndCondition';
import EqualToCondition from './Artifacts/Conditions/EqualToCondition';
import GreaterThanOrEqualToCondition from './Artifacts/Conditions/GreaterThanOrEqualToCondition';
import IncludesCondition from './Artifacts/Conditions/IncludesCondition';
import OrCondition from './Artifacts/Conditions/OrCondition';
import ArrayValue from './Artifacts/Values/ArrayValue';
import AttributeValue from './Artifacts/Values/AttributeValue';
import CalcValue from './Artifacts/Values/CalcValue';
import ConditionValue from './Artifacts/Values/ConditionValue';
import FlatValue from './Artifacts/Values/FlatValue';
import PercentageValue from './Artifacts/Values/PercentageValue';
import RandomValue from './Artifacts/Values/RandomValue';
import Battle from './Battle';
import Card from './Card';
import Deck from './Deck';
import AddToDeckEffect from './Effects/AddToDeckEffect';
import AlterEffect from './Effects/AlterEffect';
import ArmourEffect from './Effects/ArmourEffect';
import AttackEffect from './Effects/AttackEffect';
import BleedEffect from './Effects/BleedEffect';
import ClearEffect from './Effects/ClearEffect';
import CritAttackEffect from './Effects/CritAttackEffect';
import CritDamageEffect from './Effects/CritDamageEffect';
import DamageEffect from './Effects/DamageEffect';
import DiscardEffect from './Effects/DiscardEffect';
import DoubleNextGreenEffect from './Effects/DoubleNextGreenEffect';
import DoubleNextRedEffect from './Effects/DoubleNextRedEffect';
import DrawEffect from './Effects/DrawEffect';
import ExileAllEffect from './Effects/ExileAllEffect';
import FocusEffect from './Effects/FocusEffect';
import FutureEffect from './Effects/FutureEffect';
import HealEffect from './Effects/HealEffect';
import ImmuneEffect from './Effects/ImmuneEffect';
import InvulnerableEffect from './Effects/InvulnerableEffect';
import ManaGainEffect from './Effects/ManaGainEffect';
import ManaSmoothingEffect from './Effects/ManaSmoothingEffect';
import MusterEffect from './Effects/MusterEffect';
import NegateEffect from './Effects/NegateEffect';
import OppDiscardEffect from './Effects/OppDiscardEffect';
import OppDrawEffect from './Effects/OppDrawEffect';
import OppManaGainEffect from './Effects/OppManaGainEffect';
import PoisonEffect from './Effects/PoisonEffect';
import RegenEffect from './Effects/RegenEffect';
import ReinforceEffect from './Effects/ReinforceEffect';
import ResurrectionEffect from './Effects/ResurrectionEffect';
import ShieldEffect from './Effects/ShieldEffect';
import StrengthenEffect from './Effects/StrengthenEffect';
import ThornEffect from './Effects/ThornEffect';
import TimewalkEffect from './Effects/TimewalkEffect';
import TriggersEffect from './Effects/TriggersEffect';
import WeakenEffect from './Effects/WeakenEffect';
import BlessingEncounter from './Encounters/BlessingEncounter';
import ChestEncounter from './Encounters/ChestEncounter';
import EnemyEncounter from './Encounters/EnemyEncounter';
import NextLocationEncounter from './Encounters/NextLocationEncounter';
import ShopEncounter from './Encounters/ShopEncounter';
import TurnstileEncounter from './Encounters/TurnstileEncounter';
import MimicEncounter from './Encounters/MimicEncounter';
import Game from './Game';
import History from './History';
import Player from './Player';
import Stats from './Stats';
import ArmourStatusEffect from './StatusEffects/ArmourStatusEffect';
import BleedStatusEffect from './StatusEffects/BleedStatusEffect';
import DoubleNextGreenStatusEffect from './StatusEffects/DoubleNextGreenStatusEffect';
import DoubleNextRedStatusEffect from './StatusEffects/DoubleNextRedStatusEffect';
import FutureStatusEffect from './StatusEffects/FutureStatusEffect';
import ImmuneStatusEffect from './StatusEffects/ImmuneStatusEffect';
import InvulnerableStatusEffect from './StatusEffects/InvulnerableStatusEffect';
import ManaMasteryStatusEffect from './StatusEffects/ManaMasteryStatusEffect';
import MusterStatusEffect from './StatusEffects/MusterStatusEffect';
import NegateStatusEffect from './StatusEffects/NegateStatusEffect';
import PoisonStatusEffect from './StatusEffects/PoisonStatusEffect';
import RegenStatusEffect from './StatusEffects/RegenStatusEffect';
import ReinforceStatusEffect from './StatusEffects/ReinforceStatusEffect';
import ShieldStatusEffect from './StatusEffects/ShieldStatusEffect';
import StrengthenStatusEffect from './StatusEffects/StrengthenStatusEffect';
import ThornStatusEffect from './StatusEffects/ThornStatusEffect';
import WeakenStatusEffect from './StatusEffects/WeakenStatusEffect';
import TriggerManager from './TriggerManager';
import PandoraEncounter from './Encounters/PandoraEncounter';
import BurnEffect from './Effects/BurnEffect';
import BurnStatusEffect from './StatusEffects/BurnStatusEffect';
import PropogateDebuffsEffect from './Effects/PropogateDebuffsEffect';
import GreaterThanCondition from './Artifacts/Conditions/GreaterThanCondition';
import Achievements from './Achievements';
import Achievement from './Achievement';
import Experience from './Experience';

class Unpacker
{
    constructor()
    {
        this.types = {
            'game': Game.prototype,
            'battle': Battle.prototype,
            'player': Player.prototype,
            'deck': Deck.prototype,
            'card': Card.prototype,
            'stats': Stats.prototype,
            'enemyEncounter': EnemyEncounter.prototype,
            'chestEncounter': ChestEncounter.prototype,
            'shopEncounter': ShopEncounter.prototype,
            'blessingEncounter': BlessingEncounter.prototype,
            'turnstileEncounter': TurnstileEncounter.prototype,
            'nextLocationEncounter': NextLocationEncounter.prototype,
            'mimicEncounter': MimicEncounter.prototype,
            'pandoraEncounter': PandoraEncounter.prototype,
            'triggerManager': TriggerManager.prototype,
            'history': History.prototype,
            'equalToCondition': EqualToCondition.prototype,
            'greaterThanOrEqualToCondition': GreaterThanOrEqualToCondition.prototype,
            'greaterThanCondition': GreaterThanCondition.prototype,
            'orCondition': OrCondition.prototype,
            'andCondition': AndCondition.prototype,
            'includesCondition': IncludesCondition.prototype,
            'percentageValue': PercentageValue.prototype,
            'attributeValue': AttributeValue.prototype,
            'calcValue': CalcValue.prototype,
            'flatValue': FlatValue.prototype,
            'randomValue': RandomValue.prototype,
            'arrayValue': ArrayValue.prototype,
            'conditionValue': ConditionValue.prototype,
            'addToDeckEffect': AddToDeckEffect.prototype,
            'alterEffect': AlterEffect.prototype,
            'armourEffect': ArmourEffect.prototype,
            'attackEffect': AttackEffect.prototype,
            'bleedEffect': BleedEffect.prototype,
            'burnEffect': BurnEffect.prototype,
            'clearEffect': ClearEffect.prototype,
            'critAttackEffect': CritAttackEffect.prototype,
            'critDamageEffect': CritDamageEffect.prototype,
            'damageEffect': DamageEffect.prototype,
            'discardEffect': DiscardEffect.prototype,
            'doubleNextGreenEffect': DoubleNextGreenEffect.prototype,
            'doubleNextRedEffect': DoubleNextRedEffect.prototype,
            'drawEffect': DrawEffect.prototype,
            'exileAllEffect': ExileAllEffect.prototype,
            'focusEffect': FocusEffect.prototype,
            'futureEffect': FutureEffect.prototype,
            'healEffect': HealEffect.prototype,
            'immuneEffect': ImmuneEffect.prototype,
            'invulnerableEffect': InvulnerableEffect.prototype,
            'manaGainEffect': ManaGainEffect.prototype,
            'manaSmoothingEffect': ManaSmoothingEffect.prototype,
            'musterEffect': MusterEffect.prototype,
            'negateEffect': NegateEffect.prototype,
            'oppDiscardEffect': OppDiscardEffect.prototype,
            'oppDrawEffect': OppDrawEffect.prototype,
            'oppManaGainEffect': OppManaGainEffect.prototype,
            'poisonEffect': PoisonEffect.prototype,
            'propogateDebuffsEffect': PropogateDebuffsEffect.prototype,
            'regenEffect': RegenEffect.prototype,
            'reinforceEffect': ReinforceEffect.prototype,
            'resurrectionEffect': ResurrectionEffect.prototype,
            'shieldEffect': ShieldEffect.prototype,
            'strengthenEffect': StrengthenEffect.prototype,
            'thornEffect': ThornEffect.prototype,
            'timewalkEffect': TimewalkEffect.prototype,
            'triggersEffect': TriggersEffect.prototype,
            'weakenEffect': WeakenEffect.prototype,
            'armourStatusEffect': ArmourStatusEffect.prototype,
            'bleedStatusEffect': BleedStatusEffect.prototype,
            'burnStatusEffect': BurnStatusEffect.prototype,
            'doubleNextGreenStatusEffect': DoubleNextGreenStatusEffect.prototype,
            'doubleNextRedStatusEffect': DoubleNextRedStatusEffect.prototype,
            'futureStatusEffect': FutureStatusEffect.prototype,
            'immuneStatusEffect': ImmuneStatusEffect.prototype,
            'invulnerableStatusEffect': InvulnerableStatusEffect.prototype,
            'manaMasteryStatusEffect': ManaMasteryStatusEffect.prototype,
            'musterStatusEffect': MusterStatusEffect.prototype,
            'negateStatusEffect': NegateStatusEffect.prototype,
            'poisonStatusEffect': PoisonStatusEffect.prototype,
            'regenStatusEffect': RegenStatusEffect.prototype,
            'reinforceStatusEffect': ReinforceStatusEffect.prototype,
            'shieldStatusEffect': ShieldStatusEffect.prototype,
            'strengthenStatusEffect': StrengthenStatusEffect.prototype,
            'thornStatusEffect': ThornStatusEffect.prototype,
            'weakenStatusEffect': WeakenStatusEffect.prototype,
            'artifact': Artifact.prototype,
            'achievements': Achievements.prototype,
            'achievement': Achievement.prototype,
            'experience': Experience.prototype,
            'array': Array.prototype,
            'object': Object.prototype,
        };
    }

    serialize(object, version) {
        let types = this.types;
        if (typeof object === "object" && object !== null) {
            let idx = Object.values(types).findIndex(function (element) {
                return element.constructor.name === object.constructor.name;
            });
            if (idx == -1) throw "type  '" + object.constructor.name + "' not initialized";
            if (Object.values(types)[idx] === Object.prototype || Object.values(types)[idx] === Array.prototype) {
                return object;
            }
            if (compareVersions(version, '0.3.4') >= 0) {
                object.__typeIdx = Object.keys(types)[idx];
            } else {
                object.__typeIdx = idx;
            }
        }
        return stringify(object, function(key, value) {
            if (typeof value === "object" && value !== null) {
                let idx = Object.values(types).findIndex(function (element) {
                    return element.isPrototypeOf(value);
                });
                if (idx == -1) throw "type  '" + value.constructor.name + "' not initialized";
                if (Object.values(types)[idx] === Object.prototype || Object.values(types)[idx] === Array.prototype) {
                    return value;
                }
                if (compareVersions(version, '0.3.4') >= 0) {
                    value.__typeIdx = Object.keys(types)[idx];
                } else {
                    value.__typeIdx = idx;
                }
            }
            return value;
        });
    }

    deserialize(jstring, version) {
        let types = this.types;
        let result = parse(jstring, function(key, value) {
            if (typeof value === "object" && value !== null && value.__typeIdx !== undefined) {
                let proto;
                if (compareVersions(version, '0.3.4') >= 0) {
                    proto = types[value.__typeIdx];
                } else {
                    proto = Object.values(types)[value.__typeIdx];
                }
                value.__proto__ = proto;
                return value;
            }
            return value;
        });
        if (result.__typeIdx !== undefined) {
            let proto;
            if (compareVersions(version, '0.3.4') >= 0) {
                proto = types[result.__typeIdx];
            } else {
                proto = Object.values(types)[result.__typeIdx];
            }
            result.__proto__ = proto;
        }
        return result;
    }
}

export default Unpacker;
