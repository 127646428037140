import React from 'react';

class CreditsScreen extends React.Component {
    render() {
        return (
            <div className="credits-screen">
                <h1 className="credits-title">Credits</h1>
                <dl className="credits">
                    <dt>Lead Backend Engineer</dt>
                    <dd>David Fox</dd>
                    <dt>Lead Frontend Engineer</dt>
                    <dd>David Fox</dd>
                    <dt>Lead Designer</dt>
                    <dd>David Fox</dd>
                    <dt>QA Tester</dt>
                    <dd>David Fox</dd>
                    <dt>Project Management</dt>
                    <dd>David Fox</dd>
                    <dt>Lead Sound Engineer</dt>
                    <dd>David Fox</dd>
                    <dt>Concept</dt>
                    <dd>David Fox</dd>
                    <dt>Art Direction</dt>
                    <dd>David Fox</dd>
                    <dt>Score</dt>
                    <dd>David Fox</dd>
                </dl>
                <div className="actions">
                    <button className="action action--primary" onClick={() => this.props.backToHome()}>
                        <span>Back to Home Page</span>
                    </button>
                </div>
            </div>
        );
    }
}

export default CreditsScreen;
