import AbstractCondition from "./AbstractCondition";

class OrCondition extends AbstractCondition
{
    isMet(context)
    {
        return this.vals.reduce((accum, value) => accum || value.calculate(context), true);
    }
}

export default OrCondition;
