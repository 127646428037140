import AbstractEffect from "./AbstractEffect";

class InvulnerableEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let invulnerableStatusEffect = context.player.getStatusEffect('invulnerable');
            invulnerableStatusEffect.changeVal(this.getVal(context) + invulnerableStatusEffect.val, context);
            context.invulnerableVal = this.getVal(context);

            context.battle.history.write(context.player.name + ' is invulnerable for ' + this.getVal(context) + ' turns.', context.historySourceImage);
            context.battle.game.triggerSoundEffect('/sounds/effects/invulnerable.wav');
        }
    }

    generateDescription()
    {
        return 'Become invulnerable to damage for ' + parseInt(this.data) + ' ' + this.pluralise('turn', parseInt(this.data)) + '.';
    }
}

export default InvulnerableEffect;
