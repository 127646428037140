import AbstractEffect from "./AbstractEffect";

class BurnEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let immuneStatusEffect = context.enemy.getStatusEffect('immune');
            if (immuneStatusEffect.val > 0) {
                immuneStatusEffect.pulse();
                return;
            }

            let burnStatusEffect = context.enemy.getStatusEffect('burn');
            burnStatusEffect.changeVal(this.getVal(context) + burnStatusEffect.val, context);
            context.burnVal = this.getVal(context);

            context.battle.history.write(context.enemy.name + ' is burned for ' + this.getVal(context) + '.', context.historySourceImage);
            context.battle.game.triggerSoundEffect('/sounds/effects/burn.mp3');
        }
    }

    generateDescription()
    {
        return 'Inflict Burn ' + parseInt(this.data) + '.';
    }
}

export default BurnEffect;
