import React from 'react';
import Overlay from './Overlay';

class ConfirmModal extends React.Component
{
    render()
    {
        return (
            <Overlay
                overlayClasses="confirm"
                showClose={false}
                display={this.props.display}
                >
                <p className="confirm__text">{this.props.prompt ?? "Are you sure?"}</p>
                <div className="confirm-buttons grid grid--flex grid--flush-bottom">
                    <div className="l-one-half">
                        <button className="confirm-yes" onClick={() => this.props.proceedCallback(this.props.name)}>Yes</button>
                    </div>
                    <div className="l-one-half">
                        <button className="confirm-no" onClick={() => this.props.cancelCallback(this.props.name)}>No</button>
                    </div>
                </div>
            </Overlay>
        );
    }
}

export default ConfirmModal;
