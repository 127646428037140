import AbstractEffect from "./AbstractEffect";

import { shuffle } from '../Helper';
import EffectFactory from "./EffectFactory";
import { artifactsData } from "../data/artifacts";

class AlterEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let qty = this.data.qty;
            this.context = context;

            if (this.context.alterMultiply !== undefined) {
                qty *= this.context.alterMultiply;
            }

            for (let i = 0; i < qty; i++) {
                let cards = this.findCards(this.data.target, this.data.perm);
                this.performActions(this.data.action, cards, this.context);
            }

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    findCards(target, isPerm)
    {
        let cards = [];
        switch (target) {
            case 'self':
                cards.push(this.context.card);
                break;

            case 'randomHand':
                cards.push(shuffle(this.context.player.deck.hand)[0]);
                break;

            case 'randomDeck':
                let deckCards = this.context.player.deck.hand.concat(this.context.player.deck.discard).concat(this.context.player.deck.deck);
                cards.push(shuffle(deckCards)[0]);
                break;

            case 'allHand':
                cards = this.context.player.deck.hand;
                break;
        }

        if (isPerm) {
            let cloneRefs = [];

            cards.forEach(function (card) {
                if (card.cloneRef) {
                    cloneRefs.push(card.cloneRef);
                }
            });

            cards = [...cards, ...cloneRefs];
        }

        return cards;
    }

    performActions(actions, cards, context)
    {
        cards.forEach(card => {
            Object.keys(actions).forEach(key => {
                switch (key) {
                    case 'costIncrease':
                        let costIncreases = {};
                        let costDecreases = {};
                        if (actions[key].red !== undefined) {
                            let oldCostRed = card.cost.red;
                            card.cost.red += actions[key].red;
                            card.cost.red = Math.max(0, card.cost.red);
                            if (card.cost.red > oldCostRed) {
                                costIncreases.red = card.cost.red - oldCostRed;
                            } else if (card.cost.red < oldCostRed) {
                                costDecreases.red = oldCostRed - card.cost.red;
                            }
                        }
                        if (actions[key].green !== undefined) {
                            let oldCostGreen = card.cost.green;
                            card.cost.green += actions[key].green;
                            card.cost.green = Math.max(0, card.cost.green);
                            if (card.cost.green > oldCostGreen) {
                                costIncreases.green = card.cost.green - oldCostGreen;
                            } else if (card.cost.green < oldCostGreen) {
                                costDecreases.green = oldCostGreen - card.cost.green;
                            }
                        }
                        if (actions[key].blue !== undefined) {
                            let oldCostBlue = card.cost.blue;
                            card.cost.blue += actions[key].blue;
                            card.cost.blue = Math.max(0, card.cost.blue);
                            if (card.cost.blue > oldCostBlue) {
                                costIncreases.blue = card.cost.blue - oldCostBlue;
                            } else if (card.cost.blue < oldCostBlue) {
                                costDecreases.blue = oldCostBlue - card.cost.blue;
                            }
                        }
                        if (actions[key].random !== undefined) {
                            if (actions[key].random > 0) {
                                let manaColours = ['red', 'blue', 'green'];
                                for (let i = 0; i < actions[key].random; i++) {
                                    let manaColour = shuffle(manaColours)[0];
                                    if (card.cost[manaColour] !== undefined) {
                                        card.cost[manaColour]++;
                                    } else {
                                        card.cost[manaColour] = 1;
                                    }

                                    if (costIncreases[manaColour] !== undefined) {
                                        costIncreases[manaColour]++;
                                    } else {
                                        costIncreases[manaColour] = 1;
                                    }
                                }
                            } else {
                                for (let i = 0; i < Math.abs(actions[key].random); i++) {
                                    let cardColours = shuffle(Object.keys(card.cost));
                                    if (cardColours.length > 0) {
                                        card.cost[cardColours[0]]--;
                                        if (costDecreases[cardColours[0]] !== undefined) {
                                            costDecreases[cardColours[0]]++;
                                        } else {
                                            costDecreases[cardColours[0]] = 1;
                                        }
                                    }
                                }
                            }
                        }

                        let costSentences = [];

                        if (Object.keys(costIncreases).length > 0) {
                            costSentences.push('increased by ' + Object.keys(costIncreases).reduce((accum, key) => accum + ('<' + key + '_mana>' + costIncreases[key] + '</' + key + '_mana>'), ''));
                        }

                        if (Object.keys(costDecreases).length > 0) {
                            costSentences.push('decreased by ' + Object.keys(costDecreases).reduce((accum, key) => accum + ('<' + key + '_mana>' + costDecreases[key] + '</' + key + '_mana>'), ''));
                        }

                        if (card.cloneRef !== undefined) {
                            context.game.battle.history.write(card.name + ' has its cost ' + costSentences.join(' and '), context.historySourceImage);
                        }
                        return;

                    case 'costOverride':
                        card.cost = actions[key];

                        // if (card.cloneRef !== undefined) {
                        //     context.game.battle.history.write(card.name + ' has it\'s cost changed to ' + Object.keys(card.cost).reduce((accum, key) => accum + '<' + key + '_mana>' + card.cost[key] + '</' + key + '_mana>', ''), context.historySourceImage);
                        // }
                        return;

                    case 'attackIncrease':
                        if (card.effects.attack === undefined) {
                            card.effects.attack = 0;
                        }
                        card.effects.attack += actions[key];
                        if (card.cloneRef !== undefined) {
                            context.game.battle.history.write(card.name + ' gains "Attack for ' + actions[key] + ' damage"', context.historySourceImage);
                        }
                        return;

                    case 'shieldDecrease':
                        if (card.effects.shield === undefined) {
                            return;
                        }
                        if (card.effects.shield <= actions[key]) {
                            card.effects.shield = 0;
                        } else {
                            card.effects.shield -= actions[key];
                        }
                        if (card.cloneRef !== undefined) {
                            context.game.battle.history.write(card.name + ' has it\'s shield amount reduced to ' + card.effects.shield, context.historySourceImage);
                        }
                        return;

                    case 'upgrade':
                        // todo
                        return;

                    case 'gainEffects':
                        Object.keys(actions[key]).forEach(effect => {
                            if (typeof actions[key][effect] !== 'number') {
                                if (typeof card.effects[effect] === 'number') {
                                    card.effects[effect] = actions[key][effect];
                                } else {
                                    card.effects[effect] = this.deepMerge(card.effects[effect], actions[key][effect]);
                                }
                                return;
                            }
                            if (card.effects[effect] === undefined) {
                                card.effects[effect] = 0;
                            }
                            if (typeof card.effects[effect] === 'object') {
                                if (card.effects[effect].valExtra === undefined) {
                                    card.effects[effect].valExtra = 0;
                                }
                                card.effects[effect].valExtra += actions[key][effect];
                            } else {
                                card.effects[effect] += actions[key][effect];
                            }
                        });
                        if (card.cloneRef !== undefined) {
                            context.game.battle.history.write(card.name + ' has it\'s effects updated', context.historySourceImage);
                        }
                        return;

                    default:
                        return;
                }
            });

            card.generateDescription();
        });
    }

    deepMerge(obj1, obj2)
    {
        let r = {};

        if (typeof obj1 !== "object") {
            obj1 = {
                "valExtra": obj1
            };
        }
        if (typeof obj2 !== "object") {
            obj2 = {
                "valExtra": obj2
            };
        }

        let intersections = Object.keys(obj1).filter(k => k in obj2);

        Object.assign(r, obj1, obj2);

        intersections.forEach(function(k) {
            if (typeof obj1[k] === 'number' && typeof obj2[k] === 'number') {
                r[k] = obj1[k] + obj2[k];
            } else {
                r[k] = this.deepMerge(obj1[k], obj2[k]);
            }
        }.bind(this));

        return r;
    }

    generateDescription()
    {
        let description = '';

        switch (this.data.target) {
            case 'randomHand':
                description += 'For a random card in your hand, ';
                break;

            case 'randomDeck':
                description += 'For a random card in your deck, ';
                break;

            case 'allHand':
                description += 'For each card in your hand, ';
                break;
        }

        let descriptionEffects = [];

        let costDescription;
        let upOrDown;
        let costs;
        let effectDescription;
        let effectDescriptions;

        let actions = this.data.action;

        Object.keys(actions).forEach(key => {
            switch (key) {
                case 'costIncrease':
                    costDescription = "that card's cost "
                    if (this.data.perm) {
                        costDescription += "permanently ";
                    }
                    costs = [];
                    descriptionEffects.push();
                    if (actions[key].red !== undefined) {
                        upOrDown = actions[key].red < 0 ? 'decreases' : 'increases';
                        costs.push(upOrDown + ' by ' + Math.abs(actions[key].red) + ' red mana');
                    }
                    if (actions[key].green !== undefined) {
                        upOrDown = actions[key].green < 0 ? 'decreases' : 'increases';
                        costs.push(upOrDown + ' by ' + Math.abs(actions[key].green) + ' green mana');
                    }
                    if (actions[key].blue !== undefined) {
                        upOrDown = actions[key].blue < 0 ? 'decreases' : 'increases';
                        costs.push(upOrDown + ' by ' + Math.abs(actions[key].blue) + ' blue mana');
                    }
                    if (actions[key].random !== undefined) {
                        upOrDown = actions[key].random < 0 ? 'decreases' : 'increases';
                        costs.push(upOrDown + ' by ' + Math.abs(actions[key].random) + ' random mana');
                    }

                    costDescription += this.joinAsList(costs);
                    descriptionEffects.push(costDescription);
                    break;

                case 'costOverride':
                    costDescription = "that card's cost ";
                    if (this.data.perm) {
                        costDescription += "permanently ";
                    }
                    costDescription += "becomes ";
                    costs = [];
                    descriptionEffects.push();
                    if (actions[key].red !== undefined) {
                        costs.push(actions[key].red + ' red mana');
                    }
                    if (actions[key].green !== undefined) {
                        costs.push(actions[key].green + ' green mana');
                    }
                    if (actions[key].blue !== undefined) {
                        costs.push(actions[key].blue + ' blue mana');
                    }

                    costDescription += this.joinAsList(costs);
                    descriptionEffects.push(costDescription);
                    break;

                case 'attackIncrease':
                    effectDescription = "that card's attack ";
                    if (this.data.perm) {
                        effectDescription += "permanently ";
                    }
                    effectDescription += "increases by " + actions[key];
                    descriptionEffects.push(effectDescription);
                    break;

                case 'shieldDecrease':
                    effectDescription = "that card's shield "
                    if (this.data.perm) {
                        effectDescription += "permanently ";
                    }
                    effectDescription += "decreases by " + actions[key];
                    descriptionEffects.push(effectDescription);
                    break;

                case 'upgrade':
                    effectDescription = "upgrade that card";
                    if (this.data.perm) {
                        effectDescription += "permanently ";
                    }
                    descriptionEffects.push(effectDescription);
                    break;

                case 'gainEffects':
                    effectDescription = "that card "
                    if (this.data.perm) {
                        effectDescription += "permanently ";
                    }
                    effectDescription += "gains \"";
                    effectDescriptions = [];

                    Object.keys(actions[key]).forEach(effect => {
                        let dummyEffect = EffectFactory.createEffect(effect, actions[key][effect]);
                        effectDescriptions.push(dummyEffect.generateDescriptionFromTemplate());
                    });

                    effectDescription += effectDescriptions.join(' ') + '"';
                    descriptionEffects.push(effectDescription);
                    break;
            }
        });

        let finalDescription = description + this.joinAsList(descriptionEffects) + '.';

        if (this.data.target === 'self') {
            finalDescription = finalDescription.replaceAll('that card', 'this card');
        }

        return finalDescription.charAt(0).toUpperCase() + finalDescription.slice(1);
    }
}

export default AlterEffect;
