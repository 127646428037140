import React from 'react';

import ShopSectionGeneral from './ShopSectionGeneral';
import ShopSectionCard from './ShopSectionCard';
import ShopSectionArtifact from './ShopSectionArtifact';

class ShopScreen extends React.Component {

    isTabActive(tab) {
        return this.props.shopTab === tab;
    }

    render() {
        return (
            <div className="shop-screen">
                <h1 className="shop__title">Travelling Merchant</h1>
                <p className="shop__text">The travelling merchant has many wares! Take a look and purchase if you wish.</p>
                <div className="shop">
                    <div className="shop-tab-links" key="tab-links">
                        <a
                            href="#"
                            key="general"
                            onClick={() => this.props.setShopTab('general')}
                            className={"shop-tab-link " + (this.isTabActive('general') ? 'shop-tab-link--active' : '')}
                        >
                            General Wares
                        </a>
                        <a
                            href="#"
                            key="card"
                            onClick={() => this.props.setShopTab('card')}
                            className={"shop-tab-link " + (this.isTabActive('card') ? 'shop-tab-link--active' : '')}
                        >
                            Purchase Cards
                        </a>
                        <a
                            href="#"
                            key="artfact"
                            onClick={() => this.props.setShopTab('artifact')}
                            className={"shop-tab-link " + (this.isTabActive('artifact') ? 'shop-tab-link--active' : '')}
                        >
                            Purchase Artifacts
                        </a>
                    </div>
                    <div className="shop__tabs" key="tabs">
                        <ShopSectionGeneral
                            encounterAction={this.props.encounterAction}
                            gold={this.props.gold}
                            deck={this.props.deck}
                            isActiveTab={this.props.shopTab === "general"}
                        />
                        <ShopSectionCard
                            cardsForPurchase={this.props.cardsForPurchase}
                            cardsPurchased={this.props.cardsPurchased}
                            encounterAction={this.props.encounterAction}
                            gold={this.props.gold}
                            isActiveTab={this.props.shopTab === "card"}
                            getCardCost={this.props.getCardCost}
                        />
                        <ShopSectionArtifact
                            artifactsForPurchase={this.props.artifactsForPurchase}
                            artifactsPurchased={this.props.artifactsPurchased}
                            encounterAction={this.props.encounterAction}
                            gold={this.props.gold}
                            isActiveTab={this.props.shopTab === "artifact"}
                        />
                    </div>
                </div>
                <button className="action action--secondary" onClick={() => this.props.endEncounter()}>
                    <span>Leave Shop</span>
                </button>
            </div>
        );
    }
}

export default ShopScreen;
