import React from 'react';
import Overlay from './Overlay';

class Achievements extends React.Component
{
    getAchievementClasses(achievement)
    {
        let className = "achievement";

        if (achievement.unlocked === false) {
            className += " achievement--locked";
        }

        return className;
    }
    render()
    {
        this.props.achievements.sort((a, b) => a.unlocked ? -1 : 1);

        return (
            <Overlay
                overlayClasses="achievements-overlay"
                title="Achievements"
                display={this.props.display}
                closeOverlay={this.props.closeOverlay}
                name='achievements'
                >
                <div className="grid grid--flex">
                    <div className="l-one-half">
                        <div className="experience-summary">
                            <img className="experience-summary__image" src="/images/new/heroes/generic.png"></img>
                            <h2 className="experience-summary__level">Level {this.props.experience.level}</h2>
                            <div className="experience-progress">
                                <div className="experience-progress__level experience-progress__level--current">
                                    <img className="experience-progress__image" src="/images/new/heroes/generic.png" />
                                    <span>{this.props.experience.level}</span>
                                </div>
                                <div className="experience-progress__progress-wrapper">
                                    <progress min="0" max="100" value={this.props.experience.percentProgress} className="experience-progress__progress"></progress>
                                    <span>{this.props.experience.xpRemaining} XP till level {this.props.experience.level + 1}</span>
                                </div>
                                <div className="experience-progress__level experience-progress__level--next">
                                    <img className="experience-progress__image" src="/images/new/heroes/generic.png" />
                                    <span>{this.props.experience.level + 1}</span>
                                </div>
                            </div>
                        </div>
                        <h3 className="achievements__subtitle">Stats</h3>
                        <dl className="stats__list">
                            <dt>Total Blue Mana Spent</dt>
                            <dd>{this.props.stats.totalBlueManaSpent}</dd>
                            <dt>Total Green Mana Spent</dt>
                            <dd>{this.props.stats.totalGreenManaSpent}</dd>
                            <dt>Total Red Mana Spent</dt>
                            <dd>{this.props.stats.totalRedManaSpent}</dd>
                            <dt>Total Damage Done</dt>
                            <dd>{this.props.stats.totalDamageDone}</dd>
                            <dt>Total Damage Received</dt>
                            <dd>{this.props.stats.totalDamageReceived}</dd>
                            <dt>Total Damage Mitigated</dt>
                            <dd>{this.props.stats.totalDamageMitigated}</dd>
                            <dt>Total Shields Added</dt>
                            <dd>{this.props.stats.totalShieldsAdded}</dd>
                            <dt>Shield Effectiveness</dt>
                            <dd>{this.props.stats.shieldEffectiveness}</dd>
                            <dt>Highest Damage Dealt</dt>
                            <dd>{this.props.stats.highestDamageDealt}</dd>
                            <dt>Average Turns for Kill</dt>
                            <dd>{this.props.stats.averageTurnsForKill}</dd>
                            <dt>Gold Spent</dt>
                            <dd>{this.props.stats.goldSpent}</dd>
                            <dt>Enemies Defeated</dt>
                            <dd>{this.props.stats.enemiesDefeated}</dd>
                            <dt>Total Healing</dt>
                            <dd>{this.props.stats.totalHealing}</dd>
                            <dt>Favourite Card Name</dt>
                            <dd>{this.props.stats.favouriteCardName}</dd>
                        </dl>
                    </div>
                    <div className="l-one-half">
                        <h3 className="achievements__subtitle">Achievements</h3>
                        <div className="grid grid--flex grid--achievements">
                            {this.props.achievements.map(function (achievement) {
                                return (
                                    <div className="l-one-half" key={achievement.id}>
                                        <div className={this.getAchievementClasses(achievement)}>
                                            {/* <img src={achievement.unlocked ? achievement.image : '/images/new/cards/placeholder.png'} className="achievement__image" /> */}
                                            <img src={achievement.image} className="achievement__image" />
                                            <h2 className="achievement__name">{achievement.name}</h2>
                                            <p className="achievement__description">{achievement.description}</p>
                                            {!achievement.unlocked
                                                ? (<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.1819 10.7027H6.00008C5.44781 10.7027 5.0001 11.1485 5.00009 11.7008C5.00005 13.3483 5 16.6772 5.00011 18.9189C5.00023 21.4317 8.88618 22 12 22C15.1139 22 19 21.4317 19 18.9189C19 16.6773 19 13.3483 19 11.7008C19 11.1485 18.5523 10.7027 18 10.7027H15.8182M8.1819 10.7027C8.1819 10.7027 8.18193 8.13514 8.1819 6.59459C8.18186 4.74571 9.70887 3 12 3C14.2912 3 15.8182 4.74571 15.8182 6.59459C15.8182 8.13514 15.8182 10.7027 15.8182 10.7027M8.1819 10.7027H15.8182" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13 16.6181V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V16.6181C10.6931 16.3434 10.5 15.9442 10.5 15.5C10.5 14.6716 11.1716 14 12 14C12.8284 14 13.5 14.6716 13.5 15.5C13.5 15.9442 13.3069 16.3434 13 16.6181Z" fill="#ffffff"/>
                                                </svg>)
                                                : ''
                                            }
                                        </div>
                                    </div>
                                )
                            }.bind(this))}
                        </div>
                    </div>
                </div>
            </Overlay>
        );
    }
}

export default Achievements;
