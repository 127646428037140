import AbstractStatusEffect from "./AbstractStatusEffect";

class ReinforceStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Reinforce';
        this.image = '/images/new/status/reinforce.png';
        this.isBuff = true;
    }

    triggerStartPlayerTurn(context)
    {
        if (context.player === this.player) {
            this.changeVal(Math.max(this.val - 1, 0), context);
        }
    }

    generateTooltip()
    {
        return 'Shield is not removed at the start of player\'s next ' + this.val + ' turns.';
    }
}

export default ReinforceStatusEffect;
