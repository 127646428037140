import AbstractEncounter from "./AbstractEncounter";
import Player from '../Player';

class EnemyEncounter extends AbstractEncounter
{
    constructor(enemyId)
    {
        super();
        this.screen = 'battle';
        this.enemy = Player.createEnemyFromId(enemyId);
        this.name = this.enemy.name;
        this.ctas = [
            'battle'
        ];
    }

    startEncounter(game)
    {
        game.startFight(this.enemy);
    }

    getEncounterState()
    {
        let state = super.getEncounterState();
        state.image = this.enemy.image;
        state.description = this.enemy.description;
        return state;
    }

    beforeEndEncounter()
    {

    }
}

export default EnemyEncounter;
