import AbstractEffect from "./AbstractEffect";

class BleedEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let bleedStatusEffect = context.enemy.getStatusEffect('bleed');
            let immuneStatusEffect = context.enemy.getStatusEffect('immune');
            if (immuneStatusEffect.val > 0) {
                immuneStatusEffect.pulse();
                return;
            }
            bleedStatusEffect.changeVal(Math.max(0, this.getVal(context) + bleedStatusEffect.val), context);
            context.bleedVal = this.getVal(context);

            context.battle.history.write(context.enemy.name + ' is bleeding for ' + bleedStatusEffect.val, context.historySourceImage);
        }

        context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
    }

    generateDescription(context = {})
    {
        let descriptionEnding = '.';
        if (this.data?.context?.multiEffect !== undefined && this.data.context.multiEffect > 1) {
            descriptionEnding = ', ' + this.data.context.multiEffect + ' times.';
        } else if (context.multiEffect !== undefined && context.multiEffect > 1) {
            descriptionEnding = ', ' + context.multiEffect + ' times.';
        }
        return 'Inflict ' + parseInt(this.getVal(context)) + ' bleed' + descriptionEnding;
    }
}

export default BleedEffect;
