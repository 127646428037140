import AbstractStatusEffect from "./AbstractStatusEffect";

class RegenStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Regen';
        this.image = '/images/new/status/regen.png';
        this.soundEffect = '/sounds/effects/heal.wav';
        this.isBuff = true;
    }

    triggerStartPlayerTurn(context)
    {
        if (context.player === this.player) {
            if (this.val > 0) {
                let maxHp = context.player.maxHp;
                let currentHp = context.player.hp;

                context.healVal = this.val;
                let newContext = {...context};
                newContext.player = context.enemy;
                newContext.enemy = context.player;

                context.battle.game.trigger('beforePlayerHeal', context);
                newContext.healVal = context.healVal;
                context.battle.game.trigger('beforeEnemyHeal', newContext);
                context.healVal = newContext.healVal;

                let newHp = Math.min(maxHp, currentHp + context.healVal);
                context.player.hp = newHp;
                this.pulse();
                this.playSoundEffect(context);
                context.game.battle.history.write(this.player.name + ' heals for ' + (this.val), this.image);
            }
        }
    }

    generateTooltip() {
        return 'At the start of player\'s turn, heal ' + this.val + ' HP.';
    }
}

export default RegenStatusEffect;
