import AbstractEffect from "./AbstractEffect";

class CritDamageEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            context.originalDamageVal = this.getVal(context);
            context.damageVal = this.getVal(context);
            context.source = {
                effect: this,
                card: context.card,
                player: context.player
            }

            context.player.takeDamage(context, true);
            if (context.player.isPlayer()) {
                context.battle.game.triggerSoundEffect('/sounds/effects/pain2.mp3');
            } else {
                context.battle.game.triggerSoundEffect('/sounds/effects/growl2.mp3');
            }
        }
    }

    generateDescription()
    {
        return 'Deal ' + parseInt(this.data) + ' critical damage to yourself.';
    }
}

export default CritDamageEffect;
