import { v4 as uuidv4 } from 'uuid';
import ValueFactory from '../Artifacts/Values/ValueFactory';

class AbstractEffect
{
    constructor(key, effectData)
    {
        this.key = key;
        this.data = JSON.parse(JSON.stringify(effectData));
        this.uid = uuidv4();
        this.soundEffectOnPlay = effectData.soundEffectOnPlay;
        this.descriptionTemplate = null;
        this.descriptionPriority = 1;

        if (typeof this.data === 'object' && this.data.descriptionTemplate !== undefined) {
            this.descriptionTemplate = this.data.descriptionTemplate;
            delete this.data.descriptionTemplate;
        }

        if (typeof this.data === 'object' && this.data.descriptionPriority !== undefined) {
            this.descriptionPriority = this.data.descriptionPriority;
            delete this.data.descriptionPriority;
        }
    }

    getVal(context)
    {
        return this.calculateValue(this.data, context);
    }

    calculateValue(data, context)
    {
        if (typeof data === 'number') {
            return data;
        }
        if (data.context !== undefined) {
            context = {...context, ...data.context};
        }
        try {
            let val = ValueFactory.createValueFromJson(data);
            return val.calculate(context);
        } catch (e) {
            console.error(e);
        }
        return data;
    }

    handle(context)
    {
        return context;
    }

    escapeVal(val = 'X')
    {
        return parseInt(this.data);
    }

    getFutureTooltip()
    {
        return this.parseValues(this.generateDescription());
    }

    generateDescription(context = {})
    {
        return this.key.charAt(0).toUpperCase() + this.key.slice(1) + ' :X.'
    }

    generateDescriptionFromTemplate(context = {})
    {
        let template = this.descriptionTemplate;
        if (template === null || template === undefined) {
            template = this.generateDescription(context);
        }
        return this.parseValues(template);
    }

    parseValues(text)
    {
        return text.replace(':X', this.escapeVal('X'));
    }

    pluralise(singular, qty)
    {
        if (qty === 1) {
            return singular;
        }

        return singular + 's';
    }

    joinAsList(array)
    {
        array = array.filter(e => e);
        let lastElement = array.pop();

        if (array.length === 0) {
            return lastElement;
        }

        return array.join(', ') + ' and ' + lastElement;
    }
}

export default AbstractEffect;
