export const modeData = {
    1: {
        "id": 1,
        "name": "Knight",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1
        },
        "cardDraw": 4,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [],
        "image": "/images/new/heroes/knight.png",
        "unlockLevel": 1
    },
    2: {
        "id": 2,
        "name": "Priest",
        "notes": "Uses healing, overhealing, etc",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1,
            "10": 1
        },
        "cardDraw": 3,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [
        ],
        "disabled": true,
        "image": "/images/new/heroes/priest.png"
    },
    3: {
        "id": 3,
        "name": "Mage",
        "notes": "different magical stances, one for each mana type?",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1,
            "10": 1
        },
        "cardDraw": 3,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [
        ],
        "disabled": true,
        "image": "/images/new/heroes/mage.png"
    },
    4: {
        "id": 4,
        "name": "Huntress",
        "notes": "Summons monsters to fight?",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1,
            "10": 1
        },
        "cardDraw": 3,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [
        ],
        "disabled": true,
        "image": "/images/new/heroes/huntress.png"
    },
    5: {
        "id": 5,
        "name": "Warlock",
        "notes": "Deals in buffs and debuffs",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1,
            "10": 1
        },
        "cardDraw": 3,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [
        ],
        "disabled": true,
        "image": "/images/new/heroes/warlock.png"
    },
    6: {
        "id": 6,
        "name": "Paladin",
        "notes": "Upgrades cards",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1,
            "10": 1
        },
        "cardDraw": 3,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [
        ],
        "disabled": true,
        "image": "/images/new/heroes/paladin.png"
    },
    7: {
        "id": 7,
        "name": "Tinker",
        "notes":  "Deals with things that equip together",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1,
            "10": 1
        },
        "cardDraw": 3,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [
        ],
        "disabled": true,
        "image": "/images/new/heroes/tinker.png"
    },
    8: {
        "id": 8,
        "name": "Thief",
        "notes": "Equipment (as in, permament passive effects)",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1,
            "10": 1
        },
        "cardDraw": 3,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [
        ],
        "disabled": true,
        "image": "/images/new/heroes/thief.png"
    },
    9: {
        "id": 9,
        "name": "Monk",
        "notes": "Timewalk",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1,
            "10": 1
        },
        "cardDraw": 3,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [
        ],
        "disabled": true,
        "image": "/images/new/heroes/monk.png"
    },
    10: {
        "id": 10,
        "name": "Gambler",
        "notes": "Rolls dice to determine random effects? Gain effects using RNG between values?",
        "deck": {
            "1": 3,
            "2": 3,
            "3": 3,
            "4": 1,
            "10": 1
        },
        "cardDraw": 3,
        "hp": 50,
        "mana": {
            "red": 0,
            "blue": 0,
            "green": 0
        },
        "manaRegen": {
            "red": 5,
            "blue": 5,
            "green": 5
        },
        "artifacts": [
        ],
        "disabled": true,
        "image": "/images/new/heroes/gambler.png"
    }
}
