import React from 'react';
import Overlay from './Overlay';
import OverlayTabContent from './OverlayTabContent';
import OverlayTabs from './OverlayTabs';

class Info extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            activeTab: "how-to-play"
        }
        this.chooseTab = this.chooseTab.bind(this)
    }

    chooseTab(name)
    {
        this.setState({
            activeTab: name
        })
    }

    formatTabData()
    {
        return [
            {
                "name": "how-to-play",
                "title": "How To Play",
                "isActive": this.state.activeTab === 'how-to-play'
            },
            {
                "name": "effects",
                "title": "Status Effects",
                "isActive": this.state.activeTab === 'effects'
            }
        ];
    }
    render()
    {
        return (
            <Overlay
                overlayClasses="info-overlay"
                title="Help Sheet"
                display={this.props.display}
                closeOverlay={this.props.closeOverlay}
                name='info'
                >
                <OverlayTabs
                    chooseTab={this.chooseTab}
                    tabData={this.formatTabData()}
                    />
                <OverlayTabContent
                    isActive={this.state.activeTab === 'how-to-play'}
                    withBorder={false}
                    key="how-to-play"
                >
                    <h2 className="info__title">How To Play</h2>
                    <div className="info__how-to-play">
                        <p>RogueQuest is a single-player deckbuilding rogue-like game, where the object is to progress through different zones, playing cards to defeat monsters, buying powerups and more powerful cards along the way, until you face the final boss at the end. Be careful though, you only have one life - and if your hitpoints reach zero, your game will end!</p>
                        <img src="/images/new/screenshots/chest.png" />
                        <img src="/images/new/screenshots/shop.png" />
                        <p>Along your journey, you may encounter chests, which contain cards for you to add to your deck; merchants, which will sell you powerful cards and artifacts (which provide passive power-ups) and allow you to delete cards from your deck, and increase your health. You might also encounter Maps, following them might lead you to someplace entirely new!</p>
                        <p>The heart of RogueQuest is battling different creatures, using the deck you've constructed. You'll start the game with a simplistic deck, and will be given the chance to add more powerful cards throughout the journey. Each monster you defeat will allow you to add a new card to your deck, and grant you some amount of gold coins.</p>
                        <p>In battle, you and the enemy will take turns playing cards from your deck, until one of you runs out of health. At the beginning of your turn, you will draw cards equal to your draw limit. You can then play as many cards as you'd like, providing you have the mana to pay for them.</p>
                        <p>Cards you play go into the discard pile after use (unless they have the Exile keyword, in which case they move directly to the exile pile). Once the deck is depleted, the discard pile is shuffled and becomes the new deck.</p>
                        <img src="/images/new/screenshots/mana.png" />
                        <p>Each card costs a certain amount of green, red or blue mana to play. Typically, green mana is used for defensive abilities, red mana is used for offensive abilities, and blue mana is used for utility spells. The mana cost of the card is found on the left hand side, and your current mana reserves are found in the bottom left hand corner of the screen.</p>
                        <p>Your enemy will also have mana resources and limits, and their cards will also cost mana to play. You will gain mana every turn based on your mana regeneration levels (which are shown in brackets next to your current mana amount).</p>
                        <p>Once you have played all the cards you wish to play for the turn, click 'End Turn'. Any cards you did not play will be discarded and the enemies turn will begin.</p>
                        <img src="/images/new/screenshots/deck.png" />
                        <p>Any health you lose in combat will persist outside of battle, until you have a chance to heal.</p>
                        <p>If you wish to view your enemies deck, discard pile or exile pile, you can click on the icons in the top bar to view them. You may also view your own deck, discard pile or exile pile by clicking on the corresponding icons in the bottom right corner of the screen.</p>
                        <img src="/images/new/screenshots/progress.png" />
                        <p>When not in battle, you will be exploring one of three magical realms, at the end of each lies a powerful enemy which you must defeat to move through to the next realm. Your progress through the current realm (and how close you are to battling the boss creature) is shown in the progress bar at the top of the screen.</p>
                    </div>
                </OverlayTabContent>
                <OverlayTabContent
                    isActive={this.state.activeTab === 'effects'}
                    withBorder={false}
                    key="effects"
                >
                    <h2 className="info__title">Status Effects Summary</h2>
                    <div className="info__status-effects">
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Armour</h3>
                            <img className="info__status-effect-image" src="/images/new/status/armour.png"></img>
                            <p className="info__status-effect-description">A player with Armour will gain this much extra shield, whenever they gain shield.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Bleed</h3>
                            <img className="info__status-effect-image" src="/images/new/status/bleed.png"></img>
                            <p className="info__status-effect-description">A player afflicted by Bleed will take that much damage at the start of their next turn, and then the Bleed count will decrease by one.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Burn</h3>
                            <img className="info__status-effect-image" src="/images/new/status/burn.png"></img>
                            <p className="info__status-effect-description">A player afflicted by Burn will discard a card from their hand (if possible) the next time they play a card. Burn count decreases when cards are played.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Double Next Green</h3>
                            <img className="info__status-effect-image" src="/images/new/status/double-next-green.png"></img>
                            <p className="info__status-effect-description">The next green card (a card which costs more than one green mana to play, regardless of other mana costs) played by this player will act as though it has been played twice.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Double Next Red</h3>
                            <img className="info__status-effect-image" src="/images/new/status/double-next-red.png"></img>
                            <p className="info__status-effect-description">The next red card (a card which costs more than one green mana to play, regardless of other mana costs) played by this player will act as though it has been played twice.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Future</h3>
                            <img className="info__status-effect-image" src="/images/new/status/future.png"></img>
                            <p className="info__status-effect-description">This player has an effect which will trigger in the future (the count will show you how many turns remaining). To see what the effect is, hover your mouse over the icon.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Immune</h3>
                            <img className="info__status-effect-image" src="/images/new/status/immune.png"></img>
                            <p className="info__status-effect-description">This player is immune to negative buffs, including Bleed, Poison and Weaken. Being Immune does not stop these statuses from triggering (e.g. at the start of the turn Poison damage), but will stop any futher debuffs from being applied. The Immunity count will decrease one at the begninng of each turn.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Invulnerable</h3>
                            <img className="info__status-effect-image" src="/images/new/status/bleed.png"></img>
                            <p className="info__status-effect-description">An Invulnerable player will not be able to take damage from any source. They may still be afflicted by debuffs. The Invulnerability count decreases by one at the beginning of each turn.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Mana Mastery</h3>
                            <img className="info__status-effect-image" src="/images/new/status/mana-mastery.png"></img>
                            <p className="info__status-effect-description">A player with Mana Mastery can use mana of any colour to pay for spells. Mana will be paid in the usual way, if possible. If this is not enough to cover the cost for the spell, mana will be taken from the green, red, and blue pools in that order.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Muster</h3>
                            <img className="info__status-effect-image" src="/images/new/status/muster.png"></img>
                            <p className="info__status-effect-description">A player who has Mustered will deal this much extra damage whenever they deal damage.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Negate</h3>
                            <img className="info__status-effect-image" src="/images/new/status/negate.png"></img>
                            <p className="info__status-effect-description">The next X cards this player plays will have no effect (but still costs mana to play), where X is the Negation count.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Poison</h3>
                            <img className="info__status-effect-image" src="/images/new/status/poison.png"></img>
                            <p className="info__status-effect-description">A player afflicted by Poison takes this much damage at the start of every turn. The Poison count does not decrease, but can be cancelled out if the player plays a Regen effect.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Regen</h3>
                            <img className="info__status-effect-image" src="/images/new/status/regen.png"></img>
                            <p className="info__status-effect-description">The opposite of Poison, a player with Regen will heal this much health at the start of every turn. The Regen count does not decrease, but can be cancelled out if the player is Poisoned.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Reinforce</h3>
                            <img className="info__status-effect-image" src="/images/new/status/bleed.png"></img>
                            <p className="info__status-effect-description">A player with Reinforce will not have their Shield depleted at the beginning of their next turn. The Armour count will decrease by one at the beginning of each turn.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Strengthen</h3>
                            <img className="info__status-effect-image" src="/images/new/status/strengthen.png"></img>
                            <p className="info__status-effect-description">Whenever a player with Strengthen attacks, they deal 50% extra damage. Strengthen count decreases by one at the beginning of every turn.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Thorn</h3>
                            <img className="info__status-effect-image" src="/images/new/status/thorn.png"></img>
                            <p className="info__status-effect-description">Whenever a player attacks another player with Thorn, they will take this much damage back in retaliation.</p>
                        </div>
                        <div className="info__status-effect">
                            <h3 className="info__status-effect-title">Weaken</h3>
                            <img className="info__status-effect-image" src="/images/new/status/weaken.png"></img>
                            <p className="info__status-effect-description">Whenever a player with Weaken attacks, they deal 50% of their regular damage. Weaken count decreases by one at the beginning of every turn.</p>
                        </div>
                    </div>
                </OverlayTabContent>
            </Overlay>
        );
    }
}

export default Info;
