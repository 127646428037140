class BattleOverException extends Error
{
    constructor(message)
    {
        super(message);
        this.name = 'Battle Over';
    }
}

export default BattleOverException;
