import React from 'react';

class ArtifactsBar extends React.Component
{
    getArtifactClassName(artifact)
    {
        let className = "artifact artifact--icon ";
        if (artifact.shouldPulse) {
            className += "pulse ";
        }
        if (artifact.expired) {
            className += "artifact--expired ";
        }
        if (artifact.isActive) {
            className += "artifact--active ";
        }
        if (artifact.canClick) {
            className += "artifact--clickable ";
        }
        return className;
    }

    render()
    {
        return (
            <div className="artifacts-bar">

                {this.props.artifacts?.map(artifactData => {
                    return (
                        <div id={artifactData.uid} key={'artifact_' + artifactData.uid} className={this.getArtifactClassName(artifactData)} onClick={() => artifactData.canClick && this.props.clickArtifact(artifactData.uid)}>
                            <img src={artifactData.image} title={artifactData.description} />
                            <span className={artifactData.showCounter ? 'artifact__counter' : 'artifact__counter artifact__counter--hidden'}>{artifactData.internalCounter}</span>
                            <div className="artifact__hover-container">
                                <div className="artifact__hover">
                                    <h3 className="artifact__hover-title">{artifactData.name}</h3>
                                    <p className="artifact__hover-description">{artifactData.description}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}

            </div>
        );
    }
}

export default ArtifactsBar;
