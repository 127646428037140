import AbstractEncounter from "./AbstractEncounter";
import Card from '../Card';

import {shuffle} from '../Helper';

import {cardsData} from '../data/cards-new';

class ChestEncounter extends AbstractEncounter
{
    constructor()
    {
        super();
        this.name = 'Mysterious Chest';
        this.screen = 'play';
        this.image = '/images/new/encounters/chest.png';
        this.cardRewards = [];
        this.ctas = [
            'open',
            'leave'
        ];
    }

    startEncounter(game)
    {
        super.startEncounter(game);
        this.generateCardRewards();
        game.triggerSoundEffect('/sounds/effects/open_chest_2.mp3');
    }

    generateCardRewards()
    {
        let cardIds = {...cardsData};
        let possibleCardIds = Object.keys(cardIds)
            .filter(function (cardId) {
                if (cardIds[cardId].modes === undefined) {
                    return false;
                }
                return cardIds[cardId].tier > 0 && cardIds[cardId].modes.includes(this.game.modeId) && cardIds[cardId].unlockLevel <= this.game.experience.level;
            }.bind(this));

        if (this.game.artifacts.filter(artifact => artifact.hasPassiveEffect('noDuplicates')).length > 0) {
            possibleCardIds = possibleCardIds
                .filter(function (card) {
                    let cardNamesInGame = this.game.deck.deck.map(card => card.name);
                    return !cardNamesInGame.includes(card.name);
                }.bind(this));
        }

        let possibleCards = possibleCardIds.map((cardId, pos) => Card.createFromCardId(cardId));

        let possibleCommon = shuffle(possibleCards.filter(card => card.tier === 1));
        let possibleUncommon = shuffle(possibleCards.filter(card => card.tier === 2));
        let possibleRare = shuffle(possibleCards.filter(card => card.tier === 3));
        let cardRewards = [];

        while (cardRewards.length < 3) {
            // 10% chance of rare
            if (Math.random() < 0.1) {
                cardRewards.push(possibleRare.pop());
            } else if (Math.random() < 0.25) {
                cardRewards.push(possibleUncommon.pop());
            } else {
                cardRewards.push(possibleCommon.pop());
            }
        }

        this.cardRewards = cardRewards;
    }

	chooseCard(cardUid)
	{
        let card = this.cardRewards.filter(reward => reward.uid === cardUid)[0];
        this.game.deck.addToDeck(card);
        this.game.triggerSoundEffect('/sounds/effects/gain_card.wav');

		// Simple POST request with a JSON body using fetch
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(
				{
					version: this.game.version,
                    internalId: parseInt(card.id),
                    name: card.name,
                    columnName: 'chosenFromChest'
				}
			)
		};
		fetch('/.netlify/functions/increase-card-pickrate-count', requestOptions);

        let unchosenCards = this.cardRewards.filter(reward => reward.uid !== cardUid);
        unchosenCards.forEach(function(card) {
            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(card.id),
                        name: card.name,
                        columnName: 'declinedFromChest'
                    }
                )
            };
            fetch('/.netlify/functions/increase-card-pickrate-count', requestOptions);
        }.bind(this));

        this.game.endEncounter(this.encounterId);
	}

    getEncounterState()
    {
        let state = super.getEncounterState();
        state.cardRewards = this.cardRewards.map(card => card.getCardState());

        return state;
    }

    beforeEndEncounter()
    {

    }
}

export default ChestEncounter;
