import React from "react";
import Overlay from "../NewComponents/Overlay";
import { compareVersions } from "compare-versions";
import { decompressFromBase64 } from "lz-string";

// error boundry as a class
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasFatalError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasFatalError: true };
    }

	componentDidCatch(error, errorInfo)
	{
		console.log('error', error, errorInfo, this.props.children);

		let gameString = localStorage.getItem("game");
		let sessionString = localStorage.getItem("session");
		let version = localStorage.getItem("version") ?? 'v0.3.3.2';

		if (compareVersions(version, '0.3.4') >= 0) {
			gameString = decompressFromBase64(gameString);
			sessionString = decompressFromBase64(sessionString);
		}

		let session = {
			session: sessionString,
			game: gameString,
			version: version
		};

		// Simple POST request with a JSON body using fetch
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(
				{
					error: error,
					context: errorInfo,
					state: this.state,
					session: session
				}
			)
		};
		fetch('/.netlify/functions/send-error-email', requestOptions);
	}

    render() {
		return this.state.hasFatalError
			? <div>
                    <Overlay
                        overlayClasses="card-choice-overlay overlay--small"
                        title="Error"
                        text="This game has encounted an error. This has been logged and will be investigated. Please refresh the page."
                        showClose={false}
                        display={true}
                        />
                </div>
			: this.props.children;
    }
}

export default ErrorBoundary;
