import AbstractStatusEffect from "./AbstractStatusEffect";

class NegateStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Negate';
        this.image = '/images/new/status/negate.png';
        this.soundEffect = '/sounds/effects/negate2.mp3';
        this.isDebuff = true;
    }

    generateTooltip()
    {
        return 'The next ' + this.val + ' cards played by the player are negated.';
    }
}

export default NegateStatusEffect;
