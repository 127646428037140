import React from 'react';

class Encounter extends React.Component
{
    render()
    {
        return (
            <div className="location">
                <div className="location__title">{this.props.title}</div>
                <div className="location__image">
                    <img src={this.props.image} alt={this.props.title} />
                </div>
                <div className="location__cta">
                    <button onClick={() => this.props.startEncounter(this.props.id)}>
                        {this.props.buttonText}
                    </button>
                </div>
            </div>
        );
    }
}

export default Encounter;
