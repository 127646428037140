import AbstractEffect from "./AbstractEffect";

class PropogateDebuffsEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let statusEffects = Object.keys(context.enemy.statusEffects).filter(key => context.enemy.statusEffects[key].isDebuff && context.enemy.statusEffects[key].val > 0);

            statusEffects.forEach(key => context.enemy.statusEffects[key].changeVal(context.enemy.statusEffects[key].val + 1, context));

            context.battle.history.write(context.player.name + ' propogates all debuffs on ' + context.enemy.name + ', ' + this.getVal(context) + ' ' + this.pluralise('time', parseInt(this.data)) + '.', context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription(context)
    {
        return 'Propogate debuffs on enemy, ' + parseInt(this.data) + ' ' + this.pluralise('time', parseInt(this.data)) + '.';
    }
}

export default PropogateDebuffsEffect;
