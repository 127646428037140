import StatusEffectFactory from "../StatusEffects/StatusEffectFactory";
import AbstractEffect from "./AbstractEffect";

class ClearEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            this.data.map(statusEffectKey => {
                let player = context.player;
                let key = statusEffectKey;
                if (statusEffectKey.substr(0,3) === 'opp') {
                    player = context.enemy;
                    key = statusEffectKey.slice(3);
                    key = key.charAt(0).toLowerCase() + key.slice(1);
                }

                let statusEffect = player.getStatusEffect(key);
                statusEffect.changeVal(0, context);

                context.battle.history.write(player.name + ' clears their ' + statusEffect.name, context.historySourceImage);
                context.battle.game.triggerSoundEffect('/sounds/effects/clear.wav');
            });
        }
    }

    generateDescription()
    {
        let statusEffects = this.data.filter(statusEffectKey => statusEffectKey.substr(0,3) !== 'opp')
            .map(statusEffectKey => {
                return StatusEffectFactory.createDummyEffect(statusEffectKey).name;
            });
        let oppStatusEffects = this.data.filter(statusEffectKey => statusEffectKey.substr(0,3) === 'opp')
            .map(statusEffectKey => {
                let key = statusEffectKey.slice(3).toLowerCase();
                return StatusEffectFactory.createDummyEffect(key)?.name;
            });

        let descriptionSentences = [];
        if (statusEffects.length > 0) {
            descriptionSentences.push("Clears any " + this.joinAsList(statusEffects) + ' effects you have.');
        }
        if (oppStatusEffects.length > 0) {
            descriptionSentences.push("Clears any " + this.joinAsList(oppStatusEffects) + ' effects your opponent has.');
        }
        return descriptionSentences.join(' ');
    }
}

export default ClearEffect;
