import React from 'react';
import Overlay from './Overlay';

class Settings extends React.Component
{
    changeMusicVolume(event)
    {
        this.props.changeVolume(event.target.value);
    }

    changeSoundEffectsVolume(event)
    {
        this.props.changeSoundVolume(event.target.value);
    }

    setAnimationSpeed(event)
    {
        this.props.setAnimationSpeed(event.target.value);
    }

    render()
    {
        let retire = this.props.openRetireConfirm
            ? (
                <div className="l-one-half">
                    <button className="setting-retire" onClick={() => this.props.openRetireConfirm()}>Retire</button>
                </div>
            )
            : '';

        let mainMenu = this.props.returnToMenu
            ? (
                <div className="l-one-half">
                    <button className="setting-main-menu" onClick={() => this.props.returnToMenu()}>Main Menu</button>
                </div>
            )
            : '';

        let buttons = (this.props.openRetireConfirm || this.props.returnToMenu)
            ? (
                <div className="settings-buttons grid grid--flush-bottom grid--flex">
                    {mainMenu}
                    {retire}
                </div>
            )
            : '';

        return (
            <Overlay
                overlayClasses="settings-overlay"
                title="Settings"
                display={this.props.display}
                closeOverlay={this.props.closeOverlay}
                name='settings'
                >
                <ul className="settings-list">
                    <li className="settings-list-item">
                        <div className="settings-sound">
                            <span className="icon icon--sound">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g stroke="#000"><path d="M32.624 75.257l93.072-60.874v226.894l-93.072-65.905z" strokeWidth=".35271765000000005"/><path strokeWidth=".35404866" strokeLinejoin="round" d="M3.446 74.755h29.177v100.616H3.446z"/><path d="M167.453 47.085l77.979-29.18M165.44 128.082h88.041M168.63 211.888l77.979 29.18" fill="none" strokeWidth="19.36885752" strokeLinecap="round"/></g></svg>
                            </span>
                            <input type="range" className="settings-sound__slider" min="0" max="100" value={this.props.soundEffectVol} onChange={(e) => this.changeSoundEffectsVolume(e)}></input>
                        </div>
                    </li>
                    <li className="settings-list-item">
                        <div className="settings-music">
                            <span className="icon icon--music">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g stroke="#000"><g transform="matrix(1.02537 0 0 1.02537 -8.086 11.185)"><ellipse cx="103.943" cy="191.18" rx="46.066" ry="45.991" strokeWidth="14.646" strokeLinecap="round" strokeLinejoin="round"/><path d="M150.057 190.122l-.378-187.098" fill="none" strokeWidth="14.552"/></g><path d="M137.638 2.27c20.67 10.078 41.34 20.154 53.097 44.507 11.756 24.352 14.598 62.978 17.44 101.606-9.172-35.269-18.345-70.536-30.036-90.755-11.692-20.218-25.902-25.385-40.113-30.553" strokeWidth=".27172304999999997"/></g></svg>
                            </span>
                            <input type="range" className="settings-music__slider" min="0" max="100" value={this.props.volume} onChange={(e) => this.changeMusicVolume(e)}></input>
                        </div>
                    </li>
                    <li className="settings-list-item">
                        <div className="settings-animation-speed">
                            <p className="settings-animation-speed__description">Animation Speed:</p>
                            <ul className="settings-animation-speed-options">
                                <li className="settings-animation-speed-option">
                                    <label htmlFor="settings-animation-speed" className="settings-animation-speed__label">Slow</label>
                                    <input type="radio" defaultChecked={this.props.animationSpeed === 'slow'} name="settings-animation-speed" value="slow" onChange={(e) => this.setAnimationSpeed(e)}/>
                                </li>
                                <li className="settings-animation-speed-option">
                                    <label htmlFor="settings-animation-speed" className="settings-animation-speed__label">Normal</label>
                                    <input type="radio" defaultChecked={this.props.animationSpeed === 'normal'} name="settings-animation-speed" value="normal" onChange={(e) => this.setAnimationSpeed(e)} />
                                </li>
                                <li className="settings-animation-speed-option">
                                    <label htmlFor="settings-animation-speed" className="settings-animation-speed__label">Fast</label>
                                    <input type="radio" defaultChecked={this.props.animationSpeed === 'fast'} name="settings-animation-speed" value="fast" onChange={(e) => this.setAnimationSpeed(e)} />
                                </li>
                                <li className="settings-animation-speed-option">
                                    <label htmlFor="settings-animation-speed" className="settings-animation-speed__label">V. Fast</label>
                                    <input type="radio" defaultChecked={this.props.animationSpeed === 'vfast'} name="settings-animation-speed" value="vfast" onChange={(e) => this.setAnimationSpeed(e)} />
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                {buttons}
            </Overlay>
        );
    }
}

export default Settings;
