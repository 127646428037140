class History
{
    constructor(battle)
    {
        this.battle = battle;
        this.messages = [];
        this.indent = 0;
    }

    write(msg, img=null, isHeading=false)
    {
        console.log(msg, img);
        this.messages.push({
            msg: msg,
            img: img,
            indent: this.indent,
            isHeading: isHeading
        });
    }

    increaseIndent()
    {
        this.indent++;
    }

    decreaseIndent()
    {
        this.indent = Math.max(0, this.indent - 1);
    }

    setIndent(indent)
    {
        this.indent = indent;
    }
}

export default History;
