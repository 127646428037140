import AbstractEffect from "./AbstractEffect";

class ShieldEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let shieldStatusEffect = context.player.getStatusEffect('shield');
            let armourStatusEffect = context.player.getStatusEffect('armour');
            let shieldToGain = this.getVal(context);
            if (shieldToGain > 0) {
                shieldToGain = Math.max(0, shieldToGain + armourStatusEffect.val);
            }
            shieldStatusEffect.changeVal(Math.max(0, shieldToGain + shieldStatusEffect.val), context);
            context.shieldVal = shieldToGain;

            context.battle.history.write(context.player.name + ' gains ' + shieldToGain + ' shield', context.historySourceImage);
            context.battle.game.trigger('playerGainShield', context);

            let newContext = {...context};
            newContext.player = context.enemy;
            newContext.enemy = context.player;
            context.battle.game.trigger('enemyGainShield', newContext);
        }

        context.battle.game.triggerSoundEffect('/sounds/effects/shield.mp3');
    }

    generateDescription(context = {})
    {
        let descriptionEnding = '.';
        if (this.data?.context?.multiEffect !== undefined && this.data.context.multiEffect > 1) {
            descriptionEnding = ', ' + this.data.context.multiEffect + ' times.';
        } else if (context.multiEffect !== undefined && context.multiEffect > 1) {
            descriptionEnding = ', ' + context.multiEffect + ' times.';
        }
        return 'Gain ' + parseInt(this.getVal(context)) + ' shield' + descriptionEnding;
    }
}

export default ShieldEffect;
