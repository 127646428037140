import AbstractStatusEffect from "./AbstractStatusEffect";

class DoubleNextGreenStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Double Next Green';
        this.image = '/images/new/status/double-next-green.png';
        this.isBuff = true;
    }

    triggerStartPlayerTurn(context)
    {
        if (context.player === this.player) {
            this.changeVal(0, context);
        }
    }

    generateTooltip()
    {
        return 'The effect of the next green card played is doubled.';
    }
}

export default DoubleNextGreenStatusEffect;
