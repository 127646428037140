import React from 'react';

class PlayerState extends React.Component
{
    getProgressClassNames()
    {
        let classNames = 'hp-progress';
        if (this.props.hp / this.props.maxHp < 0.25) {
            classNames += ' hp-progress--quarter';
        } else if (this.props.hp / this.props.maxHp < 0.5) {
            classNames += ' hp-progress--half';
        }
        return classNames;
    }

    getManaData() {
        return {
            red: {
                amount: this.props.mana.red,
                regen: this.props.manaRegen.red
            },
            green: {
                amount: this.props.mana.green,
                regen: this.props.manaRegen.green
            },
            blue: {
                amount: this.props.mana.blue,
                regen: this.props.manaRegen.blue
            }
        }
    }

    getActions() {
        return [
            {
                name: "End Turn",
                action: this.props.endTurn,
                color: "green",
                classNames: "action action--primary"
            },
            {
                name: "Surrender",
                action: this.props.surrender,
                color: "red",
                classNames: "action action--secondary"
            }
        ];
    }

    render() {
        let statusesJsx =
            <div className="state__statuses grid grid--tight">
                {this.props.artifacts.map(artifactData => {
                    return (
                        <div id={artifactData.uid} key={'artifact_' + artifactData.uid}>
                            <div className="state__status" title={artifactData.name + ': ' + artifactData.description}>
                                <img src={artifactData.image} />
                                <div className={artifactData.showCounter ? 'state__status-overlay state__status-overlay--artifact' : 'state__status-overlay state__status-overlay--artifact state__status-overlay--hidden'}>{artifactData.internalCounter}</div>
                            </div>
                        </div>
                    );
                })}
                {Object.keys(this.props.statusEffects).map((key, pos) => this.props.statusEffects[key].icons.map((icon) => {
                    return (
                        <div id={icon.uid} key={'state_' + icon.uid}>
                            <div className="state__status" title={icon.tooltip}>
                                <img src={icon.img} />
                                <div className="state__status-overlay">{icon.val}</div>
                            </div>
                        </div>
                    );
                }))}
            </div>;

        return (
            <div className="state state--player">
                {this.props.type === 'player' && statusesJsx}
                <div className="state__inner">
                <h2 className="state__name">{this.props.name}</h2>
                    <div className="state__hp-container">
                        <span className="state__hp-title">HP</span>
                        <div className="state__hp-progress-container">
                            <progress className={this.getProgressClassNames()} max={this.props.maxHp} value={this.props.hp}></progress>
                            {this.props.statusEffects.shield !== undefined ? (
                                <div className="state__hp-progress-shield">
                                    <img src="/images/roguequest/shield.png" />
                                    <span>{this.props.statusEffects.shield.val}</span>
                                </div>
                            ) : ''}
                        </div>
                        <span className="state__hp-value">{this.props.hp}/{this.props.maxHp}</span>
                    </div>
                    <div className="state__image-and-mana">
                        <img className="state__image" src={this.props.image} />
                        <div className="state__mana-container">
                            {Object.keys(this.getManaData()).map((manaType, cost) => {
                                let className = 'state__mana state__mana--' + manaType;
                                let symbolClassName = 'mana-symbol mana-symbol--' + manaType;
                                return (
                                    <div className="state__mana-wrapper" key={manaType}>
                                        <div className={className}>
                                            <span>{this.getManaData()[manaType]['amount']}</span>
                                        </div>
                                        <span>(+ {this.getManaData()[manaType]['regen']})</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {this.props.type !== 'player' && statusesJsx}
                {this.props.type === 'player' && this.getActions().length > 0 &&
                    <div className='state__actions'>
                        {this.getActions().map((action, key) => {
                            return (
                                <button onClick={() => action.action()} className={action.classNames} key={action.name}>
                                    <span>{action.name}</span>
                                </button>
                            )
                        })}
                    </div>
                }
            </div>
        );
    }
}

export default PlayerState;
