import AbstractStatusEffect from "./AbstractStatusEffect";

import { v4 as uuidv4 } from 'uuid';
import EffectFactory from "../Effects/EffectFactory";

class FutureStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Future Effect';
        this.image = '/images/new/status/future.png';
        this.val = Object.values(this.val);
        this.val.forEach(futureEffect => {
            futureEffect.uid = uuidv4();
            futureEffect.effects = Object.keys(futureEffect.effects).map((key, pos) => {
                return EffectFactory.createEffect(key, futureEffect.effects[key]);
            });
            return futureEffect;
        });
    }

    addEffects(val)
    {
        val.forEach(futureEffect => {
            futureEffect.uid = uuidv4();
            futureEffect.effects = Object.keys(futureEffect.effects).map((key, pos) => {
                return EffectFactory.createEffect(key, futureEffect.effects[key]);
            });
            this.val.push(futureEffect);
        });
    }

    triggerStartPlayerTurn(context)
    {
        if (context.player === this.player) {
            this.val.forEach(futureEffect => {
                futureEffect.turns--;
                let historySourceImage = futureEffect.historySourceImage;
                if (historySourceImage === undefined) {
                    historySourceImage = this.image;
                }
                if (futureEffect.turns === 0) {
                    context.battle.history.write(futureEffect.name + ' activates!', historySourceImage);
                    context.historySourceImage = historySourceImage;
                    futureEffect.effects.forEach(effect => {
                        effect.handle(context);
                    });
                }
            });
            this.changeVal(this.val.filter(futureEffect => futureEffect.turns > 0), context);
        }
    }

    triggerPlayerWin(context)
    {
        if (context.player === this.player) {
            this.val.forEach(futureEffect => {
                if (futureEffect.endOfBattleTrigger === true) {
                    context.battle.history.write(futureEffect.name + ' activates!');
                    futureEffect.effects.forEach(effect => {
                        effect.handle(context);
                    });
                }
            });
            this.changeVal(this.val.filter(futureEffect => futureEffect.turns > 0), context);
        }
    }

    getStatusEffectState()
    {
        return {
            icons: this.val.map(futureEffect => {
                return {
                    uid: futureEffect.uid,
                    img: this.image,
                    val: futureEffect.turns,
                    tooltip: this.generateTooltipForEffect(futureEffect)
                };
            })
        };
    }

    generateTooltipForEffect(futureEffect)
    {
        return 'In ' + futureEffect.turns + ' turns' + futureEffect.effects.reduce((accumulator, value) => {
            return accumulator + ', ' + value.getFutureTooltip();
        }, '');
    }
}

export default FutureStatusEffect;
