export const achievementsData = {
    1: {
        "id": 1,
        "name": "Knight in Shining Armour",
        "description": "Win a game as the Knight.",
        "image": "/images/new/achievements/knight_in_shining_armour.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "gameWin"
        ],
        "conditions": [
            {
                "comparison": "equalTo",
                "vals": [
                    {
                        "valType": "attribute",
                        "val": "startingMode"
                    },
                    {
                        "valType": "flat",
                        "val": 1
                    }
                ]
            }
        ]
    },
    2: {
        "id": 2,
        "name": "Refined Engine",
        "description": "Win a game having 10 or fewer cards in your deck.",
        "image": "/images/new/achievements/refined_engine.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "gameWin"
        ],
        "conditions": [
            {
                "comparison": "greaterThanOrEqualTo",
                "vals": [
                    {
                        "valType": "flat",
                        "val": 10,
                    },
                    {
                        "valType": "attribute",
                        "val": "cardsInGameDeck"
                    }
                ]
            }
        ]
    },
    3: {
        "id": 3,
        "name": "Common as Muck",
        "description": "Win a game without a single rare card in your deck.",
        "image": "/images/new/achievements/common_as_muck.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "gameWin"
        ],
        "conditions": [
            {
                "comparison": "greaterThanOrEqualTo",
                "vals": [
                    {
                        "valType": "flat",
                        "val": 1,
                    },
                    {
                        "valType": "attribute",
                        "val": "numberOfTierThreeOrGreaterCardsInDeck"
                    }
                ]
            }
        ]
    },
    4: {
        "id": 4,
        "name": "Collector",
        "description": "Have 18 or more artifacts.",
        "image": "/images/new/achievements/collector.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "gameWin"
        ],
        "conditions": [
            {
                "comparison": "greaterThanOrEqualTo",
                "vals": [
                    {
                        "valType": "attribute",
                        "val": "numberOfArtifacts"
                    },
                    {
                        "valType": "flat",
                        "val": 18,
                    }
                ]
            }
        ]
    },
    5: {
        "id": 5,
        "name": "Hoarder",
        "description": "Have 1000 or more gold.",
        "image": "/images/new/achievements/hoarder.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [
                142
            ],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "gainGold"
        ],
        "conditions": [
            {
                "comparison": "greaterThanOrEqualTo",
                "vals": [
                    {
                        "valType": "attribute",
                        "val": "gold"
                    },
                    {
                        "valType": "flat",
                        "val": 1000,
                    }
                ]
            }
        ]
    },
    6: {
        "id": 6,
        "name": "Skin of your Teeth",
        "description": "Win a battle with 1 health remaining.",
        "image": "/images/new/achievements/skin_of_your_teeth.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "playerWin"
        ],
        "conditions": [
            {
                "comparison": "equalTo",
                "vals": [
                    {
                        "valType": "attribute",
                        "val": "playerHp"
                    },
                    {
                        "valType": "flat",
                        "val": 1,
                    }
                ]
            }
        ]
    },
    7: {
        "id": 7,
        "name": "Not What it Seems",
        "description": "Fight a Mimic",
        "image": "/images/new/achievements/not_what_it_seems.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "battleStart"
        ],
        "conditions": [
            {
                "comparison": "equalTo",
                "vals": [
                    {
                        "valType": "attribute",
                        "val": "enemyId"
                    },
                    {
                        "valType": "flat",
                        "val": 34,
                    }
                ]
            }
        ]
    },
    8: {
        "id": 8,
        "name": "Quick Finish",
        "description": "Defeat an enemy on the first turn.",
        "image": "/images/new/achievements/quick_finish.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "playerWin"
        ],
        "conditions": [
            {
                "comparison": "equalTo",
                "vals": [
                    {
                        "valType": "attribute",
                        "val": "turnCount"
                    },
                    {
                        "valType": "flat",
                        "val": 1,
                    }
                ]
            }
        ]
    },
    9: {
        "id": 9,
        "name": "Vampiric Energy",
        "description": "Stack 20 bleed on an enemy.",
        "image": "/images/new/achievements/vampiric_energy.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "tick"
        ],
        "conditions": [
            {
                "comparison": "greaterThanOrEqualTo",
                "vals": [
                    {
                        "valType": "attribute",
                        "val": "enemyBleed"
                    },
                    {
                        "valType": "flat",
                        "val": 20,
                    }
                ]
            }
        ]
    },
    10: {
        "id": 10,
        "name": "Thesaurophobia",
        "description": "Win without ever accepting a card from a chest.",
        "image": "/images/new/achievements/thesaurophobia.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "gameWin"
        ],
        "conditions": [
            {
                "comparison": "equalTo",
                "vals": [
                    {
                        "valType": "attribute",
                        "val": "internalCounter"
                    },
                    {
                        "valType": "flat",
                        "val": 0,
                    }
                ]
            }
        ],
        "effects": [
            {
                "trigger": "afterTakeCardFromChest",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ]

    },
    11: {
        "id": 11,
        "name": "Untouchable",
        "description": "Defeat a boss without taking any damage.",
        "image": "/images/new/achievements/untouchable.png",
        "xp": 50,
        "specialUnlocks": {
            "cards": [],
            "artifacts": [],
            "enemies": [],
            "startingModes": [],
        },
        "triggers": [
            "gameWin"
        ],
        "conditions": [
            {
                "comparison": "equalTo",
                "vals": [
                    {
                        "valType": "attribute",
                        "val": "internalCounter"
                    },
                    {
                        "valType": "flat",
                        "val": 0,
                    }
                ]
            }
        ],
        "effects": [
            {
                "trigger": "afterTakeDamage",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "isBoss"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            }
                        ]
                    },
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "damageReceived"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ]
    },
    // win in less than 20 minutes
}
