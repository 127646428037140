import React from 'react';

import ShopDeckList from './ShopDeckList';

class TopBar extends React.Component
{
    constructor(props) {
        super();
        this.state = {
            showDeckList: false
        };
        this.closeDeckList = this.closeDeckList.bind(this);
    }

    showDeck()
    {
        this.setState({
            showDeckList: true
        });
    }

    closeDeckList()
    {
        this.setState({
            showDeckList: false
        });
    }

    getGold()
    {
        return this.props.gold;
    }

    getDeckCount()
    {
        return this.props.deck.length;
    }

    getMaxHp()
    {
        return this.props.maxHp;
    }

    getHp()
    {
        return this.props.hp;
    }

    getProgressClassNames()
    {
        let classNames = 'hp-progress';
        if (this.getHp() / this.getMaxHp() < 0.25) {
            classNames += ' hp-progress--quarter';
        } else if (this.getHp() / this.getMaxHp() < 0.5) {
            classNames += ' hp-progress--half';
        }
        return classNames;
    }

    render() {
        return (
            <div className="topbar">
                <div className="topbar__hp-container">
                    <span className="topbar__hp-title">HP</span>
                    <div className="topbar__hp-progress-container">
                        <progress className={this.getProgressClassNames()} max={this.getMaxHp()} value={this.getHp()}></progress>
                    </div>
                    <span className="topbar__hp-value">{this.getHp()}/{this.getMaxHp()}</span>
                </div>
                <div className="gold-container">
                    <span className="icon icon--gold">
                        <img src="/images/roguequest/money.jpeg" />
                        {this.getGold()}
                    </span>
                </div>
                <div className="deck-container" onClick={() => this.showDeck()}>
                    <span className="icon icon--deck">
                        <img src="/images/roguequest/deck.png" />
                        {this.getDeckCount()}
                    </span>
                </div>
                <ShopDeckList
                    action={() => {}}
                    deck={this.props.deck}
                    isActive={this.state.showDeckList}
                    title="My Deck"
                    closeDeckList={this.closeDeckList}
                />
            </div>
        );
    }
}

export default TopBar;
