import AbstractEffect from "./AbstractEffect";

class AttackEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            context.originalDamageVal = this.getVal(context);
            context.damageVal = this.getVal(context);
            context.source = {
                effect: this,
                card: context.card,
                player: context.player
            }

            let newContext = {...context};
            newContext.enemy = context.player;
            if (context.enemy === undefined) {
                context.enemy = context.player.isPlayer() ? context.battle.enemy : context.battle.player;
            }
            newContext.player = context.enemy;

            context.battle.game.trigger('beforeAttackEffect', context);
            context.battle.game.trigger('beforeEnemyAttackEffect', newContext);

            context.player.takeDamage(newContext);
            if (newContext.damageReceived < 1) {
                if (newContext.damageMitigated > 0) {
                    newContext.battle.game.triggerSoundEffect('/sounds/effects/hit_shield.mp3');
                }
            } else if (newContext.damageReceived < 6) {
                newContext.battle.game.triggerSoundEffect('/sounds/effects/light_impact.mp3');
            } else if (newContext.damageReceived < 11) {
                newContext.battle.game.triggerSoundEffect('/sounds/effects/medium_impact.mp3');
            } else {
                newContext.battle.game.triggerSoundEffect('/sounds/effects/heavy_impact.mp3');
            }

            if (newContext.brokeShield) {
                newContext.battle.game.triggerSoundEffect('/sounds/effects/shield_break_3.mp3');
            }
        }
    }

    generateDescription(context = {})
    {
        let descriptionEnding = '.';
        if (this.data?.context?.multiEffect !== undefined && this.data.context.multiEffect > 1) {
            descriptionEnding = ', ' + this.data.context.multiEffect + ' times.';
        } else if (context.multiEffect !== undefined && context.multiEffect > 1) {
            descriptionEnding = ', ' + context.multiEffect + ' times.';
        }
        return 'Attack for ' + this.getVal(context) + ' damage' + descriptionEnding;
    }
}

export default AttackEffect;
