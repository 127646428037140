import React from 'react';
import Decklist from './Decklist';

class ShopGeneral extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            deckListAction: () => {},
            showDeckList: false
        };
        this.closeDeckList = this.closeDeckList.bind(this);
        this.shopDeleteCard = this.shopDeleteCard.bind(this);
        this.shopUpgradeCard = this.shopUpgradeCard.bind(this);
        this.generalWareCostOriginal = this.props.generalWareCost;
        this.generalWareCost = Math.ceil(this.generalWareCostOriginal * ((100 - this.props.discountPercentage) / 100));
    }

    showDeck(action, title, cta)
    {
        this.setState({
            deckListAction: action,
            showDeckList: true,
            deckListTitle: title,
            deckListCta: cta
        });
    }

    closeDeckList()
    {
        this.setState({
            showDeckList: false
        });
    }

    shopHeal()
    {
        this.props.encounterAction('increaseHealth', this.generalWareCost);
    }

    shopIncreaseMaxHealth()
    {
        this.props.encounterAction('increaseMaxHealth', this.generalWareCost);
    }

    shopDeleteCard(id)
    {
        this.props.encounterAction('deleteCard', this.generalWareCost, id);
        this.closeDeckList();
    }

    shopUpgradeCard(id)
    {
        this.props.encounterAction('upgradeCard', this.generalWareCost, id);
        this.closeDeckList();
    }

    canPurchaseDelete()
    {
        return this.props.gold >= this.generalWareCost && !this.props.servicesPurchased.includes('delete');
    }


    canPurchaseUpgrade()
    {
        return this.props.gold >= this.generalWareCost && !this.props.servicesPurchased.includes('upgrade');
    }


    canPurchaseMaxHealth()
    {
        return this.props.gold >= this.generalWareCost && !this.props.servicesPurchased.includes('maxHp');
    }


    canPurchaseHeal()
    {
        return this.props.gold >= this.generalWareCost && !this.props.servicesPurchased.includes('heal');
    }

    render() {
        let costDescription = <p class="overlay-tab__text shop-general-wares-description">Buy any service from the market for {this.generalWareCost} gold:</p>;
        if (this.generalWareCost !== this.generalWareCostOriginal) {
            costDescription =  <p class="overlay-tab__text shop-general-wares-description">Buy any service from the market for <span class="shop-discount-original-price">{this.generalWareCostOriginal}</span> {this.generalWareCost} gold:</p>;
        }

        return (
            <div>
                {costDescription}
                <div class="shop-general-wares">
                    <a href="#" class={"shop-general-ware shop-general-ware--delete " + (!this.canPurchaseDelete() ? 'shop-general-ware--sold' : '')} onClick={() => this.canPurchaseDelete() && this.showDeck(this.shopDeleteCard, 'Delete a Card', 'Delete')}>
                        <div class="general-ware__background-icon general-ware__background-icon--first">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#000" strokeWidth="14.552"><path d="M249.655 15.337L15.306 249.731M15.306 15.337l234.35 234.394" strokeWidth="17.21836296"/></g></svg>
                        </div>
                        <div class="general-ware__background-icon general-ware__background-icon--second">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#000" strokeWidth="14.552"><path d="M249.655 15.337L15.306 249.731M15.306 15.337l234.35 234.394" strokeWidth="17.21836296"/></g></svg>
                        </div>
                        <p class="general-ware__description">
                            Delete a card
                        </p>
                    </a>
                    <a href="#" class={"shop-general-ware shop-general-ware--heal " + (!this.canPurchaseHeal() ? 'shop-general-ware--sold' : '')} onClick={() => this.canPurchaseHeal() && this.shopHeal()}>
                        <div class="general-ware__background-icon general-ware__background-icon--first">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><path d="M218.725 119.971c16.618-27.58 17.752-45.85 12.397-60.843-5.355-14.993-17.198-26.71-30.238-32.506-13.04-5.795-27.278-5.67-38.387-1.435-11.11 4.234-19.09 12.576-27.072 20.917-7.641-8.341-15.347-16.753-26.254-20.952-10.907-4.2-25.144-4.325-38.185 1.47-13.04 5.796-24.883 17.513-30.238 32.506-5.355 14.994-4.22 33.262 13.021 60.88 17.242 27.619 50.592 64.586 83.942 101.554 32.166-36.968 64.396-74.01 81.014-101.59z" fill="none" stroke="#000" strokeLinecap="square" transform="matrix(.91725 0 0 .84423 .613 49.252)" strokeWidth="17.046"/><path d="M218.33 7.04h13.888v66.05H218.33z"/><path d="M192.248 47.01V33.121h66.05v13.89z"/><g><path d="M32.756 26.018h7.815V62.05h-7.815z"/><path d="M18.08 47.823v-7.577h37.166v7.577z"/></g></svg>
                        </div>
                        <div class="general-ware__background-icon general-ware__background-icon--second">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><path d="M218.725 119.971c16.618-27.58 17.752-45.85 12.397-60.843-5.355-14.993-17.198-26.71-30.238-32.506-13.04-5.795-27.278-5.67-38.387-1.435-11.11 4.234-19.09 12.576-27.072 20.917-7.641-8.341-15.347-16.753-26.254-20.952-10.907-4.2-25.144-4.325-38.185 1.47-13.04 5.796-24.883 17.513-30.238 32.506-5.355 14.994-4.22 33.262 13.021 60.88 17.242 27.619 50.592 64.586 83.942 101.554 32.166-36.968 64.396-74.01 81.014-101.59z" fill="none" stroke="#000" strokeLinecap="square" transform="matrix(.91725 0 0 .84423 .613 49.252)" strokeWidth="17.046"/><path d="M218.33 7.04h13.888v66.05H218.33z"/><path d="M192.248 47.01V33.121h66.05v13.89z"/><g><path d="M32.756 26.018h7.815V62.05h-7.815z"/><path d="M18.08 47.823v-7.577h37.166v7.577z"/></g></svg>
                        </div>
                        <p class="general-ware__description">
                            Heal for 25
                        </p>
                    </a>
                    {/* <a href="#" class={"shop-general-ware shop-general-ware--upgrade " + (!this.canPurchaseUpgrade() ? 'shop-general-ware--sold' : '')} onClick={() => this.showDeck(this.shopUpgradeCard, 'Upgrade a Card', 'Upgrade')}>
                        <div class="general-ware__background-icon general-ware__background-icon--first">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g stroke="#000"><ellipse cx="132.859" cy="131.158" rx="85.041" ry="85.045" fill="none" strokeWidth="29.104" strokeLinejoin="round"/><path d="M102.802 44.953L113.385 4.13l38.931.378L162.9 45.331zM162.908 217.362l-10.583 40.822-38.932-.378-10.583-40.821zM191.908 61.778l40.644-11.245 19.138 33.905-30.06 29.576zM72.65 200.037l-40.644 11.245-19.139-33.905 30.061-29.576zM223.486 147.1l30.06 29.575-19.793 33.527-40.644-11.245zM44.121 112.948l-30.06-29.576 19.793-33.527 40.644 11.246z" strokeWidth=".265"/></g></svg>
                        </div>
                        <div class="general-ware__background-icon general-ware__background-icon--second">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g stroke="#000"><ellipse cx="132.859" cy="131.158" rx="85.041" ry="85.045" fill="none" strokeWidth="29.104" strokeLinejoin="round"/><path d="M102.802 44.953L113.385 4.13l38.931.378L162.9 45.331zM162.908 217.362l-10.583 40.822-38.932-.378-10.583-40.821zM191.908 61.778l40.644-11.245 19.138 33.905-30.06 29.576zM72.65 200.037l-40.644 11.245-19.139-33.905 30.061-29.576zM223.486 147.1l30.06 29.575-19.793 33.527-40.644-11.245zM44.121 112.948l-30.06-29.576 19.793-33.527 40.644 11.246z" strokeWidth=".265"/></g></svg>
                        </div>
                        <p class="general-ware__description">
                            Upgrade a card
                        </p>
                    </a> */}
                    <a href="#" class={"shop-general-ware shop-general-ware--increase-max-hp " + (!this.canPurchaseMaxHealth() ? 'shop-general-ware--sold' : '')} onClick={() => this.canPurchaseMaxHealth() && this.shopIncreaseMaxHealth()}>
                        <div class="general-ware__background-icon general-ware__background-icon--first">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><path d="M218.725 119.971c16.618-27.58 17.752-45.85 12.397-60.843-5.355-14.993-17.198-26.71-30.238-32.506-13.04-5.795-27.278-5.67-38.387-1.435-11.11 4.234-19.09 12.576-27.072 20.917-7.641-8.341-15.347-16.753-26.254-20.952-10.907-4.2-25.144-4.325-38.185 1.47-13.04 5.796-24.883 17.513-30.238 32.506-5.355 14.994-4.22 33.262 13.021 60.88 17.242 27.619 50.592 64.586 83.942 101.554 32.166-36.968 64.396-74.01 81.014-101.59z" fill="none" stroke="#000" strokeLinecap="square" transform="matrix(.91725 0 0 .84423 .613 49.252)" strokeWidth="17.046"/><path d="M218.33 7.04h13.888v66.05H218.33z"/><path d="M192.248 47.01V33.121h66.05v13.89z"/><g><path d="M32.756 26.018h7.815V62.05h-7.815z"/><path d="M18.08 47.823v-7.577h37.166v7.577z"/></g></svg>
                        </div>
                        <div class="general-ware__background-icon general-ware__background-icon--second">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><path d="M218.725 119.971c16.618-27.58 17.752-45.85 12.397-60.843-5.355-14.993-17.198-26.71-30.238-32.506-13.04-5.795-27.278-5.67-38.387-1.435-11.11 4.234-19.09 12.576-27.072 20.917-7.641-8.341-15.347-16.753-26.254-20.952-10.907-4.2-25.144-4.325-38.185 1.47-13.04 5.796-24.883 17.513-30.238 32.506-5.355 14.994-4.22 33.262 13.021 60.88 17.242 27.619 50.592 64.586 83.942 101.554 32.166-36.968 64.396-74.01 81.014-101.59z" fill="none" stroke="#000" strokeLinecap="square" transform="matrix(.91725 0 0 .84423 .613 49.252)" strokeWidth="17.046"/><path d="M218.33 7.04h13.888v66.05H218.33z"/><path d="M192.248 47.01V33.121h66.05v13.89z"/><g><path d="M32.756 26.018h7.815V62.05h-7.815z"/><path d="M18.08 47.823v-7.577h37.166v7.577z"/></g></svg>
                        </div>
                        <p class="general-ware__description">
                            Increase Max Health by 10
                        </p>
                    </a>
                </div>
                <Decklist
                    display={this.state.showDeckList}
                    title={this.state.deckListTitle}
                    action={this.state.deckListAction}
                    cta={this.state.deckListCta}
                    deck={this.props.deck}
                    closeOverlay={this.closeDeckList}
                    // overlayClasses="overlay--small"
                    />

            </div>
        );
    }
}

export default ShopGeneral;
