import { v4 as uuidv4 } from 'uuid';

class AbstractEncounter
{
    constructor()
    {
        this.name = '';
        this.screen = 'play';
        this.buttonText = 'Encounter';
        this.image = '';
        this.uid = uuidv4();
        this.ctas = [];
    }

    getEncounterState()
    {
        return {
            screen: this.screen,
            id: this.encounterId,
            name: this.name,
            buttonText: this.buttonText,
            image: this.image,
            uid: this.uid,
            ctas: this.ctas,
            description: ''
        }
    }

    startEncounter(game)
    {
        this.game = game;
    }

    beforeEndEncounter()
    {
        this.game.triggerSoundEffect('/sounds/effects/leave.mp3');
    }

    beforeLeaveEncounter()
    {

    }

    handleAction(...params)
    {
        let actionName = params.shift();
        if (this[actionName] !== undefined) {
            this[actionName](...params);
        }
    }
}

export default AbstractEncounter;
