import React from 'react';
import Encounter from './Encounter';

class Encounters extends React.Component
{
    render()
    {
        let encounters = this.props.encounters;
        let jsxRenderable = [];
        var i;
        for (i=0; i<3; i++) {
            if (encounters.length > i) {
                let encounterData = encounters.slice(i, i+1)[0];
                jsxRenderable.push(<Encounter
                    title={encounterData.name}
                    image={encounterData.image}
                    id={encounterData.id}
                    description={encounterData.description}
                    buttonText={encounterData.buttonText}
                    startEncounter={this.props.startEncounter}
                    leaveEncounter={this.props.leaveEncounter}
                    key={encounterData.uid}
                    ctas={encounterData.ctas}
                />);
            }
        }

        return (
            <div className="encounters">
                {jsxRenderable}
            </div>
        );
    }
}

export default Encounters;
