import AbstractCondition from "./AbstractCondition";

class GreaterThanOrEqualToCondition extends AbstractCondition
{
    isMet(context)
    {
        let vals = [...this.vals];
        let firstValue = vals[0].calculate(context);
        return vals.every((value) => firstValue >= value.calculate(context));
    }
}

export default GreaterThanOrEqualToCondition;
