import React from 'react';
import BottomBar from './BottomBar';

class BottomBarBattle extends React.Component
{
    render()
    {
        return (
            <BottomBar
                openOverlay={this.props.openOverlay}
                classes="bottombar--battle"
                deckCount={this.props.deckCount}
                hp={this.props.hp}
                maxHp={this.props.maxHp}
                mana={this.props.mana}
                manaRegen={this.props.manaRegen}
                >
                <div className="bottombar-discard">
                    <div className="icon icon--bottombar">
                        <a href="#" onClick={() => this.props.openOverlay('discard')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><path fill="none" stroke="#000" strokeWidth="14.552" strokeLinejoin="round" stroke-opacity=".996" d="M19.418 72.917L118.619 9.85l115.586 181.815-99.201 63.066z"/><path fill="#fff" stroke="#000" strokeWidth="14.552" strokeLinejoin="round" stroke-opacity=".996" d="M218.422 48.289l32.017 113.107-207.302 58.679-32.016-113.107z"/><path fill="#fff" stroke="#000" strokeWidth="14.552" strokeLinejoin="round" stroke-opacity=".996" d="M89.86 14.69l116.51 15.618-28.623 213.536-116.51-15.617z"/><path d="M71.485 68.886l-5.387 39.121M78.666 16.725L73.28 55.847M55.232 189.838l-5.387 39.122M217.384 31.466l-5.387 39.122M200.375 158.844l-5.387 39.122M194.988 197.966l-5.386 39.121M78.619 241.527l118.685 16.253v0M50.647 84.658L12.593 95.213M242.411 175.017l-38.054 10.555" fill="none" stroke="#fff" strokeWidth="7.5"/></svg>
                        </a>
                        <span className="icon__label">{this.props.discardCount}</span>
                    </div>
                </div>
                <div className="bottombar-exile" onClick={() => this.props.openOverlay('exile')}>
                    <div className="icon icon--bottombar">
                        <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#000" strokeWidth="15" strokeLinejoin="round"><path d="M207.417 25.107l49.318 7.553-33.79 223.528-99.77-15.384 74.707-81.029-36.306-57.564z"/><path d="M123.176 240.804L75.562 255.71 7.596 40.101l96.368-30.063L72.51 115.666l57.923 35.73z"/></g></svg>
                        </a>
                        <span className="icon__label">{this.props.exileCount}</span>
                    </div>
                </div>
            </BottomBar>
        );
    }
}

export default BottomBarBattle;
