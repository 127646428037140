import React from 'react';
import Overlay from './Overlay';

class LoseOverlay extends React.Component
{
    render()
    {
        let actions = [
            {
                "action": this.props.claimXp,
                "text": "Continue"
            }
        ];

        return (
            <Overlay
                overlayClasses="lose-overlay overlay--small"
                title="Defeat..."
                display={this.props.display}
                name="lose"
                actions={actions}
                showClose={false}
                >
                <img className="lose-overlay__avatar" src={this.props.image} />
                <p className="lose-overlay__quote">“You can learn little from victory. You can learn everything from defeat.”</p>
                <p className="lose-overlay__author"> - Christy Mathewson</p>
            </Overlay>
        );
    }
}

export default LoseOverlay;
