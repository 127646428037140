import React from 'react';
import BottomBar from './BottomBar';

class BottomBarPlay extends React.Component
{
    render()
    {
        return (
            <BottomBar
                openOverlay={this.props.openOverlay}
                classes="bottombar--play"
                hp={this.props.hp}
                maxHp={this.props.maxHp}
                deckCount={this.props.deckCount}
                mana={this.props.mana}
                manaRegen={this.props.manaRegen}
                >
                <div className="bottombar-gold">
                    <div className="icon icon--bottombar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#000" strokeWidth="14.552"><path d="M61.023 21.023l31.064 47.495s-178.49 175.26 40.016 169.56M203.183 21.023l-31.065 47.495s178.49 175.26-40.015 169.56"/><path d="M67.341 40.496s15.796-12.824 25.273-7.6c9.477 5.225 11.057 12.35 25.8 4.275 14.742-8.074 14.742-14.724 27.378-3.325 12.637 11.4 4.739 14.25 20.008 8.075 15.269-6.175 13.163-9.974 28.432-5.7"/></g></svg>
                        <span className="icon__label">{this.props.gold}</span>
                    </div>
                </div>
            </BottomBar>
        );
    }
}

export default BottomBarPlay;
