import ValueFactory from "../Artifacts/Values/ValueFactory";
import Card from "../Card";
import AbstractEffect from "./AbstractEffect";

class AddToDeckEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let deckDestination = "deck";
            if (context.deckDestination !== undefined) {
                deckDestination = context.deckDestination;
            }
            let qty;
            this.data.forEach(data => {
                if (typeof data.qty === "number") {
                    qty = data.qty;
                } else {
                    qty = ValueFactory.createValueFromJson(data.qty).calculate(context);
                }
                for (let i = 0; i < qty; i++) {
                    let card = Card.createFromCardId(data.id);
                    let player = context.player;
                    if (data.type === 'enemy') {
                        player = context.enemy;
                    }
                    player.deck.addToDeck(card, deckDestination);

                    context.battle.history.write(player.name + ' adds ' + qty + ' ' + this.pluralise('"' + card.name + '"', qty) + ' to their deck.', context.historySourceImage);
                }
            });

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription()
    {
        let sentences = [];
        this.data.forEach(data => {
            if (data.descriptionOverride !== undefined) {
                sentences.push(data.descriptionOverride);
                return;
            }
            let card = Card.createFromCardId(data.id);
            let playerText = data.type === 'enemy' ? 'enemies' : 'players';

            let qty = ValueFactory.createValueFromJson(data.qty).calculate({});
            sentences.push('Adds ' + qty + ' ' + this.pluralise('"' + card.name + '"', data.qty) + ' to the ' + playerText + ' deck.');
        });

        return sentences.join(' ');
    }
}

export default AddToDeckEffect;
