import AbstractStatusEffect from "./AbstractStatusEffect";

class ManaMasteryStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Mana Mastery';
        this.image = '/images/new/status/mana-mastery.png';
        this.isBuff = true;
    }

    generateTooltip()
    {
        return 'Player can use mana of any colour to pay for cards.';
    }
}

export default ManaMasteryStatusEffect;
