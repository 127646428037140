import ValueFactory from "../Values/ValueFactory";
import AndCondition from "./AndCondition";
import EqualToCondition from "./EqualToCondition";
import GreaterThanCondition from "./GreaterThanCondition";
import GreaterThanOrEqualToCondition from "./GreaterThanOrEqualToCondition";
import IncludesCondition from "./IncludesCondition";
import OrCondition from "./OrCondition";

class ConditionFactory
{
    static createConditionFromJson(data)
    {
        let vals = data.vals.map(val => ValueFactory.createValueFromJson(val));

        switch (data.comparison) {
            case 'or':
                return new OrCondition(data.comparison, vals);

            case 'and':
                return new AndCondition(data.comparison, vals);

            case 'greaterThanOrEqualTo':
                return new GreaterThanOrEqualToCondition(data.comparison, vals);

            case 'greaterThan':
                return new GreaterThanCondition(data.comparison, vals);

            case 'equalTo':
                return new EqualToCondition(data.comparison, vals);

            case 'includes':
                return new IncludesCondition(data.comparison, vals);

            default:
                throw new Error('Cannot parse condition JSON');

        }
    }
}

export default ConditionFactory;
