import AbstractEffect from "./AbstractEffect";

class StrengthenEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let strengthenStatusEffect = context.player.getStatusEffect('strengthen');
            strengthenStatusEffect.changeVal(Math.max(0, this.getVal(context) + strengthenStatusEffect.val), context);
            context.strengthenVal = this.getVal(context);

            context.battle.history.write(context.player.name + ' is now strengthened for ' + strengthenStatusEffect.val + ' turns', context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/strengthen2.mp3');
        }
    }

    generateDescription()
    {
        return 'Strengthen for ' + parseInt(this.data) + ' ' + this.pluralise('turn', parseInt(this.data)) + '.';
    }
}

export default StrengthenEffect;
