import AbstractEffect from "./AbstractEffect";

class ExileAllEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            context.player.deck.hand.forEach(card => {
                context.player.deck.move(card, 'hand', 'exile');

                context.battle.game.trigger('afterExileCard', {
                    card: card,
                    player: context.player,
                    enemy: context.enemy,
                    battle: context.battle
                });

                context.battle.game.trigger('afterEnemyExileCard', {
                    card: card,
                    player: context.enemy,
                    enemy: context.player,
                    battle: context.battle
                });
            });
        }
    }

    generateDescription()
    {
        return 'Exile all cards in hand.';
    }
}

export default ExileAllEffect;
