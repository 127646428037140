import React from 'react';

class ChangelogScreen extends React.Component {
    render() {
        return (
            <div className="changelog-screen">
                <div className="changelog-screen-inner">
                    <h1 className="changelog-title">ChangeLog</h1>
                    <div class="changelog-release">
                        <h2>v0.0.3</h2>
                        <h3>Added</h3>
                        <ul class="changelog-list changelog-list--added">
                            <li>Added artifacts</li>
                            <li>New Artifact: Health Stone</li>
                            <li>New Artifact: Nature Stone</li>
                            <li>New Artifact: Lightning Rod</li>
                            <li>New Artifact: Nanobot Medics</li>
                            <li>New Artifact: Phoenix Ashes</li>
                            <li>New Artifact: Mana Machine</li>
                            <li>New Artifact: Amulet of Fury</li>
                            <li>New Artifact: Lucky Coin</li>
                            <li>New Artifact: Warrior's Gauntlet</li>
                            <li>Added artifacts to the shop</li>
                        </ul>
                        <h3>Changed</h3>
                        <ul class="changelog-list changelog-list--changed">
                            <li>Code: HUGE overhaul of code. Complete refactor to use ES6 classes. This will help to be a lot more flexible when adding in new features.</li>
                            <li>Code: Improved trigger handling to allow multiple triggers to be registered for the same event with different priorities.</li>
                            <li>UI: Cards (and artifacts) too expensive for purchase will show in red.</li>
                        </ul>
                        <h3>Fixed</h3>
                        <ul class="changelog-list changelog-list--changed">
                            <li>Fixed: No longer able to buy multiple of the same card (and artifact) in the shop.</li>
                        </ul>
                    </div>
                    <div class="changelog-release">
                        <h2>v0.0.2</h2>
                        <h3>Added</h3>
                        <ul class="changelog-list changelog-list--added">
                            <li>ChangeLog screen</li>
                            <li>New Encounter: Shop - with the ability to spend gold to buy cards, delete cards, heal, increase maximum health.</li>
                            <li>Added stats tracking throughout the game</li>
                            <li>New Stat: Total Damage Done</li>
                            <li>New Stat: Total Damage Received</li>
                            <li>New Stat: Total Shields Added</li>
                            <li>New Stat: Total Damage Mitigated Through Shields</li>
                            <li>New Stat: Shield Effectiveness (Damage Mitigated / Shields Added)</li>
                            <li>New Stat: Total Healing Done</li>
                            <li>New Stat: Total Enemies Killed</li>
                            <li>New Stat: Average Number of Turns to kill an enemy</li>
                            <li>New Stat: Total Red Mana Spent</li>
                            <li>New Stat: Total Green Mana Spent</li>
                            <li>New Stat: Total Blue Mana Spent</li>
                            <li>New Stat: Favourite Card (most played card)</li>
                            <li>New Stat: Highest Damage done in a single attack</li>
                            <li>New Stat: Gold Spend</li>
                            <li>New Stat: Total Score (based on enemies defeated, shield effectiveness, avg turns for a kill and highest damage in a turn)</li>
                            <li>Deck Viewer in Top Bar</li>
                            <li>UI: New Shield UI for increased visibility of shields</li>
                        </ul>
                        <h3>Changed</h3>
                        <ul class="changelog-list changelog-list--changed">
                            <li>UI: Improved positioning of status effects icons</li>
                        </ul>
                        <h3>Fixed</h3>
                        <ul class="changelog-list changelog-list--changed">
                            <li>Fixed: Starting a new game will correctly reset the deck.</li>
                        </ul>
                    </div>
                    <div class="changelog-release">
                        <h2>v0.0.1</h2>
                        <h3>Added</h3>
                        <ul class="changelog-list changelog-list--added">
                            <li>Initial release!</li>
                            <li>Base play game mode</li>
                            <li>New Location: Enchanted Forest</li>
                            <li>New Enemy: Village Guard</li>
                            <li>New Enemy: Barbarian</li>
                            <li>New Enemy: Medic</li>
                            <li>New Enemy: Venomous Snake</li>
                            <li>New Enemy: Powerful Mage</li>
                            <li>New Enemy: Warrior</li>
                            <li>New Enemy: Leaking Fuse</li>
                            <li>New Enemy: Dryad</li>
                            <li>New Enemy: Army General</li>
                            <li>New Enemy: Blue Mage</li>
                            <li>New Encounter: Turnstile</li>
                            <li>New Encounter: Mysterious Chest</li>
                            <li>Credits Screen</li>
                            <li>Top Bar for stats</li>
                        </ul>
                    </div>
                </div>
                <div className="actions">
                    <button className="action action--primary" onClick={() => this.props.backToHome()}>
                        <span>Back to Home Page</span>
                    </button>
                </div>
            </div>
        );
    }
}

export default ChangelogScreen;
