import React from 'react';
import Overlay from './Overlay';

class Credits extends React.Component
{
    render()
    {
        return (
            <Overlay
                overlayClasses="credits-overlay"
                title="Credits"
                display={this.props.display}
                closeOverlay={this.props.closeOverlay}
                name='credits'
                >
                <dl className="credits__list">
                    <dt>Lead Backend Engineer</dt>
                    <dd>David Fox</dd>
                    <dt>Lead Frontend Engineer</dt>
                    <dd>David Fox</dd>
                    <dt>Lead Designer</dt>
                    <dd>David Fox</dd>
                    <dt>QA Tester</dt>
                    <dd>David Fox</dd>
                    <dt>Project Management</dt>
                    <dd>David Fox</dd>
                    <dt>Concept</dt>
                    <dd>David Fox</dd>
                    <dt>Lead Sound Engineer</dt>
                    <dd>David Fox</dd>
                    <dt>Score</dt>
                    <dd>
                        <ul>
                            <li>Caves of Sorrow - Alexandr Zhelanov, https://soundcloud.com/alexandr-zhelanov</li>
                            <li>Dark Forest Theme - The Cynic Project / cynicmusic.com / pixelsphere.org</li>
                            <li>The Last Encounter - Matthew Pablo - http://www.matthewpablo.com</li>
                            <li>Boss Battle Music - https://opengameart.org/content/boss-battle-music</li>
                            <li>Medieval Kings Feast - https://opengameart.org/content/medieval-kings-feast</li>
                            <li>Battle March - Epic Orchestral Music Loop - playonloop.com</li>
                            <li>Colossal Boss Battle Theme - https://opengameart.org/content/colossal-boss-battle-theme</li>
                            <li>Through Space - https://opengameart.org/content/through-space</li>
                            <li>Dark Descent - Matthew Pablo - http://www.matthewpablo.com</li>
                            <li>Boss Theme - https://opengameart.org/content/boss-theme</li>
                        </ul>
                    </dd>
                    <dt>Sound Effects</dt>
                    <dd>Sound effects obtained from https://www.zapsplat.com</dd>
                    <dt>Art Direction</dt>
                    <dd>David Fox</dd>
                    <dt>Art Sources:</dt>
                    <dd>
                        <ul>
                            <li>David Fox - ui icons and vectors</li>
                            <li>https://opengameart.org/ - pauliuw - Status Effect Icons</li>
                            <li>https://opengameart.org/ - eleazzaar (J. W. Bjerk) - Status Effect Icons and some card imagery</li>
                            <li>https://opengameart.org/ - Justin Nichol - Enemy and hero avatars</li>
                            <li>https://opengameart.org/ - JoesAlotofthings - Ui (mana)</li>
                            <li>https://www.fontspace.com/pjm-homebrew-fonts - Font Face</li>
                            <li>https://tmeixnerart.artstation.com/ - Taryn Meixner - Home Screen</li>
                            <li>Humble Bundle - artifacts</li>
                            <li>Humble Bundle - card imagery</li>
                            <li>Humble Bundle - ui</li>
                            <li>https://cooltext.com/ - Cool Text Graphics Generator - Logo</li>
                            <li>https://www.svgrepo.com - Some SVGs </li>
                        </ul>
                    </dd>
                </dl>
            </Overlay>
        );
    }
}

export default Credits;
