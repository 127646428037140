import ConditionFactory from "../Conditions/ConditionFactory";
import AbstractValue from "./AbstractValue";

class ConditionValue extends AbstractValue
{
    parseVal(val)
    {
        return ConditionFactory.createConditionFromJson(val);
    }

    calculate(context)
    {
        return this.val.isMet(context);
    }
}

export default ConditionValue;
