import React from 'react';
import Hand from './Hand';
import PlayerState from './PlayerState';

class EnemyPlayArea extends React.Component {

    render() {
        return (
            <div className="enemyarea">
                <PlayerState
                    type='enemy'
                    hp={this.props.hp}
                    maxHp={this.props.maxHp}
                    mana={this.props.mana}
                    manaRegen={this.props.manaRegen}
                    statusEffects={this.props.statusEffects}
                    artifacts={this.props.artifacts}
                    name={this.props.name}
                    image={this.props.image}
                />
                <Hand
                    type='enemy'
                    hand={this.props.hand}
                    playCard={() => {}}
                />
            </div>
        );
    }
}

export default EnemyPlayArea;
