import React from 'react';

class EndScreen extends React.Component
{
    render() {
        return (
            <div className="end-screen">
                <h1 className="end-title">Congratulations!</h1>
                <p>You beat the game!</p>
                <ul className="end-screen-stats">
                    <li>Total Score: {this.props.stats.totalScore}</li>
                    <li>Total Red Mana Spent: {this.props.stats.totalRedManaSpent}</li>
                    <li>Total Green Mana Spent: {this.props.stats.totalGreenManaSpent}</li>
                    <li>Total Blue Mana Spent: {this.props.stats.totalBlueManaSpent}</li>
                    <li>Total Damage Done: {this.props.stats.totalDamageDone}</li>
                    <li>Total Damage Received: {this.props.stats.totalDamageReceived}</li>
                    <li>Total Shields Added: {this.props.stats.totalShieldsAdded}</li>
                    <li>Total Damage Mitigated with Shields: {this.props.stats.totalDamageMitigated}</li>
                    <li>Shield Effectiveness: {this.props.stats.shieldEffectiveness}</li>
                    <li>Total Healing Done: {this.props.stats.totalHealing}</li>
                    <li>Total Enemies Killed: {this.props.stats.enemiesDefeated}</li>
                    <li>Average Turns Taken to defeat Enemy: {this.props.stats.averageTurnsForKill}</li>
                    <li>Highest Damage Done in a single attack: {this.props.stats.highestDamageDealt}</li>
                    <li>Total Gold Spent: {this.props.stats.goldSpent}</li>
                    <li>Favourite Card: {this.props.stats.favouriteCardName}</li>
                </ul>
                <div className="actions">
                    <button className="action action--primary" onClick={() => this.props.backToHome()}>
                        <span>Back to Home Page</span>
                    </button>
                </div>
            </div>
        );
    }
}

export default EndScreen;
