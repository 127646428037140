import {shuffle} from './Helper';

import Card from './Card';
import { GameFactory } from './Game';

class Deck
{
    constructor()
    {
        this.deck = [];
        this.discard = [];
        this.exile = [];
        this.hand = [];
        this.player = null;
    }

    static createFromDeckIds(deckIds)
    {
        let deck = new Deck();

        for (var id in deckIds) {
            let count = deckIds[id];
            for (var i = 0; i < count; i++) {
                let card = Card.createFromCardId(id);
                deck.addToDeck(card);
            }
        }

        return deck;
    }

    clone()
    {
        let newDeck = new Deck();
        newDeck.deck = this.deck.map(function(card) {
            let newCard = card.clone();
            newCard.deck = newDeck;
            return newCard;
        });
        newDeck.hand = this.hand.map(function(card) {
            let newCard = card.clone();
            newCard.deck = newDeck;
            return newCard;
        });
        newDeck.exile = this.exile.map(function(card) {
            let newCard = card.clone();
            newCard.deck = newDeck;
            return newCard;
        });
        newDeck.discard = this.discard.map(function(card) {
            let newCard = card.clone();
            newCard.deck = newDeck;
            return newCard;
        });
        return newDeck;
    }

    getDeckState()
    {
        return {
            deck: this.deck.map(card => card.getCardState()),
            hand: this.hand.map(card => card.getCardState()),
            discard: this.discard.map(card => card.getCardState()),
            exile: this.exile.map(card => card.getCardState())
        };
    }

    addToDeck(card, destination="deck")
    {
        this[destination].push(card);
        card.deck = this;
        if (destination === "deck") {
            this.deck = shuffle(this.deck);
        }

        let game = GameFactory.getCurrentGame();
        if (game !== undefined) {
            game.trigger("afterAddToDeck", {
                game: game,
                card: card,
                deckDestination: destination
            });
        }
    }

    shuffleDeck()
    {
        this.deck = shuffle(this.deck);
    }

    removeFromDeck(cardToRemove)
    {
        cardToRemove.deck = false;
        let newDeck = this.deck.filter(card => card.uid !== cardToRemove.uid);
        this.deck = newDeck;
        this.deck = shuffle(this.deck);
    }

    reset()
    {
       let deck = [...this.deck, ...this.discard, ...this.hand, ...this.exile];
       this.deck = shuffle(deck);
       this.discard = [];
       this.exile = [];
       this.hand = [];
    }

    draw(count, context)
    {
        let actualCount = 0;
        for (let i=0; i<count; i++) {
            if (this.deck.length === 0) {
              this.deck = shuffle(this.discard);
              this.discard = [];
            }
            let card = this.deck.shift();
            if (card !== undefined) {
                this.hand.push(card);
                actualCount++
            }
        }

        if (context.battle !== undefined) {
            context.battle.game.trigger('afterDraw', {
                battle: context.battle,
                game: context.battle.game,
                player: this.player,
                drawCount: actualCount
            });
        }
    }

    afterCardPlayed(card, context)
    {
        if (card.shouldExile()) {
            this.move(card, 'hand', 'exile');

            context.battle.game.trigger('afterExileCard', {
                card: card,
                player: context.player,
                enemy: context.enemy,
                battle: context.battle
            });

            context.battle.game.trigger('afterEnemyExileCard', {
                card: card,
                player: context.enemy,
                enemy: context.player,
                battle: context.battle
            });

            return;
        }
        if (card.shouldGiveToEnemy()) {
            this['hand'] = this['hand'].filter(c => c.uid !== card.uid);
            context.enemy.deck.addToDeck(card);
        }
        if (card.isTemporary()) {
            this['hand'] = this['hand'].filter(c => c.uid !== card.uid);
            return;
        }
        this.move(card, 'hand', 'discard');
    }

    move(card, from, to)
    {
        let cardsToMove = this[from].filter(c => c.uid === card.uid);
        this[from] = this[from].filter(c => c.uid !== card.uid);
        let game = GameFactory.getCurrentGame();
        cardsToMove.map(function (c) {
            this[to].push(c);
            game.trigger('moveFrom' + from.charAt(0).toUpperCase() + from.slice(1), {
                card: c,
                battle: game?.battle,
                player: this?.player,
                game: game
            });

            game.trigger('moveTo' + to.charAt(0).toUpperCase() + to.slice(1), {
                card: c,
                battle: game?.battle,
                player: this?.player,
                game: game
            });
        }.bind(this));
    }

    discardHand(force = false)
    {
        if (force) {
            this.hand.map(card => this.discardFromHand(card));
        } else {
            this.hand.filter(card => !card.shouldKeep() && !card.isTemporary()).map(card => this.discardFromHand(card));
            this.hand = this.hand.filter(card => card.shouldKeep());
        }
    }

    discardFromHand(card)
    {
        this.move(card, 'hand', 'discard');

        let game = GameFactory.getCurrentGame();
        game.trigger('afterCardDiscard', {
            card: card,
            battle: game.battle,
            player: this?.player,
            game: game
        });
    }

    purgeDuplicates()
    {
        let allCards = [...this.deck, ...this.hand, ...this.discard, ...this.exile];

        let cardsByName = [];

        allCards.forEach(function (card) {
            cardsByName[card.name] = card;
        });

        let cardUidsToRemove = allCards.filter(function (card) {
            return cardsByName[card.name] !== card;
        }).map(card => card.uid);

        this.deck = this.deck.filter((card) => {
            return !cardUidsToRemove.includes(card.uid);
        });

        this.hand = this.hand.filter((card) => {
            return !cardUidsToRemove.includes(card.uid);
        });

        this.discard = this.discard.filter((card) => {
            return !cardUidsToRemove.includes(card.uid);
        });

        this.exile = this.exile.filter((card) => {
            return !cardUidsToRemove.includes(card.uid);
        });
    }
}

export default Deck;
