import React from 'react';
import Card from './Card';
import Overlay from './Overlay';

class XpOverlay extends React.Component
{
    render()
    {
        let actions = [
            {
                "action": this.props.retire,
                "text": "Back To Main Menu"
            }
        ];

        let achievementsSection = (this.props.achievementsUnlocked.length > 0)
            ? (
                <div className="achievements">
                    <h2 className="achievements__title">Achievements Unlocked!</h2>
                    <div className="unlocks unlocks--center">
                        {this.props.achievementsUnlocked.map(function (achievement) {
                            return (
                                <div className="unlock-preview">
                                    <img className="unlock-image" src={achievement.image} />
                                    <h3 className='unlock-name'>{achievement.name}</h3>
                                    <p className="unlock-description">{achievement.description}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )
            : '';

        let cardsUnlocked = this.props.cardsUnlocked.map(card => {
            return (
                <div class="unlock-preview">
                    <Card
                        id={card.id}
                        key={card.uid}
                        uid={card.uid}
                        name={card.name}
                        image={card.image}
                        description={card.description}
                        tier={card.tier}
                        cost={card.cost}
                        playCard={() => {}}
                        addUnplayableClass={false}
                        noBorder={false}
                        aboutToPlay={false}
                        showBackFace={false}
                    >
                    </Card>
                </div>
            )
        });

        let artifactsUnlocked = this.props.artifactsUnlocked.map(artifactData => {
            return (
                <div id={artifactData.uid} key={'artifact_' + artifactData.uid} className="unlock-preview">
                    <img src={artifactData.image} title={artifactData.description} className="unlock-image" />
                    <h3 className="unlock-name">{artifactData.name}</h3>
                    <p className="unlock-description">{artifactData.description}</p>
                </div>
            )
        });

        let enemiesUnlocked = this.props.enemiesUnlocked.map(enemyData => {
            return (
                <div id={enemyData.uid} key={'enemy_' + enemyData.uid} className="unlock-preview">
                    <img src={enemyData.image} title={enemyData.description} className="unlock-image" />
                    <h3 className="unlock-name">{enemyData.name}</h3>
                    <p className="unlock-description">{enemyData.description}</p>
                </div>
            )
        });

        let modesUnlocked = this.props.modesUnlocked.map(modeData => {
            console.log(modeData);
            // return (
            //     // <div id={modeData.id} key={'mode_' + modeData.id} className="mode">
            //     //     <img src={modeData.image} title={modeData.description} />
            //     // </div>
            // )
        });

        let unlocks = [...cardsUnlocked, ...artifactsUnlocked, ...enemiesUnlocked, ...modesUnlocked];

        let newLevelSection = this.props.hasGainedLevel
            ? (
                <div className="experience-unlocks">
                    <h2 className="unlocks-title">Level {this.props.currentLevel} reached! New Unlocks:</h2>
                    <div class="unlocks">
                        {unlocks}
                    </div>
                </div>
            )
            : '';

        return (
            <Overlay
                overlayClasses="finish-overlay overlay--small"
                title="Progress gained"
                display={this.props.display}
                name="xp"
                actions={actions}
                showClose={false}
                >
                {achievementsSection}
                <div className="experience-breakdown">
                    <h2 className="experience-breakdown__title">Experience Gained</h2>
                    <dl className="experience-breakdown__list inline-flex">
                        <dd>Defeating enemies:</dd>
                        <dt>{this.props.xpBreakdown.enemiesDefeated}</dt>
                        {this.props.xpBreakdown.lossPenalty !== undefined ?
                            (
                                <dd className="experience-breakdown__list-item--negative">Loss Penalty</dd>
                            )
                            : ''
                        }
                        {this.props.xpBreakdown.lossPenalty !== undefined ?
                            (
                                <dt className="experience-breakdown__list-item--negative">{this.props.xpBreakdown.lossPenalty}</dt>
                            )
                            : ''
                        }
                        {this.props.xpBreakdown.achievementsUnlocked !== undefined ?
                            (
                                <dd>Achievements Unlocked</dd>
                            )
                            : ''
                        }
                        {this.props.xpBreakdown.achievementsUnlocked !== undefined ?
                            (
                                <dt>{this.props.xpBreakdown.achievementsUnlocked}</dt>
                            )
                            : ''
                        }
                        <dd className="experience-breakdown__list-item--total">Total experience gained:</dd>
                        <dt className="experience-breakdown__list-item--total">{Object.values(this.props.xpBreakdown).reduce((a, b) => a + b)}</dt>
                    </dl>
                </div>

                <div className="experience-progress">
                    <div className="experience-progress__level experience-progress__level--current">
                        <img className="experience-progress__image" src="/images/new/heroes/generic.png" />
                        <span>{this.props.currentLevel}</span>
                    </div>
                    <div className="experience-progress__progress-wrapper">
                        <progress min="0" max="100" value={this.props.currentPercentProgress} className="experience-progress__progress"></progress>
                        <span>{this.props.currentXpRemaining} XP till level {this.props.currentLevel + 1}</span>
                    </div>
                    <div className="experience-progress__level experience-progress__level--next">
                        <img className="experience-progress__image" src="/images/new/heroes/generic.png" />
                        <span>{this.props.currentLevel + 1}</span>
                    </div>
                </div>

                {newLevelSection}

            </Overlay>
        );
    }
}

export default XpOverlay;
