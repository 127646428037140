import React from 'react';

class Mana extends React.Component
{
    getManaClasses()
    {
        let classes = 'mana mana--' + this.props.colour;
        return classes;
    }

    getImage()
    {
        return "/images/new/ui/mana-" + this.props.colour + ".png";
    }

    render()
    {
        let regen = this.props.regen
            ? (
                <span className="mana-regen">(+{this.props.regen})</span>
            )
            : '';

        return (
            <div className={this.getManaClasses()}>
                <div className="icon">
                    <img src={this.getImage()} />
                    <span className="icon__overlay">{this.props.amount}</span>
                </div>
                {regen}
            </div>
        );
    }
}

export default Mana;
