import StatusEffectFactory from "../StatusEffects/StatusEffectFactory";
import AbstractEffect from "./AbstractEffect";

class TriggersEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            if (this.data.enemy !== undefined) {
                Object.keys(this.data.enemy).forEach((key, pos) => {
                    let value = this.data.enemy[key];
                    let newContext = {...context};
                    newContext.enemy = context.player;
                    newContext.player = context.enemy;
                    this.handleTrigger(key, value, newContext);
                });
            }
            if (this.data.player !== undefined) {
                Object.keys(this.data.player).forEach((key, pos) => {
                    let value = this.data.enemy[key];
                    let newContext = {...context};
                    this.handleTrigger(key, value, newContext);
                });
            }
        }
    }

    handleTrigger(key, value, context)
    {
        for (let i = 0; i < value; i++) {
            switch (key) {
                case 'poison':
                    context.battle.history.write(context.player.name + ' takes poison damage', context.historySourceImage);
                    context.player.getStatusEffect('poison').activate(context);
                    return;

                default:
                    return;
            }
        }
    }

    generateDescription()
    {
        let descriptionSentences = [];

        if (this.data.player !== undefined) {
            Object.keys(this.data.player).forEach(function(key) {
                let val = this.data.player[key];
                let name = StatusEffectFactory.createDummyEffect(key).name;
                let sentence = "Triggers " + name + ' effects on yourself';
                if (val === 1) {
                    sentence += '.'
                } else {
                    sentence += ', ' + val + ' ' + this.pluralise('time', val) + '.';
                }
                descriptionSentences.push(sentence);
            }.bind(this));
        }

        if (this.data.enemy !== undefined) {
            Object.keys(this.data.enemy).forEach(function(key) {
                let val = this.data.enemy[key];
                let name = StatusEffectFactory.createDummyEffect(key).name;
                let sentence = "Triggers " + name + ' effects on your enemy';
                if (val === 1) {
                    sentence += '.'
                } else {
                    sentence += ', ' + val + ' ' + this.pluralise('time', val) + '.';
                }
                descriptionSentences.push(sentence);
            }.bind(this));
        }

        return descriptionSentences.join(' ');
    }
}

export default TriggersEffect;
