import {locationsData} from '../data/locations';

import ChestEncounter from './ChestEncounter';
import TurnstileEncounter from './TurnstileEncounter';
import ShopEncounter from './ShopEncounter';
import EnemyEncounter from './EnemyEncounter';
import NextLocationEncounter from './NextLocationEncounter';
import BlessingEncounter from './BlessingEncounter';
import PandoraEncounter from './PandoraEncounter';
import MimicEncounter from './MimicEncounter';

class EncounterFactory
{
    static createEncounter(encounterId, game)
    {
        let encounters = [...locationsData];
        let encounter = encounters.filter(value => value.id === encounterId)[0];

        switch (encounter.type) {
            case 'chest':
                return new ChestEncounter(encounter, game);

            case 'turnstile':
                return new TurnstileEncounter(encounter, game);

            case 'shop':
                return new ShopEncounter(encounter, game);

            case 'enemy':
                return new EnemyEncounter(encounter, game);

            case 'blessing':
                return new BlessingEncounter(encounter, game);

            case 'nextLocation':
                return new NextLocationEncounter(encounter, game);
        }
    }

    static createNextLocationEncounter()
    {
        return new NextLocationEncounter;
    }

    static createEnemyEncounter(enemyId)
    {
        return new EnemyEncounter(enemyId);
    }

    static createChestEncounter()
    {
        return new ChestEncounter;
    }

    static createTurnstileEncounter()
    {
        return new TurnstileEncounter;
    }

    static createShopEncounter()
    {
        return new ShopEncounter;
    }

    static createBlessingEncounter()
    {
        return new BlessingEncounter;
    }

    static createPandoraEncounter()
    {
        return new PandoraEncounter;
    }

    static createMimicEncounter()
    {
        return new MimicEncounter;
    }
}

export default EncounterFactory;
