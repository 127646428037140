export const enemiesData = {
    "1": {
        "id": 1,
        "locationId": 1,
        "name": "Goblin Hermit",
        "description": "An old, wizened goblin stands in your way.",
        "image": "/images/new/enemies/goblinhermit.png",
        "hp": 15,
        "level": 1,
        "mana": {
            "red": 2,
            "green": 2,
            "blue": 2
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 3,
            94: 1,
            3: 1,
            84: 3
        },
        "cardDraw": 2,
        "gold": 10,
        "effects": [],
        "effectDescription": "The Goblin Hermit has no special abilities.",
        "unlockLevel": 1
    },
    "2": {
        "id": 2,
        "locationId": 1,
        "name": "Frogling",
        "description": "A cute little frog! But... it's glowing?",
        "image": "/images/new/enemies/frogling.png",
        "hp": 20,
        "level": 1,
        "mana": {
            "red": 2,
            "green": 2,
            "blue": 2
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 2,
            94: 1,
            19: 1,
            84: 2,
            100: 3
        },
        "cardDraw": 2,
        "gold": 15,
        "effects": [
            {
                "trigger": "beforeTakeDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "random",
                                "val": [
                                    {
                                        "valType": "flat",
                                        "val": 0
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 100
                                    }
                                ],
                            },
                            {
                                "valType": "flat",
                                "val": 85
                            }
                        ]
                    }
                ],
                "effect": "dodgeDamage"
            }
        ],
        "effectDescription": "Has a 15% chance of dodging damage.",
        "effectSound": "/sounds/effects/whoosh.mp3",
        "unlockLevel": 1
    },
    "3": {
        "id": 3,
        "locationId": 1,
        "name": "Decaying Skeleton",
        "description": "This frail skeleton is falling apart...",
        "image": "/images/new/enemies/skeleton.png",
        "hp": 100,
        "level": 1,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "manaRegen": {
            "red": 2,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 3,
            84: 3
        },
        "cardDraw": 2,
        "gold": 20,
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "effect": "takeDamage",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "percentage",
                        "valProperty": "playerMaxHp",
                        "val": 20
                    }
                }
            }
        ],
        "effectDescription": "The Decaying Skeleton loses 20% of it's maximum health every turn.",
        "unlockLevel": 1
    },
    "4": {
        "id": 4,
        "locationId": 1,
        "name": "Goblin Scout",
        "description": "This scout has holed up in a defensive position.",
        "image": "/images/new/enemies/goblinscout.png",
        "hp": 25,
        "level": 2,
        "mana": {
            "red": 2,
            "green": 2,
            "blue": 2
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 5,
            94: 1,
            3: 1,
            19: 1,
            84: 2
        },
        "cardDraw": 3,
        "gold": 20,
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "effect": "playerGainShield",
                "val": {
                    "valType": "flat",
                    "val": 4
                }
            }
        ],
        "effectDescription": "Gains 4 shield at the beginning of it's turn.",
        "unlockLevel": 1
    },
    "5": {
        "id": 5,
        "locationId": 1,
        "name": "Treefolk",
        "image": "/images/new/enemies/treefolk.png",
        "description": "Is it just me, or did that tree just blink?",
        "hp": 30,
        "level": 2,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 2
        },
        "manaRegen": {
            "red": 2,
            "green": 2,
            "blue": 3
        },
        "deck": {
            1: 2,
            3: 1,
            16: 1,
            84: 2,
            15: 1
        },
        "cardDraw": 2,
        "gold": 20,
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "calc",
                                "valCalc": "remainder",
                                "val": [
                                    {
                                        "valType": "percentage",
                                        "val": "100",
                                        "valProperty": "turnCount"
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 2
                                    }
                                ]
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "playerGainThorns",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "effectDescription": "Gains 1 Thorn at the start of every other turn.",
        "unlockLevel": 1
    },
    "6": {
        "id": 6,
        "locationId": 1,
        "name": "Banshee",
        "image": "/images/new/enemies/banshee.png",
        "description": "It moves silently - but lets out a deafening scream if approached.",
        "level": 2,
        "hp": 30,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 2,
            94: 1,
            3: 1,
            15: 1,
            17: 1,
            27: 1,
            30: 1
        },
        "cardDraw": 3,
        "gold": 30,
        "effects": [
            {
                "trigger": "afterEnemyExileCard",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "cardExiled"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "takeCard"
            }
        ],
        "effectDescription": "When you exile a card, the Banshee will place it into their own deck.",
        "unlockLevel": 1
    },
    "7": {
        "id": 7,
        "locationId": 1,
        "name": "Ooze",
        "description": "Urgh, it's all sticky over here!",
        "image": "/images/new/enemies/ooze.png",
        "hp": 20,
        "level": 2,
        "mana": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "manaRegen": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "deck": {
            84: 3,
            1: 1
        },
        "cardDraw": 1,
        "gold": 15,
        "effects": [
            {
                "trigger": "endPlayerTurn",
                "effect": "enemyDiscard",
                "val": {
                    "valType": "calc",
                    "valCalc": "max",
                    "val": [
                        {
                            "valType": "flat",
                            "val": 0
                        },
                        {
                            "valType": "calc",
                            "valCalc": "subtract",
                            "val": [
                                {
                                    "valType": "percentage",
                                    "val": 100,
                                    "valProperty": "enemyDraw"
                                },
                                {
                                    "valType": "percentage",
                                    "val": 100,
                                    "valProperty": "enemyToBeDiscarded"
                                },
                                {
                                    "valType": "flat",
                                    "val": 1
                                }
                            ]
                        }
                    ]
                }
            }
        ],
        "effectDescription": "The Ooze slows you down, and you are only able to draw 1 card per turn!",
        "unlockLevel": 2
    },
    "8": {
        "id": 8,
        "locationId": 1,
        "name": "Goblin",
        "image": "/images/new/enemies/goblin.png",
        "description": "The Goblin is annoyed you found his hideout, and is gearing up for a fight!",
        "hp": 35,
        "level": 3,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 3,
            2: 1,
            3: 2,
            4: 1
        },
        "cardDraw": 4,
        "gold": 25,
        "effects": [
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "cardCostRed"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "dealDamage",
                "val": {
                    "valType": "percentage",
                    "valProperty": "internalCounter",
                    "val": 100
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "resetCounter"
            }
        ],
        "effectDescription": "At the end of his turn, the Goblin deals damage to you for each red card he has played that turn.",
        "unlockLevel": 1
    },
    "9": {
        "id": 9,
        "locationId": 1,
        "name": "Orc",
        "image": "/images/new/enemies/orc.png",
        "description": "You see the destruction of the trees long before you come across the Orc itself...",
        "hp": 30,
        "level": 3,
        "mana": {
            "red": 4,
            "green": 4,
            "blue": 4
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 3,
            94: 1,
            8: 2,
            89: 1
        },
        "cardDraw": 2,
        "gold": 25,
        "effects": [
            {
                "trigger": "endPlayerTurn",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "calc",
                                "valCalc": "remainder",
                                "val": [
                                    {
                                        "valType": "percentage",
                                        "val": "100",
                                        "valProperty": "turnCount"
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 2
                                    }
                                ]
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "playerMuster",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "endPlayerTurn",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "calc",
                                "valCalc": "remainder",
                                "val": [
                                    {
                                        "valType": "percentage",
                                        "val": "100",
                                        "valProperty": "turnCount"
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 2
                                    }
                                ]
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "playerIncreaseArmour",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "effectDescription": "The Orc gains 1 Muster and 1 Armour at the end of its turn.",
        "unlockLevel": 1
    },
    "10": {
        "id": 10,
        "locationId": 1,
        "name": "Spider Hive",
        "image": "/images/new/enemies/giantspider.png",
        "description": "I HATE spiders!!!",
        "hp": 50,
        "level": 3,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 2,
            94: 1,
            30: 1,
            84: 1,
            46: 1,
            59: 1
        },
        "cardDraw": 3,
        "gold": 30,
        "effects": [
            {
                "trigger": "beforeTakeDamage",
                "effect": "limitDamage",
                "val": {
                    "valType": "flat",
                    "val": 4
                }
            }
        ],
        "effectDescription": "The Spider Hive can only take up to 4 damage at a time.",
        "unlockLevel": 1
    },
    "11": {
        "id": 11,
        "locationId": 1,
        "name": "Goblin King",
        "image": "/images/new/enemies/goblinbigboss.png",
        "description": "In the darkest part of the forest, the Goblin King has been preparing for your arrival...",
        "backgroundMusic": "/sounds/music/boss-forest.wav",
        "hp": 50,
        "level": 3,
        "boss": true,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            90: 1,
            15: 1,
            2: 1,
            94: 1,
            1: 4,
            3: 1
        },
        "cardDraw": 2,
        "gold": 50,
        "effects": [
            {
                "trigger": "afterDealDamage",
                "conditions": [],
                "effect": "inflictBleed",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "effectDescription": "The Goblin King inflicts Bleed with every attack.",
        "unlockLevel": 1
    },
    "12": {
        "id": 12,
        "locationId": 2,
        "name": "Crystal Golem",
        "description": "A shining golem witha tough shell.",
        "image": "/images/new/enemies/crystal_golem.png",
        "hp": 40,
        "level": 4,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 2
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 2
        },
        "deck": {
            1: 3,
            2: 1,
            37: 1,
            29: 1,
            27: 1
        },
        "cardDraw": 3,
        "gold": 25,
        "effects": [
            {
                "trigger": "enemyGainShield",
                "effect": "playerGainShield",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "percentage",
                        "val": "50",
                        "valProperty": "shieldVal"
                    }
                }
            }
        ],
        "effectDescription": "Whenever you gain shield, the Crystal Golem gains half of that shield.",
        "unlockLevel": 8
    },
    "13": {
        "id": 13,
        "locationId": 2,
        "name": "Mage In Training",
        "description": "This young mage is working towards his doctorate in Advanced Wizardry.",
        "image": "/images/new/enemies/mage_in_training.png",
        "hp": 40,
        "level": 4,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 5
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 5
        },
        "deck": {
            1: 2,
            94: 1,
            3: 1,
            19: 1,
            27: 1,
            42: 1
        },
        "cardDraw": 3,
        "gold": 25,
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "incrementAllCardsCostBlue",
                "val": {
                    "valType": "flat",
                    "val": "1"
                }
            }
        ],
        "effectDescription": "Every card played in this battle costs 1 blue mana more.",
        "unlockLevel": 1
    },
    "14": {
        "id": 14,
        "locationId": 2,
        "name": "Wight",
        "description": "This ghostly vision has a few tricks up her sleeve...",
        "image": "/images/new/enemies/wight.png",
        "hp": 40,
        "level": 4,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 3,
            94: 1,
            3: 1,
            75: 1,
            76: 1,
            80: 1,
        },
        "cardDraw": 3,
        "gold": 25,
        "effects": [
            {
                "trigger": "beforeEnemyPlayCard",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "flat",
                                "val": 1
                            },
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            }
                        ]
                    }
                ],
                "effect": "playerGainManaBlue",
                "val": {
                    "valType": "percentage",
                    "val": "100",
                    "valProperty": "cardCostBlue"
                }
            },
            {
                "trigger": "beforeEnemyPlayCard",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "flat",
                                "val": 1
                            },
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            }
                        ]
                    }
                ],
                "effect": "playerGainManaGreen",
                "val": {
                    "valType": "percentage",
                    "val": "100",
                    "valProperty": "cardCostGreen"
                }
            },
            {
                "trigger": "beforeEnemyPlayCard",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "flat",
                                "val": 1
                            },
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            }
                        ]
                    }
                ],
                "effect": "playerGainManaRed",
                "val": {
                    "valType": "percentage",
                    "val": "100",
                    "valProperty": "cardCostRed"
                }
            },
            {
                "trigger": "beforeEnemyPlayCard",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "flat",
                                "val": 1
                            },
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            }
                        ]
                    }
                ],
                "effect": "resetCounter"
            },
            {
                "trigger": "startEnemyTurn",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1,
                }
            }
        ],
        "effectDescription": "The Wight will gain mana equal to the cost of the first card you play each turn.",
        "unlockLevel": 1
    },
    "15": {
        "id": 15,
        "locationId": 2,
        "name": "Water Elemental",
        "description": "The Water Elemental has mastery over blue mana.",
        "image": "/images/new/enemies/waterelemental.png",
        "hp": 50,
        "level": 5,
        "mana": {
            "red": 2,
            "green": 2,
            "blue": 5
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 6
        },
        "deck": {
            73: 1,
            75: 1,
            76: 1,
            80: 1,
            1: 1,
            2: 1,
            3: 1,
            93: 1,
            61: 1,
            62: 1,
            64: 1,
            65: 1,
            66: 1,
            67: 1,
            69: 1
        },
        "cardDraw": 3,
        "gold": 30,
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "setAllCardsCostBlue",
                "val": {
                    "valType": "flat",
                    "val": 0
                }
            }
        ],
        "effectDescription": "All cards cost zero blue mana to play.",
        "unlockLevel": 1
    },
    "16": {
        "id": 16,
        "locationId": 2,
        "name": "Demon Bard",
        "description": "The demon bard's music is so divine, you feel compelled to listen...",
        "image": "/images/new/enemies/demon_bard.png",
        "hp": 50,
        "level": 5,
        "mana": {
            "red": 4,
            "green": 4,
            "blue": 4
        },
        "manaRegen": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "deck": {
            7: 1,
            1: 1,
            9: 1,
            10: 1,
            15: 1,
            20: 1,
            46: 1
        },
        "cardDraw": 2,
        "gold": 30,
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerGainInvulnerable",
                "val": {
                    "valType": "flat",
                    "val": 4
                }
            },
            {
                "trigger": "battleStart",
                "effect": "playerFuture",
                "val": {
                    "valType": "flat",
                    "val": [
                        {
                            "turns":4,
                            "effects": {
                                "damage": 1000
                            }
                        }
                    ]
                }
            }
        ],
        "effectDescription": "You cannot damage the Demon Bard! But, his magical allure should fade off after 4 turns...",
        "unlockLevel": 1
    },
    "17": {
        "id": 17,
        "locationId": 2,
        "name": "Vampire",
        "description": "This vampire looks thirsty...",
        "image": "/images/new/enemies/vampire.png",
        "hp": 50,
        "level": 5,
        "mana": {
            "red": 4,
            "green": 4,
            "blue": 4
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 3,
            2: 1,
            3: 2,
            15: 1,
            8: 1,
            89: 1
        },
        "cardDraw": 3,
        "gold": 30,
        "effects": [
            {
                "trigger": "afterDealDamage",
                "effect": "playerHeal",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "percentage",
                        "val": 100,
                        "valProperty": "damageReceived"
                    }
                }
            }
        ],
        "effectDescription": "The Vampire will heal for unblocked damage dealt.",
        "unlockLevel": 1
    },
    "18": {
        "id": 18,
        "locationId": 2,
        "name": "Demon Warlock",
        "description": "The demon warlock is surrounded by a malevolent aura...",
        "image": "/images/new/enemies/demon_warlock.png",
        "hp": 50,
        "level": 5,
        "mana": {
            "red": 4,
            "green": 4,
            "blue": 4
        },
        "manaRegen": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "deck": {
            1: 3,
            2: 1,
            3: 2,
            46: 2,
            24: 1,
            31: 1,
            40: 1
        },
        "cardDraw": 3,
        "gold": 30,
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                        {
                                "comparison": "equalTo",
                                "vals": [
                                        {
                                                "valType": "calc",
                                                "valCalc": "remainder",
                                                "val": [
                                                    {
                                                        "valType": "percentage",
                                                        "val": "100",
                                                        "valProperty": "turnCount"
                                                    },
                                                    {
                                                        "valType": "flat",
                                                        "val": 2
                                                    }
                                                ]
                                        },
                                        {
                                                "valType": "flat",
                                                "val": 1
                                        }
                                ]
                        }
                ],
                "effect": "playerGainInvulnerable",
                "val": {
                        "valType": "flat",
                        "val": 1
                }
            }
        ],
        "effectDescription": "Every other turn, the Demon Warlock channels dark energy to become Invulnerable.",
        "unlockLevel": 1
    },
    "19": {
        "id": 19,
        "locationId": 2,
        "name": "Farsight Wizard",
        "description": "To be able to see into the future is a powerful gift indeed...",
        "image": "/images/new/enemies/farsight_wizard.png",
        "hp": 60,
        "level": 6,
        "mana": {
            "red": 4,
            "green": 4,
            "blue": 4
        },
        "manaRegen": {
            "red": 4,
            "green": 4,
            "blue": 4
        },
        "deck": {
            60: 1,
            12: 1,
            27: 1,
            46: 1,
            58: 1,
            16: 1,
            3: 3
        },
        "cardDraw": 3,
        "gold": 35,
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "enemyNegate",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "startPlayerTurn",
                "effect": "enemyNegate",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "effectDescription": "The Farsight Wizard sees the first card you play each turn, and negates it.",
        "unlockLevel": 1
    },
    "20": {
        "id": 20,
        "locationId": 2,
        "name": "Dragon",
        "description": "Your journey leads you to the lair of a fearsome dragon!",
        "image": "/images/new/enemies/dragon.png",
        "hp": 60,
        "level": 6,
        "mana": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "manaRegen": {
            "red": 5,
            "green": 4,
            "blue": 4
        },
        "deck": {
            17: 1,
            1: 2,
            15: 1,
            16: 1,
            23: 1,
            17: 1,
            35: 1,
            19: 2
        },
        "cardDraw": 3,
        "gold": 35,
        "effects": [
            {
                "trigger": "beforeTakeDamage",
                "effect": "stopCrit"
            }
        ],
        "effectDescription": "The Dragon is immune to Critical damage.",
        "unlockLevel": 1
    },
    "21": {
        "id": 21,
        "locationId": 2,
        "name": "Master Manipulator",
        "description": "Legend says that the highest members of the Blue Mage Order have the ability to control time itself...",
        "image": "/images/new/enemies/master_manipulator.png",
        "hp": 60,
        "level": 6,
        "mana": {
            "red": 4,
            "green": 4,
            "blue": 5
        },
        "manaRegen": {
            "red": 4,
            "green": 4,
            "blue": 5
        },
        "deck": {
            1: 3,
            2: 1,
            3: 2,
            4: 1,
            12: 3,
            27: 3
        },
        "cardDraw": 3,
        "gold": 35,
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "effect": "playerTimewalk",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "effectDescription": "At the beginning of their turn, the Master Manipulator will Timewalk 1.",
        "unlockLevel": 1
    },
    "22": {
        "id": 22,
        "locationId": 2,
        "name": "Master Sorcerer",
        "description": "The leader of the wizarding world would like a word with you about recent casualties in their ranks...",
        "image": "/images/new/enemies/master_sorceror.png",
        "hp": 100,
        "level": 6,
        "boss": true,
        "backgroundMusic": "/sounds/music/boss-plains.mp3",
        "mana": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "manaRegen": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "deck": {
            1: 3,
            2: 1,
            94: 1,
            3: 2,
            4: 1,
            64: 1,
            65: 1,
            66: 1,
            80: 1,
            27: 1,
            67: 1
        },
        "cardDraw": 4,
        "gold": 75,
        "statusEffects": {
            "manaMastery": 1
        },
        "effectDescription": "The Master Sorcerer can pay for cards using any mana.",
        "unlockLevel": 1
    },
    "23": {
        "id": 23,
        "locationId": 3,
        "name": "Imp",
        "description": "The imp strolls the battlefield, looking to scavenge from fallen heroes.",
        "image": "/images/new/enemies/imp.png",
        "hp": 75,
        "level": 7,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 5
        },
        "manaRegen": {
            "red": 6,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 2,
            94: 1,
            3: 1,
            84: 2,
            8: 1,
            15: 1
        },
        "cardDraw": 3,
        "gold": 40,
        "effects": [
            {
                "trigger": "afterPlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "cardCostRed"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "enemyDiscard",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "effectDescription": "After the Imp plays a red card, discard a card.",
        "unlockLevel": 1
    },
    "24": {
        "id": 24,
        "locationId": 3,
        "name": "Plague Zombie",
        "description": "I don't what to know what this zombie died of...",
        "image": "/images/new/enemies/plaguezombie.png",
        "hp": 75,
        "level": 7,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 5
        },
        "manaRegen": {
            "red": 3,
            "green": 6,
            "blue": 3
        },
        "deck": {
            1: 3,
            16: 3,
            2: 1,
            12: 1,
            15: 1,
            33: 1
        },
        "cardDraw": 3,
        "gold": 40,
        "effects": [
            {
                "trigger": "afterDealDamage",
                "effect": "enemyPoison",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "effectDescription": "The Plague Zombie inflicts 1 Poison whenever it attacks.",
        "unlockLevel": 1
    },
    "25": {
        "id": 25,
        "locationId": 3,
        "name": "Arms Dealer",
        "description": "Whenever there's a war, there are Arms Dealers, looking to make a profit.",
        "image": "/images/new/enemies/arms_dealer.png",
        "hp": 75,
        "level": 7,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 5
        },
        "manaRegen": {
            "red": 4,
            "green": 4,
            "blue": 2,
        },
        "deck": {
            1: 2,
            2: 1,
            5: 1,
            8: 1,
            10: 1,
            11: 1,
            84: 3,
            94: 2
        },
        "cardDraw": 3,
        "gold": 40,
        "effects": [
            {
                "trigger": "startEnemyTurn",
                "effect": "addCardToEnemyDeck",
                "val": {
                    "valType": "flat",
                    "val": 84
                }
            },
            {
                "trigger": "startEnemyTurn",
                "effect": "loseGold",
                "val": {
                    "valType": "flat",
                    "val": 5
                }
            }
        ],
        "effectDescription": "At the beginning of your turn, the Arms Dealer will add a Dagger into your hand, and take 5 of your gold.",
        "unlockLevel": 1
    },
    "26": {
        "id": 26,
        "locationId": 3,
        "name": "Faith Healer",
        "description": "Aren't medics supposed to be friendly?",
        "image": "/images/new/enemies/faith_healer.png",
        "hp": 85,
        "level": 8,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 5
        },
        "manaRegen": {
            "red": 3,
            "green": 5,
            "blue": 4
        },
        "deck": {
            1: 3,
            2: 1,
            3: 2,
            4: 2,
            33: 1,
            42: 1,
            43: 1,
            16: 1,
            58: 1,
            69: 1
        },
        "cardDraw": 3,
        "gold": 40,
        "effects": [
            {
                "trigger": "beforePlayerHeal",
                "effect": "increaseHealEffect",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "beforeEnemyHeal",
                "effect": "increaseHealEffect",
                "val": {
                    "valType": "percentage",
                    "val": -100,
                    "valProperty": "healVal"
                }
            }
        ],
        "effectDescription": "The Faith Healer's healing effects are more potent. She also stops you from healing at all.",
        "unlockLevel": 1
    },
    "27": {
        "id": 27,
        "locationId": 3,
        "name": "Giant",
        "description": "This man has a big club and an even bigger glare.",
        "image": "/images/new/enemies/giant.png",
        "hp": 85,
        "level": 8,
        "mana": {
            "red": 1,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 1,
            "green": 0,
            "blue": 0
        },
        "deck": {
            95: 3
        },
        "cardDraw": 1,
        "gold": 40,
        "effects": [
            {
                "trigger": "afterTakeDamage",
                "effect": "incrementCounter",
                "val": {
                    "valType": "percentage",
                    "val": 100,
                    "valProperty": "damageReceived"
                }
            },
            {
                "trigger": "afterTakeDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 2
                            }
                        ]
                    }
                ],
                "effect": "playerDiscard",
                "val": {
                    "valType": "flat",
                    "val": 1,
                }
            }
        ],
        "effectDescription": "The Giant will try to smash you with his club! If he is dealt 20+ damage in a turn, however, he will discard his next attack.",
        "unlockLevel": 9
    },
    "28": {
        "id": 28,
        "locationId": 3,
        "name": "Goblin Warrior",
        "description": "A loyal soldier in the Perpertual War",
        "image": "/images/new/enemies/goblin_warrior.png",
        "hp": 85,
        "level": 8,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 5
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 6
        },
        "deck": {
            1: 3,
            2: 2,
            3: 2,
            4: 1,
            7: 1,
            88: 1,
            70: 1,
            37: 1
        },
        "cardDraw": 3,
        "gold": 40,
        "effects": [
            {
                "trigger": "afterTakeDamage",
                "effect": "incrementCounter",
                "val": {
                    "valType": "percentage",
                    "val": 100,
                    "valProperty": "damageReceived"
                }
            },
            {
                "trigger": "afterTakeDamage",
                "effect": "playerMuster",
                "val": {
                        "valType": "calc",
                        "valCalc": "floor",
                        "val": {
                                "valType": "calc",
                                "valCalc": "divide",
                                "val": [
                                        {
                                                "valType": "percentage",
                                                "valProperty": "internalCounter",
                                                "val": 100,
                                        },
                                        {
                                                "valType": "flat",
                                                "val": 5
                                        }
                                ]
                        }
                }
            },
            {
                "trigger": "afterTakeDamage",
                "effect": "decrementCounter",
                "val": {
                        "valType": "calc",
                        "valCalc": "multiply",
                        "val": [
                                {
                                        "valType": "calc",
                                        "valCalc": "floor",
                                        "val": {
                                                "valType": "calc",
                                                "valCalc": "divide",
                                                "val": [
                                                        {
                                                                "valType": "percentage",
                                                                "valProperty": "internalCounter",
                                                                "val": 100,
                                                        },
                                                        {
                                                                "valType": "flat",
                                                                "val": 5
                                                        }
                                                ]
                                        }
                                },
                                {
                                        "valType": "flat",
                                        "val": 5
                                }
                        ]
                }
            }
        ],
        "effectDescription": "The Goblin Warrior will muster 1 for each 5 health he loses.",
        "unlockLevel": 1
    },
    "29": {
        "id": 29,
        "locationId": 3,
        "name": "Elf Assassin",
        "description": "The assassins blades can pierce through any armour.",
        "image": "/images/new/enemies/elf_assassin.png",
        "hp": 100,
        "level": 8,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 5
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 6
        },
        "deck": {
            1: 3,
            2: 1,
            3: 1,
            4: 1,
            5: 1,
            17: 1,
            94: 1,
            84: 2,
            30: 1
        },
        "cardDraw": 3,
        "gold": 50,
        "effects": [
            {
                "trigger": "beforeDealDamage",
                "effect": "forceCrit"
            }
        ],
        "effectDescription": "Every hit the Elf Assassin lands is critical (i.e. ignores shield).",
        "unlockLevel": 1
    },
    "30": {
        "id": 30,
        "locationId": 3,
        "name": "Dragon Knight",
        "description": "The Dragon Knight is a master of combat - as agile as he is deadly.",
        "image": "/images/new/enemies/dragon_knight.png",
        "hp": 100,
        "level": 9,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 5
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 6
        },
        "deck": {
            1: 3,
            2: 1,
            3: 1,
            33: 1,
            20: 1,
            23: 1,
            46: 1,
            70: 1,
            84: 2,
            64: 1,
            92: 1,
            88: 1,
            94: 2,
            35: 1,
        },
        "cardDraw": 3,
        "gold": 50,
        "effects": [
            {
                "trigger": "afterPlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "cardCostRed"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "playerDraw",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "effectDescription": "The Dragon Knight draws a card each time he plays a red card.",
        "unlockLevel": 1
    },
    "31": {
        "id": 31,
        "locationId": 3,
        "name": "Minotaur",
        "description": "The Minotaur's brute strength is second to none.",
        "image": "/images/new/enemies/minotaur.png",
        "hp": 100,
        "level": 9,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 5
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 6
        },
        "deck": {
            1: 3,
            2: 1,
            4: 1,
            89: 1,
            92: 1,
            32: 1,
            20: 1,
            19: 1
        },
        "cardDraw": 3,
        "gold": 50,
        "effects": [
            {
                "trigger": "afterDealDamage",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "afterDealDamage",
                "effect": "playerMuster",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "playerMuster",
                "val": {
                    "valType": "percentage",
                    "val": -100,
                    "valProperty": "internalCounter"
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "resetCounter"
            }
        ],
        "effectDescription": "The Minotaur will Muster 1 each time they deal damage that turn. This bonus is lost at the end of the turn.",
        "unlockLevel": 1
    },
    "32": {
        "id": 32,
        "locationId": 3,
        "name": "Demon Warrior",
        "description": "The Demon Warrior seems to be summonning something huge...",
        "image": "/images/new/enemies/demon_warrior.png",
        "hp": 100,
        "level": 9,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 5
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 6
        },
        "deck": {
            1: 3,
            2: 2,
            3: 2,
            4: 1,
            73: 1,
            77: 1,
            78: 1,
            81: 1,
            59: 1,
            16: 1,
            42: 1
        },
        "cardDraw": 3,
        "gold": 50,
        "effects": [
            {
                "trigger": "beforePayForCard",
                "effect": "setCardCostRed",
                "val": {
                    "valType": "flat",
                    "val": 0
                }
            }
        ],
        "effectDescription": "The Demon Warrior does not pay red mana to play cards.",
        "unlockLevel": 1
    },
    "33": {
        "id": 33,
        "locationId": 3,
        "name": "Void Monster",
        "description": "An inky black demon is taking out huge sectors of the battlefield at once.",
        "image": "/images/new/enemies/void_monster.png",
        "hp": 200,
        "level": 9,
        "boss": true,
        "backgroundMusic": "/sounds/music/boss-warship.mp3",
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "deck": {
            1: 3,
            2: 2,
            3: 2,
            4: 1,
            7: 2,
            8: 2,
            9: 1,
            12: 1,
            15: 1,
            17: 1,
            18: 2,
            20: 2,
            22: 1,
            27: 2,
            29: 2,
            31: 1,
            32: 1,
            47: 3,
            35: 1,
            40: 1,
            64: 1,
            65: 1,
            66: 1,
            69: 1,
            76: 1,
            77: 1,
            78: 1,
            80: 1,
            87: 1,
            89: 1,
            90: 1,
            91: 1,
            96: 1,
            97: 1,
            98: 1,
            99: 1,
            100: 1,
            101: 1,
            102: 1,
            103: 1,
            106: 1,
            107: 1,
            108: 3,
            115: 1
        },
        "cardDraw": 5,
        "gold": 200,
        "statusEffects": {
            "immune": 1
        },
        "effects": [
            {
                "trigger": "startEnemyTurn",
                "effect": "addCardToEnemyDeck",
                "val": {
                    "valType": "flat",
                    "val": 85
                }
            },
            {
                "trigger": "startPlayerTurn",
                "effect": "playerGainImmune",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
        ],
        "effectDescription": "The Void Monster is Immune to most negative status effects. At the beginning of your turn, will add Void Matter to your deck.",
        "unlockLevel": 1
    },
    "34": {
        "id": 34,
        "name": "Mimic",
        "image": "/images/new/enemies/mimic.png",
        "hp": 50,
        "level": 0,
        "mana": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "manaRegen": {
            "red": 3,
            "green": 2,
            "blue": 4
        },
        "deck": {
            1: 2,
            77: 1,
            73: 1,
            59: 1,
            107: 1,
            93: 1,
            94: 2,
            101: 1,
            84: 2,
            61: 1,
            67: 1,
            90: 1,
            27: 1,
            105: 1,
            41: 1,
            39: 1,
            8: 1
        },
        "cardDraw": 3,
        "gold": 80,
        "haste": true,
        "effectDescription": "The Mimic suprises you! It takes the first turn in this battle.",
        "unlockLevel": 1
    },
    "35": {
        "id": 35,
        "locationId": 1,
        "name": "Ratling",
        "description": "Aww, a cute little rat! OUCH, it bit me!!",
        "image": "/images/new/enemies/ratling.png",
        "hp": 20,
        "level": 1,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 2,
            "green": 2,
            "blue": 2
        },
        "deck": {
            1: 1,
            84: 5,
            94: 2,
            104: 1
        },
        "cardDraw": 2,
        "gold": 10,
        "effects": [
            {
                "trigger": "beforeDealDamage",
                "effect": "increaseDamage",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "random",
                        "val": [
                            {
                                "valType": "flat",
                                "val": 1
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                }
            }
        ],
        "effectDescription": "Each time the Ratling deals damage, it randomly adds between 1 and 4 to the damage amount.",
        "unlockLevel": 3
    },
    "36": {
        "id": 36,
        "locationId": 2,
        "name": "Fire Elemental",
        "description": "I was wondering why this area was so arid...",
        "image": "/images/new/enemies/fireelemental.png",
        "hp": 40,
        "level": 4,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 4,
            "green": 2,
            "blue": 2
        },
        "deck": {
            1: 2,
            9: 1,
            84: 3,
            94: 2,
            64: 1,
            18: 1
        },
        "cardDraw": 3,
        "gold": 25,
        "effects": [
            {
                "trigger": "afterTakeDamage",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "condition",
                                "val": {
                                    "comparison": "includes",
                                    "vals": [
                                        {
                                            "valType": "attribute",
                                            "val": "cardCostTypes"
                                        },
                                        {
                                            "valType": "flat",
                                            "val": "blue"
                                        }
                                    ]
                                }
                            },
                            {
                                "valType": "flat",
                                "val": false
                            }
                        ]
                    }
                ],
                "effect": "enemyBurn",
                "val": {
                    "valType": "flat",
                    "val": 2,
                }
            }
        ],
        "effectDescription": "If the Fire Elemental takes damage from a source without a blue mana cost, it inflicts 2 Burn.",
        "unlockLevel": 1
    },
    "37": {
        "id": 37,
        "locationId": 2,
        "name": "Medusa",
        "description": "She causes mayham whenever she tries to go for a haircut.",
        "image": "/images/new/enemies/medusa.png",
        "hp": 50,
        "level": 5,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1: 1,
            20: 1,
            21: 1,
            28: 1,
            30: 1,
            42: 1,
            46: 1,
            84: 1,
            99: 1,
            100: 1,
            101: 1,
            102: 1,
            106: 1
        },
        "cardDraw": 3,
        "gold": 25,
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "disableArtifacts"
            },
            {
                "trigger": "enemyLose",
                "effect": "enableArtifacts"
            }
        ],
        "effectDescription": "What's this? The Medusa has turned all your artifacts into stone!",
        "unlockLevel": 4
    },
    "38": {
        "id": 38,
        "locationId": 1,
        "name": "Doppelganger",
        "description": "It copies my every movement... what is the purpose of such an illusion?",
        "image": "/images/new/enemies/doppelganger.png",
        "hp": 40,
        "level": 3,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            17: 1,
            19: 1,
            39: 1,
            41: 2,
            106: 2
        },
        "cardDraw": 3,
        "gold": 25,
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "copyEnemyDeck"
            }
        ],
        "effectDescription": "The Doppelganger has a copy of your own deck - with a few nasty additions...",
        "unlockLevel": 5
    },
    "39": {
        "id": 39,
        "locationId": 1,
        "name": "Goblin Bomber",
        "description": "There's a lot of explosive ammunition littered around here...",
        "image": "/images/new/enemies/goblin_bomber.png",
        "hp": 40,
        "level": 2,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 3,
            "green": 3,
            "blue": 3
        },
        "deck": {
            1:1,
            84: 3,
            94: 2,
            9: 1
        },
        "cardDraw": 3,
        "gold": 25,
        "effects": [
            {
                "trigger": "afterTakeDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "random",
                                "val": [
                                    {
                                        "valType": "flat",
                                        "val": 0
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 100
                                    }
                                ],
                            },
                            {
                                "valType": "flat",
                                "val": 50
                            }
                        ]
                    }
                ],
                "effect": [
                    "dealDamage",
                    "takeDamage"
                ],
                "val": {
                    "valType": "flat",
                    "val": 5
                }
            }
        ],
        "effectSound": "/sounds/effects/explosion.mp3",
        "effectDescription": "Whenever you deal damage, you have a 50% chance of igniting a fuse and dealing 5 damage to both players. Careful, this could cause a chain reaction!",
        "unlockLevel": 1
    },
    "40": {
        "id": 40,
        "locationId": 3,
        "name": "Witch",
        "description": "Under the surface, she's just a lonely old lady...",
        "image": "/images/new/enemies/witch.png",
        "hp": 75,
        "level": 7,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 4,
            "green": 4,
            "blue": 4
        },
        "deck": {
            112: 1,
            113: 1,
            114: 1,
            30: 1,
            92: 1,
            98: 1,
            60: 1,
            115: 1,
            100: 1,
            101: 1,
            84: 1,
            94: 1
        },
        "cardDraw": 4,
        "gold": 45,
        "effects": [
            {
                "trigger": "afterChangeStatusEffect",
                "effect": "syncBuffsAndDebuffs"
            },
            {
                "trigger": "afterEnemyChangeStatusEffect",
                "effect": "syncBuffsAndDebuffs"
            }
        ],
        "effectDescription": "The Witch will share all buffs and debuffs with you.",
        "unlockLevel": 6
    },
    "41": {
        "id": 41,
        "locationId": 3,
        "name": "Earth Elemental",
        "description": "Very defensive and very stong, this earthern elemental seems to thrive in this rocky terrain...",
        "image": "/images/new/enemies/earth_elemental.png",
        "hp": 100,
        "level": 9,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "deck": {
            37: 4,
            107: 1,
            65: 1,
            66: 1,
            64: 1,
            76: 1,
            93: 1,
            111: 1,
            60: 1,
            61: 1,
            62: 1,
            93: 1,
            55: 4
        },
        "cardDraw": 4,
        "gold": 100,
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "effect": "playerReinforce",
                "val": {
                    "valType": "flat",
                    "val": 2
                }
            }
        ],
        "effectDescription": "The Earthen Elemental keeps his shield up at all times (he gains 2 Reinforce each turn).",
        "unlockLevel": 10,
        "unlockLevel": 1
    },
    "42": {
        "id": 42,
        "locationId": 3,
        "name": "Air Elemental",
        "description": "Small sparks fly out of it's body as it darts around quickly.",
        "image": "/images/new/enemies/air_elemental.png",
        "hp": 80,
        "level": 8,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 5,
            "green": 5,
            "blue": 5
        },
        "deck": {
            105: 1,
            8: 2,
            64: 1,
            15: 1,
            89: 1,
            90: 1,
            97: 1,
            103: 1,
            104: 1,
            40: 2,
            59: 1,
            67: 1,
            5: 1
        },
        "cardDraw": 5,
        "gold": 100,
        "effects": [
            {
                "trigger": "afterDraw",
                "effect": "dealDamage",
                "val": {
                    "valType": "percentage",
                    "valProperty": "drawCount",
                    "val": 100
                }
            }
        ],
        "effectSound": "/sounds/effects/draw3.mp3",
        "effectDescription": "Each time the Air Elemental draws a card, it deals one damage.",
        "unlockLevel": 1
    },
    "43": {
        "id": 43,
        "locationId": 2,
        "name": "Gorgoth",
        "description": "What is this vile abomination?!",
        "image": "/images/new/enemies/gorgoth.png",
        "hp": 60,
        "level": 6,
        "mana": {
            "red": 0,
            "green": 0,
            "blue": 0
        },
        "manaRegen": {
            "red": 4,
            "green": 4,
            "blue": 4
        },
        "deck": {
            1: 1,
            84: 1,
            94: 1,
            77: 1,
            78: 1,
            105: 1,
            111: 1,
            19: 1,
            76: 1
        },
        "cardDraw": 4,
        "gold": 35,
        "effects": [
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "includes",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "cardEffects"
                            },
                            {
                                "valType": "flat",
                                "val": "alter"
                            }
                        ]
                    }
                ],
                "effect": "mergeContext",
                "val": {
                    "valType": "flat",
                    "val": {
                        "alterMultiply": 2
                    }
                }
            }
        ],
        "effectSound": "/sounds/effects/draw3.mp3",
        "effectDescription": "Each time the Gorgoth mutates a card, the effect is doubled.",
        "unlockLevel": 7
    }
}
