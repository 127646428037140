import React from 'react';
import Mana from './Mana';

class Manas extends React.Component
{
    render()
    {
        return (
            <div className="manas">
                <Mana
                    colour="red"
                    amount={this.props.mana.red}
                    regen={this.props.regen.red}
                    />
                <Mana
                    colour="green"
                    amount={this.props.mana.green}
                    regen={this.props.regen.green}
                    />
                <Mana
                    colour="blue"
                    amount={this.props.mana.blue}
                    regen={this.props.regen.blue}
                    />
            </div>
        );
    }
}

export default Manas;
