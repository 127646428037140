import { v4 as uuidv4 } from 'uuid';
import ConditionFactory from './Artifacts/Conditions/ConditionFactory';
import AbstractValue from './Artifacts/Values/AbstractValue';
import ValueFactory from './Artifacts/Values/ValueFactory';

import {cardsData} from './data/cards-new';
import EffectFactory from './Effects/EffectFactory';

class Card
{
    constructor()
    {
        this.id = null;
        this.image = null;
        this.description = null;
        this.effects = null;
        this.name = null;
        this.cost = null;
        this.tier = null;
        this.exile = false;
        this.uid = uuidv4();
        this.goldCost = 100;
        this.deck = null;
        this.aboutToPlay = false;
        this.unplayable = null;
        this.keep = null;
        this.module = null;
        this.moduleSlots = 0;
        this.giveToEnemy = null;
        this.endTurn = null;
        this.context = {};
        this.modes = [];
        this.triggeredEffects = [];
        this.triggerPriorities = {};
        this.descriptionTemplate = null;
        this.temporary = false;
        this.onKillEffects = [];
        this.permEffects = [];
        this.unlockLevel = 1;
    }

    static createFromCardId(cardId)
    {
        let card = new Card();
        let cards = {...cardsData};
        let data = cards[cardId];

        card.id = parseInt(cardId);
        card.image = data.image;
        // card.description = data.text;
        card.effects = JSON.parse(JSON.stringify(data.effects));
        card.name = data.name;
        card.cost = JSON.parse(JSON.stringify(data.cost));
        card.tier = data.tier;
        card.modes = data.modes;
        if (data.triggeredEffects !== undefined) {
            card.triggeredEffects = card.parseTriggeredEffects([...data.triggeredEffects]);
        }
        if (data.exile !== undefined) {
            card.exile = data.exile;
        }
        if (data.unplayable !== undefined) {
            card.unplayable = data.unplayable;
        }
        if (data.keep !== undefined) {
            card.keep = data.keep;
        }
        if (data.giveToEnemy !== undefined) {
            card.giveToEnemy = data.giveToEnemy;
        }
        if (data.moduleSlots !== undefined) {
            card.moduleSlots = data.moduleSlots;
        }
        if (data.module !== undefined) {
            card.module = data.module;
        }
        if (data.endTurn !== undefined) {
            card.endTurn = data.endTurn;
        }
        if (data.context !== undefined) {
            card.context = data.context;
        }
        if (data.descriptionTemplate !== undefined) {
            card.descriptionTemplate = data.descriptionTemplate;
        }
        if (data.temporary !== undefined) {
            card.temporary = data.temporary;
        }
        if (data.onKill !== undefined) {
            card.onKillEffects = data.onKill;
        }
        if (data.permEffects !== undefined) {
            card.permEffects = data.permEffects;
        }
        if (data.unlockLevel !== undefined) {
            card.unlockLevel = data.unlockLevel;
        }

        switch(data.tier) {
            case 0:
                card.goldCost = 10;
                break;
            case 1:
                card.goldCost = 20;
                break;
            case 2:
                card.goldCost = 35;
                break;
            case 3:
                card.goldCost = 60;
                break;
            default:
                card.goldCost = 100;
                break;
        }

        card.generateDescription();

        return card;
    }

    clone()
    {
        let newCard = new Card();
        newCard.id = this.id;
        newCard.image = this.image;
        newCard.description = this.description;
        newCard.effects = JSON.parse(JSON.stringify(this.effects));
        newCard.name = this.name;
        newCard.cost = JSON.parse(JSON.stringify(this.cost));
        newCard.tier = this.tier;
        newCard.exile = this.exile;
        newCard.deck = this.deck;
        newCard.moduleSlots = this.moduleSlots;
        newCard.keep = this.keep;
        newCard.giveToEnemy = this.giveToEnemy;
        newCard.unplayable = this.unplayable;
        newCard.module = this.module;
        newCard.endTurn = this.endTurn;
        newCard.context = JSON.parse(JSON.stringify(this.context));
        newCard.modes = this.modes;
        newCard.triggeredEffects = JSON.parse(JSON.stringify(this.triggeredEffects));
        newCard.permEffects = JSON.parse(JSON.stringify(this.permEffects));
        newCard.onKillEffects = JSON.parse(JSON.stringify(this.onKillEffects));
        newCard.descriptionTemplate = this.descriptionTemplate;
        newCard.temporary = this.temporary;
        newCard.cloneRef = this;
        return newCard;
    }

    getCardState()
    {
        return {
            id: this.id,
            description: this.description,
            image: this.image,
            effects: this.effects,
            name: this.name,
            cost: this.cost,
            unplayable: this.unplayable,
            tier: this.tier,
            exile: this.exile,
            uid: this.uid,
            goldCost: this.goldCost,
            aboutToPlay: this.aboutToPlay
        }
    }

    parseTriggeredEffects(effects)
    {
        let triggeredEffects = effects.map(effect => {
            if (effect.conditions !== undefined) {
                effect.conditions = effect.conditions.map(conditionData => {
                    return ConditionFactory.createConditionFromJson(conditionData);
                });
            }
            return effect;
        });

        return triggeredEffects;
    }

    addRawTriggeredEffect(triggeredEffect)
    {
        this.triggeredEffects.push(this.parseTriggeredEffects([triggeredEffect])[0]);
    }

    shouldExile()
    {
        return this.exile;
    }

    shouldGiveToEnemy()
    {
        return this.giveToEnemy;
    }

    shouldKeep()
    {
        return this.keep;
    }

    isTemporary()
    {
        return this.temporary;
    }

    generateDescription()
    {
        let description = [];

        if (this.descriptionTemplate) {
            description.push(this.descriptionTemplate);
        }

        Object.keys(this.effects).sort(function (key1, key2) {
            if (this.effects[key1].descriptionPriority === undefined || this.effects[key2].descriptionPriority === undefined) {
                return 0;
            }
            if (this.effects[key1].descriptionPriority > this.effects[key2].descriptionPriority) {
                return -1;
            }
            return 1;
        }.bind(this)).forEach(function (key, pos) {
            let effect = EffectFactory.createEffect(key, this.effects[key]);
            description.push(effect.generateDescriptionFromTemplate(this.context));
        }.bind(this));

        this.permEffects.forEach(function (permEffect) {
            switch (permEffect.effect) {
                case 'gainMaxHealth':
                    description.push('Permanently increase your maximum health by ' + permEffect.val + '.');
                    break;
            }
        }.bind(this));

        Object.keys(this.triggeredEffects).forEach(function (key, pos) {
            let effect = EffectFactory.createEffect(this.triggeredEffects[key].effect, this.triggeredEffects[key].val);
            description.push(effect.generateDescriptionFromTemplate(this.context));
        }.bind(this));

        if (this.onKillEffects.length > 0) {
            description.push("If this card kills the enemy, ");

            let onKillEffectDescriptions = [];
            this.onKillEffects.forEach(function (effect) {
                switch (effect.effect) {
                    case 'gainGold':
                        onKillEffectDescriptions.push('gain ' + effect.val + ' gold');
                        break;

                    case 'gainMaxHealth':
                        onKillEffectDescriptions.push('permanently gain ' + effect.val + ' maximum health');
                        break;
                }
            }.bind(this));

            description.push(onKillEffectDescriptions.join(' and ') + '.');
        }

        if (this.shouldGiveToEnemy()) {
            description.push("Put this card in the enemy's deck.");
        }

        if (this.shouldExile()) {
            description.push("Exile.");
        }

        if (this.module !== null) {
            let moduleCard = Card.createFromCardId(this.module);
            description.push("Module for " + moduleCard.name + '.');
        }

        if (this.moduleSlots > 0) {
            description.push("Can be equipped by up to " + this.moduleSlots + " modules.");
        }

        if (this.endTurn) {
            description.push("Discards all cards in hand once played.");
        }

        if (this.keep) {
            description.push("Keep.")
        }

        if (this.temporary) {
            description.push("Temporary.")
        }

        this.description = description.join(' ');
    }

    trigger(triggerName, context)
    {
        if ((context.player !== undefined && context.player === this.deck.player) || (context.source?.player !== undefined && context.source.player === this.deck.player)) {
            if (context.card === undefined || context.card === this || context.source?.card === this) {
                let effects = this.triggeredEffects.filter(effect => effect.trigger === triggerName);
                effects.forEach(effect => this.handleEffect(effect, {...context}));

                if (triggerName === 'playerWin' && (context.card === this || context?.source?.card === this)) {
                    this.onKillEffects.forEach(effect => this.handleOnKillEffect(effect, {...context}));
                }
            }
        }
    }

    registerTriggers(triggerName, triggerManager)
    {
        let priority = 100;
        if (this.triggerPriorities[triggerName] !== undefined) {
            priority = this.triggerPriorities[triggerName];
        }
        triggerManager.registerListener(triggerName, priority, this.trigger.bind(this));
    }

    handleEffect(effectData, context)
    {
        context.internalCounter = this.internalCounter;
        context.card = this;

        let contextInstance = {...context};
        contextInstance.historySourceImage = this.image;

        if (!this.areConditionsMet(effectData, context)) {
            return;
        }

        let effect = EffectFactory.createEffect(effectData.effect, effectData.val);
        context.effect = effect;
        effect.handle(contextInstance);
    }

    handleOnKillEffect(effectData, context)
    {
        switch (effectData.effect) {
            case 'gainMaxHealth':
                context.game.maxHp += effectData.val;
                context.game.battle.player.maxHp += effectData.val;
                break;

            case 'gainGold':
                context.game.gold += effectData.val;
                break;
        }
    }

    handlePermEffect(effectData, context)
    {
        switch (effectData.effect) {
            case 'gainMaxHealth':
                let val = effectData.val;
                context.game.maxHp += val;
                context.game.battle.player.maxHp += val;
                context.game.battle.history.write(context.game.battle.player.name + " gains " + val + " maximum health", context.historySourceImage);
                break;
        }
    }

    areConditionsMet(effectData, context)
    {
        if (effectData.conditions !== undefined) {
            return effectData.conditions.reduce((accum, condition) => accum && condition.isMet(context), true);
        }
        return true;
    }

    getHistorySourceImage()
    {
        return this.image;
    }
}

export default Card;
