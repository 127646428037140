import AbstractCondition from "./AbstractCondition";

class IncludesCondition extends AbstractCondition
{
    isMet(context)
    {
        let vals = [...this.vals];
        let firstValue = vals.shift().calculate(context);
        return vals.every((value) => firstValue.includes(value.calculate(context)));
    }
}

export default IncludesCondition;
