import React from 'react';

class TopBar extends React.Component
{
    getClasses()
    {
        let classes = 'topbar ' + this.props.classes;
        return classes;
    }

    render()
    {
        return (
            <div className={this.getClasses()}>
                {this.props.children}
                <div className="topbar-info">
                    <div className="icon icon--topbar">
                        <a href="#" onClick={() => this.props.openOverlay('info')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 18.01L12.01 17.9989" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </a>
                    </div>
                </div>
                <div className="topbar-settings">
                    <div className="icon icon--topbar">
                        <a href="#" onClick={() => this.props.openOverlay('settings')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g stroke="#000"><ellipse cx="132.859" cy="131.158" rx="85.041" ry="85.045" fill="none" strokeWidth="29.104" strokeLinejoin="round"/><path fill="#000" d="M102.802 44.953L113.385 4.13l38.931.378L162.9 45.331zM162.908 217.362l-10.583 40.822-38.932-.378-10.583-40.821zM191.908 61.778l40.644-11.245 19.138 33.905-30.06 29.576zM72.65 200.037l-40.644 11.245-19.139-33.905 30.061-29.576zM223.486 147.1l30.06 29.575-19.793 33.527-40.644-11.245zM44.121 112.948l-30.06-29.576 19.793-33.527 40.644 11.246z" strokeWidth=".265"/></g></svg>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBar;
