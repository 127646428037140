import AbstractStatusEffect from "./AbstractStatusEffect";

class InvulnerableStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Invulnerable';
        this.image = '/images/new/status/invulnerable.png';
        this.triggerPriorities = {
            'beforeTakeDamage': 10,
            'beforeDealDamage': 10
        };
        this.soundEffect = '/sounds/effects/negate3.mp3';
        this.isBuff = true;
    }

    triggerBeforeTakeDamage(context)
    {
        if (context.receivingDamage === this.player) {
            if (this.val > 0) {
                context.damageVal = 0;
                context.damageMitigated = context.damageVal;
                this.pulse();
                this.playSoundEffect(context);
            }
        }
    }

    triggerBeforeDealDamage(context)
    {
        if (context.receivingDamage === this.player) {
            if (this.val > 0) {
                context.damageVal = 0;
                context.damageMitigated = context.damageVal;
                this.pulse();
                this.playSoundEffect(context);
            }
        }
    }

    triggerStartPlayerTurn(context)
    {
        if (context.player === this.player) {
            if (this.val > 0) {
                this.changeVal(this.val - 1, context);
            } else {
                this.changeVal(0, context);
            }
        }
    }

    generateTooltip()
    {
        return 'Player is immune to damage for ' + this.val + ' turns.';
    }
}

export default InvulnerableStatusEffect;
