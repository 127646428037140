import AbstractEffect from "./AbstractEffect";

class FocusEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            Object.keys(this.data).map(manaType => {
                context.focusType = manaType;
                context.focusAmount = this.data[manaType];
                context.battle.game.trigger('beforeGainFocus', context);

                context.player.manaRegen[manaType] += context.focusAmount;

                context.battle.history.write(context.player.name + ' increases their ' + manaType + ' mana regeneration by ' + context.focusAmount, context.historySourceImage);
            });

            context.battle.game.triggerSoundEffect('/sounds/effects/focus4.mp3');
        }
    }

    generateDescription()
    {
        let description = [];
        Object.keys(this.data).map(manaType => {
            description.push('Increases ' + manaType + ' mana regeneration by ' + this.data[manaType] + '.');
        });

        return description.join(' ');
    }
}

export default FocusEffect;
