import React from 'react';
import Overlay from './Overlay';

class Roadmap extends React.Component
{
    render()
    {
        return (
            <Overlay
                overlayClasses="roadmap-overlay"
                title="Roadmap"
                display={this.props.display}
                closeOverlay={this.props.closeOverlay}
                name='roadmap'
                >
                <p className="roadmap-description">Here's what you can expect coming up in future releases:
                    <ul className="roadmap-legend">
                        <li><span className="roadmap-example roadmap-example--complete">This is complete</span></li>
                        <li><span className="roadmap-example roadmap-example">Upcoming work</span></li>
                        <li><span className="roadmap-example roadmap-example--later">Far off work, subject to change</span></li>
                    </ul>
                </p>
                <div className="roadmap grid">
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--complete">
                            <p>Automatic logging of errors</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--complete">
                            <p>XP system</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--complete">
                            <p>Compendium of artifacts, cards and monsters</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--complete">
                            <p>Achievements</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--complete">
                            <p>Card Rarity</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--complete">
                            <p>Added roadmap to homepage</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--complete">
                            <p>Code improvements for better backwards-compatibility with older save files</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--complete">
                            <p>Ability to import/export save string</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--complete">
                            <p>Collect Analytics for Pickrate</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card">
                            <p>Mobile friendly layout</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card">
                            <p>Priest starting class</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card">
                            <p>Discord community</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card">
                            <p>Improved UI</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card">
                            <p>Improved Achievements</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card">
                            <p>Improve performance</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--later">
                            <p>Tutorial Mode</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--later">
                            <p>Add different difficulties</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--later">
                            <p>Consumables/Active Powers</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--later">
                            <p>Loading/Splash Screens</p>
                        </div>
                    </div>
                    <div className="l-one-third">
                        <div className="roadmap__card roadmap__card--later">
                            <p>More and improved animations</p>
                        </div>
                    </div>
                </div>
            </Overlay>
        );
    }
}

export default Roadmap;
