import AbstractEffect from "./AbstractEffect";

class ReinforceEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let reinforceStatusEffect = context.player.getStatusEffect('reinforce');
            reinforceStatusEffect.changeVal(Math.max(0, this.getVal(context) + reinforceStatusEffect.val), context);
            context.reinforceVal = this.getVal(context);

            context.battle.history.write(context.player.name + ' is now reinforced for ' + reinforceStatusEffect.val + ' turns', context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription()
    {
        return 'Reinforce for ' + parseInt(this.data) + ' ' + this.pluralise('turn', parseInt(this.data)) + '.';
    }
}

export default ReinforceEffect;
