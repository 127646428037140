import React from 'react';
import Mana from './Mana';

class Card extends React.Component
{
    getCardClasses() {
        let classNames = 'card ' + (this.props.addUnplayableClass ? ' card--unplayable' : '');
        if (Object.keys(this.props.cost).filter(cost => this.props.cost[cost] > 0).length > 1) {
            classNames += ' card--mix';
            if (this.props.cost.red !== undefined && this.props.cost.red > 0) {
                classNames += '-red';
            }
            if (this.props.cost.green !== undefined && this.props.cost.green > 0) {
                classNames += '-green';
            }
            if (this.props.cost.blue !== undefined && this.props.cost.blue > 0) {
                classNames += '-blue';
            }
        } else if (this.props.cost.red !== undefined && this.props.cost.red > 0) {
            classNames += ' card--red';
        } else if (this.props.cost.green !== undefined && this.props.cost.green > 0) {
            classNames += ' card--green';
        } else if (this.props.cost.blue !== undefined && this.props.cost.blue > 0) {
            classNames += ' card--blue';
        }
        classNames += ' card--tier-' + this.props.tier;
        classNames += (' ' + this.props.cardClasses);
        if (this.props.aboutToPlay) {
            classNames += ' card--about-to-play';
            if (this.props.animationSpeed !== undefined) {
                classNames += (' card--about-to-play--' + this.props.animationSpeed);
            }
        }
        return classNames;
    }

    getRarityClasses() {
        let classNames = 'card__rarity';
        if (this.props.tier === 1) {
            classNames += ' card__rarity--bronze';
        }
        if (this.props.tier === 2) {
            classNames += ' card__rarity--silver';
        }
        if (this.props.tier === 3) {
            classNames += ' card__rarity--gold';
        }
        return classNames;
    }

    parseDescription() {
        let description = this.props.description;
        let keywords = [
            'Regen',
            'Exile',
            'Muster',
            'Armour',
            'Shield',
            'Critical',
            'Poison',
            'Weaken',
            'Strengthen',
            'Bleed',
            'Thorn',
            'Keep',
            'Module',
            'Temporary',
            'Burn',
            'Immune',
            'Negate'
        ];
        let keywordDescriptions = [];
        keywords.forEach(function (keyword) {
            let keywordLower = keyword.toLowerCase();
            if (description.search(keyword) !== -1 || description.search(keywordLower) !== -1) {
                description = description.replace(keywordLower, '<span class="card__keyword">' + keywordLower + '</span>');
                description = description.replace(keyword, '<span class="card__keyword">' + keyword + '</span>');
                let keywordDescription = (
                    <p className="card__keyword-description" key={"desc_" + keyword}>
                        <span className="card__keyword">{keyword}</span>: {this.getKeywordDescription(keywordLower)}
                    </p>
                );
                keywordDescriptions.push(keywordDescription);
            }
        }.bind(this));
        this.description = description;
        this.keywordDescriptions = keywordDescriptions;
    }

    getKeywordDescription(keyword)
    {
        let keywordDescriptions = {
            'regen': "Heal this much at the start of every turn.",
            'exile': "Once played, this card is exiled and cannot be played again for the rest of the combat.",
            'critical': "Critical damage ignores shield.",
            'muster': "Deal extra damage whenever you attack.",
            'armour': "Gain extra shield whenever you gain shield.",
            'shield': "Block this much damage during the next turn.",
            'poison': "At the beginning of your turn, lose this much health.",
            'weaken': "Your opponent's attacks will do 50% damage for this many turns.",
            'strengthen': "Your attacks will do 150% damage for this many turns.",
            'bleed': "At the beginning of your turn, take damage equal to the Bleed amount, and reduce the Bleed amount by 1.",
            'thorn': "Whenever an opponent attacks, they take this much damage.",
            'keep': "This card is not discarded at the end of your turn.",
            'module': "If you have in your hand both a module card, and the card to equip it, both cards are merged into one joint card (with cumulative mana costs and effects).",
            'temporary': "This card is removed from the game upon play, or upon discard.",
            'burn': "Playing a card causes a random card from your hand to be discarded.",
            'immune': "You are immune from most debuffs. Immunity amount reduces by 1 at the beginning of your turn.",
            'negate': "Your opponents next card will have no effect.",
        };

        return keywordDescriptions[keyword.toLowerCase()] ?? '';
    }

    render() {
        this.parseDescription();
        let backFace = '';
        if (this.props.showBackFace === true) {
            backFace = (<div className="card-back"></div>);
        }

        return (
            <div className={this.getCardClasses()} style={this.props.styles} onClick={() => this.props.playCard(this.props.uid)} key={this.props.uid}>
                <div className="card-front">
                <div className="card-inner">
                    <div className={this.getRarityClasses()}></div>
                    <span className="card__title">{this.props.name}</span>
                    <div className="card__mana-wrapper">
                        {Object.keys(this.props.cost).map((manaType, cost) => {
                            if (this.props.cost[manaType] > 0) {
                                return (
                                    <Mana
                                        key={manaType}
                                        colour={manaType}
                                        amount={this.props.cost[manaType]}
                                        />
                                );
                            }
                        })}
                    </div>
                    <div className="card__image-wrapper">
                        <img className="card__image" src={this.props.image} />
                        {/**
                         *
                         * WIP
                         *
                        <div className="card__glare"></div>
                        <div className="card__shine"></div>
                         *
                         */}
                    </div>
                    <p className="card__description"
                        dangerouslySetInnerHTML={{
                            "__html": this.description
                        }}
                    >
                    </p>
                    <div className="card__keyword-descriptions">
                        {Object.keys(this.keywordDescriptions).map((keyword, description) => {
                            return this.keywordDescriptions[keyword];
                        })}
                    </div>
                    {
                        this.props.isUnlocked === false
                        ? (
                            <div className="card__locked">
                                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.1819 10.7027H6.00008C5.44781 10.7027 5.0001 11.1485 5.00009 11.7008C5.00005 13.3483 5 16.6772 5.00011 18.9189C5.00023 21.4317 8.88618 22 12 22C15.1139 22 19 21.4317 19 18.9189C19 16.6773 19 13.3483 19 11.7008C19 11.1485 18.5523 10.7027 18 10.7027H15.8182M8.1819 10.7027C8.1819 10.7027 8.18193 8.13514 8.1819 6.59459C8.18186 4.74571 9.70887 3 12 3C14.2912 3 15.8182 4.74571 15.8182 6.59459C15.8182 8.13514 15.8182 10.7027 15.8182 10.7027M8.1819 10.7027H15.8182" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13 16.6181V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V16.6181C10.6931 16.3434 10.5 15.9442 10.5 15.5C10.5 14.6716 11.1716 14 12 14C12.8284 14 13.5 14.6716 13.5 15.5C13.5 15.9442 13.3069 16.3434 13 16.6181Z" fill="#ffffff"/>
                                </svg>
                                <span>Unlock at level {this.props.unlockLevel}</span>
                            </div>
                        )
                        : (
                            <></>
                        )
                    }
                    {/* <div className="card__levels">
                        <p className="card__level-label">Level:</p>
                        <span className="card__level card__level--full"></span>
                        <span className="card__level"></span>
                        <span className="card__level"></span>
                    </div> */}
                </div>
                </div>
                {backFace}
            </div>
        );
    }
}

export default Card;
