import AbstractEffect from "./AbstractEffect";

class NegateEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let negateStatusEffect = context.enemy.getStatusEffect('negate');
            negateStatusEffect.changeVal(Math.max(0, this.getVal(context) + negateStatusEffect.val), context);
            context.negateVal = this.getVal(context);

            context.battle.history.write(context.enemy.name + ' has the next ' + this.getVal(context) + ' card(s) negated', context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription()
    {
        return 'Negate the next ' + parseInt(this.data) + ' ' + this.pluralise('card', parseInt(this.data)) + ' the opponent plays.';
    }
}

export default NegateEffect;
