import { v4 as uuidv4 } from 'uuid';
import {shuffle, hashCode, selectionSort} from './Helper';
import Unpacker from './Unpacker';
import { compressToBase64 } from 'lz-string';

import {locationsData} from './data/locations-new';
import {enemiesData} from './data/enemies-new';
import {modeData} from './data/startingModes';
import Deck from './Deck';
import EncounterFactory from './Encounters/EncounterFactory';
import Stats from './Stats';
import Battle from './Battle';
import BattleOverException from './Exceptions/BattleOverException';
import Artifact from './Artifacts/Artifact';
import TriggerManager from './TriggerManager';
import EnemyEncounter from './Encounters/EnemyEncounter';
import Achievement from './Achievement';
import Achievements from './Achievements';

export class GameFactory
{
    currentGame = null;

    static getCurrentGame()
    {
        return this.currentGame;
    }

    static setCurrentGame(game)
    {
        this.currentGame = game;
    }
}

export default class Game
{
    constructor(startingModeId, achievements = null, experience = null, stats = null)
    {
        GameFactory.setCurrentGame(this);

        var jsonVersion = require('../package.json').version;
        let mode = {...modeData};
        let data = mode[startingModeId];
        this.cardDraw = data.cardDraw;
        this.hp = data.hp;
        this.maxHp = data.hp;
        this.mana = data.mana;
        this.manaRegen = data.manaRegen;
        this.modeId = startingModeId;
        this.gold = 100;
        this.locationsEncountered = 0;
        this.artifacts = [];
        this.stats = stats;
        this.playerImage = data.image;
        this.playerName = data.name;
        this.version = jsonVersion;
        this.experience = experience;
        this.achievements = achievements;
        this.soundEffects = [];
        this.statusEffects = {};
        this.xpBreakdown = {
            enemiesDefeated: 0,
            lossPenalty: 0,
            achievementsUnlocked: 0
        }
        this.finishUnlocks = {
            "enemies": [],
            "cards": [],
            "artifacts": [],
            "modes": []
        };
        this.showXpScreen = false;
        this.achievementsUnlocked = [];
        this.hasGainedLevel = false;
        this.deck = Deck.createFromDeckIds(data.deck);
        this.loadLocations();
        Artifact.createFromArtifactIds(data.artifacts, this).map(artifact => this.addArtifact(artifact));
        this.loadAchievements();

        this.baseSeed = uuidv4();

        this.currentEncounter = false;
    }

    loadLocations()
    {
        let locations = {...locationsData};
        let enemies = {...enemiesData};

        let id = 0;

        let level = this.experience.level;

        Object.keys(locations).forEach(locationId => {
            let encounterData = locations[locationId].encounterData;
            locations[locationId].totalEncounters = 0;
            locations[locationId].encounters = [];
            Object.keys(encounterData.enemies).forEach(enemyKey => {
                if (enemyKey !== "boss") {
                    for (let i = 0; i < encounterData.enemies[enemyKey]; i++) {
                        let possibleEnemies = Object.values(enemies).filter(function (enemyData) {
                            return (enemyData.level === parseInt(enemyKey)) && (enemyData.boss !== true) && (enemyData.unlockLevel <= level);
                        });
                        if (possibleEnemies.length === 0) {
                            throw new Error('error parsing location enemies.');
                        }
                        let enemyId = possibleEnemies[Math.floor(Math.random() * possibleEnemies.length)].id;
                        delete enemies[enemyId];
                        let encounter = EncounterFactory.createEnemyEncounter(enemyId);
                        if (encounter.enemy.name === 'Doppelganger') {
                            encounter.enemy.setImage(this.playerImage);
                        }
                        encounter.encounterId = id;
                        id++;
                        locations[locationId].totalEncounters++;
                        locations[locationId].encounters.push(encounter);
                    }
                } else {
                    let possibleEnemies = Object.values(enemies).filter(function (enemyData) {
                        return (enemyData.level === encounterData.enemies[enemyKey]) && (enemyData.boss === true) && (enemyData.unlockLevel <= level);
                    });
                    if (possibleEnemies.length === 0) {
                        throw new Error('error parsing location enemies.');
                    }
                    let enemyId = possibleEnemies[Math.floor(Math.random() * possibleEnemies.length)].id;
                    delete enemies[enemyId];
                    let encounter = EncounterFactory.createEnemyEncounter(enemyId);
                    encounter.encounterId = id;
                    id++;
                    locations[locationId].totalEncounters++;
                    locations[locationId].encounters.push(encounter);
                }
            });
            if (encounterData.chests !== undefined) {
                for (let i = 0; i < encounterData.chests; i++) {
                    let encounter = EncounterFactory.createChestEncounter();
                    encounter.encounterId = id;
                    id++;
                    locations[locationId].totalEncounters++;
                    locations[locationId].encounters.push(encounter);
                }
            }
            if (encounterData.turnstiles !== undefined) {
                for (let i = 0; i < encounterData.turnstiles; i++) {
                    let encounter = EncounterFactory.createTurnstileEncounter();
                    encounter.encounterId = id;
                    id++;
                    locations[locationId].totalEncounters++;
                    locations[locationId].encounters.push(encounter);
                }
            }
            if (encounterData.shops !== undefined) {
                for (let i = 0; i < encounterData.shops; i++) {
                    let encounter = EncounterFactory.createShopEncounter();
                    encounter.encounterId = id;
                    id++;
                    locations[locationId].totalEncounters++;
                    locations[locationId].encounters.push(encounter);
                }
            }
            if (encounterData.blessings !== undefined) {
                for (let i = 0; i < encounterData.blessings; i++) {
                    let encounter = EncounterFactory.createBlessingEncounter();
                    encounter.encounterId = id;
                    id++;
                    locations[locationId].totalEncounters++;
                    locations[locationId].encounters.push(encounter);
                }
            }
            if (encounterData.pandoras !== undefined) {
                for (let i = 0; i < encounterData.pandoras; i++) {
                    let encounter = EncounterFactory.createPandoraEncounter();
                    encounter.encounterId = id;
                    id++;
                    locations[locationId].totalEncounters++;
                    locations[locationId].encounters.push(encounter);
                }
            }
        });

        this.locations = Object.values(locations);
        this.currentLocation = this.locations.shift();
        this.currentLocation.encountersCompleted = 0;
        this.currentLocation.totalEncounters = this.currentLocation.encounters.length;
        this.shuffleCurrentLocationEncounters();
    }

    shuffleCurrentLocationEncounters()
    {
        let encounters = this.currentLocation.encounters;
        encounters = shuffle(encounters);

        let sortFunction = function(arr) {
            for (let i = 0; i < (arr.length - 1); i++) {
                if (arr[i] instanceof EnemyEncounter) {
                    for (let j = (i + 1); j < arr.length; j++) {
                        if (arr[i] instanceof EnemyEncounter) {
                            if (arr[i].enemy.boss === true
                                || (arr[j] instanceof EnemyEncounter
                                    && arr[i].enemy.level > arr[j].enemy.level
                                )
                            ) {
                                [arr[i], arr[j]] = [arr[j], arr[i]];
                            }
                        }
                    }
                }
            }
            return arr;
        }

        encounters = sortFunction(encounters);
        this.currentLocation.encounters = encounters;
    }

    loadAchievements()
    {
        this.achievements.loadAll();
    }

    triggerSoundEffect(soundEffect)
    {
        this.soundEffects.push(soundEffect);
    }

    getState()
    {
        let data = {
            'game_modeId': this.modeId,
            'game_deck': this.deck.getDeckState().deck,
            'game_cardDraw': this.cardDraw,
            'game_hp': this.hp,
            'game_maxHp': this.maxHp,
            'game_mana': this.mana,
            'game_manaRegen': this.manaRegen,
            'game_gold': this.gold,
            'game_playerImage': this.playerImage,
            'game_currentLocation': {
                "name": this.currentLocation.name,
                "backgroundMusicPlay": this.currentLocation.backgroundMusicPlay,
                "backgroundMusicBattle": this.getBackgroundMusicBattle(),
                "backgroundImage": this.currentLocation.backgroundImage,
                "encountersCount": this.currentLocation.encounters.length
            },
            'game_futureLocationsCount': this.locations.length,
            'game_artifacts': this.artifacts.map(artifact => artifact.getArtifactState()),
            'game_encounters': this.currentLocation.encounters.map(encounter => encounter.getEncounterState()),
            'game_seed': this.calculateSeed(),
            'game_progress': this.calculateProgress(),
            'game_soundEffects': this.soundEffects,
            'game_achievementsUnlocked': this.achievementsUnlocked.map(achievement => achievement.getState()),
            'game_xpBreakdown': this.xpBreakdown,
            'game_hasGainedLevel': this.hasGainedLevel,
            'game_finishCardsUnlocked': this.finishUnlocks.cards,
            'game_finishEnemiesUnlocked': this.finishUnlocks.enemies,
            'game_finishArtifactsUnlocked': this.finishUnlocks.artifacts,
            'game_finishModesUnlocked': this.finishUnlocks.modes,
            'game_showXpScreen': this.showXpScreen
        };

        if (this.currentEncounter !== false) {
            data.game_currentEncounter = this.currentEncounter.getEncounterState();
            data.game_screen = this.currentEncounter.getEncounterState().screen;
        } else {
            data.game_currentEncounter = false;
            data.game_screen = 'play';
        }

        if (this.battle !== undefined) {
            data = {...data, ...this.battle.getBattleState()};
        }

        this.soundEffects = [];

        return data;
    }

    isGameWon()
    {
        return this.battle !== undefined && this.battle.showWinScreen === true && this.locations.length === 0 && this.battle.enemy.boss === true;
    }

    getBackgroundMusicBattle()
    {
        if (this.currentEncounter instanceof EnemyEncounter
            && (this.currentEncounter.enemy.backgroundMusic !== null)
        ) {
            return this.currentEncounter.enemy.backgroundMusic;
        }
        return this.currentLocation.backgroundMusicBattle;
    }

    calculateProgress()
    {
        let encountersCompleted = this.currentLocation.encountersCompleted;

        if (this.currentLocation.totalEncounters <= 3) {
            return 100;
        }
        let progress = encountersCompleted / (this.currentLocation.totalEncounters - 3);

        return 100 * progress;
    }

    calculateSeed()
    {
        return hashCode(this.baseSeed + '_' + this.encountersEncountered);
    }

    handleEncounter(encounterId)
    {
        let encounter = this.currentLocation.encounters.filter(e => e.encounterId === encounterId)[0];

        this.currentEncounter = encounter;

        encounter.startEncounter(this);

        this.saveGame();
    }

    endEncounter(encounterId)
    {
        let encounterLeaving = this.currentLocation.encounters.filter(encounter => encounter.encounterId === encounterId)[0];

        this.currentLocation.encounters = this.currentLocation.encounters.filter(encounter => encounter.encounterId !== encounterId);
        this.encountersEncountered++;
        this.currentLocation.encountersCompleted++;

        if (this.currentEncounter === false) {
            this.triggerSoundEffect('/sounds/effects/leave.mp3');
        } else {
            this.currentEncounter.beforeEndEncounter();
        }

        if (encounterLeaving) {
            encounterLeaving.beforeLeaveEncounter();
        }

        if (this.currentEncounter instanceof EnemyEncounter
            && this.currentEncounter.enemy.boss === true
            && this.locations.length > 0
        ) {
            this.currentLocation.encounters.push(EncounterFactory.createNextLocationEncounter());
        }

        this.currentEncounter = false;

        this.saveGame();
    }

    closeEncounter()
    {
        this.currentEncounter = false;
    }

    nextLocation()
    {
        this.currentLocation = this.locations.shift();
        this.currentEncounter = false;
        this.currentLocation.encountersCompleted = 0;
        this.currentLocation.totalEncounters = this.currentLocation.encounters.length;
        this.shuffleCurrentLocationEncounters();
    }

    hasArtifact(artifactName)
    {
        return this.artifacts.filter(artifact => artifact.name === artifactName).length > 0;
    }

    addArtifact(artifact)
    {
        this.artifacts.push(artifact);
        artifact.acquire({
            'artifact': artifact,
            'game': this
        });
    }

    getListeners()
    {
        let listeners = [];
        if (this.artifacts !== undefined) {
            listeners = [...Object.values(this.artifacts)];
        }
        if (this.stats !== undefined) {
            listeners.push(this.stats);
        }
        if (this.battle !== undefined) {
            listeners.push(this.battle);
        }
        listeners.push(this.achievements);

        return listeners;
    }

    claimXp()
    {
        let oldLevel = this.experience.level;
        this.claimAchievements();
        this.experience.gainXp(this.xpBreakdown.enemiesDefeated + this.xpBreakdown.lossPenalty);
        let newLevel = this.experience.level;
        this.finishUnlocks.cards = this.finishUnlocks.cards.concat(this.experience.getUnlocksBetweenLevels(oldLevel, newLevel).cards);
        this.finishUnlocks.enemies = this.finishUnlocks.enemies.concat(this.experience.getUnlocksBetweenLevels(oldLevel, newLevel).enemies);
        this.finishUnlocks.artifacts = this.finishUnlocks.artifacts.concat(this.experience.getUnlocksBetweenLevels(oldLevel, newLevel).artifacts);
        this.finishUnlocks.modes = this.finishUnlocks.modes.concat(this.experience.getUnlocksBetweenLevels(oldLevel, newLevel).modes);
        if (oldLevel < newLevel) {
            this.hasGainedLevel = true;
        }
        this.showXpScreen = true;
    }

    claimAchievements()
    {
        this.achievements.achievements.forEach(function (achievement) {
            if (achievement.pending && !achievement.unlocked) {
                achievement.claim(this.experience);
                this.achievementsUnlocked.push(achievement);
                this.xpBreakdown.achievementsUnlocked += achievement.xp;
                this.finishUnlocks.cards = this.finishUnlocks.cards.concat(achievement.specialUnlocks.cards);
                this.finishUnlocks.enemies = this.finishUnlocks.enemies.concat(achievement.specialUnlocks.enemies);
                this.finishUnlocks.artifacts = this.finishUnlocks.artifacts.concat(achievement.specialUnlocks.artifacts);
                this.finishUnlocks.modes = this.finishUnlocks.modes.concat(achievement.specialUnlocks.modes);
            }
        }.bind(this));
    }

    clickArtifact(uid)
    {
        let artifacts = this.artifacts.filter(artifact => artifact.uid === uid);
        if (artifacts.length > 0) {
            artifacts[0].click(this);
        }
    }

    trigger(triggerName, context = {}, checkWinLoseCondition = true)
    {
        if (checkWinLoseCondition === false) {
            context.checkWinLoseCondition = checkWinLoseCondition;
        }

        if (this.battle !== undefined) {
            this.battle.history.increaseIndent();
        }

        let triggerManager = new TriggerManager();
        if (this.getListeners().length > 0) {
            this.getListeners().forEach(listener => {
                listener.registerTriggers(triggerName, triggerManager)
            });
            triggerManager.triggerEvent(triggerName, context);
        }

        if (checkWinLoseCondition && context.checkWinLoseCondition !== false && this.battle !== undefined && this.battle.showLoseScreen === false && this.battle.showWinScreen === false) {
            this.battle.checkWinLoseCondition(context);
        }

        if (this.battle !== undefined) {
            this.battle.history.decreaseIndent();
        }
    }

    startFight(enemy)
    {
        try {
            this.battle = new Battle(enemy, this);
            this.battle.game.trigger('battleStart', {
                game: this,
                battle: this.battle,
                player: this.battle.enemy,
                enemy: this.battle.player
            });
            this.battle.game.trigger('battleStart', {
                game: this,
                battle: this.battle,
                player: this.battle.player,
                enemy: this.battle.enemy
            });
            this.battle.startTurnAction();
            this.triggerSoundEffect('/sounds/effects/growl.mp3');
        } catch (e) {
            if (!(e instanceof BattleOverException)) {
                throw e;
            }
        }
    }

    addCardToDeck(cardUid)
    {
        let card = this.battle.cardRewards.filter(reward => reward.uid === cardUid)[0];
        this.deck.addToDeck(card);
    }

    endBattle()
    {
        this.endEncounter(this.currentEncounter.encounterId);
        this.hp = this.battle.player.hp;
        delete this.battle;
    }

    saveGame()
    {
        let unpacker = new Unpacker;
        let game = unpacker.serialize(this, this.version);
        localStorage.setItem("game", compressToBase64(game));
        localStorage.setItem("version", this.version);
    }

    addEncounterType(type)
    {
        let encounter;

        let highestEncounterId = Math.max(
            this.locations.reduce(function (accum, location) {
                return Math.max(accum, location.encounters.reduce(function (accum2, encounter) {
                    return Math.max(accum2, encounter.encounterId);
                }, 0), 0);
            }, 0),
            this.currentLocation.encounters.reduce(function (accum3, encounter) {
                return Math.max(accum3, encounter.encounterId);
            }, 0)
        );

        switch (type) {
            case 'shop':
                encounter = EncounterFactory.createShopEncounter();
                this.currentLocation.encounters.push(encounter);
                break;
            case 'chest':
                encounter = EncounterFactory.createChestEncounter();
                this.currentLocation.encounters.push(encounter);
                break;
            case 'blessing':
                encounter = EncounterFactory.createBlessingEncounter();
                this.currentLocation.encounters.push(encounter);
                break;
            case 'pandora':
                encounter = EncounterFactory.createPandoraEncounter();
                this.currentLocation.encounters.push(encounter);
                break;
        }

        encounter.encounterId = (highestEncounterId + 1);
    }
}
