import { shuffle } from "../Helper";
import AbstractEffect from "./AbstractEffect";

class ManaSmoothingEffect extends AbstractEffect
{
    handle(context)
    {
        let totalMana = Object.values(context.player.mana).reduce((a, v) => a + v, 0);

        let newManas = {
            red: Math.floor(totalMana / 3),
            green: Math.floor(totalMana / 3),
            blue: Math.floor(totalMana / 3)
        };

        totalMana -= (Math.floor(totalMana / 3) * 3);

        let manaTypes = shuffle(Object.keys(newManas));

        for (let i = 0; i < totalMana; i++) {
            newManas[manaTypes[i]]++;
        }

        context.player.mana = newManas;

        context.game.triggerSoundEffect('/sounds/effects/generic.wav');
    }

    generateDescription()
    {
        return 'Redistribute your mana evenly.';
    }
}

export default ManaSmoothingEffect;
