import AbstractEffect from "./AbstractEffect";

class ImmuneEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let immuneStatusEffect = context.player.getStatusEffect('immune');
            immuneStatusEffect.changeVal(this.getVal(context) + immuneStatusEffect.val, context);
            context.immuneVal = this.getVal(context);

            context.battle.history.write(context.player.name + ' is immune for ' + this.getVal(context) + ' turns.', context.historySourceImage);
            context.battle.game.triggerSoundEffect('/sounds/effects/immune.wav');
        }
    }

    generateDescription()
    {
        return 'Become immune to status effects for ' + parseInt(this.data) + ' ' + this.pluralise('turn', parseInt(this.data)) + '.';
    }
}

export default ImmuneEffect;
