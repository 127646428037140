import AbstractStatusEffect from "./AbstractStatusEffect";

class DoubleNextRedStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Double Next Red';
        this.image = '/images/new/status/double-next-red.png';
        this.isBuff = true;
    }

    triggerStartPlayerTurn(context)
    {
        if (context.player === this.player) {
            this.changeVal(0, context);
        }
    }

    generateTooltip()
    {
        return 'The effect of the next red card played is doubled.';
    }
}

export default DoubleNextRedStatusEffect;
