class TriggerManager
{
    constructor()
    {
        this.listeners = {};
        this.recursive = true;
        this.running = false;
        this.halt = false;
    }

    setRecursive(bool)
    {
        this.recursive = bool;
    }

    setHalt(bool)
    {
        this.halt = bool;
    }

    registerListener(event, priority, callback)
    {
        if (this.listeners[event] == undefined) {
            this.listeners[event] = {};
        }
        if (this.listeners[event][priority] == undefined) {
            this.listeners[event][priority] = [];
        }
        this.listeners[event][priority].push(callback);
    }

    triggerEvent(event, context)
    {
        if (this.listeners[event] !== undefined) {
            let keys = Object.keys(this.listeners[event]).sort().reverse();
            keys.forEach((key, pos) => {
                if (!this.halt) {
                    this.listeners[event][key].forEach((callback) => callback(event, context));
                    this.listeners[event][key].forEach((callback) => callback("tick", context));
                }
            });
        }
    }

    clear()
    {
        this.listeners = {};
        this.recursive = true;
        this.running = false;
        this.halt = false;
        return this;
    }
}

export default TriggerManager;
