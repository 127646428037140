import FutureStatusEffect from './FutureStatusEffect';
import MusterStatusEffect from './MusterStatusEffect';
import PoisonStatusEffect from './PoisonStatusEffect';
import RegenStatusEffect from './RegenStatusEffect';
import ShieldStatusEffect from './ShieldStatusEffect';
import StrengthenStatusEffect from './StrengthenStatusEffect';
import WeakenStatusEffect from './WeakenStatusEffect';
import ReinforceStatusEffect from './ReinforceStatusEffect';
import BleedStatusEffect from './BleedStatusEffect';
import ThornStatusEffect from './ThornStatusEffect';
import ArmourStatusEffect from './ArmourStatusEffect';
import InvulnerableStatusEffect from './InvulnerableStatusEffect';
import DoubleNextRedStatusEffect from './DoubleNextRedStatusEffect';
import DoubleNextGreenStatusEffect from './DoubleNextGreenStatusEffect';
import NegateStatusEffect from './NegateStatusEffect';
import ImmuneStatusEffect from './ImmuneStatusEffect';
import ManaMasteryStatusEffect from './ManaMasteryStatusEffect';
import Player from '../Player';
import BurnStatusEffect from './BurnStatusEffect';

class StatusEffectFactory
{
    static createStatusEffect(key, player, val)
    {
        switch (key) {
            case 'muster':
                return new MusterStatusEffect(key, player, val);

            case 'future':
                return new FutureStatusEffect(key, player, val);

            case 'poison':
                return new PoisonStatusEffect(key, player, val);

            case 'regen':
                return new RegenStatusEffect(key, player, val);

            case 'shield':
                return new ShieldStatusEffect(key, player, val);

            case 'strengthen':
                return new StrengthenStatusEffect(key, player, val);

            case 'weaken':
                return new WeakenStatusEffect(key, player, val);

            case 'reinforce':
                return new ReinforceStatusEffect(key, player, val);

            case 'bleed':
                return new BleedStatusEffect(key, player, val);

            case 'thorn':
                return new ThornStatusEffect(key, player, val);

            case 'armour':
                return new ArmourStatusEffect(key, player, val);

            case 'invulnerable':
                return new InvulnerableStatusEffect(key, player, val);

            case 'immune':
                return new ImmuneStatusEffect(key, player, val);

            case 'manaMastery':
                return new ManaMasteryStatusEffect(key, player, val);

            case 'negate':
                return new NegateStatusEffect(key, player, val);

            case 'doubleNextRed':
                return new DoubleNextRedStatusEffect(key, player, val);

            case 'doubleNextGreen':
                return new DoubleNextGreenStatusEffect(key, player, val);

            case 'burn':
                return new BurnStatusEffect(key, player, val);
        }
    }

    static createDummyEffect(key)
    {
        return StatusEffectFactory.createStatusEffect(key, Player.createEnemyFromId(1), 1);
    }
}

export default StatusEffectFactory;
