import React from 'react';
import Battle from './Battle';
import ArtifactsBar from './ArtifactsBar';
import BottomBarBattle from './BottomBarBattle';
import BottomBarPlay from './BottomBarPlay';
import Decklist from './Decklist';
import Encounters from './Encounters';
import Settings from './Settings';
import TopBarBattle from './TopBarBattle';
import TopBarPlay from './TopBarPlay';
import SettingsConfirm from './SettingsConfirm';
import WinOverlay from './CardChoiceOverlay';
import FinishOverlay from './FinishOverlay';
import LoseOverlay from './LoseOverlay';
import CardChoiceOverlay from './CardChoiceOverlay';
import Info from './Info';
import History from './History';
import XpOverlay from './XpOverlay';

class BattleScreen extends React.Component
{
    constructor(props) {
        super();
        this.state = {
            displaySettings: false,
            displayDeck: false,
            displayDiscard: false,
            displayExile: false,
            displayEnemyDeck: false,
            displayEnemyDiscard: false,
            displayEnemyExile: false,
            displayRetireConfirm: false,
            displayInfo: false
        };
        this.closeOverlay = this.closeOverlay.bind(this);
        this.openOverlay = this.openOverlay.bind(this);
        this.openRetireConfirm = this.openRetireConfirm.bind(this);
    }

    openOverlay(overlay)
    {
        let stateProperty = 'display' + overlay.charAt(0).toUpperCase() + overlay.slice(1);
        let newStateObject = {};
        newStateObject[stateProperty] = true;

        this.setState(newStateObject);
    }

    closeOverlay(overlay)
    {
        let stateProperty = 'display' + overlay.charAt(0).toUpperCase() + overlay.slice(1);
        let newStateObject = {};
        newStateObject[stateProperty] = false;

        this.setState(newStateObject);
    }

    openRetireConfirm()
    {
        this.setState({
            displayRetireConfirm: true
        });
    }

    render()
    {
        let styles = {
            "background-image": "url('" + this.props.currentLocation.backgroundImage + "')"
        };

        return (
            <div>
                <TopBarBattle
                    openOverlay={this.openOverlay}
                    enemyName={this.props.enemyName}
                    gold={this.props.gold}
                    enemyDeckCount={this.props.enemyDeck.length}
                    enemyDiscardCount={this.props.enemyDiscard.length}
                    enemyExileCount={this.props.enemyExile.length}
                    />
                <div className="content" style={styles}>
                    <ArtifactsBar
                        artifacts={this.props.artifacts}
                        clickArtifact={this.props.clickArtifact}
                        />
                    <Battle
                        playCard={this.props.playCard}
                        endTurn={this.props.endTurn}
                        statusEffects={this.props.statusEffects}
                        image={this.props.image}
                        hand={this.props.hand}
                        animateHurt={this.props.animateHurt}
                        shouldPulse={this.props.shouldPulse}
                        numbers={this.props.numbers}
                        enemyHand={this.props.enemyHand}
                        enemyHp={this.props.enemyHp}
                        enemyMaxHp={this.props.enemyMaxHp}
                        enemyMana={this.props.enemyMana}
                        enemyManaRegen={this.props.enemyManaRegen}
                        enemyStatusEffects={this.props.enemyStatusEffects}
                        enemyImage={this.props.enemyImage}
                        enemyEffectDescription={this.props.enemyEffectDescription}
                        enemyAnimateHurt={this.props.enemyAnimateHurt}
                        enemyShouldPulse={this.props.enemyShouldPulse}
                        enemyNumbers={this.props.enemyNumbers}
                        enemyName={this.props.enemyName}
                        mana={this.props.mana}
                        interactionDisabled={this.props.interactionDisabled}
                        animationSpeed={this.props.animationSpeed}
                        />
                    <History
                        display={this.state.displayInfo}
                        closeOverlay={this.closeOverlay}
                        history={this.props.history}
                        enemyName={this.props.enemyName}
                        playerName={this.props.name}
                        />
                    {/* <a className="history-toggle-button"></a> */}
                </div>
                <BottomBarBattle
                    openOverlay={this.openOverlay}
                    hp={this.props.hp}
                    maxHp={this.props.maxHp}
                    deckCount={this.props.deck.length}
                    discardCount={this.props.discard.length}
                    exileCount={this.props.exile.length}
                    mana={this.props.mana}
                    manaRegen={this.props.manaRegen}
                    />
                <Settings
                    display={this.state.displaySettings}
                    closeOverlay={this.closeOverlay}
                    openRetireConfirm={this.openRetireConfirm}
                    volume={this.props.backgroundMusicVol}
                    changeSoundVolume={this.props.changeSoundVolume}
                    soundEffectVol={this.props.soundEffectVol}
                    changeVolume={this.props.changeVolume}
                    setAnimationSpeed={this.props.setAnimationSpeed}
                    animationSpeed={this.props.animationSpeed}
                    />
                <SettingsConfirm
                    display={this.state.displayRetireConfirm}
                    closeOverlay={this.closeOverlay}
                    retire={this.props.retire}
                    />
                <Decklist
                    closeOverlay={this.closeOverlay}
                    display={this.state.displayDeck}
                    name="deck"
                    title="My Deck"
                    deck={this.props.deck}
                    />
                <Decklist
                    closeOverlay={this.closeOverlay}
                    display={this.state.displayDiscard}
                    name="discard"
                    title="My Discard Pile"
                    deck={this.props.discard}
                    />
                <Decklist
                    closeOverlay={this.closeOverlay}
                    display={this.state.displayExile}
                    name="exile"
                    title="My Exiled Cards"
                    deck={this.props.exile}
                    />
                <Decklist
                    closeOverlay={this.closeOverlay}
                    display={this.state.displayEnemyDeck}
                    name="enemyDeck"
                    title="Enemy Deck"
                    deck={this.props.enemyDeck}
                    />
                <Decklist
                    closeOverlay={this.closeOverlay}
                    display={this.state.displayEnemyDiscard}
                    name="enemyDiscard"
                    title="Enemy Discard Pile"
                    deck={this.props.enemyDiscard}
                    />
                <Decklist
                    closeOverlay={this.closeOverlay}
                    display={this.state.displayEnemyExile}
                    name="enemyExile"
                    title="Enemy's Exiled Cards"
                    deck={this.props.enemyExile}
                    />
                <CardChoiceOverlay
                    display={this.props.isGameWon}
                    chooseCard={this.props.chooseCard}
                    endEncounter={this.props.endEncounter}
                    cards={this.props.cardRewards}
                    title="Enemy Defeated"
                    text="As a reward, please take a card from the choices below..."
                    />
                <LoseOverlay
                    display={this.props.isGameLost}
                    claimXp={this.props.claimXp}
                    image={this.props.image}
                    />
                <FinishOverlay
                    display={this.props.isGameFinish}
                    claimXp={this.props.claimXp}
                    image={this.props.image}
                    playerName={this.props.name}
                    />
                <XpOverlay
                    display={this.props.showXpScreen}
                    retire={this.props.retire}
                    xpBreakdown={this.props.xpBreakdown}
                    currentXp={this.props.currentXp}
                    currentLevel={this.props.currentLevel}
                    currentXpRemaining={this.props.currentXpRemaining}
                    currentPercentProgress={this.props.currentPercentProgress}
                    achievementsUnlocked={this.props.achievementsUnlocked}
                    cardsUnlocked={this.props.finishCardsUnlocked}
                    artifactsUnlocked={this.props.finishArtifactsUnlocked}
                    enemiesUnlocked={this.props.finishEnemiesUnlocked}
                    modesUnlocked={this.props.finishModesUnlocked}
                    hasGainedLevel={this.props.hasGainedLevel}
                    />
                <Info
                    display={this.state.displayInfo}
                    closeOverlay={this.closeOverlay}
                    name="info"
                    />
            </div>
        );
    }
}

export default BattleScreen;
