import React from 'react';
import ArtifactsBar from './ArtifactsBar';
import BookChoices from './BookChoices';
import Blessing from './BookChoices';
import BottomBarPlay from './BottomBarPlay';
import CardChoiceOverlay from './CardChoiceOverlay';
import Decklist from './Decklist';
import Encounters from './Encounters';
import Info from './Info';
import Settings from './Settings';
import Shop from './Shop';
import TopBarPlay from './TopBarPlay';

class PlayScreen extends React.Component
{
    constructor(props) {
        super();
        this.state = {
            displaySettings: false,
            displayDeck: false,
            displayInfo: false
        };
        this.closeOverlay = this.closeOverlay.bind(this);
        this.openOverlay = this.openOverlay.bind(this);
        this.chooseChestCard = this.chooseChestCard.bind(this);
    }

    chooseChestCard(id)
    {
        this.props.triggerEncounterAction('chooseCard', id);
    }

    openOverlay(overlay)
    {
        let stateProperty = 'display' + overlay.charAt(0).toUpperCase() + overlay.slice(1);
        let newStateObject = {};
        newStateObject[stateProperty] = true;

        this.setState(newStateObject);
    }

    closeOverlay(overlay)
    {
        let stateProperty = 'display' + overlay.charAt(0).toUpperCase() + overlay.slice(1);
        let newStateObject = {};
        newStateObject[stateProperty] = false;

        this.setState(newStateObject);
    }

    render()
    {
        let cardChoices = this.props.displayChestCardChoice
            ? (
                <CardChoiceOverlay
                    display={this.props.displayChestCardChoice}
                    chooseCard={this.chooseChestCard}
                    endEncounter={this.props.endEncounter}
                    cards={this.props.chestCardRewards}
                    title="Choose a card"
                    />
            )
            : ''
            ;

        let styles = {
            "background-image": "url('" + this.props.currentLocation.backgroundImage + "')"
        };

        return (
            <div>
                <TopBarPlay
                    openOverlay={this.openOverlay}
                    progress={this.props.progress}
                    locationName={this.props.currentLocation.name}
                    />
                <Info
                    display={this.state.displayInfo}
                    closeOverlay={this.closeOverlay}
                    />
                <Settings
                    display={this.state.displaySettings}
                    closeOverlay={this.closeOverlay}
                    volume={this.props.backgroundMusicVol}
                    changeVolume={this.props.changeVolume}
                    changeSoundVolume={this.props.changeSoundVolume}
                    soundEffectVol={this.props.soundEffectVol}
                    setAnimationSpeed={this.props.setAnimationSpeed}
                    animationSpeed={this.props.animationSpeed}
                    />
                <div className="content" style={styles}>
                    <ArtifactsBar
						artifacts={this.props.artifacts}
                        clickArtifact={this.props.clickArtifact}
                        />
                    <Encounters
                        encounters={this.props.encounters}
                        startEncounter={this.props.startEncounter}
                        leaveEncounter={this.props.leaveEncounter}
                        />
                </div>
                <BottomBarPlay
                    openOverlay={this.openOverlay}
                    hp={this.props.hp}
                    maxHp={this.props.maxHp}
                    gold={this.props.gold}
                    deckCount={this.props.deck.length}
                    mana={this.props.mana}
                    manaRegen={this.props.manaRegen}
                    />
                <Decklist
                    closeOverlay={this.closeOverlay}
                    display={this.state.displayDeck}
                    deck={this.props.deck}
                    title="My Deck"
                    name="deck"
                    />
                {(this.props.shouldDisplayShop) ?
                    <Shop
                        cardsForPurchase={this.props.cardsForPurchase}
                        cardsPurchased={this.props.cardsPurchased}
                        artifactsForPurchase={this.props.artifactsForPurchase}
                        artifactsPurchased={this.props.artifactsPurchased}
                        encounterAction={this.props.triggerEncounterAction}
                        servicesPurchased={this.props.servicesPurchased}
						generalWareCost={this.props.generalWareCost}
						discountPercentage={this.props.discountPercentage}
                        chooseTab={this.props.setShopTab}
                        deck={this.props.deck}
                        display={this.props.shouldDisplayShop}
                        gold={this.props.gold}
                        closeOverlay={this.props.closeEncounter}
                        />
                    : ''
                }
                {(this.props.shouldDisplayBlessing) ?
                    <BookChoices
                        choices={this.props.blessingChoices}
                        closeOverlay={this.props.closeEncounter}
                        display={this.props.shouldDisplayBlessing}
                        encounterAction={this.props.triggerEncounterAction}
                        postChoiceMessage={this.props.postChoiceMessage}
                        title={'Ancient Tome'}
                        description={'You discover an ancient and mysterious tome. Struggling to read the dated script, you can just about make out instructions on how to focus your energy and improve your skills. What chapter do you read?'}
                        encounterActionName={'chooseBlessing'}
                        endEncounter={this.props.endEncounter}
                        />
                    : ''
                }
                {(this.props.shouldDisplayPandora) ?
                    <BookChoices
                        choices={this.props.pandoraChoices}
                        closeOverlay={this.props.closeEncounter}
                        display={this.props.shouldDisplayPandora}
                        encounterAction={this.props.triggerEncounterAction}
                        postChoiceMessage={this.props.postChoiceMessage}
                        title={'Grimoire'}
                        description={'You come a cross a stained and rotting book entitled Grimoire. You can see the names of spells on different pages, but it looks as though they all come with a cost...'}
                        encounterActionName={'readIncantation'}
                        endEncounter={this.props.endEncounter}
                        />
                    : ''
                }
                {cardChoices}
            </div>
        );
    }
}

export default PlayScreen;
