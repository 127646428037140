import AbstractStatusEffect from "./AbstractStatusEffect";

class ImmuneStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Immune';
        this.image = '/images/new/status/immune.png';
        this.soundEffect = '/sounds/effects/negate3.mp3';
        this.isBuff = true;
    }

    triggerStartPlayerTurn(context)
    {
        if (context.player === this.player) {
            if (this.val > 0) {
                this.changeVal(this.val - 1, context);
            } else {
                this.changeVal(0, context);
            }
        }
    }

    generateTooltip()
    {
        return 'Player is immune to most debuffs for ' + this.val + 'turns.';
    }
}

export default ImmuneStatusEffect;
