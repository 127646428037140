import Card from './Card';

class Stats
{
    constructor()
    {
        this.totalBlueManaSpent = 0;
        this.totalGreenManaSpent = 0;
        this.totalRedManaSpent = 0;
        this.totalDamageDone = 0;
        this.totalDamageReceived = 0;
        this.totalDamageMitigated = 0;
        this.totalShieldsAdded = 0;
        this.highestDamageDealt = 0;
        this.totalTurnsForKill = 0;
        this.goldSpent = 0;
        this.enemiesDefeated = 0;
        this.totalHealing = 0;
        this.cardUsage = {};

        this.triggerPriorities = {};
    }

    static loadFromSessionData(sessionData)
    {
        let stats = new Stats();

        if (sessionData) {
            stats.totalBlueManaSpent = sessionData.totalBlueManaSpent;
            stats.totalGreenManaSpent = sessionData.totalGreenManaSpent;
            stats.totalRedManaSpent = sessionData.totalRedManaSpent;
            stats.totalDamageDone = sessionData.totalDamageDone;
            stats.totalDamageReceived = sessionData.totalDamageReceived;
            stats.totalDamageMitigated = sessionData.totalDamageMitigated;
            stats.totalShieldsAdded = sessionData.totalShieldsAdded;
            stats.highestDamageDealt = sessionData.highestDamageDealt;
            stats.totalTurnsForKill = sessionData.totalTurnsForKill;
            stats.goldSpent = sessionData.goldSpent;
            stats.enemiesDefeated = sessionData.enemiesDefeated;
            stats.totalHealing = sessionData.totalHealing;
            stats.cardUsage = sessionData.cardUsage;
        }

        return stats;
    }

    getSessionData()
    {
        return {
            totalBlueManaSpent: this.totalBlueManaSpent,
            totalGreenManaSpent: this.totalGreenManaSpent,
            totalRedManaSpent: this.totalRedManaSpent,
            totalDamageDone: this.totalDamageDone,
            totalDamageReceived: this.totalDamageReceived,
            totalDamageMitigated: this.totalDamageMitigated,
            totalShieldsAdded: this.totalShieldsAdded,
            highestDamageDealt: this.highestDamageDealt,
            totalTurnsForKill: this.totalTurnsForKill,
            goldSpent: this.goldSpent,
            enemiesDefeated: this.enemiesDefeated,
            totalHealing: this.totalHealing,
            cardUsage: this.cardUsage,
        };
    }

    registerTriggers(triggerName, triggerManager)
    {
        let priority = 100;
        if (this.triggerPriorities[triggerName] !== undefined) {
            priority = this.triggerPriorities[triggerName];
        }
        triggerManager.registerListener(triggerName, priority, this.trigger.bind(this));
    }

    trigger(triggerName, context)
    {
        let methodName = 'trigger' + triggerName.charAt(0).toUpperCase() + triggerName.slice(1);
        if (this[methodName] !== undefined) {
            this[methodName](context);
        }
    }

    triggerStartPlayerTurn(context)
    {
        if (context.player.isPlayer()) {
            this.totalTurnsForKill++;
        }
    }

    triggerPlayerGainShield(context)
    {
        if (context.player.isPlayer()) {
            this.totalShieldsAdded += context.shieldVal;
        }
    }

    triggerBeforePlayCard(context)
    {
        if (context.player.isPlayer()) {
            let card = context.card;
            if (this.cardUsage[card.id] === undefined) {
                this.cardUsage[card.id] = 1;
            } else {
                this.cardUsage[card.id]++;
            }

            this.totalBlueManaSpent += card.cost?.blue || 0;
            this.totalGreenManaSpent += card.cost?.green || 0;
            this.totalRedManaSpent += card.cost?.red || 0;
        }
    }

    triggerAfterSpendGold(context)
    {
        this.goldSpent += context.goldSpent;
    }

    triggerPlayerWin(context)
    {
        this.enemiesDefeated++;
    }

    triggerAfterTakeDamage(context)
    {
        let sourcePlayer = context?.source?.player;
        if (context.player.isPlayer() === false
            && (
                sourcePlayer === undefined
                || sourcePlayer.isPlayer() === true
            )
        ) {
            this.totalDamageDone += parseInt(context.damageVal);
            if (context.damageVal > this.highestDamageDealt) {
                this.highestDamageDealt = context.damageVal;
            }
        } else {
            this.totalDamageReceived += context.damageVal;
            this.totalDamageMitigated += context.damageMitigated;
        }
    }

    triggerHeal(context)
    {
        if (context.player.isPlayer()) {
            this.totalHealing += context.healVal;
        }
    }

    getState()
    {
        let averageTurnsForKill = 0;
        let shieldEffectiveness = 0;
        let favouriteCardName = "None";
        if (this.enemiesDefeated !== 0) {
            averageTurnsForKill = Math.round(this.totalTurnsForKill * 100 / this.enemiesDefeated) / 100;
        }
        if (this.totalShieldsAdded !== 0) {
            shieldEffectiveness = Math.round(this.totalDamageMitigated * 100 / this.totalShieldsAdded) / 100;
        }
        let favouriteCardId = Object.keys(this.cardUsage).reduce((a, b) => this.cardUsage[a] > this.cardUsage[b] ? a : b, 0);
        if (favouriteCardId > 0) {
            let favouriteCard = Card.createFromCardId(favouriteCardId);
            favouriteCardName = favouriteCard.name;
        }
        let totalScore =
            Math.round(100 * (
            (this.enemiesDefeated * 10)
            + (shieldEffectiveness * 20)
            + (100 / (averageTurnsForKill > 0 ? averageTurnsForKill : 100))
            + (this.highestDamageDealt)
            )) / 100;

        return {
            totalBlueManaSpent: this.totalBlueManaSpent,
            totalGreenManaSpent: this.totalGreenManaSpent,
            totalRedManaSpent: this.totalRedManaSpent,
            totalDamageDone: this.totalDamageDone,
            totalDamageReceived: this.totalDamageReceived,
            totalDamageMitigated: this.totalDamageMitigated,
            totalShieldsAdded: this.totalShieldsAdded,
            shieldEffectiveness: shieldEffectiveness,
            highestDamageDealt: this.highestDamageDealt,
            totalTurnsForKill: this.totalTurnsForKill,
            averageTurnsForKill: averageTurnsForKill,
            goldSpent: this.goldSpent,
            enemiesDefeated: this.enemiesDefeated,
            totalHealing: this.totalHealing,
            favouriteCardName: favouriteCardName,
            cardUsage: this.cardUsage,
            totalScore: totalScore
        }
    }
}

export default Stats;
