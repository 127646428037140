import React from 'react';

class Card extends React.Component
{
    getCardClasses() {
        let classNames = 'card card-front' + (this.props.addUnplayableClass ? ' card--unplayable' : '');
        if (Object.keys(this.props.cost).length > 1) {
            classNames += ' card--mix';
            if (this.props.cost.red !== undefined) {
                classNames += '-red';
            }
            if (this.props.cost.green !== undefined) {
                classNames += '-green';
            }
            if (this.props.cost.blue !== undefined) {
                classNames += '-blue';
            }
        } else if (this.props.cost.red !== undefined) {
            classNames += ' card--red';
        } else if (this.props.cost.green !== undefined) {
            classNames += ' card--green';
        } else if (this.props.cost.blue !== undefined) {
            classNames += ' card--blue';
        }
        if (this.props.noBorder === true) {
            classNames += ' card--no-border';
        }
        classNames += ' card--tier-' + this.props.tier;
        return classNames;
    }

    getStyles()
    {
        return this.props.styles;
    }

    render() {
        let backFace = '';
        if (this.props.showBackFace === true) {
            backFace = (<div className="card-back"></div>);
        }
        return (
            <div className={"card-wrapper " + (this.props.aboutToPlay ? ' card-wrapper--about-to-play' : '')} style={this.getStyles()}>
                <div className={this.getCardClasses()} onClick={() => this.props.playCard(this.props.uid)}>
                    <h3 className="card__name">{this.props.name}</h3>
                    <div className="card__costs">
                        {Object.keys(this.props.cost).map((manaType, cost) => {
                            let className = 'card__cost card__cost--' + manaType;
                            let symbolClassName = 'mana-symbol mana-symbol--' + manaType;
                            return (
                                <div className={className} key={manaType}>
                                    <span className={symbolClassName}>{this.props.cost[manaType]}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div className="card__image">
                        <img src={this.props.image} alt={this.props.name} />
                    </div>
                    <div className="card__text">{this.props.description}</div>
                </div>
                {backFace}
            </div>
        );
    }
}

export default Card;
