import React from 'react';
import PlayerState from './PlayerState';
import Hand from './Hand';

class PlayArea extends React.Component
{
    render() {
        return (
            <div className='playerarea'>
                <Hand
                    type='player'
                    playCard={this.props.playCard}
                    hand={this.props.hand}
                    mana={this.props.mana}
                />
                <PlayerState
                    type='player'
                    hp={this.props.hp}
                    maxHp={this.props.maxHp}
                    mana={this.props.mana}
                    manaRegen={this.props.manaRegen}
                    statusEffects={this.props.statusEffects}
                    artifacts={this.props.artifacts}
                    name={this.props.name}
                    image={this.props.image}
                    surrender={this.props.surrender}
                    endTurn={this.props.endTurn}
                />
            </div>
        );
    }
}

export default PlayArea;
