import AbstractStatusEffect from "./AbstractStatusEffect";

class ArmourStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Armour';
        this.image = '/images/new/status/armour.png';
        this.isBuff = true;
    }

    generateTooltip()
    {
        return 'Whenever player gains shield, gain ' + this.val + ' more.';
    }
}

export default ArmourStatusEffect;
