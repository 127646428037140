import { shuffle } from "../Helper";
import AbstractStatusEffect from "./AbstractStatusEffect";

class BurnStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Burn';
        this.image = '/images/new/status/burn.png';
        this.soundEffect = '/sounds/effects/burn.mp3';
        this.isDebuff = true;
    }

    triggerBeforePlayCard(context)
    {
        this.activate(context);
    }

    activate(context)
    {
        if (context.player === this.player) {
            if (this.val > 0) {
                let hand = this.player.deck.hand.filter(card => context?.card?.id !== card.id);
                if (hand.length > 0) {
                    this.player.deck.move(hand[0], 'hand', 'discard');
                }
                this.changeVal(this.val - 1, context);
                context.game.battle.history.write(this.player.name + ' discards a card due to Burn.', this.image);
                this.pulse();
                this.playSoundEffect(context);
            } else {
                this.changeVal(0, context);
            }
        }
    }

    generateTooltip()
    {
        return 'The next ' + this.val + ' cards you play will cause a card to be discarded.';
    }
}

export default BurnStatusEffect;
