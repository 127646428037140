import AbstractEffect from "./AbstractEffect";

class OppManaGainEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            Object.keys(this.data).map(manaType => {
                context.enemy.mana[manaType] += this.calculateValue(this.data[manaType], context);

                context.battle.history.write(context.enemy.name + ' gains ' + this.calculateValue(this.data[manaType], context) + ' ' + manaType + ' mana', context.historySourceImage);
            });

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription()
    {
        let description = '';
        let descriptionParts = [];
        let manaText = [];

        let manaTypesToIncrease = Object.keys(this.data).filter(key => this.data[key] > 0);
        let manaTypesToDecrease = Object.keys(this.data).filter(key => this.data[key] < 0);

        if (manaTypesToIncrease.length > 0) {
            manaText = [];
            description = 'gains ';

            manaTypesToIncrease.map(manaType => {
                manaText.push(this.data[manaType] + ' ' + manaType + ' mana');
            });

            description += this.joinAsList(manaText);

            descriptionParts.push(description);
        }

        if (manaTypesToDecrease.length > 0) {
            manaText = [];
            description = 'loses ';

            manaTypesToDecrease.map(manaType => {
                manaText.push(Math.abs(this.data[manaType]) + ' ' + manaType + ' mana');
            });

            description += this.joinAsList(manaText);

            descriptionParts.push(description);
        }

        description = this.joinAsList(descriptionParts) + '.';

        return 'The opponent ' + description;
    }
}

export default OppManaGainEffect;
