import React from 'react';
import Overlay from './Overlay';

class SettingsConfirm extends React.Component
{
    render()
    {
        return (
            <Overlay
                overlayClasses="settings-confirm"
                showClose={false}
                display={this.props.display}
                >
                <p className="settings-confirm__text">Are you sure? This will end your current run.</p>
                <div className="settings-confirm-buttons grid grid--flex grid--flush-bottom">
                    <div className="l-one-half">
                        <button className="settings-confirm-yes" onClick={() => this.props.retire()}>Yes</button>
                    </div>
                    <div className="l-one-half">
                        <button className="settings-confirm-no" onClick={() => this.props.closeOverlay('retireConfirm')}>No</button>
                    </div>
                </div>
            </Overlay>
        );
    }
}

export default SettingsConfirm;
