import AbstractEffect from "./AbstractEffect";

class RegenEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let currentRegenVal = 0;
            let regenStatusEffect = context.player.getStatusEffect('regen');
            let poisonStatusEffect = context.player.getStatusEffect('poison');

            currentRegenVal += regenStatusEffect.val;
            currentRegenVal -= poisonStatusEffect.val;

            let newRegenVal = currentRegenVal + this.getVal(context);

            if (newRegenVal > 0) {
                regenStatusEffect.changeVal(newRegenVal, context);
                poisonStatusEffect.changeVal(0, context);
                context.battle.history.write(context.player.name + ' now regens for ' + newRegenVal, context.historySourceImage);
                return;
            }
            if (newRegenVal < 0) {
                poisonStatusEffect.changeVal(-1 * newRegenVal, context);
                regenStatusEffect.changeVal(0, context);
                context.battle.history.write(context.player.name + ' now only poisoned for ' + (-1 * newRegenVal), context.historySourceImage);
                return;
            }
            regenStatusEffect.changeVal(0, context);
            poisonStatusEffect.changeVal(0, context);
            context.battle.history.write(context.player.name + ' cured of poison', context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription()
    {
        return 'Regen for ' + parseInt(this.data) + '.';
    }
}

export default RegenEffect;
