import AbstractValue from "./AbstractValue";
import ValueFactory from "./ValueFactory";

class RandomValue extends AbstractValue
{
    constructor(data)
    {
        super(data);
        this.val = data.val.map(value => ValueFactory.createValueFromJson(value));
    }

    calculate(context)
    {
        let range = this.val[1].calculate(context) - this.val[0].calculate(context);
        let r = (Math.random() * range) + this.val[0].calculate(context);
        return r + this.valExtra;
    }
}

export default RandomValue;
