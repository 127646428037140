import AbstractEffect from "./AbstractEffect";

class PoisonEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let currentPoisonVal = 0;
            let regenStatusEffect = context.enemy.getStatusEffect('regen');
            let poisonStatusEffect = context.enemy.getStatusEffect('poison');
            let immuneStatusEffect = context.enemy.getStatusEffect('immune');
            if (immuneStatusEffect.val > 0) {
                immuneStatusEffect.pulse();
                return;
            }

            currentPoisonVal += poisonStatusEffect.val;
            currentPoisonVal -= regenStatusEffect.val;

            let newPoisonVal = currentPoisonVal + this.getVal(context);

            if (newPoisonVal > 0) {
                poisonStatusEffect.changeVal(newPoisonVal, context);
                regenStatusEffect.changeVal(0, context);
                context.battle.history.write(context.enemy.name + ' now poisoned for ' + newPoisonVal, context.historySourceImage);
            } else if (newPoisonVal < 0) {
                regenStatusEffect.changeVal(-1 * newPoisonVal, context);
                poisonStatusEffect.changeVal(0, context);
                context.battle.history.write(context.enemy.name + ' now only regens for ' + (-1 * newPoisonVal), context.historySourceImage);
            } else {
                regenStatusEffect.changeVal(0, context);
                poisonStatusEffect.changeVal(0, context);
                context.battle.history.write(context.enemy.name + ' no longer regenerating', context.historySourceImage);
            }

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
            let newContext = {...context};
            context.battle.game.trigger('playerInflictPoison', newContext);
        }
    }

    generateDescription()
    {
        return 'Poison the enemy for ' + parseInt(this.data) + '.';
    }
}

export default PoisonEffect;
