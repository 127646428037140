import AbstractEffect from "./AbstractEffect";

class TimewalkEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            context.battle.history.write(context.player.name + ' warps time through ' + this.getVal(context) + ' turns');

            let futureStatusEffect = context.player.getStatusEffect('future');

            for (let i = 0; i < this.getVal(context); i++) {
                futureStatusEffect.val.forEach(futureEffect => {
                    futureEffect.turns--;
                    if (futureEffect.turns === 0) {
                        context.battle.history.write(futureEffect.name + ' activates!');
                        futureEffect.effects.forEach(effect => {
                            effect.handle(context);
                        })
                    }
                });
                futureStatusEffect.changeVal(futureStatusEffect.val.filter(futureEffect => futureEffect.turns > 0), context);
            }

            console.log('afterTimewalkHandle');

            context.battle.game.triggerSoundEffect('/sounds/effects/timewalk.wav');
        }
    }

    generateDescription()
    {
        return 'Speeds up future effects by ' + parseInt(this.data) + ' ' + this.pluralise('turn', parseInt(this.data)) + '.';
    }
}

export default TimewalkEffect;
