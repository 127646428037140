export const artifactsData = {
    1: {
        "id": 1,
        "name": "Health Stone",
        "description": "Gain 15 max health and 15 health.",
        "image": "/images/new/artifacts/healthstone.png",
        "effects": [
            {
                "trigger": "onEnable",
                "effect": "gainMaxHealth",
                "val": {
                    "valType": "flat",
                    "val": 15
                }
            },
            {
                "trigger": "onEnable",
                "effect": "playerHeal",
                "val": {
                    "valType": "flat",
                    "val": 15
                }
            },
            {
                "trigger": "onDisable",
                "effect": "loseMaxHealth",
                "val": {
                    "valType": "flat",
                    "val": 15
                }
            }
        ]
    },
    2: {
        "id": 2,
        "name": "Nature Stone",
        "description": "Upon starting a turn with 10 or more green mana, gain 3 shield.",
        "image": "/images/new/artifacts/naturestone.png",
        "effects": [
            {
                "trigger": "afterStartPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "playerGreenMana"
                            },
                            {
                                "valType": "flat",
                                "val": 10
                            }
                        ]
                    }
                ],
                "effect": "playerGainShield",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            }
        ]
    },
    3: {
        "id": 3,
        "name": "Bear Claw",
        "description": "Every 5th time you deal damage, deal an extra 3 damage.",
        "image": "/images/new/artifacts/bearclaw.png",
        "effects": [
            {
                "trigger": "beforeDealDamage",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "beforeDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "increaseDamage",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "beforeDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "resetCounter"
            }
        ]
    },
    4: {
        "id": 4,
        "name": "Healing Fungus",
        "description": "Every 3rd time you play a green card, heal 1.",
        "image": "/images/new/artifacts/healingfungus.png",
        "effects": [
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "cardCostGreen"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 3
                            }
                        ]
                    }
                ],
                "effect": "playerHeal",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 3
                            }
                        ]
                    }
                ],
                "effect": "resetCounter"
            }
        ]
    },
    5: {
        "id": 5,
        "name": "Phoenix Ashes",
        "description": "If you would take lethal damage, heal 50% of your max health. One use only.",
        "image": "/images/new/artifacts/phoenixashes.png",
        "effects": [
            {
                "trigger": "afterTakeDamage",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "playerHp"
                            },
                            {
                                "valType": "flat",
                                "val": 0
                            }
                        ]
                    }
                ],
                "effect": "contextMerge",
                "val": {
                    "valType": "flat",
                    "val": {
                        "checkWinLoseCondition": false
                    }
                },
            },
            {
                "trigger": "afterTakeDamage",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "playerHp"
                            },
                            {
                                "valType": "flat",
                                "val": 0
                            }
                        ]
                    }
                ],
                "effect": "playerHeal",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "percentage",
                        "val": 50,
                        "valProperty": "playerMaxHp"
                    }
                },
                "singleUse": true
            }
        ]
    },
    6: {
        "id": 6,
        "name": "Chalice of Blood",
        "description": "Dealing damage with a red card will heal 1.",
        "image": "/images/new/artifacts/chaliceofblood.png",
        "effects": [
            {
                "trigger": "afterPlayCard",
                "conditions": [
                    {
                        "comparison": "or",
                        "vals": [
                            {
                                "valType": "condition",
                                "val": {
                                    "comparison": "includes",
                                    "vals": [
                                        {
                                            "valType": "attribute",
                                            "val": "cardEffects"
                                        },
                                        {
                                            "valType": "flat",
                                            "val": "attack"
                                        }
                                    ]
                                }
                            },
                            {
                                "valType": "condition",
                                "val": {
                                    "comparison": "includes",
                                    "vals": [
                                        {
                                            "valType": "attribute",
                                            "val": "cardEffects"
                                        },
                                        {
                                            "valType": "flat",
                                            "val": "critAttack"
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        "comparison": "includes",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "cardCostTypes"
                            },
                            {
                                "valType": "flat",
                                "val": "red"
                            }
                        ]
                    }
                ],
                "effect": "playerHeal",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "unlockLevel": 10
    },
    7: {
        "id": 7,
        "name": "Mana Machine",
        "description": "For every 5 blue mana spent, gain 1 red and 1 green mana.",
        "image": "/images/new/artifacts/manamachine.png",
        "effects": [
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "cardCostBlue"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "incrementCounter",
                "val": {
                    "valType": "percentage",
                    "val": 100,
                    "valProperty": "cardCostBlue"
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "playerGainManaGreen",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "calc",
                        "valCalc": "divide",
                        "val": [
                            {
                                "valType": "percentage",
                                "valProperty": "internalCounter",
                                "val": 100
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "playerGainManaRed",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "calc",
                        "valCalc": "divide",
                        "val": [
                            {
                                "valType": "percentage",
                                "valProperty": "internalCounter",
                                "val": 100
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "decrementCounter",
                "val": {
                    "valType": "calc",
                    "valCalc": "multiply",
                    "val": [
                        {
                            "valType": "calc",
                            "valCalc": "floor",
                            "val": {
                                "valType": "calc",
                                "valCalc": "divide",
                                "val": [
                                    {
                                        "valType": "percentage",
                                        "valProperty": "internalCounter",
                                        "val": 100,
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 5
                                    }
                                ]
                            }
                        },
                        {
                            "valType": "flat",
                            "val": 5
                        }
                    ]
                }
            }
        ],
        "unlockLevel": 10
    },
    8: {
        "id": 8,
        "name": "Amulet of Fury",
        "description": "Gain 1 red and green mana gain per turn. Lose 1 blue mana gain per turn.",
        "image": "/images/new/artifacts/amuletoffury.png",
        "effects": [
            {
                "trigger": "onEnable",
                "effect": "playerFocusRedPerm",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "onEnable",
                "effect": "playerFocusGreenPerm",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "onEnable",
                "effect": "playerFocusBluePerm",
                "val": {
                    "valType": "flat",
                    "val": -1
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerFocusRedPerm",
                "val": {
                    "valType": "flat",
                    "val": -1
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerFocusGreenPerm",
                "val": {
                    "valType": "flat",
                    "val": -1
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerFocusBluePerm",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ]
    },
    9: {
        "id": 9,
        "name": "Lucky Coin",
        "description": "Gold gain is increased by 50%.",
        "image": "/images/new/artifacts/luckycoin.png",
        "effects": [
            {
                "trigger": "gainGold",
                "effect": "gainGold",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "percentage",
                        "val": 50,
                        "valProperty": "goldGain"
                    }
                }
            }
        ]
    },
    10: {
        "id": 10,
        "name": "Magician's Hat",
        "description": "Every card in your starting hand costs nothing (effect ends once discarded).",
        "image": "/images/new/artifacts/magicianshat.png",
        "effects": [
            {
                "trigger": "afterPlayerDraw",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "valProperty": "turnCount",
                                "val": 100
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "makeHandFreeOnce",
            }
        ],
        "unlockLevel": 9
    },
    11: {
        "id": 11,
        "name": "Warrior's Gauntlet",
        "description": "Muster 1",
        "image": "/images/new/artifacts/warriorsgauntlet.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerMuster",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "unlockLevel": 1
    },
    12: {
        "id": 12,
        "name": "Six Fingered Glove",
        "description": "Increase card draw by 1.",
        "image": "/images/new/artifacts/sixfingeredglove.png",
        "effects": [
            {
                "trigger": "onEnable",
                "effect": "playerIncreaseDraw",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerIncreaseDraw",
                "val": {
                    "valType": "flat",
                    "val": -1
                }
            }
        ],
        "unlockLevel": 1
    },
    13: {
        "id": 13,
        "name": "Shield of Hope",
        "description": "Start each battle with 5 shield.",
        "image": "/images/new/artifacts/shieldofhope.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerGainShield",
                "val": {
                    "valType": "flat",
                    "val": 5
                }
            }
        ],
        "unlockLevel": 1
    },
    14: {
        "id": 14,
        "name": "Leather Purse",
        "description": "The next three items you purchase are half off.",
        "image": "/images/new/artifacts/leatherpurse.png",
        "effects": [
            {
                "trigger": "onEnable",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "afterSpendGold",
                "effect": "decrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "afterSpendGold",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "valProperty": "internalCounter",
                                "val": 100
                            },
                            {
                                "valType": "flat",
                                "val": 0
                            }
                        ]
                    }
                ],
                "effect": "expire"
            }
        ],
        "passiveEffects": [
            "itemsHalfOff"
        ],
        "unlockLevel": 2
    },
    15: {
        "id": 15,
        "name": "Poisoned Apple",
        "description": "Whenever you inflict poison, heal 1.",
        "image": "/images/new/artifacts/poisonedapple.png",
        "effects": [
            {
                "trigger": "playerInflictPoison",
                "effect": "playerHeal",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "unlockLevel": 1
    },
    16: {
        "id": 16,
        "name": "Pink Lotus",
        "description": "Focus effects are twice as effective",
        "image": "/images/new/artifacts/pinklotus.png",
        "effects": [
            {
                "trigger": "beforeGainFocus",
                "effect": "doubleFocusAmount",
            }
        ],
        "unlockLevel": 1
    },
    17: {
        "id": 17,
        "name": "Supression Net",
        "description": "Every five turns, your opponent loses all their mana.",
        "image": "/images/new/artifacts/supressionnet.png",
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "enemyOverrideManaGreen",
                "val": {
                    "valType": "flat",
                    "val": 0
                }
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "enemyOverrideManaBlue",
                "val": {
                    "valType": "flat",
                    "val": 0
                }
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "enemyOverrideManaRed",
                "val": {
                    "valType": "flat",
                    "val": 0
                }
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "resetCounter"
            }
        ],
        "unlockLevel": 5
    },
    18: {
        "id": 18,
        "name": "Polished Sword",
        "description": "Every five damage you deal, gain one shield.",
        "image": "/images/new/artifacts/polishedsword.png",
        "effects": [
            {
                "trigger": "afterDealDamage",
                "effect": "incrementCounter",
                "val": {
                    "valType": "percentage",
                    "val": 100,
                    "valProperty": "damageReceived"
                }
            },
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "playerGainShield",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "calc",
                        "valCalc": "divide",
                        "val": [
                            {
                                "valType": "percentage",
                                "valProperty": "internalCounter",
                                "val": 100
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                }
            },
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            }
                        ]
                    }
                ],
                "effect": "decrementCounter",
                "val": {
                    "valType": "calc",
                    "valCalc": "multiply",
                    "val": [
                        {
                            "valType": "calc",
                            "valCalc": "floor",
                            "val": {
                                "valType": "calc",
                                "valCalc": "divide",
                                "val": [
                                    {
                                        "valType": "percentage",
                                        "valProperty": "internalCounter",
                                        "val": 100,
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 5
                                    }
                                ]
                            }
                        },
                        {
                            "valType": "flat",
                            "val": 5
                        }
                    ]
                }
            }
        ],
        "unlockLevel": 1
    },
    19: {
        "id": 19,
        "name": "Friendship Bracelet",
        "description": "Causing yourself damage also hurts the enemy for 50% of the damage.",
        "image": "/images/new/artifacts/friendshipbracelet.png",
        "effects": [
            {
                "trigger": "afterTakeDamage",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "selfInflictedDamage"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            },
                        ]
                    }
                ],
                "effect": "damageEnemy",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "percentage",
                        "valProperty": "damageReceived",
                        "val": 50
                    }
                }
            }
        ],
        "unlockLevel": 1
    },
    20: {
        "id": 20,
        "name": "Antidote",
        "description": "Every three turns, reduce poison amount by 1.",
        "image": "/images/new/artifacts/poisonimmunity.png",
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 3
                            }
                        ]
                    }
                ],
                "effect": "reducePoison",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 3
                            }
                        ]
                    }
                ],
                "effect": "resetCounter"
            }
        ],
        "unlockLevel": 1
    },
    21: {
        "id": 21,
        "name": "Serrated Dagger",
        "description": "Every third turn, dealing damage causes 1 bleed.",
        "image": "/images/new/artifacts/serrateddagger.png",
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 2
                            }
                        ]
                    },
                ],
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": true
                }
            },
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 2
                            }
                        ]
                    },
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "damageReceived"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "enemyBleed",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 3
                            }
                        ]
                    }
                ],
                "effect": "resetCounter"
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 0
                            }
                        ]
                    },
                ],
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": false
                }
            },
        ],
        "unlockLevel": 1
    },
    22: {
        "id": 22,
        "name": "Spiked Mace",
        "description": "Causing 10 damage in a turn inflicts 2 Bleed",
        "image": "/images/new/artifacts/spikedmace.png",
        "effects": [
            {
                "trigger": "startPlayerTurn",
                "effect": "enableTriggers",
            },
            {
                "trigger": "startPlayerTurn",
                "effect": "resetCounter",
            },
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "damageReceived"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    },
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "selfInflictedDamage",
                            },
                            {
                                "valType": "flat",
                                "val": false
                            }
                        ]
                    },
                ],
                "effect": "incrementCounter",
                "val": {
                    "valType": "calc",
                    "valCalc": "min",
                    "val": [
                        {
                            "valType": "percentage",
                            "valProperty": "damageReceived",
                            "val": 100
                        },
                        {
                            "valType": "calc",
                            "valCalc": "subtract",
                            "val": [
                                {
                                    "valType": "flat",
                                    "val": 10
                                },
                                {
                                    "valType": "percentage",
                                    "valProperty": "internalCounter",
                                    "val": 100
                                },
                            ]
                        }
                    ]
                }
            },
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 10
                            }
                        ]
                    }
                ],
                "effect": "enemyBleed",
                "val": {
                    "valType": "flat",
                    "val": 2
                }
            },
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 10
                            }
                        ]
                    }
                ],
                "effect": "disableTriggers"
            }
        ],
        "unlockLevel": 1
    },
    23: {
        "id": 23,
        "name": "Rock Pile",
        "description": "Spending 10 mana adds a Tiny Rock to the enemy's deck.",
        "image": "/images/new/artifacts/rockpile.png",
        "effects": [
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "cardCostBlue"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "incrementCounter",
                "val": {
                    "valType": "percentage",
                    "val": 100,
                    "valProperty": "cardCostBlue"
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "cardCostGreen"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "incrementCounter",
                "val": {
                    "valType": "percentage",
                    "val": 100,
                    "valProperty": "cardCostGreen"
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "cardCostRed"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "incrementCounter",
                "val": {
                    "valType": "percentage",
                    "val": 100,
                    "valProperty": "cardCostRed"
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 10
                            }
                        ]
                    }
                ],
                "effect": "enemyAddCardToDeck",
                "val": {
                    "valType": "array",
                    "val": [
                        {
                            "valType": "flat",
                            "val": 109
                        },
                        {
                            "valType": "calc",
                            "valCalc": "floor",
                            "val": {
                                "valType": "calc",
                                "valCalc": "divide",
                                "val": [
                                    {
                                        "valType": "percentage",
                                        "valProperty": "internalCounter",
                                        "val": 100,
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 10
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 10
                            }
                        ]
                    }
                ],
                "effect": "decrementCounter",
                "val": {
                    "valType": "calc",
                    "valCalc": "multiply",
                    "val": [
                        {
                            "valType": "calc",
                            "valCalc": "floor",
                            "val": {
                                "valType": "calc",
                                "valCalc": "divide",
                                "val": [
                                    {
                                        "valType": "percentage",
                                        "valProperty": "internalCounter",
                                        "val": 100,
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 10
                                    }
                                ]
                            }
                        },
                        {
                            "valType": "flat",
                            "val": 10
                        }
                    ]
                }
            }
        ],
        "unlockLevel": 9
    },
    24: {
        "id": 24,
        "name": "Vampire Fang",
        "description": "At the end of every turn, the enemy loses one max HP and you gain one max HP. This bonus is lost when the battle ends.",
        "image": "/images/new/artifacts/vampirefang.png",
        "effects": [
            {
                "trigger": "endPlayerTurn",
                "effect": "playerGainMaxHp",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "enemyLoseMaxHp",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "playerWin",
                "effect": "playerLoseMaxHp",
                "val": {
                    "valType": "percentage",
                    "valProperty": "internalCounter",
                    "val": 100
                }
            },
            {
                "trigger": "playerWin",
                "effect": "resetCounter"
            }
        ],
        "unlockLevel": 2
    },
    25: {
        "id": 25,
        "name": "Trident",
        "description": "At the beginning of the enemy's turn, if they suffer from 3 or more debuffs, they take 5 damage.",
        "image": "/images/new/artifacts/trident.png",
        "effects": [
            {
                "trigger": "startEnemyTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "valProperty": "enemyNumberOfDebuffs",
                                "val": 100
                            },
                            {
                                "valType": "flat",
                                "val": 3
                            }
                        ]
                    }
                ],
                "effect": "damageEnemy",
                "val": {
                    "valType": "flat",
                    "val": 5
                }
            }
        ],
        "unlockLevel": 1
    },
    26: {
        "id": 26,
        "name": "Bounty Poster",
        "description": "Defeating an enemy before turn 5 yields an extra 10 gold.",
        "image": "/images/new/artifacts/bountyposter.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": true
                }
            },
            {
                "trigger": "startPlayerTurn",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "turnCount"
                            },
                            {
                                "valType": "flat",
                                "val": 5
                            },
                        ]
                    }
                ],
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": false
                }
            },
            {
                "trigger": "playerWin",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "flat",
                                "val": 4
                            },
                            {
                                "valType": "attribute",
                                "val": "turnCount"
                            }
                        ]
                    }
                ],
                "effect": "gainGold",
                "val": {
                    "valType": "flat",
                    "val": 10
                }
            },
            {
                "trigger": "startPlayerTurn",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "playerWin",
                "effect": "resetCounter"
            },
            {
                "trigger": "playerWin",
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": false
                }
            }
        ],
        "unlockLevel": 3
    },
    27: {
        "id": 27,
        "name": "Double Edged Sword",
        "description": "Gain 1 muster, but so does the opponent.",
        "image": "/images/new/artifacts/double_edged_sword.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerMuster",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "battleStart",
                "effect": "enemyMuster",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "pandora": true
    },
    28: {
        "id": 28,
        "name": "Cloak of Concealment",
        "description": "Each player starts with a Mini Agent in their deck.",
        "image": "/images/new/artifacts/cloak_of_concealment.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "enemyAddCardToDeck",
                "val": {
                    "valType": "flat",
                    "val": [
                        110, 1
                    ]
                }
            },
            {
                "trigger": "battleStart",
                "effect": "playerAddCardToDeck",
                "val": {
                    "valType": "flat",
                    "val": [
                        110, 1
                    ]
                }
            }
        ],
        "pandora": true,
        "unlockLevel": 3
    },
    29: {
        "id": 29,
        "name": "Witch Doctor's Staff",
        "description": "Each player starts poisoned.",
        "image": "/images/new/artifacts/witch_doctors_staff.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerPoison",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "battleStart",
                "effect": "enemyPoison",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "pandora": true,
        "unlockLevel": 1
    },
    30: {
        "id": 30,
        "name": "Short Fuse",
        "description": "Start with 5 Strengthen. At the end of every turn, your maximum health decreases by 3 (until the end of battle).",
        "image": "/images/new/artifacts/short_fuse.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerStrengthen",
                "val": {
                    "valType": "flat",
                    "val": 5
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "playerLoseMaxHp",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "playerWin",
                "effect": "playerGainMaxHp",
                "val": {
                    "valType": "percentage",
                    "valProperty": "internalCounter",
                    "val": 100
                }
            },
            {
                "trigger": "playerWin",
                "effect": "resetCounter"
            }
        ],
        "pandora": true,
        "unlockLevel": 4
    },
    31: {
        "id": 31,
        "name": "Anti-Magic Bracers",
        "description": "Increase your mana regen by two of each colour. At the end of your turn, lose half of your unspent mana (rounded up).",
        "image": "/images/new/artifacts/anti_magic_bracers.png",
        "effects": [
            {
                "trigger": "onEnable",
                "effect": "playerFocusGreenPerm",
                "val": {
                    "valType": "flat",
                    "val": 2
                }
            },
            {
                "trigger": "onEnable",
                "effect": "playerFocusRedPerm",
                "val": {
                    "valType": "flat",
                    "val": 2
                }
            },
            {
                "trigger": "onEnable",
                "effect": "playerFocusBluePerm",
                "val": {
                    "valType": "flat",
                    "val": 2
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerFocusGreenPerm",
                "val": {
                    "valType": "flat",
                    "val": -2
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerFocusRedPerm",
                "val": {
                    "valType": "flat",
                    "val": -2
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerFocusBluePerm",
                "val": {
                    "valType": "flat",
                    "val": -2
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "playerOverrideManaGreen",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "percentage",
                        "valProperty": "playerGreenMana",
                        "val": 50
                    }
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "playerOverrideManaRed",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "percentage",
                        "valProperty": "playerRedMana",
                        "val": 50
                    }
                }
            },
            {
                "trigger": "endPlayerTurn",
                "effect": "playerOverrideManaBlue",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "percentage",
                        "valProperty": "playerBlueMana",
                        "val": 50
                    }
                }
            }
        ],
        "pandora": true,
        "unlockLevel": 5
    },
    32: {
        "id": 32,
        "name": "Chainbreaker",
        "description": "Every 4th attack is critical.",
        "image": "/images/new/artifacts/chainbreaker.png",
        "effects": [
            {
                "trigger": "beforeDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 4
                            }
                        ]
                    }
                ],
                "effect": "contextMerge",
                "val": {
                    "valType": "flat",
                    "val": {
                        "isCrit": true
                    }
                }
            },
            {
                "trigger": "afterDealDamage",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 4
                            }
                        ]
                    }
                ],
                "effect": "resetCounter"
            },
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 0
                            }
                        ]
                    }
                ],
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": false
                }
            },
            {
                "trigger": "afterDealDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 3
                            }
                        ]
                    },
                ],
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": true
                }
            }
        ],
        "unlockLevel": 1
    },
    33: {
        "id": 33,
        "name": "Lucky Rabbit's Foot",
        "description": "5% chance to dodge each attack by an enemy.",
        "image": "/images/new/artifacts/luckyrabbitsfoot.png",
        "effects": [
            {
                "trigger": "beforeTakeDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "random",
                                "val": [
                                    {
                                        "valType": "flat",
                                        "val": 0
                                    },
                                    {
                                        "valType": "flat",
                                        "val": 100
                                    }
                                ],
                            },
                            {
                                "valType": "flat",
                                "val": 95
                            }
                        ]
                    }
                ],
                "effect": "dodgeDamage"
            }
        ],
        "unlockLevel": 1
    },
    35: {
        "id": 35,
        "name": "Buzzsaw",
        "description": "Each card discarded at the end of your turn deals 1 damage.",
        "image": "/images/new/artifacts/buzzsaw.png",
        "effects": [
            {
                "trigger": "afterCardDiscard",
                "effect": "damageEnemy",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "unlockLevel": 4
    },
    36: {
        "id": 36,
        "name": "Love Letter",
        "description": "Strengthen now boosts your damage by 100%, instead of 50%.",
        "image": "/images/new/artifacts/loveletter.png",
        "effects": [
            {
                "trigger": "beforePlayCard",
                "effect": "contextMerge",
                "val": {
                    "valType": "flat",
                    "val": {
                        "strengthenMultiplier": 2
                    }
                }
            },
        ],
        "unlockLevel": 1
    },
    37: {
        "id": 37,
        "name": "Small Mana Potion",
        "description": "Start each battle with 3 extra mana of each colour.",
        "image": "/images/new/artifacts/smallmanapotion.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerGainManaGreen",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "battleStart",
                "effect": "playerGainManaRed",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "battleStart",
                "effect": "playerGainManaBlue",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
        ],
        "unlockLevel": 1
    },
    38: {
        "id": 38,
        "name": "Hearty Booze",
        "description": "Start each battle with 1 regen.",
        "image": "/images/new/artifacts/heartybooze.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerRegen",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
        ],
        "unlockLevel": 1
    },
    39: {
        "id": 39,
        "name": "Beserker's Spear",
        "description": "If your HP is less than 50%, gain 3 muster and 3 armour.",
        "image": "/images/new/artifacts/bezerkersspear.png",
        "effects": [
            {
                "trigger": "battleStart",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            },
                        ]
                    }
                ],
                "effect": "playerMuster",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "battleStart",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            },
                        ]
                    }
                ],
                "effect": "playerGainArmour",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "afterActivateArtifact",
                "effect": "playerMuster",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "afterActivateArtifact",
                "effect": "playerGainArmour",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "tick",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": false
                            },
                        ]
                    },
                    {
                        "comparison": "greaterThan",
                        "vals": [
                            {
                                "valType": "flat",
                                "val": 0.5
                            },
                            {
                                "valType": "calc",
                                "valCalc": "divide",
                                "val": [
                                    {
                                        "valType": "attribute",
                                        "val": "playerHp"
                                    },
                                    {
                                        "valType": "attribute",
                                        "val": "playerMaxHp"
                                    }
                                ]
                            },
                        ]
                    }
                ],
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": true
                }
            },
            {
                "trigger": "afterDeactivateArtifact",
                "effect": "playerMuster",
                "val": {
                    "valType": "flat",
                    "val": -3
                }
            },
            {
                "trigger": "afterDeactivateArtifact",
                "effect": "playerGainArmour",
                "val": {
                    "valType": "flat",
                    "val": -3
                }
            },
            {
                "trigger": "tick",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            },
                        ]
                    },
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "calc",
                                "valCalc": "divide",
                                "val": [
                                    {
                                        "valType": "attribute",
                                        "val": "playerHp"
                                    },
                                    {
                                        "valType": "attribute",
                                        "val": "playerMaxHp"
                                    }
                                ]
                            },
                            {
                                "valType": "flat",
                                "val": 0.5
                            },
                        ]
                    }
                ],
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": false
                }
            }
        ],
        "unlockLevel": 8
    },
    40: {
        "id": 40,
        "name": "Blue Dragon's Egg",
        "description": "After spending a total of 150 blue mana, gain 3 blue focus.",
        "image": "/images/new/artifacts/bluedragonsegg.png",
        "effects": [
            {
                "trigger": "beforePlayCard",
                "effect": "incrementCounter",
                "val": {
                    "valType": "attribute",
                    "val": "cardCostBlue"
                }
            },
            {
                "trigger": "afterActivateArtifact",
                "effect": "playerFocusBluePerm",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 150
                            },
                        ]
                    },
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": false
                            },
                        ]
                    }
                ],
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": true
                }
            },
            {
                "trigger": "onEnable",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            },
                        ]
                    }
                ],
                "effect": "playerFocusBluePerm",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            },
            {
                "trigger": "onDisable",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            },
                        ]
                    }
                ],
                "effect": "playerFocusBluePerm",
                "val": {
                    "valType": "flat",
                    "val": -3
                }
            }
        ],
        "unlockLevel": 6
    },
    41: {
        "id": 41,
        "name": "Tough Nut",
        "description": "After losing a total of 50 HP, gain 25 max HP.",
        "image": "/images/new/artifacts/toughnut.png",
        "effects": [
            {
                "trigger": "afterTakeDamage",
                "effect": "incrementCounter",
                "val": {
                    "valType": "attribute",
                    "val": "damageReceived"
                }
            },
            {
                "trigger": "afterTakeDamage",
                "conditions": [
                    {
                        "comparison": "greaterThanOrEqualTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 50
                            },
                        ]
                    },
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": false
                            },
                        ]
                    }
                ],
                "effect": "changeIsActive",
                "val": {
                    "valType": "flat",
                    "val": true
                }
            },
            {
                "trigger": "afterActivateArtifact",
                "effect": "gainMaxHealth",
                "val": {
                    "valType": "flat",
                    "val": 25
                }
            },
            {
                "trigger": "onEnable",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            },
                        ]
                    }
                ],
                "effect": "gainMaxHealth",
                "val": {
                    "valType": "flat",
                    "val": 25
                }
            },
            {
                "trigger": "onDisable",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "artifactIsActive"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            },
                        ]
                    }
                ],
                "effect": "loseMaxHealth",
                "val": {
                    "valType": "flat",
                    "val": 25
                }
            }
        ],
        "unlockLevel": 6
    },
    42: {
        "id": 42,
        "name": "Magic Toadstool",
        "description": "At the start of each battle, gain a temporary Fairy Armour.",
        "image": "/images/new/artifacts/magictoadstool.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerAddCardToHand",
                "val": {
                    "valType": "flat",
                    "val": [
                        134, 1
                    ]
                }
            },
        ],
        "unlockLevel": 7
    },
    43: {
        "id": 43,
        "name": "Unstable Bomb",
        "description": "If you Exile a card, deal 4 damage to the enemy.",
        "image": "/images/new/artifacts/unstablebomb.png",
        "effects": [
            {
                "trigger": "afterExileCard",
                "effect": "damageEnemy",
                "val": {
                    "valType": "flat",
                    "val": 4
                }
            },
        ],
        "unlockLevel": 1
    },
    44: {
        "id": 44,
        "name": "Skull Ring",
        "description": "Get a temporary copy of the first card you play each turn.",
        "image": "/images/new/artifacts/skullring.png",
        "effects": [
            {
                "trigger": "afterPlayCard",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 0
                            }
                        ]
                    }
                ],
                "effect": "cloneCard",
                "val": {
                    "valType": "flat",
                    "val": {
                        "qty": 1,
                        "isTemporary": true,
                        "deckDestination": "hand"
                    }
                }
            },
            {
                "trigger": "afterPlayCard",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "startPlayerTurn",
                "effect": "resetCounter",
            }
        ],
        "hideCounter": true,
        "unlockLevel": 7
    },
    45: {
        "id": 45,
        "name": "Premium Bond",
        "description": "All your gold is invested in this bond. Gain 5% multiplicative interest after each battle. Click on this artifact to retrieve your money and close the bond.",
        "image": "/images/new/artifacts/premiumbond.png",
        "effects": [
            {
                "trigger": "onAcquire",
                "effect": "incrementCounter",
                "val": {
                    "valType": "attribute",
                    "val": "gold"
                }
            },
            {
                "trigger": "onAcquire",
                "effect": "gainGold",
                "val": {
                    "valType": "calc",
                    "valCalc": "multiply",
                    "val": [
                        {
                            "valType": "attribute",
                            "val": "internalCounter"
                        },
                        {
                            "valType": "flat",
                            "val": -1
                        }
                    ]
                }
            },
            {
                "trigger": "playerWin",
                "effect": "incrementCounter",
                "val": {
                    "valType": "calc",
                    "valCalc": "floor",
                    "val": {
                        "valType": "calc",
                        "valCalc": "multiply",
                        "val": [
                            {
                                "valType": "attribute",
                                "val": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 0.05
                            }
                        ]
                    }
                }
            },
            {
                "trigger": "onClick",
                "effect": "gainGold",
                "val": {
                    "valType": "attribute",
                    "val": "internalCounter"
                }
            },
            {
                "trigger": "onClick",
                "effect": "resetCounter"
            }
        ],
        "charges": 1,
        "unlockLevel": 8
    },
    46: {
        "id": 46,
        "name": "Multi-coloured Spices",
        "description": "Playing a card which costs red, green and blue mana makes you draw a card.",
        "image": "/images/new/artifacts/multicolouredspices.png",
        "effects": [
            {
                "trigger": "afterPlayCard",
                "conditions": [
                    {
                        "comparison": "includes",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "cardCostTypes"
                            },
                            {
                                "valType": "flat",
                                "val": "red"
                            }
                        ]
                    },
                    {
                        "comparison": "includes",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "cardCostTypes"
                            },
                            {
                                "valType": "flat",
                                "val": "green"
                            }
                        ]
                    },
                    {
                        "comparison": "includes",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "cardCostTypes"
                            },
                            {
                                "valType": "flat",
                                "val": "blue"
                            }
                        ]
                    }
                ],
                "effect": "playerDraw",
                "val": {
                    "valType": "flat",
                    "val": [
                        1
                    ]
                }
            },
        ],
        "unlockLevel": 1
    },
    47: {
        "id": 47,
        "name": "Green Eye",
        "description": "Cards which are purely green have their effect doubled.",
        "image": "/images/new/artifacts/greeneye.png",
        "effects": [
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "includes",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "cardCostTypes"
                            },
                            {
                                "valType": "flat",
                                "val": "green"
                            }
                        ]
                    },
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "condition",
                                "val": {
                                    "comparison": "includes",
                                    "vals": [
                                        {
                                            "valType": "attribute",
                                            "val": "cardCostTypes"
                                        },
                                        {
                                            "valType": "flat",
                                            "val": "red"
                                        }
                                    ]
                                },
                            },
                            {
                                "valType": "flat",
                                "val": false
                            }
                        ]
                    },
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "condition",
                                "val": {
                                    "comparison": "includes",
                                    "vals": [
                                        {
                                            "valType": "attribute",
                                            "val": "cardCostTypes"
                                        },
                                        {
                                            "valType": "flat",
                                            "val": "blue"
                                        }
                                    ]
                                },
                            },
                            {
                                "valType": "flat",
                                "val": false
                            }
                        ]
                    },
                ],
                "effect": "multiplyMultiEffect",
                "val": {
                    "valType": "flat",
                    "val": 2
                }
            },
        ],
        "unlockLevel": 11
    },
    48: {
        "id": 48,
        "name": "Faulty Watch",
        "description": "Multi-effects trigger one additional time.",
        "image": "/images/new/artifacts/faultywatch.png",
        "effects": [
            {
                "trigger": "beforePlayCard",
                "effect": "increaseExistingMultiEffect",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            }
        ],
        "unlockLevel": 1
    },
    49: {
        "id": 49,
        "name": "Flickering Candle",
        "description": "Discarded cards have their cost reduced by one random mana.",
        "image": "/images/new/artifacts/flickeringcandle.png",
        "effects": [
            {
                "trigger": "afterCardDiscard",
                "effect": "alterCard",
                "val": {
                    "valType": "flat",
                    "val": {
                        "target": "self",
                        "qty": 1,
                        "action": {
                            "costIncrease": {
                                "random": -1
                            }
                        }
                    }
                }
            }
        ],
        "unlockLevel": 11
    },
    50: {
        "id": 50,
        "name": "Small Trophy",
        "description": "One-cost cards have their effect doubled.",
        "image": "/images/new/artifacts/smalltrophy.png",
        "effects": [
            {
                "trigger": "beforePlayCard",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "cardCost"
                            },
                            {
                                "valType": "flat",
                                "val": 1
                            }
                        ]
                    }
                ],
                "effect": "multiplyMultiEffect",
                "val": {
                    "valType": "flat",
                    "val": 2
                }
            }
        ],
        "unlockLevel": 1
    },
    51: {
        "id": 51,
        "name": "Fertilizer",
        "description": "Add one mana regen of each type. Add two Large Weeds to your deck.",
        "image": "/images/new/artifacts/fertilizer.png",
        "effects": [
            {
                "trigger": "onEnable",
                "effect": "playerFocusRedPerm",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "onEnable",
                "effect": "playerFocusGreenPerm",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "onEnable",
                "effect": "playerFocusBluePerm",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "onEnable",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "internalCounter"
                            },
                            {
                                "valType": "flat",
                                "val": 0
                            },
                        ]
                    }
                ],
                "effect": "playerAddCardToDeck",
                "val": {
                    "valType": "flat",
                    "val": [
                        74, 2
                    ]
                }
            },
            {
                "trigger": "onEnable",
                "effect": "incrementCounter",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerFocusRedPerm",
                "val": {
                    "valType": "flat",
                    "val": -1
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerFocusGreenPerm",
                "val": {
                    "valType": "flat",
                    "val": -1
                }
            },
            {
                "trigger": "onDisable",
                "effect": "playerFocusBluePerm",
                "val": {
                    "valType": "flat",
                    "val": -1
                }
            },
        ],
        "pandora": true,
        "hideCounter": true,
        "unlockLevel": 1
    },
    52: {
        "id": 52,
        "name": "Cursed Statue",
        "description": "Enemies start battles with 1 poison, 2 weakness and 3 bleed. Debuffs you acquire persist between battles.",
        "image": "/images/new/artifacts/cursedstatue.png",
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "enemyPoison",
                "val": {
                    "valType": "flat",
                    "val": 1
                }
            },
            {
                "trigger": "battleStart",
                "effect": "enemyWeaken",
                "val": {
                    "valType": "flat",
                    "val": 2
                }
            },
            {
                "trigger": "battleStart",
                "effect": "enemyBleed",
                "val": {
                    "valType": "flat",
                    "val": 3
                }
            }
        ],
        "pandora": true,
        "passiveEffects": [
            "debuffsPersist"
        ],
        "unlockLevel": 12
    },
    53: {
        "id": 53,
        "name": "Library Card",
        "description": "Add an extra Ancient Tome encounter in this location and every future location.",
        "image": "/images/new/artifacts/library_card.png",
        "effects": [
            {
                "trigger": "onAcquire",
                "effect": "addEncounterToLocation",
                "val": {
                    "valType": "flat",
                    "val": {
                        "location": "futureAndCurrent",
                        "encounterType": "blessing"
                    }
                }
            }
        ],
        "unlockLevel": 1
    },
    54: {
        "id": 54,
        "name": "Translation Guide",
        "description": "You may now choose from multiple options in each Grimoire encounter.",
        "image": "/images/new/artifacts/translation_guide.png",
        "effects": [],
        "unlockLevel": 1
    },
    55: {
        "id": 55,
        "name": "Odd Trinket",
        "description": "You may no longer have cards of the same name in your deck. All such cards are purged. Duplicate cards are no longer offered at shops/chests.",
        "image": "/images/new/artifacts/odd_trinket.png",
        "effects": [
            {
                "trigger": "onEnable",
                "effect": "purgeDuplicates"
            },
            {
                "trigger": "afterAddToDeck",
                "effect": "purgeDuplicates"
            }
        ],
        "pandora": true,
        "passiveEffects": [
            "noDuplicates"
        ],
        "unlockLevel": 12
    },
    // 56: {
    //     "id": 56,
    //     "name": "Explorer's Magnificatinator",
    //     "description": "If you acquire a card you have never played before, gain 5 max health and level it up.",
    //     "image": "/images/new/artifacts/explorers_magnificatinator.png",
    //     "effects": [],
    //     "todo": "requires card persistence mechanic",
    // },
    57: {
        "id": 57,
        "name": "Hunter's Heart",
        "description": "Defeating a boss will heal you to full HP.",
        "image": "/images/new/artifacts/hunters_heart.png",
        "effects": [
            {
                "trigger": "playerWin",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "isBoss"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            }
                        ]
                    }
                ],
                "effect": "playerHealFull",
            },
        ],
        "unlockLevel": 1
    },
    58: {
        "id": 58,
        "name": "Snail Armour",
        "description": "Permanently increase your max HP by 10. Temporarily decrease your max HP by 20 at the start of a boss fight.",
        "image": "/images/new/artifacts/snail_armour.png",
        "pandora": true,
        "effects": [
            {
                "trigger": "battleStart",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "isBoss"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            }
                        ]
                    }
                ],
                "effect": "loseMaxHealth",
                "val": {
                    "valType": "flat",
                    "val": 20
                }
            },
            {
                "trigger": "playerWin",
                "conditions": [
                    {
                        "comparison": "equalTo",
                        "vals": [
                            {
                                "valType": "attribute",
                                "val": "isBoss"
                            },
                            {
                                "valType": "flat",
                                "val": true
                            }
                        ]
                    }
                ],
                "effect": "gainMaxHealth",
                "val": {
                    "valType": "flat",
                    "val": 20
                }
            },
            {
                "trigger": "onEnable",
                "effect": "gainMaxHealth",
                "val": {
                    "valType": "flat",
                    "val": 10
                }
            },
            {
                "trigger": "onDisable",
                "effect": "loseMaxHealth",
                "val": {
                    "valType": "flat",
                    "val": 10
                }
            }
        ],
        "unlockLevel": 1
    },
    59: {
        "id": 59,
        "name": "Wonderous Box",
        "description": "Start with a temporary Magic Chest at the beginning of each battle. Every enemy gains Haste.",
        "image": "/images/new/artifacts/wonderous_box.png",
        "pandora": true,
        "effects": [
            {
                "trigger": "battleStart",
                "effect": "playerAddCardToHand",
                "val": {
                    "valType": "flat",
                    "val": [
                        136, 1
                    ]
                }
            },
            {
                "trigger": "battleStart",
                "effect": "enemyGainHaste",
                "val": {
                    "valType": "flat",
                    "val": true
                }
            },
        ],
        "unlockLevel": 13
    },
    // 60: {
    //     "id": 60,
    //     "name": "Trusty Wrench",
    //     "description": "Upgrade each card in yours and every enemy's deck.",
    //     "image": "/images/new/artifacts/trusty_wrench.png",
    //     "pandora": true,
    //     "effects": [],
    //     "todo": "Wait till upgrades are implemented",
    // }
    61: {
        "id": 61,
        "name": "Mystery Bag",
        "description": "Gain a random artifact.",
        "image": "/images/new/artifacts/mystery_bag.png",
        "effects": [
            {
                "trigger": "onAcquire",
                "effect": "gainRandomBlessing"
            },
            {
                "trigger": "onAcquire",
                "effect": "expire"
            }
        ],
        "goldCost": 25,
        "unlockLevel": 1
    },
}
