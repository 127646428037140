import React from 'react';

import HomeScreen from './Components/HomeScreen';
import Encounter from './Components/Encounter';
import PlayScreen from './Components/PlayScreen';
import ChestScreen from './Components/ChestScreen';
import CreditsScreen from './Components/CreditsScreen';
import ChangelogScreen from './Components/ChangelogScreen';
import EndScreen from './Components/EndScreen';
import ShopScreen from './Components/ShopScreen';
import TopBar from './Components/TopBar';

import Game from './Game';

class App extends React.Component
{
	constructor(props) {
		super(props);
		this.state = {"screen": "home"};
		this.version = "v0.0.1";
		this.startNewGame = this.startNewGame.bind(this);
		this.playCard = this.playCard.bind(this);
		this.endTurn = this.endTurn.bind(this);
		this.retire = this.retire.bind(this);
		this.surrender = this.surrender.bind(this);
		this.chooseCard = this.chooseCard.bind(this);
		this.startEncounter = this.startEncounter.bind(this);
		this.endEncounter = this.endEncounter.bind(this);
		this.backToHome = this.backToHome.bind(this);
		this.goToCredits = this.goToCredits.bind(this);
		this.goToChangelog = this.goToChangelog.bind(this);
		this.setShopTab = this.setShopTab.bind(this);
		this.triggerEncounterAction = this.triggerEncounterAction.bind(this);
		this.uninterruptable = false;
	}

	componentDidUpdate() {
		this.playstate = this.state.playstate;
		this.gamestate = this.state.gamestate;
		this.stats = this.state.stats;
	}

	startNewGame()
	{
		if (this.uninterruptable === false) {
			this.game = new Game("basic");
			this.updateState();
		}
	}

	backToHome()
	{
		this.setState({
			screen: "home"
		});
	}

	goToCredits()
	{
		this.setState({
			screen: "credits"
		});
	}

	goToChangelog()
	{
		this.setState({
			screen: "changelog"
		});
	}

	startEncounter(id)
	{
		if (this.uninterruptable === false) {
			this.game.handleEncounter(id);
			this.updateState();
		}
	}

	setShopTab(tab)
	{
		if (this.uninterruptable === false) {
			this.game.currentEncounter.setShopTab(tab);
			this.updateState();
		}
	}

	updateState()
	{
		if (this.game !== undefined) {
            let gamestate = this.game.getState();
            this.setState(gamestate);
        }
	}

	continueEnemyTurn()
	{
		this.game.battle.continueEnemyTurn();
		this.updateStateFromEnemyTurn();
	}

	updateStateFromEnemyTurn()
	{
		if (this.game.battle.enemyTurn === true) {
			this.uninterruptable = true;
			setTimeout(this.continueEnemyTurn.bind(this), 1000);
		} else {
			this.uninterruptable = false;
		}
		this.updateState();
	}

	endTurn() {
		this.game.battle.endTurnAction();
		this.updateStateFromEnemyTurn();
	}

	surrender()
	{
		if (this.uninterruptable === false) {
			this.game.battle.surrender();
			this.updateState();
		}
	}

	retire()
	{
		delete this.game;
		this.setState({
			screen: 'home'
		});
	}

	chooseCard(id)
	{
		if (this.uninterruptable === false) {
			this.game.addCardToDeck(id);
			this.game.endBattle();
			this.updateState();
		}
	}

	endEncounter()
	{
		if (this.uninterruptable === false) {
			this.game.endEncounter(this.game.currentEncounter.encounterId);
			this.updateState();
		}
	}

	playCard(uid)
	{
		if (this.uninterruptable === false) {
			this.game.battle.playCard('player', uid);
			this.updateState();
		}
	}

	triggerEncounterAction(...params)
	{
		if (this.uninterruptable === false) {
			this.game.currentEncounter.handleAction(...params);
			this.updateState();
		}
	}

	render()
	{
		if (this.state.screen === 'home') {
			return (
				<HomeScreen
					startNewGame={this.startNewGame}
					goToCredits={this.goToCredits}
					goToChangelog={this.goToChangelog}
					version={this.version}
				/>
			);
		} else if (this.state.screen === 'credits') {
			return (
				<CreditsScreen backToHome={this.backToHome} />
			);
		} else if (this.state.screen === 'changelog') {
			return (
				<ChangelogScreen backToHome={this.backToHome} />
			);
		} else if (this.state.screen === 'chest') {
			return (
				<div className="game-container">
					<TopBar
						gold={this.state.game_gold}
						hp={this.state.game_hp}
						maxHp={this.state.game_maxHp}
						deck={this.state.game_deck}
					/>
					<ChestScreen
						cardChoices={this.state.game_currentEncounter.cardRewards}
						encounterAction={this.triggerEncounterAction}
						endEncounter={this.endEncounter}
					/>
				</div>
			)
		} else if (this.state.screen === 'shop') {
			return (
				<div className="game-container">
					<TopBar
						gold={this.state.game_gold}
						hp={this.state.game_hp}
						maxHp={this.state.game_maxHp}
						deck={this.state.game_deck}
					/>
					<ShopScreen
						gold={this.state.game_gold}
						deck={this.state.game_deck}
						cardsForPurchase={this.state.game_currentEncounter.cardsForPurchase}
						cardsPurchased={this.state.game_currentEncounter.cardsPurchased}
						artifactsForPurchase={this.state.game_currentEncounter.artifactsForPurchase}
						artifactsPurchased={this.state.game_currentEncounter.artifactsPurchased}
						encounterAction={this.triggerEncounterAction}
						setShopTab={this.setShopTab}
						endEncounter={this.endEncounter}
						shopTab={this.state.game_currentEncounter.shopTab}
					/>
				</div>
			)
		} else if (this.state.screen === 'journey') {
			let locations = this.state.game_locations;
			if (locations.length === 0) {
				return (
					<div className="game-container">
						<TopBar
							gold={this.state.game_gold}
							hp={this.state.game_hp}
							maxHp={this.state.game_maxHp}
							deck={this.state.game_deck}
						/>
						<EndScreen
							stats={this.state.game_stats}
							backToHome={this.backToHome}
                        />
					</div>
				);
			}
			let jsxRenderable = [];
			var i;
			for (i=0; i<3; i++) {
				if (locations.length > i) {
					let locationData = locations.slice(i, i+1)[0];
					jsxRenderable.push(<Encounter
						title={locationData.name}
                        image={locationData.image}
                        id={locationData.id}
                        buttonText={locationData.buttonText}
						startEncounter={this.startEncounter}
						key={locationData.uid}
					/>);
				}
			}
			return (
				<div className="game-container">
					<TopBar
						gold={this.state.game_gold}
						hp={this.state.game_hp}
						maxHp={this.state.game_maxHp}
						deck={this.state.game_deck}
					/>
					<div className="journey-page">
						<h1 className="journey-title">The Enchanted Forest</h1>
						<div className="locations-wrapper">
							{jsxRenderable}
						</div>
					</div>
				</div>
			);
		} else if (this.state.screen === 'play') {
			return <div className="game-container">
				<TopBar
					gold={this.state.game_gold}
					hp={this.state.game_hp}
					maxHp={this.state.game_maxHp}
					deck={this.state.game_deck}
				/>
				<PlayScreen
					playCard={this.playCard}
					surrender={this.surrender}
					endTurn={this.endTurn}
					hp={this.state.player_hp}
					maxHp={this.state.player_maxHp}
					mana={this.state.player_mana}
					manaRegen={this.state.player_manaRegen}
					statusEffects={this.state.player_statusEffects}
					artifacts={this.state.player_artifacts}
					name={this.state.player_name}
					image={this.state.player_image}
					hand={this.state.player_deck.hand}
					enemyHp={this.state.enemy_hp}
					enemyMaxHp={this.state.enemy_maxHp}
					enemyMana={this.state.enemy_mana}
					enemyManaRegen={this.state.enemy_manaRegen}
					enemyStatusEffects={this.state.enemy_statusEffects}
					enemyArtifacts={this.state.enemy_artifacts}
					enemyName={this.state.enemy_name}
					enemyImage={this.state.enemy_image}
					enemyHand={this.state.enemy_deck.hand}
					history={this.state.battle_history}
					isGameWon={this.state.battle_showWinScreen === true}
					isGameLost={this.state.battle_showLoseScreen === true}
					stats={this.state.game_stats}
					cardRewards={this.state.battle_cardRewards}
					chooseCard={this.chooseCard}
					retire={this.retire}

					// playCard={this.playCard}
					// endTurn={this.endTurn}
					// enemyData={this.getEnemyData()[this.state.playstate.enemy.id]}
					// enemyState={this.state.playstate.enemy}
					// playerData={this.state.gamestate}
					// playerState={this.state.playstate.me}
					// history={this.state.playstate.history}
					// getStatusInformation={this.getStatusInformation}
					// surrender={this.surrender}
					// cardChoices={this.cardChoices()}
					// isGameWon={this.isGameWon}
					// isGameLost={this.isGameLost}
				/>
			</div>
		}
	}
}

export default App;
