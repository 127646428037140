import React from 'react';
import Card from './Card';
import Overlay from './Overlay';

class CardChoiceOverlay extends React.Component
{
    render()
    {
        let actions = [
            {
                "action": this.props.endEncounter,
                "text": "No Thanks"
            }
        ];

        return (
            <Overlay
                overlayClasses="card-choice-overlay overlay--small"
                title={this.props.title}
                text={this.props.text}
                display={this.props.display}
                name="cardChoice"
                actions={actions}
                showClose={false}
                >
                <div className="cards cards--choice">
                    {this.props.cards.map((card, handPos) => {
                        return (
                            <Card
                                id={card.id}
                                key={card.uid}
                                uid={card.uid}
                                name={card.name}
                                image={card.image}
                                description={card.description}
                                tier={card.tier}
                                cost={card.cost}
                                goldCost={card.goldCost}
                                playCard={this.props.chooseCard}
                                addUnplayableClass={false} />
                        );
                    })}
                </div>
            </Overlay>
        );
    }
}

export default CardChoiceOverlay;
