export const cardsData = {
	"1":{
		"name":"Basic Sword",
		"id":1,
		"image":"/images/new/cards/basic_sword.png",
		"text":"Deals 4 damage",
		"cost":{
			"red":5
		},
		"effects":{
			"attack":4
		},
		"tier": 0,
        "upgrades": [
            {
                "effects": {
                    "attack": 6
                }
            }
        ],
        "modes": [],
        "unlockLevel": 1
	},
	"2":{
		"name":"Basic Shield",
		"id":2,
		"image":"/images/new/cards/basic_shield.png",
		"text":"Blocks 5 damage",
		"cost":{
			"green":5
		},
		"effects":{
			"shield":5
		},
		"tier": 0,
        "upgrades": [
            {
                "effects": {
                    "shield": 6
                }
            }
        ],
        "modes": [],
        "unlockLevel": 1
	},
	"3":{
		"name":"Basic Spell",
		"id":3,
		"image":"/images/new/cards/basic_spell.png",
		"text":"Deals 2 damage and blocks 3 damage",
		"cost":{
			"blue":5
		},
		"effects":{
			"attack":2,
			"shield":3
		},
		"tier": 0,
        "upgrades": [
            {
                "effects": {
                    "attack": 3,
                    "shield": 4
                }
            }
        ],
        "modes": [],
        "unlockLevel": 1
	},
	"4":{
		"name":"Minor Heal",
		"id":4,
		"image":"/images/new/cards/minor_heal.png",
		"text":"Heals 3 damage",
		"cost":{
			"green":4
		},
		"effects":{
			"heal":3
		},
		"tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
    "5": {
        "name":"Broadsword",
        "id":5,
        "image":'/images/new/cards/broadsword.png',
        "text":"Deals 12 damage",
        "cost": {
            "red": 7
        },
        "effects": {
            "attack": 12
        },
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "attack": 15
                }
            },
            {
                "cost": {
                    "red": 6
                },
                "effects": {
                    "attack": 15
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "7": {
        "name":"Seismic Slam",
        "id":7,
        "image":'/images/new/cards/seismic_slam.png',
        "text":"Deals 15 damage. Exile.",
        "cost": {
            "red": 5
        },
        "effects": {
            "attack": 15,
        },
        "exile": 1,
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "attack": 20
                }
            },
            {
                "cost": {
                    "red": 6
                },
                "effects": {
                    "attack": 30
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "8": {
        "name":"Boomerang",
        "id":8,
        "image":'/images/new/cards/boomerang.png',
        "text":"Deals 1 damage. Draw a card.",
        "cost": {
            "red": 1
        },
        "effects": {
            "attack": 1,
            "draw": 1
        },
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "attack": 3,
                }
            },
            {
                "cost": {
                    "red": 2
                },
                "effects": {
                    "attack": 3,
                    "draw": 2
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
	"9":{
		"name":"Explosion",
		"id":9,
		"image":"/images/new/cards/explosion.png",
		"text":"Both players take 15 damage",
		"cost":{
			"red":5
		},
		"effects":{
			"attack": {
                "descriptionTemplate": "Both players take 15 damage.",
                "valType": "flat",
                "val": 15
            },
			"damage": {
                "descriptionTemplate": "",
                "valType": "flat",
                "val": 15
            }
		},
		"tier": 3,
        "upgrades": [
            {
                "effects": {
                    "attack": 12,
                    "damage": 12
                }
            },
            {
                "effects": {
                    "attack": 15,
                    "damage": 15
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"10":{
		"name":"Piercing Sword",
		"id":10,
		"image":"/images/new/cards/piercing_sword.png",
		"text":"Deal 8 critical damage",
		"cost":{
			"red":8
		},
		"effects":{
			"critAttack":8
		},
		"tier": 2,
        "upgrades": [
            {
                "effects": {
                    "critAttack": 10
                }
            },
            {
                "effects": {
                    "critAttack": 12
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"11":{
		"name":"Brass Knuckles",
		"id":11,
		"image":"/images/new/cards/brass_knuckles.png",
		"text":"Deal 10 damage. Take 1 critical damage",
		"cost":{
			"red":5
		},
		"effects":{
			"attack":10,
			"critDamage":1
		},
		"tier": 2,
        "upgrades": [
            {
                "effects": {
                    "attack": 12,
                    "critDamage": 2
                }
            },
            {
                "effects": {
                    "attack": 18,
                    "critDamage": 3
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"12":{
		"name":"Battering Ram",
		"id":12,
		"image":"/images/new/cards/battering_ram.png",
		"text":"Deal 3 damage for the next 3 turns",
		"cost":{
			"red":6
		},
		"effects":{
			"attack":3,
			"future": [
				{
					"turns": 1,
					"effects": {
						"attack": 3
					}
				},
				{
					"turns": 2,
					"effects": {
						"attack": 3
					}
				}
			]
		},
		"tier": 2,
        "upgrades": [,
            {
                "effects": {
                    "attack":4,
                    "future": [
                        {
                            "turns": 1,
                            "effects": {
                                "attack": 4
                            }
                        },
                        {
                            "turns": 2,
                            "effects": {
                                "attack": 4
                            }
                        }
                    ]
                }
            },
            {
                "effects": {
                    "attack":4,
                    "future": [
                        {
                            "turns": 1,
                            "effects": {
                                "attack": 4
                            }
                        },
                        {
                            "turns": 2,
                            "effects": {
                                "attack": 4
                            }
                        },
                        {
                            "turns": 3,
                            "effects": {
                                "attack": 4
                            }
                        }
                    ]
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 2
	},
    "15": {
        "name":"Quick Strikes",
        "id":15,
        "image":'/images/new/cards/quick_strikes.png',
        "text":"Attack 3 times for 2 damage",
        "cost": {
            "red": 6
        },
        "effects": {
            "attack": {
                "valType": "flat",
                "val": 2,
                "context": {
                    "multiEffect": 3
                },
            }
        },
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "attack": 3
                }
            },
            {
                "effects": {
                    "attack": 3
                },
                "context": {
                    "multiEffect": 4
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "16": {
        "name":"Poisoned Spear",
        "id":16,
        "image":'/images/new/cards/poisoned_knife.png',
        "text":"Attack for 5. Poison 1. Exile",
        "cost": {
            "red": 6
        },
        "effects": {
            "attack": 5,
            "poison": 1
        },
        "tier": 2,
        "exile": 1,
        "unlockLevel": 1
    },
    "17": {
        "name":"Parry",
        "id":17,
        "image":'/images/new/cards/parry.png',
        "text":"Attack for 5. Shield 5",
        "cost": {
            "red": 4,
            "green": 4
        },
        "effects": {
            "attack": 5,
            "shield": 5
        },
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "attack": 7,
                    "shield": 7
                }
            },
            {
                "cost": {
                    "red": 5,
                    "green": 5
                },
                "effects": {
                    "attack": 10,
                    "shield": 10
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "18": {
        "name":"Desparate Blow",
        "id":18,
        "image":'/images/new/cards/desparate_blow.png',
        "text":"Attack for 12. Mana cost increases by 2 each time this card is played",
        "cost": {
            "red": 5
        },
        "effects": {
            "attack": 12,
            "alter": {
                "target": "self",
                "qty": 1,
                "action": {
                    "costIncrease": {
                        "red": 2
                    }
                }
            }
        },
        "tier": 3,
        "upgrades": [
            {
                "effects": {
                    "attack": 15,
                    "alter": {
                        "target": "self",
                        "qty": 1,
                        "action": {
                            "costIncrease": {
                                "red": 2
                            }
                        }
                    }
                }
            },
            {
                "cost": {
                    "red": 6
                },
                "effects": {
                    "attack": 20,
                    "alter": {
                        "target": "self",
                        "qty": 1,
                        "action": {
                            "costIncrease": {
                                "red": 3
                            }
                        }
                    }
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 2
    },
    "19": {
        "name":"Refined Technique",
        "id":19,
        "image":'/images/new/cards/refined_technique.png',
        "text":"Attack for 2. Damage increases by 2 each time this card is played.",
        "cost": {
            "red": 1
        },
        "effects": {
            "attack": 2,
            "alter": {
                "target": "self",
                "qty": 1,
                "action": {
                    "attackIncrease": 2
                }
            }
        },
        "tier": 2,
        "upgrades": [
            {
                "effects": {
                    "attack": 3,
                    "alter": {
                        "target": "self",
                        "qty": 1,
                        "action": {
                            "attackIncrease": 3
                        }
                    }
                }
            },
            {
                "cost": {
                    "red": 2
                },
                "effects": {
                    "attack": 4,
                    "alter": {
                        "target": "self",
                        "qty": 1,
                        "action": {
                            "attackIncrease": 4
                        }
                    }
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 3
    },
    "20": {
        "name":"Violent Slash",
        "id":20,
        "image":'/images/new/cards/violent_slash.png',
        "text":"Attack for 3. Inflict 2 bleed.",
        "cost": {
            "red": 6
        },
        "effects": {
            "attack": 3,
            "bleed": 2
        },
        "tier": 2,
        "upgrades": [
            {
                "effects": {
                    "affack": 4,
                    "bleed": 3
                }
            },
            {
                "cost": {
                    "red": 7
                },
                "effects": {
                    "affack": 6,
                    "bleed": 4
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "21": {
        "name":"Thorn Whip",
        "id":21,
        "image":'/images/new/cards/thorn_whip.png',
        "text": "Attack for 3. Inflict 2 thorn.",
        "cost": {
            "red": 6
        },
        "effects": {
            "attack": 3,
            "thorn": 2
        },
        "tier": 2,
        "upgrades": [
            {
                "effects": {
                    "attack": 6,
                    "thorn": 2
                }
            },
            {
                "effects": {
                    "attack": 6,
                    "thorn": 3
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 2
    },
    "22": {
        "name":"Train Recruits",
        "id":22,
        "image":'/images/new/cards/train_recruits.png',
        "text": "Muster 1.",
        "cost": {
            "red": 3
        },
        "effects": {
            "muster": 1
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "23": {
        "name":"Recruitment Drive",
        "id":23,
        "image":'/images/new/cards/recruitment_drive.png',
        "text": "Muster 2. Exile.",
        "cost": {
            "red": 4
        },
        "effects": {
            "muster": 2
        },
        "exile": 1,
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "24": {
        "name":"Painful tuition",
        "id":24,
        "image":'/images/new/cards/painful_tuition.png',
        "text": "Attack 3. Muster 1.",
        "cost": {
            "red": 6
        },
        "effects": {
            "attack": 3,
            "muster": 1
        },
        "tier": 2,
        "upgrades": [
            {
                "effects": {
                    "attack": 8,
                    "muster": 1
                }
            },
            {
                "cost": {
                    "red": 7
                },
                "effects": {
                    "attack": 10,
                    "muster": 2
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 4
    },
    "25": {
        "name":"Cruel Tutor",
        "id":25,
        "image":'/images/new/cards/cruel_tutor.png',
        "text": "Damage 3. Muster 2.",
        "cost": {
            "red": 5
        },
        "effects": {
            "muster": 2,
            "damage": 3
        },
        "tier": 3,
        "modes": [
            1
        ],
        "unlockLevel": 3
    },
    "26": {
        "name":"War Front",
        "id":26,
        "image":'/images/new/cards/war_front.png',
        "text": "Attack 10. Muster -1.",
        "cost": {
            "red": 4
        },
        "effects": {
            "attack": 10,
            "muster": -1
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
	"27":{
		"name":"Future Blast",
		"id":27,
		"image":"/images/new/cards/future_blast.png",
		"text":"In 2 turns, deal 12 damage",
		"cost":{
			"red":6
		},
		"effects":{
			"future":[
				{
					"turns":2,
					"effects": {
						"attack":12
					}
				}
			]
		},
		"tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 2
	},
	"28":{
		"name":"Relapse",
		"id":28,
		"image":"/images/new/cards/relapse.png",
		"text":"Immediately trigger poison damage on enemy",
		"cost":{
			"red":2
		},
		"effects":{
			"triggers":{
				"enemy":{
					"poison":1
				}
			}
		},
		"tier": 1,
        "modes": [
            1
        ]
	},
	"29":{
		"name":"Reinforced Shielding",
		"id":29,
		"image":"/images/new/cards/reinforced_shielding.png",
		"text":"Shield will not break at the end of the turn for 2 turns",
		"cost":{
			"green":4
		},
		"effects":{
			"reinforce":2
		},
		"tier": 2,
        "upgrades": [
            {
                "cost": {
                    "green": 5
                },
                "effects": {
                    "reinforce": 2
                }
            },
            {
                "cost": {
                    "green": 6
                },
                "effects": {
                    "reinforce": 3
                }
            }
        ],
        "modes": [
            1
        ]
	},
	"30":{
		"name":"Weaken",
		"id":30,
		"image":"/images/new/cards/weaken.png",
		"text":"All enemy attacks deal 50% damage for 2 turns",
		"cost":{
			"green":4,
			"red":1,
            "blue": 1
		},
		"effects":{
			"weaken":2
		},
		"tier": 1,
        "modes": [
            1
        ]
	},
	"31":{
		"name":"Minor Repairs",
		"id":31,
		"image":"/images/new/cards/minor_repairs.png",
		"text":"Shield 3, Heal 2",
		"cost":{
			"green":5
		},
		"effects":{
			"shield":3,
			"heal":2
		},
		"tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 3
	},
	"32":{
		"name":"Experimentation",
		"id":32,
		"image":"/images/new/cards/experimentation.png",
		"text":"Take 6 damage but Regen 2. Exile.",
		"cost":{
			"red":5,
			"green":5
		},
		"effects":{
			"regen":2,
			"damage":6
		},
		"tier": 3,
        "exile":true,
        "modes": [
            1
        ],
        "unlockLevel": 6
	},
	"33":{
		"name":"Leech Life",
		"id":33,
		"image":"/images/new/cards/leech_life.png",
		"text":"Deal 4 damage, heal for 2",
		"cost":{
            "red": 4,
			"green":2
		},
		"effects":{
			"attack":4,
			"heal":2
		},
		"tier": 1,
        "upgrades": [
            {
                "cost":{
                    "red": 5,
                    "green":3
                },
                "effects": {
                    "attack": 6,
                    "heal": 3
                }
            },
            {
                "cost":{
                    "red": 6,
                    "green":4
                },
                "effects": {
                    "attack": 8,
                    "heal": 4
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 5
	},
    "35": {
        "name": "Dragon Armour",
        "id": 35,
        "image": '/images/new/cards/dragon_armour.png',
        "text": "Blocks 9 damage",
        "cost": {
            "green": 5
        },
        "effects": {
            "shield": 9
        },
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "shield": 12
                }
            },
            {
                "effects": {
                    "shield": 16
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 2
    },
    "36": {
        "name": "Stasis",
        "id": 36,
        "image": '/images/new/cards/statis.png',
        "text": "Blocks 15 damage. Ends the turn.",
        "cost": {
            "green": 7
        },
        "effects": {
            "shield": 15,
        },
        "endTurn": 1,
        "tier": 3,
        "upgrades": [
            {
                "cost": {
                    "green": 8
                },
                "effects": {
                    "shield": 22
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 5
    },
    "37": {
        "name": "Shield bounce",
        "id": 37,
        "image": '/images/new/cards/shield_bounce.png',
        "text": "Lose half your shield. Deal it as damage.",
        "cost": {
            "red": 3,
            "green": 1
        },
        "effects": {
            "attack": {
                "descriptionTemplate": "Deal it as damage.",
                "descriptionPriority": 1,
                "valType": "calc",
                "valCalc": "floor",
                "val": {
                    "valType": "percentage",
                    "val": 50,
                    "valProperty": "shield"
                }
            },
            "shield": {
                "descriptionTemplate": "Lose half your shield.",
                "descriptionPriority": 2,
                "valType": "calc",
                "valCalc": "floor",
                "val": {
                    "valType": "percentage",
                    "val": -50,
                    "valProperty": "shield"
                }
            }
        },
        "tier": 2,
        "upgrades": [],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "38": {
        "name": "Armoury",
        "id": 38,
        "image": '/images/new/cards/armoury.png',
        "text": "Armour 1.",
        "cost": {
            "green": 3
        },
        "effects": {
            "armour": 1
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "39": {
        "name": "Blacksmith",
        "id": 39,
        "image": '/images/new/cards/blacksmith.png',
        "text": "Armour 1. Muster 1.",
        "cost": {
            "green": 5,
            "red": 5
        },
        "effects": {
            "armour": 1,
            "muster": 1
        },
        "tier": 2,
        "upgrades": [
            {
                "cost": {
                    "green": 3,
                    "red": 3
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 4
    },
    "40": {
        "name": "Energy Shield",
        "id": 40,
        "image": '/images/new/cards/energy_shield.png',
        "text": "Block 3 damage. Draw a card",
        "cost": {
            "green": 3
        },
        "effects": {
            "shield": 3,
            "draw": 1
        },
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "shield": 7,
                    "draw": 1
                }
            },
            {
                "cost": {
                    "green": 5
                },
                "effects": {
                    "shield": 7,
                    "draw": 2
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 5
    },
    "41": {
        "name": "Double Agent",
        "id": 41,
        "image": '/images/new/cards/double_agent.png',
        "text": "Deal 9 damage. Permanently increase it's damage by 2, the cost by 2 and put this in the opponent's deck.",
        "cost": {
            "red": 2,
            "blue": 1
        },
        "effects": {
            "attack": 9,
            "alter": {
                "descriptionTemplate": "Increase the damage by 2 and the cost by 1 red and 1 blue mana.",
                "target": "self",
                "qty": 1,
                "action": {
                    "costIncrease": {
                        "red": 1,
						"blue": 1
                    },
					"attackIncrease": 2
                }
            }
        },
        "giveToEnemy": 1,
        "tier": 3,
        "modes": [
            1
        ],
        "unlockLevel": 7
    },
	"42":{
		"name":"Paralyzing Armour",
		"id":42,
		"image":"/images/new/cards/paralyzing_armour.png",
		"text":"Weaken for 3 turns. Shield 3.",
		"cost":{
			"green":6,
			"blue":1
		},
		"effects":{
			"weaken":3,
            "shield": 3
		},
		"tier": 2,
        "upgrades": [
            {
                "effetcts": {
                    "weaken": 3,
                    "shield": 6
                }
            },
            {
                "cost": {
                    "green": 6,
                    "blue": 3
                },
                "effects": {
                    "weaken": 4,
                    "shield": 8
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 3
	},
    "43": {
        "name": "Holy Protection",
        "id": 43,
        "image": '/images/new/cards/holy_protection.png',
        "text": "Become invulnerable to damage for 1 turn. Exile",
        "cost": {
            "green": 10,
        },
        "effects": {
            "invulnerable": 1,
        },
        "exile": 1,
        "modes": [
            1
        ],
        "unlockLevel": 5
    },
    // "44": {
    //     "name": "Stagger",
    //     "id": 44,
    //     "image": '',
    //     "text": "Become invulnerable for 1 turn. Take 75% of the damage received during this time over the next two turns.",
    //     "cost": {
    //         "green": 5,
    //         "blue": 5
    //     },
    //     "effects": {
    //         "invulnerable": 1,
    //         "dot": {
    //             "turns": 2,
    //             "val": {
    //                 "valType": "percentage",
    //                 "val": 75,
    //                 "valProperty": "damageMitigated"
    //             }
    //         }
    //     },
    //     "tier": 3
    // },
    "45": {
        "name": "Desparate Defence",
        "id": 45,
        "image": '/images/new/cards/desparate_defence.png',
        "text": "Block 20 damage. Lose 1 Muster and Armour.",
        "cost": {
            "green": 5
        },
        "effects": {
            "shield": 20,
            "muster": -1,
            "armour": -1
        },
        "tier": 2,
        "upgrades": [
            {
                "effects": {
                    "shield": 25,
                    "muster": -1,
                    "armour": -1
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "46": {
        "name": "Barricade",
        "id": 46,
        "image": '/images/new/cards/barricade.png',
        "text": "Negates the next card the enemy plays.",
        "cost": {
            "green": 3,
            "blue": 3
        },
        "effects": {
            "negate": 1
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 4
    },
	"47":{
		"name":"Emergency Medkit",
		"id":47,
		"image":"/images/new/cards/emergency_medkit.png",
		"text":"Heal 10 damage. Exile.",
		"cost":{
			"green":6
		},
		"effects":{
			"heal":15
		},
		"tier": 3,
        "exile":1,
        "modes": [
            1
        ],
        "unlockLevel": 8
	},
    "48": {
        "name": "Reserves",
        "id": 48,
        "text": "Shield 4. Keep.",
        "image": '/images/new/cards/reserves.png',
        "cost": {
            "green": 5
        },
        "effects": {
            "shield": 4
        },
        "keep": 1,
        "tier": 2,
        "upgrades": [
            {
                "effects": {
                    "shield": 7
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 2
    },
    "49": {
        "name": "Armour Module",
        "id": 49,
        "text": "Blocks 4 damage. Module for Killer Robot Chassis.",
        "image": '/images/new/cards/armour_module.png',
        "cost": {
            "green": 3
        },
        "effects": {
            "shield": 4
        },
        "module": 50,
        "tier": 1,
        "modes": [
            2
        ],
        "unlockLevel": 1
    },
    "50": {
        "name": "Killer Robot Chassis",
        "id": 50,
        "text": "Blocks 3 damage. Attacks for 3. Can be equipped by modules.",
        "image": '/images/new/cards/killer_robot_chassis.png',
        "cost": {
            "green": 2,
            "red": 2
        },
        "effects": {
            "shield": 3,
            "attack": 3,
        },
        "moduleSlots": 4,
        "tier": 2,
        "modes": [
            2
        ],
        "unlockLevel": 1
    },
    "51": {
        "name": "Damage Module",
        "id": 51,
        "text": "Attacks for 4. Module for Killer Robot Chassis.",
        "image": '/images/new/cards/damage_module.png',
        "cost": {
            "red": 3
        },
        "effects": {
            "attack": 3
        },
        "module": 50,
        "tier": 1,
        "modes": [
            2
        ],
        "unlockLevel": 1
    },
    "52": {
        "name": "Crit Damage Module",
        "id": 52,
        "text": "Crit attacks for 3. Module for Killer Robot Chassis.",
        "image": '/images/new/cards/crit_damage_module.png',
        "cost": {
            "red": 3
        },
        "effects": {
            "critAttack": 3
        },
        "module": 50,
        "tier": 1,
        "modes": [
            2
        ],
        "unlockLevel": 1
    },
    "53": {
        "name": "Recycle Module",
        "id": 53,
        "text": "Draw card. Opponent discards card. Module for Killer Robot Chassis.",
        "image": '/images/new/cards/recycle_module.png',
        "cost": {
            "green": 3,
            "blue": 3
        },
        "effects": {
            "draw": 1,
            "oppDiscard": 1
        },
        "module": 50,
        "tier": 1,
        "modes": [
            2
        ],
        "unlockLevel": 1
    },
    "54": {
        "name": "Heal Module",
        "id": 54,
        "text": "Heal 2. Module for Killer Robot Chassis.",
        "image": '/images/new/cards/heal_module.png',
        "cost": {
            "green": 3,
        },
        "effects": {
            "heal": 2
        },
        "module": 50,
        "tier": 1,
        "modes": [
            2
        ],
        "unlockLevel": 1
    },
    "55": {
        "name": "Mana Shield",
        "id": 55,
        "text": "Drains all mana. Shield for the amount of mana drained.",
        "image": '/images/new/cards/mana_shield.png',
        "cost": {
            "green": 8
        },
        "effects": {
            "shield": {
                "descriptionTemplate": "Shield for the amount of mana drained.",
                "descriptionPriority": 1,
                "valType": "calc",
                "valCalc": "sum",
                "val": [
                    {
                        "valType": "percentage",
                        "val": 100,
                        "valProperty": "playerRedMana"
                    },
                    {
                        "valType": "percentage",
                        "val": 100,
                        "valProperty": "playerGreenMana"
                    },
                    {
                        "valType": "percentage",
                        "val": 100,
                        "valProperty": "playerBlueMana"
                    }
                ]
            },
            "manaGain": {
                "descriptionTemplate": "Drains all mana.",
                "descriptionPriority": 2,
                "red": {
                    "valType": "percentage",
                    "val": -100,
                    "valProperty": "playerRedMana"
                },
                "green": {
                    "valType": "percentage",
                    "val": -100,
                    "valProperty": "playerGreenMana"
                },
                "blue": {
                    "valType": "percentage",
                    "val": -100,
                    "valProperty": "playerBlueMana"
                }
            }
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "56": {
        "name": "Kyanite Steel",
        "id": 56,
        "image": '/images/new/cards/kyanite_steel.png',
        "text": "Armour doesn't break at the end of the turn for 6 turns. Exile.",
        "cost": {
            "green": 10
        },
        "effects": {
            "reinforce": 6
        },
        "exile": 1,
        "tier": 3,
        "modes": [
            1
        ],
        "unlockLevel": 2
    },
    // "57": {
    //     "name": "Call For Aid",
    //     "id": 57,
    //     "image": '',
    //     "text": "If below 50% health, heal 5. Else, attack for 7",
    //     "effects": {
    //         "attack": 7,
    //         "heal": 5
    //     },
    //     "cost": {
    //         "red": 3,
    //         "green": 3,
    //         "blue": 1
    //     }
    // },
	"58":{
		"name":"Courage Potion",
		"id":58,
		"image":"/images/new/cards/courage_potion.png",
		"text":"Strengthen 2. Heal 2.",
		"cost":{
			"blue":1,
            "green": 2,
            "red": 2
		},
		"effects":{
			"strengthen":2,
			"heal":2,
		},
		"tier": 2,
        "upgrades": [
            {
                "cost": {
                    "red": 3,
                    "blue": 2,
                    "green": 3
                },
                "effects": {
                    "strengthen": 3,
                    "heal": 3
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"59":{
		"name":"Tricky Manouvers",
		"id":59,
		"image":"/images/new/cards/tricky_manouvers.png",
		"text":"Draw 1. Opponent discards 1.",
		"cost":{
			"blue":7
		},
		"effects":{
			"draw":1,
			"oppDiscard":1,
		},
		"tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"60":{
		"name":"Mana Loan",
		"id":60,
		"image":"/images/new/cards/mana_loan.png",
		"text":"Grants 15 red and green mana for 2 turns.",
		"cost":{
			"blue":5
		},
		"effects":{
			"manaGain": {
				"green":15,
				"red":15
			},
			"future": [
				{
					"turns":2,
					"effects": {
						"manaGain": {
							"green":-15,
							"red":-15
						}
					}
				}
			]
		},
		"tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"61":{
		"name":"Mana Boost (red)",
		"id":61,
		"image":"/images/new/cards/mana_boost_red.png",
		"text":"Grants 5 red mana",
		"cost":{
			"blue":2
		},
		"effects":{
			"manaGain": {
				"red":5
			}
		},
		"tier": 1,
        "upgrades": [
            {
                "cost": {
                    "blue": 3
                },
                "effects": {
                    "manaGain": {
                        "red": 8
                    }
                }
            },
            {
                "cost": {
                    "blue": 4
                },
                "effects": {
                    "manaGain": {
                        "red": 12
                    }
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"62":{
		"name":"Mana Boost (green)",
		"id":62,
		"image":"/images/new/cards/mana_boost_green.png",
		"text":"Grants 5 green mana",
		"cost":{
			"blue":2
		},
		"effects":{
			"manaGain": {
				"green":5
			}
		},
		"tier": 1,
        "upgrades": [
            {
                "cost": {
                    "blue": 3
                },
                "effects": {
                    "manaGain": {
                        "green": 8
                    }
                }
            },
            {
                "cost": {
                    "blue": 4
                },
                "effects": {
                    "manaGain": {
                        "green": 12
                    }
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"63":{
		"name":"Neutralize",
		"id":63,
		"image":"/images/new/cards/neutralize.png",
		"text":"Remove Poison and Regen effects of both players.",
		"cost":{
			"blue":4
		},
		"effects":{
            "clear": [
                "poison",
                "regen",
                "oppPoison",
                "oppRegen"
            ]
		},
		"tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"64":{
		"name":"Focus Red",
		"id":64,
		"image":"/images/new/cards/focus_red.png",
		"text":"Increases red mana gain by 1",
		"cost":{
			"red":3,
			"blue":3
		},
		"effects":{
            "focus": {
			    "red":1
            }
		},
		"tier": 1,
        "upgrades": [
            {
                "effects": {
                    "focus": {
                        "red": 1
                    },
                    "manaGain": {
                        "red": 3
                    }
                }
            },
            {
                "cost": {
                    "red": 5,
                    "blue": 5
                },
                "effects": {
                    "focus": {
                        "red": 2
                    },
                    "manaGain": {
                        "red": 3
                    }
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"65":{
		"name":"Focus Green",
		"id":65,
		"image":"/images/new/cards/focus_green.png",
		"text":"Increases green mana gain by 1",
		"cost":{
			"green":3,
			"blue":3
		},
		"effects":{
            "focus": {
			    "green":1
            }
		},
		"tier": 1,
        "upgrades": [
            {
                "effects": {
                    "focus": {
                        "green": 1
                    },
                    "manaGain": {
                        "green": 3
                    }
                }
            },
            {
                "cost": {
                    "green": 5,
                    "blue": 5
                },
                "effects": {
                    "focus": {
                        "green": 2
                    },
                    "manaGain": {
                        "green": 3
                    }
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"66":{
		"name":"Focus Blue",
		"id":66,
		"image":"/images/new/cards/focus_blue.png",
		"text":"Increases blue mana gain by 1",
		"cost":{
			"blue":6
		},
		"effects":{
            "focus": {
			    "blue":1
            }
		},
		"tier": 1,
        "upgrades": [
            {
                "effects": {
                    "focus": {
                        "blue": 1
                    },
                    "manaGain": {
                        "blue": 3
                    }
                }
            },
            {
                "cost": {
                    "blue": 10
                },
                "effects": {
                    "focus": {
                        "blue": 2
                    },
                    "manaGain": {
                        "blue": 3
                    }
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
    "67": {
        "name": "Arcane Wisdom",
        "id": 67,
        "image": '/images/new/cards/arcane_wisdom.png',
        "text": "Draw 3.",
        "cost": {
            "blue": 5
        },
        "effects": {
            "draw": 3
        },
        "tier": 1,
        "upgrades": [
            {
                "cost": {
                    "blue": 6
                },
                "effects": {
                    "draw": 4
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 3
    },
	"68":{
		"name":"Cure Poison",
		"id":68,
		"image":"/images/new/cards/cure_poison.png",
		"text":"Cure Poison effects. Exile.",
		"cost":{
			"blue":3,
			"green":3
		},
		"effects":{
            "clear": [
                "poison"
            ]
		},
		"tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
    "69": {
        "name": "Icy Grasp",
        "id": 69,
        "image": '/images/new/cards/icy_grasp.png',
        "text": "Both players draw 1 less card next turn.",
        "cost": {
            "blue": 2
        },
        "effects": {
            "discard": 1,
            "oppDiscard": 1
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "70": {
        "name": "Double attack",
        "id": 70,
        "image": '/images/new/cards/double_attack.png',
        "text": "The next red card you play this turn, play it twice.",
        "cost": {
            "blue": 6
        },
        "effects": {
			"doubleNextRed": 1
		},
        "tier": 3,
        "modes": [
            1
        ],
        "unlockLevel": 4
    },
    "71": {
        "name": "Double defence",
        "id": 71,
        "image": '/images/new/cards/double_defence.png',
        "text": "The next green card you play this turn, play it twice.",
        "cost": {
            "blue": 6
        },
        "effects": {
			"doubleNextGreen": 1
        },
        "tier": 3,
        "modes": [
            1
        ],
        "unlockLevel": 4
    },
    "72": {
        "name": "Blitz",
        "id": 72,
        "image": '/images/new/cards/blitz.png',
        "text": "Exile every card in your hand. Deal 4 damage for each card exiled in this way. Exile.",
        "cost": {
            "red": 5,
            "blue": 5
        },
        "effects": {
            "attack": {
                "descriptionTemplate": "Deal 4 damage for each card exiled in this way.",
                "descriptionPriority": 1,
                "valType": "calc",
                "valCalc": "multiply",
                "val": [
                    {
                        "valType": "percentage",
                        "val": 100,
                        "valProperty": "handCount"
                    },
                    {
                        "valType": "flat",
                        "val": 4
                    }
                ]
            },
            "exileAll": {
                "descriptionTemplate": "Exile every card in your hand.",
                "descriptionPriority": 2,
                "valType": "flat",
                "val": 1
            }
        },
        "exile": true,
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "attack": {
                        "valType": "calc",
                        "valCalc": "multiply",
                        "val": [
                            {
                                "valType": "percentage",
                                "val": 100,
                                "valProperty": "handCount"
                            },
                            {
                                "valType": "flat",
                                "val": 6
                            }
                        ]
                    },
                    "exileAll": 1
                },
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 5
    },
    "73": {
        "name": "Strangle",
        "id": 73,
        "image": '/images/new/cards/strangle.png',
        "text": "Add two Weeds to the enemy's deck.",
        "cost": {
            "blue": 7
        },
        "effects": {
            "addToDeck": [
                {
                    "type": "enemy",
                    "id": 74,
                    "qty": {
                        "valType": "flat",
                        "val": 2
                    }
                }
            ]
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 6
    },
    "74": {
        "name": "Weed",
        "id": 74,
        "image": '/images/new/cards/weed.png',
        "text": "A large weed. Unplayable.",
        "descriptionTemplate": "A large weed. Unplayable.",
        "cost": {

        },
        "effects": {

        },
        "unplayable": 1,
        "tier": -1,
        "unlockLevel": 6
    },
    "75": {
        "name": "Minor Upgrades",
        "id": 75,
        "image": '/images/new/cards/minor_upgrades.png',
        "text": "Upgrade a random card in your hand.",
        "cost": {
            "blue": 5
        },
        "effects": {
            "alter": {
                "target": "randomHand",
                "qty": 1,
                "action": {
                    "upgrade": 1
                }
            }
        },
        "tier": 1,
        "modes": [],
        "unlockLevel": 1
    },
    "76": {
        "name": "Negotiations",
        "id": 76,
        "image": '/images/new/cards/negotiations.png',
        "text": "Lower the mana cost of a random card in the deck by 2 for the rest of the battle.",
        "cost": {
            "blue": 6
        },
        "effects": {
            "alter": {
                "target": "randomDeck",
                "qty": 1,
                "action": {
                    "costIncrease": {
                        "random": -2
                    }
                }
            }
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "77": {
        "name": "Sharpened Points",
        "id": 77,
        "image": '/images/new/cards/sharpened_points.png',
        "text": "All cards in your hand gain \"Attack 1\" until the end of battle.",
        "cost": {
            "red": 4,
            "blue": 4
        },
        "effects": {
            "alter": {
                "target": "allHand",
                "qty": 1,
                "action": {
                    "gainEffects": {
                        "attack": 1
                    }
                }
            }
        },
        "tier": 1,
        "upgrades": [
            {
                "cost": {
                    "red": 3,
                    "blue": 3
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "78": {
        "name": "Polished Edges",
        "id": 78,
        "image": '/images/new/cards/polished_edges.png',
        "text": "All cards in your hand gain \"Shield 1\" until the end of battle.",
        "cost": {
            "green": 4,
            "blue": 4
        },
        "effects": {
            "alter": {
                "target": "allHand",
                "qty": 1,
                "action": {
                    "gainEffects": {
                        "shield": 1
                    }
                }
            }
        },
        "tier": 1,
        "upgrades": [
            {
                "cost": {
                    "red": 3,
                    "blue": 3
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "79": {
        "name": "Resurrection",
        "id": 79,
        "image": '/images/new/cards/resurrection.png',
        "text": "All cards in exile are shuffled into the deck. Exile.",
        "cost": {
            "blue": 9
        },
        "effects": {
            "resurrection": 1
        },
        "exile": 1,
        "tier": 3,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
	"80":{
		"name":"Magic Blast",
		"id":80,
		"image":"/images/new/cards/magic_blast.png",
		"text":"Deal 12 damage. Gain 12 of each mana.",
		"cost":{
			"blue":14,
			"red":14,
			"green":14,
		},
		"effects":{
			"attack":12,
			"manaGain":{
				"red":12,
				"green":12,
				"blue":12
			}
		},
		"tier": 3,
        "modes": [
            1
        ],
        "unlockLevel": 8
	},
	"81":{
		"name":"Devil's Bargain",
		"id":81,
		"image":"/images/new/cards/devils_bargain.png",
		"text":"Take 20 critical damage. In 2 turns, heal for 20. For 2 turns, Muster 3.",
		"cost":{
			"red":4,
			"green":2,
			"blue":1
		},
		"effects":{
			"critDamage":20,
			"muster":3,
			"future":[
				{
					"turns":2,
					"effects": {
						"heal":20
					},
                    "endOfBattleTrigger": true
				},
				{
					"turns":2,
					"effects": {
						"muster":-3
					},
                    "endOfBattleTrigger": true
				}
			]
		},
		"tier": 3,
        "upgrades": [
            {
                "effects":{
                    "critDamage":20,
                    "muster":3,
                    "future":[
                        {
                            "turns":3,
                            "effects": {
                                "heal":20
                            },
                            "endOfBattleTrigger": true
                        },
                        {
                            "turns":3,
                            "effects": {
                                "muster":-3
                            }
                        }
                    ]
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 9
	},
	// "82":{
	// 	"name": "Mimic",
	// 	"id": 82,
	// 	"image": '',
	// 	"text": "Gains power based on the previous card played. Exile.",
	// 	"cost": {
	// 		"blue": 5
	// 	},
	// 	"effect": {
	// 		"mimic": 1 //or alter?
	// 	}
	// },
    // "83": {
    //     "name": "Infinite Horde",
    //     "id": 83,
    //     "image": '',
    //     "text": "Draw a card each time you play a red card this turn.",
    //     "cost": {
    //         "blue": 5,
    //         "red": 1
    //     },
    //     "effects": {
    //         "draw": 1
    //     },
    //     "tier": 2
    // },
    "84": {
        "name": "Dagger",
        "id": 84,
        "image": '/images/new/cards/dagger.png',
        "text": "Deal 1 damage.",
        "cost": {
            "red": 1
        },
        "effects": {
            "attack": 1
        },
        "tier": -1,
        "unlockLevel": 1
    },
    "85": {
        "name": "Void Matter",
        "id": 85,
        "image": '/images/new/cards/void_matter.png',
        "text": "A chunk of void matter. Exile.",
        "descriptionTemplate": "A chunk of void matter.",
        "cost": {
            "red": 1,
            "green": 1,
            "blue": 1
        },
        "effects": {
        },
        "exile": 1,
        "tier": -1,
        "unlockLevel": 1
    },
    "86": {
        "name":"Layered Defense",
        "id":86,
        "image":'/images/new/cards/layered_defence.png',
        "text":"Gain 2 shield, 3 times",
        "cost": {
            "green": 5
        },
        "effects": {
            "shield": {
                "valType": "flat",
                "val": 2,
                "context": {
                    "multiEffect": 3
                },
            }
        },
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "shield": 4
                }
            },
            {
                "effects": {
                    "shield": 4
                },
                "context": {
                    "multiEffect": 4
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "87": {
        "name":"Suit of Armour",
        "id":87,
        "image":'/images/new/cards/suit_of_armour.png',
        "text":"Shields for 15. Exile.",
        "cost": {
            "green": 5
        },
        "effects": {
            "shield": 15,
        },
        "exile": 1,
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "shield": 20
                }
            },
            {
                "cost": {
                    "green": 6
                },
                "effects": {
                    "shield": 30
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 3
    },
    "88": {
        "name":"Adaptive Shielding",
        "id":88,
        "image":'/images/new/cards/adaptive_shielding.png',
        "text":"Shield for 4. Shield increases by 2 each time this card is played.",
        "cost": {
            "green": 3
        },
        "effects": {
            "shield": 4,
            "alter": {
                "descriptionTemplate": "Shield increases by 2 each time this card is played.",
                "target": "self",
                "qty": 1,
                "action": {
                    "gainEffects": {
                        "shield": 2
                    }
                }
            }
        },
        "tier": 2,
        "upgrades": [
            {
                "effects": {
                    "shield": 5,
                    "alter": {
                        "target": "self",
                        "qty": 1,
                        "action": {
                            "shieldIncrease": 3
                        }
                    }
                }
            },
            {
                "cost": {
                    "green": 4
                },
                "effects": {
                    "shield": 7,
                    "alter": {
                        "target": "self",
                        "qty": 1,
                        "action": {
                            "shieldIncrease": 4
                        }
                    }
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "89": {
        "name": "Finesse",
        "id": 89,
        "image": '/images/new/cards/finesse.png',
        "text": "Muster 1. Then deal 1 damage, 2 times.",
        "cost": {
            "red": 4,
            "blue": 3
        },
        "effects": {
            "muster": 1,
            "attack": {
                "valType": "flat",
                "val": 1,
                "context": {
                    "multiEffect": 2
                },
            }
        },
        "tier": 2,
        "upgrades": [
            {
                "context": {
                    "multiEffect": 4
                },
            },
            {
                "cost": {
                    "red": 5,
                    "blue": 3
                },
                "effects": {
                    "muster": 2,
                    "attack": 1
                },
                "context": {
                    "multiEffect": 4
                },
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 2
    },
    "90": {
        "name": "Relay Strikes",
        "id": 90,
        "image": '/images/new/cards/relay_strikes.png',
        "text": "Deal 1 damage, twice. Draw a card.",
        "cost": {
            "red": 3,
            "blue": 1
        },
        "effects": {
            "attack": {
                "valType": "flat",
                "val": 1,
                "context": {
                    "multiEffect": 2
                },
            },
            "draw": 1
        },
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "attack": 1,
                    "draw": 2
                },
                "context": {
                    "multiEffect": 3
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "91": {
        "name": "Charge Cannon",
        "id": 91,
        "image": '/images/new/cards/charge_cannon.png',
        "text": "Deals 25 damage. Exile. Cost is decreased by 2 red per turn.",
        "cost": {
            "red": 20
        },
        "effects": {
            "attack": 25
        },
        "tier": 3,
        "upgrades": [],
        "modes": [
            1
        ],
        "triggeredEffects": [
            {
                "trigger": "endPlayerTurn",
                "effect": "alter",
                "val": {
                    "descriptionTemplate": "Decrease the cost of this card by 2 red mana every turn.",
                    "target": "self",
                    "qty": 1,
                    "action": {
                        "costIncrease": {
                            "red": -2
                        }
                    }
                }
            }
        ],
        "unlockLevel": 10
    },
    "92": {
        "name": "Bolster",
        "id": 92,
        "image": '/images/new/cards/bolster.png',
        "text": "Strengthen 2. Attack for 4.",
        "cost": {
            "red": 3,
            "blue": 2
        },
        "effects": {
            "strengthen": 2,
            "attack": 4
        },
        "tier": 1,
        "upgrades": [
            {
                "effects": {
                    "strengthen": 2,
                    "attack": 7
                }
            },
            {
                "cost": {
                    "red": 3,
                    "blue": 3
                },
                "effects": {
                    "strengthen": 3,
                    "attack": 7
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
	"93":{
		"name":"Mana Boost (blue)",
		"id":93,
		"image":"/images/new/cards/mana_boost_blue.png",
		"text":"Grants 5 blue mana",
		"cost":{
			"blue":2
		},
		"effects":{
			"manaGain": {
				"blue":5
			}
		},
		"tier": 1,
        "upgrades": [
            {
                "cost": {
                    "blue": 3
                },
                "effects": {
                    "manaGain": {
                        "blue": 8
                    }
                }
            },
            {
                "cost": {
                    "blue": 4
                },
                "effects": {
                    "manaGain": {
                        "blue": 12
                    }
                }
            }
        ],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"94":{
		"name":"Small Shield",
		"id":94,
		"image":"/images/new/cards/small_shield.png",
		"text":"Block 3",
		"cost":{
			"green":3
		},
		"effects":{
			"shield": 3
		},
		"tier": -1,
        "upgrades": [],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
	"95":{
		"name":"Mega Smash",
		"id":95,
		"image":"/images/new/cards/mega_smash.png",
		"text":"Attack for 20",
		"cost":{
			"red":1
		},
		"effects":{
			"attack": 20
		},
		"tier": -1,
        "upgrades": [],
        "modes": [
            1
        ],
        "unlockLevel": 1
	},
    "96": {
        "name": "Nature Attack",
        "id": 96,
        "image": "/images/new/cards/nature_attack.png",
        "text": "Attack for 7",
        "cost": {
            "green": 5
        },
        "effects": {
            "attack": 7
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "97": {
        "name": "Small mana potion",
        "id": 97,
        "image": "/images/new/cards/small_mana_potion.png",
        "text": "Gain 1 of each mana. Draw a card.",
        "cost": {
            "blue": 1
        },
        "effects": {
			"manaGain": {
				"green":1,
				"red":1,
                "blue":1
			},
            "draw": 1
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 9
    },
    "98": {
        "name": "Fortify",
        "id": 98,
        "image": "/images/new/cards/fortify.png",
        "text": "Strengthen 1. Weaken 1. Shield 1. Heal 1.",
        "cost": {
            "blue":3,
            "green": 2
        },
        "effects": {
			"strengthen": 1,
            "weaken": 1,
            "shield": 1,
            "heal": 1
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "99": {
        "name": "Gaping Wound",
        "id": 99,
        "image": "/images/new/cards/gaping_wound.png",
        "text": "Attack 4. Bleed 4.",
        "cost": {
            "red": 7
        },
        "effects": {
			"attack": 4,
            "bleed": 4
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "100": {
        "name": "Infect",
        "id": 100,
        "image": "/images/new/cards/infect.png",
        "text": "Poison 1. Bleed 1.",
        "cost": {
            "red": 3,
            "green": 3
        },
        "effects": {
			"poison": 1,
            "bleed": 1
        },
        "tier": 3,
        "modes": [
            1
        ],
        "unlockLevel": 6
    },
    "101": {
        "name": "Ambush",
        "id": 101,
        "image": "/images/new/cards/ambush.png",
        "text": "Attack for 1 and Bleed 1, twice.",
        "cost": {
            "red": 5
        },
        "effects": {
			"attack": {
                "valType": "flat",
                "val": 1,
                "context": {
                    "multiEffect": 2
                },
            },
            "bleed": {
                "valType": "flat",
                "val": 1,
                "context": {
                    "multiEffect": 2
                },
            }
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "102": {
        "name": "Venomous Spike",
        "id": 102,
        "image": "/images/new/cards/venomous_spike.png",
        "text": "Poison 2. Thorn 2. Exile.",
        "cost": {
            "red": 5,
            "green": 9
        },
        "effects": {
			"poison": 2,
            "thorn": 2
        },
        "exile": true,
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 7
    },
    "103": {
        "name": "Meditation",
        "id": 103,
        "image": "/images/new/cards/meditation.png",
        "text": "For the next 4 turns, draw an extra card.",
        "cost": {
            "blue": 8
        },
        "effects": {
			"future": [
				{
                    "descriptionOverride": "For the next 4 turns, draw an extra card.",
					"turns": 1,
					"effects": {
						"draw": 1
					}
				},
				{
                    "descriptionOverride": "",
					"turns": 2,
					"effects": {
						"draw": 1
					}
				},
				{
                    "descriptionOverride": "",
					"turns": 3,
					"effects": {
						"draw": 1
					}
				},
				{
                    "descriptionOverride": "",
					"turns": 4,
					"effects": {
						"draw": 1
					}
				}
			]
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 10
    },
    "104": {
        "name": "Energize",
        "id": 104,
        "image": "/images/new/cards/energize.png",
        "text": "You and the opponent draw an extra card.",
        "cost": {
            "blue": 2
        },
        "effects": {
			"draw": 2,
            "oppDraw": 1
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "105": {
        "name": "Invigorate",
        "id": 105,
        "image": "/images/new/cards/invigorate.png",
        "text": "Add 'Draw a card' to a random card in your deck.",
        "cost": {
            "blue": 5
        },
        "effects": {
            "alter": {
                "target": "randomDeck",
                "qty": 1,
                "action": {
                    "gainEffects": {
                        "draw": 1
                    }
                }
            }
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "106": {
        "name": "Mana Sap",
        "id": 106,
        "image": "/images/new/cards/mana_sap.png",
        "text": "Opponent loses 3 of each mana.",
        "cost": {
            "blue": 3
        },
        "effects": {
            "oppManaGain": {
                "red": -3,
                "blue": -3,
                "green": -3
            }
        },
        "tier": 3,
        "modes": [
            1
        ],
        "unlockLevel": 11
    },
    "107": {
        "name": "Full Focus",
        "id": 107,
        "image": "/images/new/cards/full_focus.png",
        "text": "Boost all mana production by 1.",
        "cost": {
            "blue": 12
        },
        "effects": {
			"focus": {
                "red": 1,
                "blue": 1,
                "green": 1
            }
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 2
    },
    "108": {
        "name": "It Comes",
        "id": 108,
        "image": "/images/new/cards/it_comes.png",
        "text": "In 5 turns, damage 50. Drains 3 green mana per turn. Exile.",
        "cost": {
            "red": 5,
            "blue": 5
        },
        "effects": {
			"future": [
				{
                    "descriptionOverride": "Drains 3 green mana per turn.",
					"turns": 1,
					"effects": {
						"manaGain": {
                            "green": -3
                        }
					}
				},
				{
                    "descriptionOverride": "",
					"turns": 2,
					"effects": {
						"manaGain": {
                            "green": -3
                        }
					}
				},
				{
                    "descriptionOverride": "",
					"turns": 3,
					"effects": {
						"manaGain": {
                            "green": -3
                        }
					}
				},
				{
                    "descriptionOverride": "",
					"turns": 4,
					"effects": {
						"manaGain": {
                            "green": -3
                        }
					}
				},
				{
                    "turns": 5,
					"effects": {
                        "attack": 50,
						"manaGain": {
                            "descriptionTemplate": "",
                            "green": -3
                        }
					}
				}
			]
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 12
    },
    "109": {
        "name": "Tiny Rock",
        "id": 109,
        "image": '/images/new/cards/tiny_rock.png',
        "text": "Deal 1 damage.",
        "cost": {
            "red": 1
        },
        "effects": {
            "attack": 1
        },
        "tier": -1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "110": {
        "name": "Mini Agent",
        "id": 110,
        "image": '/images/new/cards/mini_agent.png',
        "text": "Deal 6 damage. Permanently increase it's damage by 1, the cost by 1 and put this in the opponent's deck.",
        "cost": {
            "red": 1,
        },
        "effects": {
            "attack": 6,
            "alter": {
                "descriptionTemplate": "Increase the damage by 1 and the cost by 1 random mana.",
                "target": "self",
                "qty": 1,
                "action": {
                    "costIncrease": {
                        "random": 1,
                    },
					"attackIncrease": 1
                }
            }
        },
        "giveToEnemy": 1,
        "tier": -1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "111": {
        "name": "Aurora",
        "id": 111,
        "image": "/images/new/cards/aurora.png",
        "cost": {
            "blue": 2,
            "red": 2,
            "green": 2
        },
        "effects": {
            "alter": {
                "target": "randomDeck",
                "qty": 1,
                "action": {
                    "gainEffects": {
                        "manaGain": {
                            "random": 1
                        }
                    }
                }
            }
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "112": {
        "name": "Insult to Injury",
        "id": 112,
        "image": "/images/new/cards/insult_to_injury.png",
        "cost": {
            "blue": 1,
            "red": 3,
            "green": 1
        },
        "effects": {
            "attack": {
                "descriptionTemplate": "Deal 2 damage for each debuff on the enemy.",
                "valType": "percentage",
                "valProperty": "enemyNumberOfDebuffs",
                "val": 200
            }
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "113": {
        "name": "Nourishing Greens",
        "id": 113,
        "image": "/images/new/cards/nourishing_greens.png",
        "cost": {
            "blue": 1,
            "green": 2
        },
        "effects": {
            "heal": {
                "descriptionTemplate": "Heal 1 for each buff.",
                "valType": "percentage",
                "valProperty": "numberOfBuffs",
                "val": 100
            }
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "114": {
        "name": "Stoke the Flames",
        "id": 114,
        "image": "/images/new/cards/stoke_the_flames.png",
        "cost": {
            "blue": 1,
            "green": 3,
            "red": 3
        },
        "effects": {
            "propogateDebuffs": 1
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "115": {
        "name": "Small Fire",
        "id": 115,
        "image": "/images/new/cards/small_fire.png",
        "cost": {
            "red": 3,
        },
        "effects": {
            "burn": 2
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "116": {
        "name": "Energy Storage",
        "id": 116,
        "image": "/images/new/cards/energy_storage.png",
        "cost": {
            "blue": 1,
        },
        "effects": {
            "draw": 1
        },
        "keep": 1,
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 7
    },
    "117": {
        "name": "Reserve Squad",
        "id": 117,
        "image": "/images/new/cards/reserve_squad.png",
        "cost": {
            "red": 4,
        },
        "effects": {
            "attack": 4
        },
        "keep": 1,
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 5
    },
    "118": {
        "name": "Heavy Bandages",
        "id": 118,
        "image": "/images/new/cards/heavy_bandages.png",
        "cost": {
            "green": 4,
        },
        "effects": {
            "clear": [
                "bleed"
            ]
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "119": {
        "name": "Laser Rifle",
        "id": 119,
        "image": "/images/new/cards/laser_rifle.png",
        "cost": {
            "red": 12,
        },
        "effects": {
            "critAttack": 8
        },
        "keep": 1,
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 8
    },
    "120": {
        "name": "Armour Caddy",
        "id": 120,
        "image": "/images/new/cards/armour_caddy.png",
        "cost": {
            "green": 3,
        },
        "effects": {
            "reinforce": 1
        },
        "keep": 1,
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 5
    },
    "121": {
        "name": "Hemorrhage",
        "id": 121,
        "image": "/images/new/cards/hemorrhage.png",
        "cost": {
            "red": 6,
        },
        "effects": {
			"triggers":{
				"enemy":{
					"bleed":2
				}
			}
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "122": {
        "name": "Raining Fire",
        "id": 122,
        "image": "/images/new/cards/raining_fire.png",
        "cost": {
            "red": 8
        },
        "effects": {
            "attack": {
                "descriptionTemplate": "Attack equal to the number of cards in your deck (not including hand, discard pile or in exile).",
                "valType": "attribute",
                "val": "cardsInDeck"
            }
        },
        "exile": 1,
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "123": {
        "name": "Sentient Dagger",
        "id": 123,
        "image": "/images/new/cards/sentient_dagger.png",
        "cost": {
            "red": 6
        },
        "effects": {
            "attack": 1,
            "alter": {
                "target": "self",
                "perm": 1,
                "qty": 1,
                "action": {
                    "attackIncrease": 1
                }
            }
        },
        "exile": 1,
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 8
    },
    "124": {
        "name": "Brittle Shield",
        "id": 124,
        "image": "/images/new/cards/brittle_shield.png",
        "cost": {
            "green": 3
        },
        "effects": {
            "shield": 10,
            "alter": {
                "target": "self",
                "perm": 1,
                "qty": 1,
                "action": {
                    "shieldDecrease": 1
                }
            }
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 3
    },
    "125": {
        "name": "Soulsnatcher",
        "id": 125,
        "image": "/images/new/cards/soul_snatcher.png",
        "cost": {
            "red": 4,
            "green": 2
        },
        "effects": {
            "attack": 5
        },
        "permEffects": [
            {
                "effect": "gainMaxHealth",
                "val": 1
            }
        ],
        "exile": 1,
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "126": {
        "name": "Metal Splinters",
        "id": 126,
        "image": "/images/new/cards/metal_splinters.png",
        "cost": {
            "red": 5
        },
        "effects": {
            "critAttack": {
                "valType": "flat",
                "val": 1,
                "context": {
                    "multiEffect": 3
                },
            }
        },
        "exile": 1,
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "127": {
        "name": "Lightning Prediction",
        "id": 127,
        "image": "/images/new/cards/lightning_prediction.png",
        "cost": {
            "red": 5
        },
        "effects": {
			"future": [
				{
					"turns": 3,
					"effects": {
						"critAttack": 5
					}
				}
            ]
        },
        "exile": 1,
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "129": {
        "name": "Backpack Hurl",
        "id": 129,
        "image": "/images/new/cards/backpack_hurl.png",
        "cost": {
            "red": 3,
            "blue": 3
        },
        "effects": {
			"critAttack": {
                "descriptionTemplate": "Deal critical damage equal to the number of artifacts you have.",
                "valType": "attribute",
                "val": "numberOfArtifacts"
            }
        },
        "exile": 1,
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 12
    },
    "130": {
        "name": "Tap Mana Vein",
        "id": 130,
        "image": "/images/new/cards/tap_mana_vein.png",
        "cost": {
            "blue": 6
        },
        "effects": {
			"manaGain": {
                "red": 6,
                "green": 6,
                "blue": 6
            }
        },
        "endTurn": 1,
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 8
    },
    "131": {
        "name": "Finishing Blow",
        "id": 131,
        "image": "/images/new/cards/finishing_blow.png",
        "cost": {
            "red": 5
        },
        "effects": {
			"attack": 10
        },
        "endTurn": 1,
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 9
    },
    "132": {
        "name": "Emerald Staff",
        "id": 132,
        "image": "/images/new/cards/emerald_staff.png",
        "cost": {
            "red": 5
        },
        "effects": {
			"attack": 5
        },
        "onKill": [
            {
                "effect": "gainGold",
                "val": 20
            }
        ],
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "133": {
        "name": "Spectral Blade",
        "id": 133,
        "image": "/images/new/cards/spectral_blade.png",
        "cost": {
            "red": 5,
            "blue": 1
        },
        "effects": {
			"attack": 5
        },
        "onKill": [
            {
                "effect": "gainMaxHealth",
                "val": 5
            }
        ],
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "134": {
        "name": "Fairy Armour",
        "id": 134,
        "image": "/images/new/cards/fairy_armour.png",
        "cost": {},
        "effects": {
			"shield": 3,
        },
        "keep": 1,
        "temporary": 1,
        "tier": -1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "135": {
        "name": "Shield Cleaver",
        "id": 135,
        "image": "/images/new/cards/shield_cleaver.png",
        "cost": {
            "green": 2,
            "blue": 4
        },
        "effects": {
			"clear": [
                "oppShield"
            ]
        },
        "tier": 1,
        "modes": [
            1
        ],
        "unlockLevel": 6
    },
    "136": {
        "name": "Magical Chest",
        "id": 136,
        "description": "Gain between 0 and 4 temporary magic runes.",
        "image": "/images/new/cards/magical_chest.png",
        "cost": {
            "blue": 5
        },
        "effects": {
            "addToDeck": [
                {
                    "descriptionOverride": "Gain between 0 and 4 temporary magic runes.",
                    "type": "player",
                    "id": 137,
                    "qty": {
                        "valType": "calc",
                        "valCalc": "floor",
                        "val": {
                            "valType": "random",
                            "val": [
                                {
                                    "valType": "flat",
                                    "val": 0
                                },
                                {
                                    "valType": "flat",
                                    "val": 2
                                }
                            ]
                        }
                    }
                },
                {
                    "descriptionOverride": "",
                    "type": "player",
                    "id": 138,
                    "qty": {
                        "valType": "calc",
                        "valCalc": "floor",
                        "val": {
                            "valType": "random",
                            "val": [
                                {
                                    "valType": "flat",
                                    "val": 0
                                },
                                {
                                    "valType": "flat",
                                    "val": 2
                                }
                            ]
                        }
                    }
                },
                {
                    "descriptionOverride": "",
                    "type": "player",
                    "id": 139,
                    "qty": {
                        "valType": "calc",
                        "valCalc": "floor",
                        "val": {
                            "valType": "random",
                            "val": [
                                {
                                    "valType": "flat",
                                    "val": 0
                                },
                                {
                                    "valType": "flat",
                                    "val": 2
                                }
                            ]
                        }
                    }
                },
                {
                    "descriptionOverride": "",
                    "type": "player",
                    "id": 140,
                    "qty": {
                        "valType": "calc",
                        "valCalc": "floor",
                        "val": {
                            "valType": "random",
                            "val": [
                                {
                                    "valType": "flat",
                                    "val": 0
                                },
                                {
                                    "valType": "flat",
                                    "val": 2
                                }
                            ]
                        }
                    }
                }
            ]
        },
        "keep": 1,
        "temporary": 1,
        "tier": -1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "137": {
        "name": "Rune of Damage",
        "id": 137,
        "image": "/images/new/cards/rune_of_damage.png",
        "cost": {
            "blue": 1
        },
        "effects": {
            "attack": 8
        },
        "keep": 1,
        "temporary": 1,
        "tier": -1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "138": {
        "name": "Rune of Protection",
        "id": 138,
        "image": "/images/new/cards/rune_of_protection.png",
        "cost": {
            "blue": 1
        },
        "effects": {
            "shield": 8,
            "reinforce": 1
        },
        "keep": 1,
        "temporary": 1,
        "tier": -1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "139": {
        "name": "Rune of Healing",
        "id": 139,
        "image": "/images/new/cards/rune_of_healing.png",
        "cost": {
            "blue": 1
        },
        "effects": {
            "regen": 1,
            "heal": 5
        },
        "keep": 1,
        "temporary": 1,
        "tier": -1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "140": {
        "name": "Rune of Power",
        "id": 140,
        "image": "/images/new/cards/rune_of_power.png",
        "cost": {
            "blue": 1
        },
        "effects": {
            "poison": {
                "descriptionTemplate": "Poison 1.",
                "valType": "flat",
                "val": 1

            },
            "bleed": {
                "descriptionTemplate": "Bleed 1.",
                "valType": "flat",
                "val": 1

            },
            "weaken": {
                "descriptionTemplate": "Weaken 1.",
                "valType": "flat",
                "val": 1

            },
            "strengthen": {
                "descriptionTemplate": "Strengthen 1.",
                "valType": "flat",
                "val": 1

            },
            "immune": {
                "descriptionTemplate": "Immune 1.",
                "valType": "flat",
                "val": 1

            },
            "burn": {
                "descriptionTemplate": "Burn 1.",
                "valType": "flat",
                "val": 1

            },
            "negate": {
                "descriptionTemplate": "Negate 1.",
                "valType": "flat",
                "val": 1

            },
        },
        "keep": 1,
        "temporary": 1,
        "tier": -1,
        "modes": [
            1
        ],
        "unlockLevel": 1
    },
    "141": {
        "name": "Smooth Sides",
        "id": 141,
        "image": "/images/new/cards/smooth_sides.png",
        "cost": {
            "blue": 5
        },
        "effects": {
            "manaSmoothing": 1 // mana gets added up and distributed evenly
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockLevel": 10
    },
    "142": {
        "name": "Golden Purge",
        "id": 142,
        "image": "/images/new/cards/golden_purge.png",
        "cost": {
            "blue": 3,
            "red": 2
        },
        "effects": {
            "attack": {
                "descriptionTemplate": "Attack for 1/25th of your gold.",
                "valType": "calc",
                "valCalc": "floor",
                "val": {
                    "valType": "calc",
                    "valCalc": "divide",
                    "val": [
                        {
                            "valType": "attribute",
                            "val": "gold"
                        },
                        {
                            "valType": "flat",
                            "val": 25
                        }
                    ]
                }
            }
        },
        "tier": 2,
        "modes": [
            1
        ],
        "unlockAchievement": 5
    },
}
