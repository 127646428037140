import React from 'react';

class HomeScreen extends React.Component {
    render() {
        return (
            <div className="home-page">
                <div className="version">{this.props.version}</div>
                <h1 className="home-title">RogueQuest</h1>
                <div className="home-buttons">
                    <button className="home-button home-button--start" onClick={() => {this.props.startNewGame()}}>
                        <span>Start New Game</span>
                    </button>
                    <button className="home-button home-button--credits" onClick={() => {this.props.goToCredits()}}>
                        <span>Credits</span>
                    </button>
                    <button className="home-button home-button--changelog" onClick={() => {this.props.goToChangelog()}}>
                        <span>Changelog</span>
                    </button>
                </div>
            </div>
        );
    }
}

export default HomeScreen;
