import React from 'react';

class History extends React.Component
{
    transformMessage(message) {
        let transformedMessage = message.replace(this.props.playerName, '<span class="history-list-item__player">' + this.props.playerName + '</span>');
        transformedMessage = transformedMessage.replace(this.props.enemyName, '<span class="history-list-item__opponent">' + this.props.enemyName + '</span>');
        return transformedMessage;
    }

    render() {
        return (
            <div className="history">
                <h2 className="history__title">Play History</h2>
                <ul className="history-list">
                    {this.props.history.map((message, id) => {
                        return (
                            <li className="history-list-item" dangerouslySetInnerHTML={{__html: this.transformMessage(message)}} key={id}></li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default History;
