import AbstractEncounter from "./AbstractEncounter";
import Artifact from "../Artifacts/Artifact";

import {shuffle} from '../Helper';

import {artifactsData} from '../data/artifacts';
import EncounterFactory from "./EncounterFactory";
import EnemyEncounter from "./EnemyEncounter";

class BlessingEncounter extends AbstractEncounter
{
    constructor()
    {
        super();
        this.name = 'Ancient Tome';
        this.screen = 'play';
        this.image = '/images/new/encounters/ancient_tome.png';
        this.ctas = [
            'open-book',
            'leave'
        ];
        this.choices = [];
        this.chosenChoice = null;
    }

    populateChoices()
    {
        if (this.choices.length > 0) {
            return;
        }

        let possibleBlessings = [
            'gainHealth',
            'gainMaxHealth',
            'gainArtifact',
            'gainGold',
            // 'gainXP',
            'scareEnemy',
            'addShop',
            'increaseStartingMana',
            'increaseStartingFocus',
            'increaseHandSize'
        ];

        let choices = shuffle(possibleBlessings).slice(0, 2);

        choices = choices.forEach(function(choiceName, index) {
            let choiceData = {
                'name': choiceName,
                'id': index
            }
            let rand;
            let manaType;
            switch (choiceName) {
                case 'gainHealth':
                    rand = this.getRandomNumber(30, 50);
                    choiceData.chapterName = 'How To Improve Your Constitution';
                    choiceData.description = 'Gain ' + rand + ' health.';
                    choiceData.val = rand;
                    break;

                case 'gainMaxHealth':
                    rand = this.getRandomNumber(5, 15);
                    choiceData.chapterName = 'How To Eat Right And Exercise';
                    choiceData.description = 'Gain ' + rand + ' maximum health.';
                    choiceData.val = rand;
                    break;

                case 'gainArtifact':
                    let artifactIds = {...artifactsData};
                    let possibleArtifacts = Object.keys(artifactIds).filter(artifactId => !this.game.hasArtifact(artifactIds[artifactId].name) && artifactIds[artifactId].pandora !== true && artifactIds[artifactId].unlockLevel <= this.game.experience.level).map((artifactId, pos) => Artifact.createFromId(artifactId));
                    if (possibleArtifacts.length > 0) {
                        let chosenArtifact = shuffle(possibleArtifacts)[0];

                        choiceData.chapterName = 'How To Use A Metal Detector';
                        choiceData.description = 'Gain the ' + chosenArtifact.name + ' artifact (' + chosenArtifact.description + ').';
                        choiceData.artifactId = chosenArtifact.id;
                    } else {
                        choiceData.chapterName = 'Ho...us...por...bies';
                        choiceData.description = 'This chapter is too faded to read.';
                    }
                    break;

                case 'gainGold':
                    rand = this.getRandomNumber(25, 75);
                    choiceData.chapterName = 'How To Become An Adult Film Star';
                    choiceData.description = 'Gain ' + rand + ' gold.';
                    choiceData.val = rand;
                    break;

                // case 'gainXP':
                //     rand = this.getRandomNumber(100, 200);
                //     choiceData.description = 'Gain ' + rand + ' XP';
                //     choiceData.val = rand;
                //     break;

                case 'scareEnemy':
                    choiceData.chapterName = 'How To Roar Like A Lion';
                    choiceData.description = 'Scare away a random non-boss enemy from this location.';
                    break;

                case 'addShop':
                    if (this.game.locations.length > 0) {
                        choiceData.chapterName = 'How To Negotiate With Anyone';
                        choiceData.description = 'Add an extra shop encounter in the next location.';
                    } else {
                        choiceData.chapterName = 'Ho...us...por...bies';
                        choiceData.description = 'This chapter is too faded to read.';
                    }
                    break;

                case 'increaseStartingMana':
                    rand = this.getRandomNumber(1, 10);
                    manaType = shuffle(['red', 'blue', 'green', 'all'])[0];

                    if (manaType === 'all') {
                        rand = Math.ceil(rand / 2);
                        choiceData.description = 'Start each battle with an extra ' + rand + ' of every mana.';
                    } else {
                        choiceData.description = 'Start each battle with an extra ' + rand + ' ' + manaType + ' mana.';
                    }

                    choiceData.chapterName = 'How To Start A Budget';
                    choiceData.val = rand;
                    choiceData.manaType = manaType;
                    break;

                case 'increaseStartingFocus':
                    rand = this.getRandomNumber(1, 2);
                    manaType = shuffle(['red', 'blue', 'green', 'all'])[0];

                    if (manaType === 'all') {
                        rand = 1;
                        choiceData.description = 'Gain one extra mana of every colour every turn.';
                    } else {
                        choiceData.description = 'Gain ' + rand + ' extra ' + manaType + ' mana every turn.';
                    }

                    choiceData.chapterName = 'How To Clear Your Mind';
                    choiceData.val = rand;
                    choiceData.manaType = manaType;
                    break;

                case 'increaseHandSize':
                    choiceData.chapterName = 'How To Grow Extra Fingers';
                    choiceData.description = 'Increase your card draw by one.';
                    break;
            }

            this.choices[index] = choiceData;
        }.bind(this));
    }

    getRandomNumber(min, max)
    {
        max += 1;
        return Math.floor((Math.random() * (max - min)) + min);
    }

    chooseBlessing(id)
    {
        let choice = this.choices[id];
        this.chosenChoice = choice;

        switch (choice.name) {
            case 'gainHealth':
                this.game.hp = Math.min(this.game.maxHp, this.game.hp + choice.val);
                this.game.triggerSoundEffect('/sounds/effects/heal.wav');
                break;

            case 'gainMaxHealth':
                this.game.maxHp += choice.val;
                this.game.triggerSoundEffect('/sounds/effects/heal.wav');
                break;

            case 'gainArtifact':
                if (choice.artifactId === undefined) {
                    break;
                }
                let artifact = Artifact.createFromId(choice.artifactId);
                this.game.addArtifact(artifact);
                this.game.triggerSoundEffect('/sounds/effects/purchase2.wav');
                break;

            case 'gainGold':
                this.game.gold += choice.val;
                this.game.triggerSoundEffect('/sounds/effects/coins.wav');
                break;

            case 'scareEnemy':
                let possibleEnemies = this.game.currentLocation.encounters.filter(encounter => encounter instanceof EnemyEncounter && encounter.enemy.boss === false);
                if (possibleEnemies.length > 0) {
                    let enemyEncounter = shuffle(possibleEnemies)[0];
                    this.game.currentLocation.encounters = this.game.currentLocation.encounters.filter(encounter => encounter.encounterId !== enemyEncounter.encounterId);
                    this.game.encountersEncountered++;
                    this.game.currentLocation.encountersCompleted++;
                    this.game.triggerSoundEffect('/sounds/effects/growl3.mp3');
                } else {
                    this.game.triggerSoundEffect('/sounds/effects/squeal.mp3');
                }
                break;

            case 'addShop':
                if (this.game.locations.length > 0) {
                    let highestEncounterId = Math.max(
                        this.game.locations.reduce(function (accum, location) {
                            return Math.max(accum, location.encounters.reduce(function (accum2, encounter) {
                                return Math.max(accum2, encounter.encounterId);
                            }, 0), 0);
                        }, 0),
                        this.game.currentLocation.encounters.reduce(function (accum3, encounter) {
                            return Math.max(accum3, encounter.encounterId);
                        }, 0)
                    );
                    let encounter = EncounterFactory.createShopEncounter();
                    encounter.encounterId = highestEncounterId + 1;
                    this.game.locations[0].totalEncounters++;
                    this.game.locations[0].encounters.push(encounter);
                    this.game.triggerSoundEffect('/sounds/effects/construction.mp3');
                    break;
                }

            case 'increaseStartingMana':
                if (choice.manaType === 'all') {
                    this.game.mana.red += choice.val;
                    this.game.mana.green += choice.val;
                    this.game.mana.blue += choice.val;
                } else {
                    this.game.mana[choice.manaType] += choice.val;
                }
                this.game.triggerSoundEffect('/sounds/effects/gain_mana.wav');
                break;

            case 'increaseStartingFocus':
                if (choice.manaType === 'all') {
                    this.game.manaRegen.red += choice.val;
                    this.game.manaRegen.green += choice.val;
                    this.game.manaRegen.blue += choice.val;
                } else {
                    this.game.manaRegen[choice.manaType] += choice.val;
                }
                this.game.triggerSoundEffect('/sounds/effects/focus.wav');
                break;

            case 'increaseHandSize':
                this.game.cardDraw++;
                this.game.triggerSoundEffect('/sounds/effects/generic.wav');
                break;
        }

        this.game.endEncounter(this.encounterId);
    }

    beforeEndEncounter()
    {

    }

    beforeLeaveEncounter()
    {
        let hasArtifactChoice = this.choices.filter(choice => choice.name === 'gainArtifact').length > 0;
        if (!hasArtifactChoice) {
            return;
        }

        if (this.chosenChoice.name === 'gainArtifact') {
            let artifact = Artifact.createFromId(this.chosenChoice.artifactId);

            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(artifact.id),
                        name: artifact.name,
                        columnName: 'chosenFromAncientTome'
                    }
                )
            };
            fetch('/.netlify/functions/increase-artifact-pickrate-count', requestOptions);
        } else {
            let unchosenChoice = this.choices.filter(choice => choice.name === 'gainArtifact')[0];

            let artifact = Artifact.createFromId(unchosenChoice.artifactId);

            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(artifact.id),
                        name: artifact.name,
                        columnName: 'declinedFromAncientTome'
                    }
                )
            };
            fetch('/.netlify/functions/increase-artifact-pickrate-count', requestOptions);
        }
    }

    startEncounter(game)
    {
        super.startEncounter(game);
        this.populateChoices();
        game.triggerSoundEffect('/sounds/effects/book.mp3');
    }

    getEncounterState()
    {
        let state = super.getEncounterState();
        state.blessingChoices = this.choices;

        return state;
    }
}

export default BlessingEncounter;
