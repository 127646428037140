import React from 'react';

class Statuses extends React.Component
{
    getClassName(status)
    {
        let className = "status ";
        if (status.pulse === true) {
            className += "pulse";
        }
        return className;
    }

    render()
    {
        let statuses = [];
        let className = this.getClassName;
        Object.keys(this.props.statuses).forEach(function(key) {
            let status = this.props.statuses[key];
            status.icons.forEach(function (icon) {
                let statusCounter = "";
                if (icon.val !== undefined) {
                    statusCounter = <span className="status-counter">{icon.val}</span>;
                }
                statuses.push(
                    <div className={className(icon)}>
                        <img src={icon.img} title={icon.tooltip} />
                        {statusCounter}
                        <div className="status__hover-container">
                            <div className="status__hover">
                                <h3 className="status__hover-title">{icon.name}</h3>
                                <p className="status__hover-description">{icon.tooltip}</p>
                            </div>
                        </div>
                    </div>
                );
            });
        }.bind(this));

        return (
            <div class="statuses">
                {statuses}
            </div>
        );
    }
}

export default Statuses;
