import RegenEffect from './RegenEffect';
import AttackEffect from './AttackEffect';
import PoisonEffect from './PoisonEffect';
import HealEffect from './HealEffect';
import FocusEffect from './FocusEffect';
import ManaGainEffect from './ManaGainEffect';
import ReinforceEffect from './ReinforceEffect';
import FutureEffect from './FutureEffect';
import ShieldEffect from './ShieldEffect';
import DrawEffect from './DrawEffect';
import OppDiscardEffect from './OppDiscardEffect';
import TriggersEffect from './TriggersEffect';
import CritAttackEffect from './CritAttackEffect';
import CritDamageEffect from './CritDamageEffect';
import MusterEffect from './MusterEffect';
import ClearEffect from './ClearEffect';
import StrengthenEffect from './StrengthenEffect';
import WeakenEffect from './WeakenEffect';
import DamageEffect from './DamageEffect';
import AlterEffect from './AlterEffect';
import BleedEffect from './BleedEffect';
import ArmourEffect from './ArmourEffect';
import InvulnerableEffect from './InvulnerableEffect';
import DoubleNextRedEffect from './DoubleNextRedEffect';
import DoubleNextGreenEffect from './DoubleNextGreenEffect';
import NegateEffect from './NegateEffect';
import DiscardEffect from './DiscardEffect';
import ExileAllEffect from './ExileAllEffect';
import ThornEffect from './ThornEffect';
import AddToDeckEffect from './AddToDeckEffect';
import ResurrectionEffect from './ResurrectionEffect';
import TimewalkEffect from './TimewalkEffect';
import ImmuneEffect from './ImmuneEffect';
import OppManaGainEffect from './OppManaGainEffect';
import OppDrawEffect from './OppDrawEffect';
import BurnEffect from './BurnEffect';
import PropogateDebuffsEffect from './PropogateDebuffsEffect';
import ManaSmoothingEffect from './ManaSmoothingEffect';

class EffectFactory
{
    static createEffect(key, effectData)
    {
        switch (key) {
            case 'regen':
                return new RegenEffect(key, effectData);

            case 'attack':
                return new AttackEffect(key, effectData);

            case 'damage':
                return new DamageEffect(key, effectData);

            case 'poison':
                return new PoisonEffect(key, effectData);

            case 'heal':
                return new HealEffect(key, effectData);

            case 'manaGain':
                return new ManaGainEffect(key, effectData);

            case 'focus':
                return new FocusEffect(key, effectData);

            case 'reinforce':
                return new ReinforceEffect(key, effectData);

            case 'future':
                return new FutureEffect(key, effectData);

            case 'shield':
                return new ShieldEffect(key, effectData);

            case 'draw':
                return new DrawEffect(key, effectData);

            case 'oppDiscard':
                return new OppDiscardEffect(key, effectData);

            case 'discard':
                return new DiscardEffect(key, effectData);

            case 'reinforce':
                return new ReinforceEffect(key, effectData);

            case 'triggers':
                return new TriggersEffect(key, effectData);

            case 'critAttack':
                return new CritAttackEffect(key, effectData);

            case 'critDamage':
                return new CritDamageEffect(key, effectData);

            case 'muster':
                return new MusterEffect(key, effectData);

            case 'clear':
                return new ClearEffect(key, effectData);

            case 'strengthen':
                return new StrengthenEffect(key, effectData);

            case 'weaken':
                return new WeakenEffect(key, effectData);

            case 'alter':
                return new AlterEffect(key, effectData);

            case 'bleed':
                return new BleedEffect(key, effectData);

            case 'armour':
                return new ArmourEffect(key, effectData);

            case 'invulnerable':
                return new InvulnerableEffect(key, effectData);

            case 'immune':
                return new ImmuneEffect(key, effectData);

            case 'negate':
                return new NegateEffect(key, effectData);

            case 'doubleNextRed':
                return new DoubleNextRedEffect(key, effectData);

            case 'doubleNextGreen':
                return new DoubleNextGreenEffect(key, effectData);

            case 'exileAll':
                return new ExileAllEffect(key, effectData);

            case 'thorn':
                return new ThornEffect(key, effectData);

            case 'addToDeck':
                return new AddToDeckEffect(key, effectData);

            case 'resurrection':
                return new ResurrectionEffect(key, effectData);

            case 'timewalk':
                return new TimewalkEffect(key, effectData);

            case 'oppManaGain':
                return new OppManaGainEffect(key, effectData);

            case 'oppDraw':
                return new OppDrawEffect(key, effectData);

            case 'burn':
                return new BurnEffect(key, effectData);

            case 'propogateDebuffs':
                return new PropogateDebuffsEffect(key, effectData);

            case 'manaSmoothing':
                return new ManaSmoothingEffect(key, effectData);
        }
    }
}

export default EffectFactory;
