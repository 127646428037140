import React from 'react';
import Overlay from './Overlay';

class StartingModes extends React.Component
{
    startGame(startingMode)
    {
        if (startingMode.disabled !== true) {
            this.props.chooseMode(startingMode.id);
        }
    }

    getClassNames(startingMode)
    {
        let classNames = 'starting-mode';
        if (startingMode.disabled === true) {
            classNames += ' starting-mode--disabled';
        }
        return classNames;
    }

    render()
    {
        let modes = Object.values(this.props.startingModes).map(function(startingMode) {
            return <div className="l-one-quarter" key={startingMode.id}>
                <div className={this.getClassNames(startingMode)} onClick={() => this.startGame(startingMode)}>
                    <img className="starting-mode__image" src={startingMode.image} />
                    <p className="starting-mode__name">{startingMode.name}</p>
                </div>
            </div>
        }.bind(this));

        return (
            <Overlay
                overlayClasses="starting-modes-overlay"
                title="Choose Your Class"
                display={this.props.display}
                closeOverlay={this.props.closeOverlay}
                name='startingModes'
                >
                <div className="starting-modes grid grid--flex">
                    {modes}
                </div>
            </Overlay>
        );
    }
}

export default StartingModes;
