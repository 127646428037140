import AbstractStatusEffect from "./AbstractStatusEffect";

class ThornStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Thorn';
        this.image = '/images/new/status/thorn.png';
        this.soundEffect = '/sounds/effects/thorn_trigger_2.mp3';
        this.isBuff = true;
    }

    triggerBeforeAttackEffect(context)
    {
        this.activate(context);
    }

    activate(context)
    {
        if (context.player !== this.player) {
            context.originalDamageVal = this.val;
            context.damageVal = this.val;
            context.source = {
                effect: this,
                player: this.player
            }

            let newContext = {...context};
            newContext.historySourceImage = this.image;

            context.player.takeDamage(newContext);
            this.pulse();
            this.playSoundEffect(context);
        }
    }

    generateTooltip()
    {
        return 'When attacked, deal ' + this.val + ' damage to the attacker.';
    }
}

export default ThornStatusEffect;
