import EffectFactory from '../Effects/EffectFactory';
import ConditionFactory from './Conditions/ConditionFactory';
import ValueFactory from './Values/ValueFactory';

import {artifactsData} from '../data/artifacts';

import {mergeObj2IntoObj1, shuffle} from '../Helper';

import { v4 as uuidv4 } from 'uuid';
import AbstractValue from './Values/AbstractValue';
import BlessingEncounter from '../Encounters/BlessingEncounter';
import EncounterFactory from '../Encounters/EncounterFactory';
import { GameFactory } from '../Game';
import Card from '../Card';

class Artifact
{
    constructor(data, game)
    {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.image = data.image;
        this.internalCounter = 0;
        this.expired = false;
        this.uid = uuidv4();
        this.parseEffects(data.effects);
        this.triggerPriorities = {};
        this.passiveEffects = [];
        this.unlockLevel = 1;
        if (data.unlockLevel !== this.unlockLevel) {
            this.unlockLevel = data.unlockLevel;
        }
        if (data.passiveEffects !== undefined) {
            this.passiveEffects = data.passiveEffects;
        }
        this.shouldPulse = false;
        this.goldCost = 50;
        if (data.goldCost !== undefined) {
            this.goldCost = data.goldCost;
        }
        this.isActive = false;
        this.disabled = false;
        this.triggersEnabled = true;
        this.charges = null;
        if (data.charges !== undefined) {
            this.charges = data.charges;
        }
        this.hideCounter = false;
        if (data.hideCounter !== undefined) {
            this.hideCounter = data.hideCounter;
        }
    }

    static createFromArtifactIds(artifactIds, game)
    {
        return artifactIds.map(id => Artifact.createFromId(id, game));
    }

    static createFromId(id, game)
    {
        let data = JSON.parse(JSON.stringify(artifactsData[id]));
        return new Artifact(data, game);
    }

    parseEffects(effects)
    {
        this.effects = effects.map(effect => {
            if (effect.conditions !== undefined) {
                effect.conditions = effect.conditions.map(conditionData => {
                    return ConditionFactory.createConditionFromJson(conditionData);
                });
            }
            if (effect.val !== undefined && ((effect.val instanceof AbstractValue) === false)) {
                effect.val = ValueFactory.createValueFromJson(effect.val);
            }
            return effect;
        });
    }

    disableTriggers()
    {
        this.triggersEnabled = false;
    }

    enableTriggers()
    {
        this.triggersEnabled = true;
    }

    hasPassiveEffect(passiveEffect)
    {
        return this.expired !== true && this.passiveEffects.includes(passiveEffect);
    }

    pulse()
    {
        this.shouldPulse = true;
    }

    enable(context)
    {
        this.disabled = false;

        context.game.trigger('onEnable', {...context,
            game: context.game,
            artifact: this
        });
    }

    acquire(context)
    {
        context.game.trigger('onAcquire', {...context,
            game: context.game,
            artifact: this
        });

        this.enable(context);
    }

    disable(context)
    {
        context.game.trigger('onDisable', {...context,
            game: context.game,
            artifact: this
        });

        this.disabled = true;
    }

    click(game)
    {
        if (this.disabled) {
            return;
        }

        if (this.expired) {
            return;
        }

        game.trigger('onClick', {
            game: game,
            artifact: this
        });

        if (this.charges > 0) {
            this.charges--;
        }

        if (this.charges === undefined || this.charges === 0) {
            this.expired = true;
        }
    }

    trigger(triggerName, context)
    {
        if (this.expired === false
            && this.disabled === false
            && (context.player === undefined
                || context.player.isPlayer()
            )
            && ((triggerName !== 'onEnable' && triggerName !== 'onDisable' && triggerName !== 'onAcquire' && triggerName !== 'afterActivateArtifact' && triggerName !== 'afterDeactivateArtifact')
                || context.artifact === this
            )
        ) {
            let effects = this.effects.filter(effect => effect.trigger === triggerName);
            effects.forEach(effect => this.handleEffect(effect, context));
        }
    }

    registerTriggers(triggerName, triggerManager)
    {
        let priority = 100;
        if (this.triggerPriorities[triggerName] !== undefined) {
            priority = this.triggerPriorities[triggerName];
        }
        triggerManager.registerListener(triggerName, priority, this.trigger.bind(this));
    }

    changeIsActive(bool, context)
    {
        this.isActive = bool;
        let game = GameFactory.getCurrentGame();
        context.artifact = this;

        if (bool) {
            game.trigger('afterActivateArtifact', context);
        } else {
            game.trigger('afterDeactivateArtifact', context);
        }
    }

    handleEffect(effectData, context)
    {
        mergeObj2IntoObj1(context, {artifact: this});
        context.internalCounter = this.internalCounter;

        if (!this.areConditionsMet(effectData, context)) {
            return;
        }

        if (this.triggersEnabled === false && effectData.effect !== 'enableTriggers') {
            return;
        }

        let originalSourceImage = context.historySourceImage;
        context.historySourceImage = this.image;

        let val;
        let cardId;
        let amountToAdd;
        let addToDeckEffect;
        let newContext;
        let poisonEffect;
        let musterEffect;
        let multiEffect;
        let bleedEffect;
        let weakenEffect;
        let healEffect;
        let regenEffect;
        let drawEffect;
        let player;
        let deckDestination;

        switch (effectData.effect) {
            case 'gainMaxHealth':
                context.game.maxHp += effectData.val.calculate(context);

                if (context.game.battle !== undefined) {
                    context.game.battle.player.maxHp += effectData.val.calculate(context);
                    context.game.battle.history.write(context.game.battle.player.name + ' gains ' + effectData.val.calculate(context) + ' max health', context.historySourceImage);
                }
                break;

            case 'loseMaxHealth':
                context.game.maxHp -= effectData.val.calculate(context);
                context.game.hp = Math.min(context.game.maxHp, context.game.hp);

                if (context.game.battle !== undefined) {
                    context.game.battle.player.maxHp -= effectData.val.calculate(context);
                    context.game.battle.player.hp = Math.min(context.game.battle.player.maxHp, context.game.battle.player.hp);
                    context.game.battle.history.write(context.game.battle.player.name + ' loses ' + effectData.val.calculate(context) + ' max health', context.historySourceImage);
                }
                break;

            case 'playerIncreaseDraw':
                context.game.cardDraw += effectData.val.calculate(context);
                break;

            case 'playerGainShield':
                let shieldEffect = EffectFactory.createEffect('shield', effectData.val.calculate(context));
                shieldEffect.handle(context);
                break;

            case 'playerHeal':
                if (context.game.battle !== undefined) {
                    healEffect = EffectFactory.createEffect('heal', effectData.val.calculate(context));
                    healEffect.handle(context);
                    break;
                }

                context.game.hp += effectData.val.calculate(context);
                break;

            case 'playerHealFull':
                if (context.battle !== undefined) {
                    healEffect = EffectFactory.createEffect('heal', context.battle.player.maxHp - context.battle.player.hp);
                    healEffect.handle(context);
                } else {
                    context.game.hp = context.game.maxHp;
                }
                break;

            case 'playerMuster':
                if (context.player === undefined) {
                    if (context.battle !== undefined) {
                        player = context.battle.player;
                    } else {
                        if (context.game.battle === undefined) {
                            break;
                        }
                        player = context.game.battle.player;
                    }
                    context.player = player;
                }
                musterEffect = EffectFactory.createEffect('muster', effectData.val.calculate(context));
                musterEffect.handle(context);
                break;

            case 'playerDraw':
                drawEffect = EffectFactory.createEffect('draw', effectData.val.calculate(context));
                drawEffect.handle(context);
                break;

            case 'playerRegen':
                regenEffect = EffectFactory.createEffect('regen', effectData.val.calculate(context));
                regenEffect.handle(context);
                break;

            case 'enemyMuster':
                newContext = {...context};
                newContext.game = context.game;
                newContext.battle = context.battle;
                newContext.enemy = context.battle.player;
                newContext.player = context.battle.enemy;
                musterEffect = EffectFactory.createEffect('muster', effectData.val.calculate(newContext));
                musterEffect.handle(newContext);
                break;

            case 'playerStrengthen':
                let strengthenEffect = EffectFactory.createEffect('strengthen', effectData.val.calculate(context));
                strengthenEffect.handle(context);
                break;

            case 'playerGainArmour':
                if (context.player === undefined) {
                    if (context.battle !== undefined) {
                        player = context.battle.player;
                    } else {
                        if (context.game.battle === undefined) {
                            break;
                        }
                        player = context.game.battle.player;
                    }
                    context.player = player;
                }
                let armourEffect = EffectFactory.createEffect('armour', effectData.val.calculate(context));
                armourEffect.handle(context);
                break;

            case 'playerPoison':
                newContext = {...context};
                newContext.game = context.game;
                newContext.battle = context.battle;
                newContext.enemy = context.battle.player;
                newContext.player = context.battle.enemy;
                poisonEffect = EffectFactory.createEffect('poison', effectData.val.calculate(newContext));
                poisonEffect.handle(newContext);
                break;

            case 'enemyPoison':
                context.player = context.battle.player;
                context.enemy = context.battle.enemy;
                poisonEffect = EffectFactory.createEffect('poison', effectData.val.calculate(context));
                poisonEffect.handle(context);
                break;

            case 'enemyWeaken':
                context.player = context.battle.player;
                context.enemy = context.battle.enemy;
                weakenEffect = EffectFactory.createEffect('weaken', effectData.val.calculate(context));
                weakenEffect.handle(context);
                break;

            case 'incrementCounter':
                this.internalCounter += parseInt(effectData.val.calculate(context));
                context.historySourceImage = originalSourceImage;
                break;

            case 'decrementCounter':
                this.internalCounter -= parseInt(effectData.val.calculate(context));
                context.historySourceImage = originalSourceImage;
                break;

            case 'increaseDamage':
                context.game.battle.history.write(this.name + ' increases the damage by ' + effectData.val.calculate(context), context.historySourceImage);
                context.damageVal = parseInt(context.damageVal) + parseInt(effectData.val.calculate(context));
                break;

            case 'resetCounter':
                this.internalCounter = 0;
                context.historySourceImage = originalSourceImage;
                break;

            case 'playerGainManaGreen':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' gains <green_mana>' + effectData.val.calculate(context) + '</green_mana>', context.historySourceImage);
                }
                context.player.mana.green += effectData.val.calculate(context);
                break;

            case 'playerGainManaBlue':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' gains <blue_mana>' + effectData.val.calculate(context) + '</blue_mana>', context.historySourceImage);
                }
                context.player.mana.blue += effectData.val.calculate(context);
                break;

            case 'playerGainManaRed':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' gains <red_mana>' + effectData.val.calculate(context) + '</red_mana>', context.historySourceImage);
                }
                context.player.mana.red += effectData.val.calculate(context);
                break;

            case 'playerOverrideManaGreen':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' now has <green_mana>' + effectData.val.calculate(context) + '</green_mana>', context.historySourceImage);
                }
                context.player.mana.green = effectData.val.calculate(context);
                break;

            case 'playerOverrideManaBlue':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' now has <blue_mana>' + effectData.val.calculate(context) + '</blue_mana>', context.historySourceImage);
                }
                context.player.mana.blue = effectData.val.calculate(context);
                break;

            case 'playerOverrideManaRed':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' now has <red_mana>' + effectData.val.calculate(context) + '</red_mana>', context.historySourceImage);
                }
                context.player.mana.red = effectData.val.calculate(context);
                break;

            case 'enemyGainManaGreen':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.enemy.name + ' gains <green_mana>' + effectData.val.calculate(context) + '</green_mana>', context.historySourceImage);
                }
                context.enemy.mana.green += effectData.val.calculate(context);
                break;

            case 'enemyGainManaBlue':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.enemy.name + ' gains <blue_mana>' + effectData.val.calculate(context) + '</blue_mana>', context.historySourceImage);
                }
                context.enemy.mana.blue += effectData.val.calculate(context);
                break;

            case 'enemyGainManaRed':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.enemy.name + ' gains <red_mana>' + effectData.val.calculate(context) + '</red_mana>', context.historySourceImage);
                }
                context.enemy.mana.red += effectData.val.calculate(context);
                break;

            case 'enemyOverrideManaGreen':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.enemy.name + ' now has <green_mana>' + effectData.val.calculate(context) + '</green_mana>', context.historySourceImage);
                }
                context.enemy.mana.green = effectData.val.calculate(context);
                break;

            case 'enemyOverrideManaBlue':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.enemy.name + ' now has <blue_mana>' + effectData.val.calculate(context) + '</blue_mana>', context.historySourceImage);
                }
                context.enemy.mana.blue = effectData.val.calculate(context);
                break;

            case 'enemyOverrideManaRed':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.enemy.name + ' now has <red_mana>' + effectData.val.calculate(context) + '</red_mana>', context.historySourceImage);
                }
                context.enemy.mana.red = effectData.val.calculate(context);
                break;

            case 'enemyGainHaste':
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.enemy.name + ' gains Haste, and takes the first turn', context.historySourceImage);
                }
                context.enemy.haste = effectData.val.calculate(context);
                break;

            case 'alterCard':
                let alterEffect = EffectFactory.createEffect('alter', effectData.val.calculate(context));
                alterEffect.handle(context);
                break;

            case 'cloneCard':
                val = effectData.val.calculate(context);

                if (context.card === undefined) {
                    break;
                }

                let newCard = context.card.clone();

                if (val.isTemporary) {
                    newCard.temporary = true;
                    newCard.generateDescription();
                }

                deckDestination = "deck";
                if (val.deckDestination !== undefined) {
                    deckDestination = val.deckDestination;
                }

                for (let i = 0; i < val.qty; i++) {
                    context.player.deck.addToDeck(newCard, deckDestination);
                    if (context.game.battle !== undefined) {
                        context.game.battle.history.write(newCard.name + ' has been added to your ' + deckDestination, context.historySourceImage);
                    }
                }
                break;

            case 'playerAddCardToDeck':
                val = effectData.val.calculate(context);
                cardId = val[0];
                amountToAdd = val[1];

                if (context.game.battle !== undefined) {
                    addToDeckEffect = EffectFactory.createEffect('addToDeck', [{
                        "type": "player",
                        "id": cardId,
                        "qty": amountToAdd
                    }]);

                    addToDeckEffect.handle(context);
                    break;
                }

                let qty;
                deckDestination = "deck";
                if (context.deckDestination !== undefined) {
                    deckDestination = context.deckDestination;
                }
                if (typeof amountToAdd === "number") {
                    qty = amountToAdd;
                } else {
                    qty = ValueFactory.createValueFromJson(amountToAdd).calculate(context);
                }
                for (let i = 0; i < qty; i++) {
                    let card = Card.createFromCardId(cardId);
                    context.game.deck.addToDeck(card, deckDestination);
                }

                break;

            case 'playerAddCardToHand':
                val = effectData.val.calculate(context);
                cardId = val[0];
                amountToAdd = val[1];

                context.deckDestination = "hand";

                addToDeckEffect = EffectFactory.createEffect('addToDeck', [{
                    "type": "player",
                    "id": cardId,
                    "qty": amountToAdd,
                }]);
                addToDeckEffect.handle(context);
                break;

            case 'enemyAddCardToDeck':
                val = effectData.val.calculate(context);
                cardId = val[0];
                amountToAdd = val[1];

                addToDeckEffect = EffectFactory.createEffect('addToDeck', [{
                    "type": "enemy",
                    "id": cardId,
                    "qty": amountToAdd
                }]);
                addToDeckEffect.handle(context);
                break;

            case 'playerFocusGreenPerm':
                context.game.manaRegen.green += effectData.val.calculate(context);
                // fallthrough

            case 'playerFocusGreen':
                if (context.game.battle !== undefined) {
                    context.game.battle.player.manaRegen.green += effectData.val.calculate(context);
                    context.game.battle.history.write(context.game.battle.player.name + '\'s green mana regeneration is increased by ' + effectData.val.calculate(context), context.historySourceImage);
                }
                break;

            case 'playerFocusBluePerm':
                context.game.manaRegen.blue += effectData.val.calculate(context);
                // fallthrough

            case 'playerFocusBlue':
                if (context.game.battle !== undefined) {
                    context.game.battle.player.manaRegen.blue += effectData.val.calculate(context);
                    context.game.battle.history.write(context.game.battle.player.name + '\'s blue mana regeneration is increased by ' + effectData.val.calculate(context), context.historySourceImage);
                }
                break;

            case 'playerFocusRedPerm':
                context.game.manaRegen.red += effectData.val.calculate(context);
                // fallthrough

            case 'playerFocusRed':
                if (context.game.battle !== undefined) {
                    context.game.battle.player.manaRegen.red += effectData.val.calculate(context);
                    context.game.battle.history.write(context.game.battle.player.name + '\'s red mana regeneration is increased by ' + effectData.val.calculate(context), context.historySourceImage);
                }
                break;

            case 'gainGold':
                context.game.gold += effectData.val.calculate(context);
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.game.battle.player.name + ' gains ' + effectData.val.calculate(context) + ' gold', context.historySourceImage);
                }
                break;

            case 'playerGainMaxHp':
                context.player.maxHp += effectData.val.calculate(context);
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' gains ' + effectData.val.calculate(context) + ' max health', context.historySourceImage);
                }
                break;

            case 'playerLoseMaxHp':
                context.player.maxHp -= effectData.val.calculate(context);
                context.player.hp = Math.min(context.player.maxHp, context.player.hp);
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' loses ' + effectData.val.calculate(context) + ' max health', context.historySourceImage);
                }
                break;

            case 'enemyLoseMaxHp':
                context.enemy.maxHp -= effectData.val.calculate(context);
                context.enemy.hp = Math.min(context.enemy.maxHp, context.enemy.hp);
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.enemy.name + ' loses ' + effectData.val.calculate(context) + ' max health', context.historySourceImage);
                }
                break;

            case 'changeIsActive':
                this.changeIsActive(effectData.val.calculate(context), context);
                break;

            case 'disableTriggers':
                this.disableTriggers();
                break;

            case 'enableTriggers':
                this.enableTriggers();
                break;

            case 'multiplyMultiEffect':
                multiEffect = 1;
                if (context.multiEffect !== undefined) {
                    multiEffect = context.multiEffect;
                }

                multiEffect *= effectData.val.calculate(context);
                context.multiEffect = multiEffect;
                break;

            case 'increaseExistingMultiEffect':
                if (context.card === undefined) {
                    break;
                }
                multiEffect = effectData.val.calculate(context);
                Object.keys(context.card.effects).forEach(function (key) {
                    if (context.card.effects[key]?.context?.multiEffect !== undefined) {
                        context.card.effects[key].context.multiEffect += multiEffect;
                    }
                });
                break;

            case 'reducePoison':
                let poison = context.player.getStatusEffect('poison');
                if (poison !== undefined && poison.val > 0) {
                    poison.val --;
                }
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' has their poison reduced by 1', context.historySourceImage);
                }
                break;

            case 'enemyBleed':
                bleedEffect = EffectFactory.createEffect('bleed', effectData.val.calculate(context));
                bleedEffect.handle(context);
                break;

            case 'damageEnemy':
                let enemy = context.player.isPlayer() ? context.battle.enemy : context.battle.player;
                newContext = {
                    battle: context.battle,
                    game: context.game,
                    player: enemy,
                    enemy: context.player,
                    source: context.player,
                    damageVal: effectData.val.calculate(context),
                    originalDamageVal: effectData.val.calculate(context),
                    historySourceImage: context.historySourceImage
                };
                enemy.takeDamage(newContext);
                if (newContext.damageReceived < 1) {
                    if (newContext.damageMitigated > 0) {
                        newContext.battle.game.triggerSoundEffect('/sounds/effects/hit_shield.mp3');
                    }
                } else if (newContext.damageReceived < 6) {
                    newContext.battle.game.triggerSoundEffect('/sounds/effects/light_impact.mp3');
                } else if (newContext.damageReceived < 11) {
                    newContext.battle.game.triggerSoundEffect('/sounds/effects/medium_impact.mp3');
                } else {
                    newContext.battle.game.triggerSoundEffect('/sounds/effects/heavy_impact.mp3');
                }

                if (newContext.brokeShield) {
                    newContext.battle.game.triggerSoundEffect('/sounds/effects/shield_break_3.mp3');
                }
                break;

            case 'doubleFocusAmount':
                if (context.focusAmount !== undefined) {
                    context.focusAmount *= 2;
                }
                break;

            case 'dodgeDamage':
                context.damageVal = 0;
                if (context.game.battle !== undefined && context.receivingDamage !== undefined) {
                    context.game.battle.history.write(context.receivingDamage.name + ' dodges the damage', context.historySourceImage);
                }
                break;

            case 'contextMerge':
                let contextToMerge = effectData.val.calculate(context);
                mergeObj2IntoObj1(context, contextToMerge);
                break;

            case 'addEncounterToLocation':
                let locationsToAdd = [];
                let data = effectData.val.calculate(context);

                if (typeof data.location === "number") {
                    locationsToAdd = [...context.game.locations.filter(location => location.id === data.location)];
                    if (context.game.currentLocation.id === data.location) {
                        locationsToAdd.push(context.game.currentLocation);
                    }
                } else {
                    switch (data.location) {
                        case 'futureAndCurrent':
                            locationsToAdd = [...context.game.locations, context.game.currentLocation];
                            break;
                    }
                }

                locationsToAdd.forEach(function (location) {
                    let encounter;

                    switch (data.encounterType) {
                        case 'blessing':
                            encounter = EncounterFactory.createBlessingEncounter();
                            break;
                    }

                    location.encounters.push(encounter);
                });

                break;

            case 'purgeDuplicates':
                if (context.game === undefined) {
                    return;
                }

                if (context.game.battle !== undefined) {
                    context.game.battle.player.deck.purgeDuplicates();
                }

                context.game.deck.purgeDuplicates();

                break;

            case 'gainRandomBlessing':
                let artifactIds = {...artifactsData};
                let possibleArtifacts = Object.keys(artifactIds).filter(artifactId => !context.game.hasArtifact(artifactIds[artifactId].name) && artifactIds[artifactId].pandora !== true).map((artifactId, pos) => Artifact.createFromId(artifactId));

                let artifact = shuffle(possibleArtifacts)[0];
                if (artifact) {
                    context.game.addArtifact(artifact);
                }
                break;

            case 'makeHandFreeOnce':
                context.player.deck.hand.forEach(function(card) {
                    card.addRawTriggeredEffect(
                        {
                            "trigger": "moveFromHand",
                            "effect": "alter",
                            "val": {
                                "descriptionTemplate": "",
                                "target": "self",
                                "qty": 1,
                                "action": {
                                    "costOverride": card.cost
                                }
                            }
                        }
                    );
                    card.cost = {};
                });
                if (context.game.battle !== undefined) {
                    context.game.battle.history.write(context.player.name + ' has their hand made free', context.historySourceImage);
                }
                break;

            case 'expire':
                this.expired = true;
                break;
        }

        if (effectData.singleUse === true) {
            this.expired = true;
        }

        this.pulse();
    }

    areConditionsMet(effectData, context)
    {
        if (effectData.conditions !== undefined) {
            return effectData.conditions.reduce((accum, condition) => accum && condition.isMet(context), true);
        }
        return true;
    }

    getArtifactState()
    {
        let state = {
            name: this.name,
            uid: this.uid,
            description: this.description,
            image: this.image,
            internalCounter: this.internalCounter,
            showCounter: !this.hideCounter && this.effects.filter(effect => (
                effect.effect === 'incrementCounter'
                || effect.effect === 'resetCounter'
                || effect.effect === 'decrementCounter'
            )).length > 0,
            purchased: this.purchased,
            shouldPulse: this.shouldPulse,
            goldCost: this.goldCost,
            // this is just for looking like it's expired
            expired: this.expired || this.disabled,
            isActive: this.isActive,
            canClick: this.charges !== undefined && this.charges > 0 && !this.disabled
        }

        if (this.shouldPulse === true) {
            setTimeout(function() {
                this.shouldPulse = false;
            }.bind(this), 250);
        }

        return state;
    }
}

export default Artifact;
