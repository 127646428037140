class AbstractValue
{
    constructor(data)
    {
        this.val = this.parseVal(data.val);
        this.type = data.valType;
        this.valExtra = 0;
        if (data.valExtra) {
            this.valExtra = data.valExtra;
        }
    }

    parseVal(val)
    {
        return val;
    }

    calculate(context)
    {
        if (typeof this.val === 'number') {
            return this.val + this.valExtra;
        }
        return this.val;
    }
}

export default AbstractValue;
