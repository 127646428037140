import React from 'react';
import Mana from './Mana';
import reactStringReplace from 'react-string-replace';

class HistoryListItemPlayer extends React.Component
{
    render() {
        return (
            <span class="history-list-item__player">
                {this.props.children}
            </span>
        );
    }
}

class HistoryListItemEnemy extends React.Component
{
    render() {
        return (
            <span class="history-list-item__enemy">
                {this.props.children}
            </span>
        );
    }
}

class History extends React.Component
{

    constructor(props) {
        super();
        this.state = {
            displayHistory: false
        };
        this.toggleHistory = this.toggleHistory.bind(this);
        this.messagesEndRef = React.createRef();
        this.lock = false;
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.state.displayHistory !== prevState.displayHistory) {
            this.lock = true;
            setTimeout(function() {
                this.lock = false;
            }.bind(this), 1000);
        }
        if (!this.lock && this.state.displayHistory) {
            this.messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }

    toggleHistory()
    {
        this.setState({
            displayHistory: !this.state.displayHistory
        });
    }

    getHistoryClassNames()
    {
        let classNames = "history";
        if (this.state.displayHistory === false) {
            classNames += " history--hidden";
        }
        return classNames;
    }

    getHistoryListItemClassNames(item)
    {
        let classNames = "history-list-item";
        if (item.indent !== undefined) {
            classNames += " history-list-item--indent-" + Math.min(item.indent, 4);
        }
        if (item.isHeading) {
            classNames += " history-list-item--heading";
        }
        return classNames;
    }

    transformMessage(message) {
        let transformedMessage = reactStringReplace(message, this.props.playerName, () => <HistoryListItemPlayer>{this.props.playerName}</HistoryListItemPlayer>);
        transformedMessage = reactStringReplace(transformedMessage, this.props.enemyName, () => <HistoryListItemEnemy>{this.props.enemyName}</HistoryListItemEnemy>);
        transformedMessage = reactStringReplace(transformedMessage, /<red_mana>(\d+)<\/red_mana>/, (match) => <Mana colour="red" amount={match}></Mana>);
        transformedMessage = reactStringReplace(transformedMessage, /<green_mana>(\d+)<\/green_mana>/, (match) => <Mana colour="green" amount={match}></Mana>);
        transformedMessage = reactStringReplace(transformedMessage, /<blue_mana>(\d+)<\/blue_mana>/, (match) => <Mana colour="blue" amount={match}></Mana>);
        return transformedMessage;
    }

    render() {
        let icon = !this.state.displayHistory
            ? <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M17.17,24a1,1,0,0,1-.71-.29L8.29,15.54a5,5,0,0,1,0-7.08L16.46.29a1,1,0,1,1,1.42,1.42L9.71,9.88a3,3,0,0,0,0,4.24l8.17,8.17a1,1,0,0,1,0,1.42A1,1,0,0,1,17.17,24Z"/></svg>
            : <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M7,24a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l8.17-8.17a3,3,0,0,0,0-4.24L6.29,1.71A1,1,0,0,1,7.71.29l8.17,8.17a5,5,0,0,1,0,7.08L7.71,23.71A1,1,0,0,1,7,24Z"/></svg>

        return (
            <div className={this.getHistoryClassNames()}>
                <a className="history__toggle-button" onClick={() => this.toggleHistory()}>
                    {icon}
                </a>
                <div className="history__inner">
                    <h2 className="history__title">Play History</h2>
                    <ul className="history-list">
                        {this.props.history.map((message, id) => {
                            let imgJsx = message.img
                                ? <img src={message.img} className="history-list-item__image" />
                                : '';

                            return (
                                <li className={this.getHistoryListItemClassNames(message)}>
                                    {imgJsx}
                                    <div class="history-list-item__message">
                                        {this.transformMessage(message.msg)}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    <div ref={this.messagesEndRef} />
                </div>
            </div>
        );
    }
}

export default History;
