import React from 'react';
import Manas from './Manas';

class BottomBar extends React.Component
{
    getClasses()
    {
        let classes = 'bottombar ' + this.props.classes;
        return classes;
    }

    render()
    {
        return (
            <div className={this.getClasses()}>
                <Manas
                    mana={this.props.mana}
                    regen={this.props.manaRegen}
                    />
                <div class="bottombar-hp">
                    <span class="bottombar-hp__label">Life:</span>
                    <div class="progress-wrapper">
                        <progress class="progress progress--hp-bottombar" min="0" max={this.props.maxHp} value={this.props.hp}></progress>
                        <span>{this.props.hp}/{this.props.maxHp}</span>
                    </div>
                </div>
                <div className="bottombar-deck">
                    <div className="icon icon--bottombar">
                        <a href="#" onClick={() => this.props.openOverlay('deck')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#000" strokeWidth="15.605" strokeLinecap="round" strokeLinejoin="round" stroke-miterlimit="22.9"><path d="M8.32 195.1l96.948 61.257 150.94-103.596v0"/><path d="M8.518 154.112l96.947 61.257 150.94-103.597v0"/><path d="M8.518 112.673l96.947 61.257 150.94-103.596v0M256.405 70.334L159.457 9.076 8.517 112.673v0"/></g></svg>
                        </a>
                        <span className="icon__label">{this.props.deckCount}</span>
                    </div>
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default BottomBar;
