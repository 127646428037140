import React from 'react';
import Overlay from './Overlay';
import ConfirmModal from './ConfirmModal';

class MyData extends React.Component
{
    constructor(props) {
        super();
        this.state = {
            uploadMessage: null,
            file: null,
            displayResetConfirm: false
        };
        this.closeOverlay = this.closeOverlay.bind(this);
        this.openOverlay = this.openOverlay.bind(this);
        this.resetAll = this.resetAll.bind(this);
    }

    openOverlay(overlay)
    {
        let stateProperty = 'display' + overlay.charAt(0).toUpperCase() + overlay.slice(1);
        let newStateObject = {};
        newStateObject[stateProperty] = true;

        this.setState(newStateObject);
    }

    closeOverlay(overlay)
    {
        let stateProperty = 'display' + overlay.charAt(0).toUpperCase() + overlay.slice(1);
        let newStateObject = {};
        newStateObject[stateProperty] = false;

        this.setState(newStateObject);
    }

    getDataUrl()
    {
        let data = {};
        let gameData = localStorage.getItem('game');
        let sessionData = localStorage.getItem('session');
        let versionData = localStorage.getItem('version');

        if (gameData) data.gameData = gameData;
        if (sessionData) data.sessionData = sessionData;
        if (versionData) data.versionData = versionData;

        let string = JSON.stringify(data);
        const blob = new Blob([string], { type: 'text/plain' }); // Create a BLOB object
        return window.URL.createObjectURL(blob);
    }

    changeHandler(event)
    {
        this.setState({
            file: event.target.files[0]
        });
    }

    processUpload()
    {
        let fileReader = new FileReader();
        let testImportData = this.props.testImportData;

        try {
            fileReader.readAsText(this.state.file);
            fileReader.onloadend = function(){
                try {
                    let result = fileReader.result;
                    let resultObject = JSON.parse(result);
                    testImportData(resultObject);
                    this.setState({
                        uploadMessage: "Successful upload.",
                        file: null
                    });
                } catch (e) {
                    this.setState({
                        uploadMessage: "There was an error parsing your data."
                    });
                }
            }.bind(this);
        } catch (e) {
            this.setState({
                uploadMessage: "There was an error parsing your data."
            });
        }
    }

    getDataFileUploaderLabelClass()
    {
        let className = "button data-file-uploader-label";
        if (this.state.file) {
            className += " data-file-uploader-label--hidden";
        }
        return className;
    }

    getDataFileConfirmClass()
    {
        let className = "button data-file-confirm";
        if (!this.state.file) {
            className += " data-file-confirm--hidden";
        }
        return className;
    }

    resetAll()
    {
        this.props.resetAll();
        this.closeOverlay('resetConfirm');
        this.setState({
            uploadMessage: "All data deleted."
        });
    }

    render()
    {
        let uploadJsx;
        let fileNameJsx;
        if (this.state.uploadMessage) {
            uploadJsx = <p>{this.state.uploadMessage}</p>;
        }
        if (this.state.file) {
            fileNameJsx = <span className="data-file-upload-name">{this.state.file.name}</span>
        }

        return (
            <Overlay
                overlayClasses="my-data-overlay overlay--small"
                title="My Data"
                display={this.props.display}
                closeOverlay={this.props.closeOverlay}
                name='myData'
                >
                <p>All game data is stored in your browser's local storage. As such, achievements and other data are not synced between different browsers. However, you are able to import and export your data below. Be warned that the export string can be quite long.</p>
                {uploadJsx}
                <div className="data-form">
                    <div className="data-import">
                        <label for="data" className={this.getDataFileUploaderLabelClass()}>
                            Upload Data
                        </label>
                        {fileNameJsx}
                        <input type="file" id="data" name="data" className="data-file-uploader" value={''} onChange={(e) => this.changeHandler(e)} />
                        <button className={this.getDataFileConfirmClass()} onClick={() => this.processUpload()}>Confirm Upload</button>
                    </div>
                    <div className="data-export">
                        <a className="button" href={this.getDataUrl()} download="roguequest-data.txt">Download Data</a>
                    </div>
                    <div className="data-reset">
                        <a className="button" onClick={() => this.openOverlay('resetConfirm')}>Reset Data</a>
                        <ConfirmModal
                            name="resetConfirm"
                            display={this.state.displayResetConfirm}
                            prompt="Are you sure? This will permamently erase ALL your data. Make sure you take a backup first!"
                            proceedCallback={this.resetAll}
                            cancelCallback={this.closeOverlay}
                            />
                    </div>
                </div>
            </Overlay>
        );
    }
}

export default MyData;
