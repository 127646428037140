import AbstractEncounter from "./AbstractEncounter";
import Player from '../Player';

class MimicEncounter extends AbstractEncounter
{
    constructor()
    {
        super();
        this.screen = 'battle';
        this.enemy = Player.createEnemyFromId(34);
        this.name = this.enemy.name;
        this.ctas = [
            'open',
            'leave'
        ];
    }

    startEncounter(game)
    {
        game.startFight(this.enemy);
    }

    getEncounterState()
    {
        let state = super.getEncounterState();
        state.image = "/images/new/encounters/chest.png";
        state.description = this.enemy.description;
        state.name = 'Mysterious Chest';
        return state;
    }

    beforeEndEncounter()
    {

    }
}

export default MimicEncounter;
