import AbstractEffect from "./AbstractEffect";

class ResurrectionEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let resurrectionCount = 0;
            context.player.deck.exile.forEach(card => {
                context.player.deck.move(card, 'exile', 'deck');
                resurrectionCount++;
            });
            context.player.deck.shuffleDeck();
            context.resurrectionCount = resurrectionCount;

            context.battle.game.triggerSoundEffect('/sounds/effects/resurrection.wav');
        }
    }

    generateDescription()
    {
        return 'All cards in exile are shuffled back into the deck.';
    }
}

export default ResurrectionEffect;
