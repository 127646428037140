import React from 'react';

class Overlay extends React.Component
{
    getOverlayOuterClasses()
    {
        let classes = 'overlay ' + this.props.overlayClasses;
        if (this.props.display === true) {
            classes += ' overlay--show';
        }
        return classes;
    }

    getOverlayInnerClasses()
    {
        let classes = 'overlay-inner ';
        return classes;
    }

    closeOverlay()
    {
        this.props.closeOverlay(this.props.name);
    }

    render()
    {
        let close = this.props.showClose === false
            ? ''
            : (
                <a href="#" onClick={() => this.closeOverlay()} className="icon icon--close overlay-close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#fff" strokeWidth="14.552"><path d="M249.655 15.337L15.306 249.731M15.306 15.337l234.35 234.394" strokeWidth="17.21836296"/></g></svg>
                </a>
            )
            ;

        let title = this.props.title
            ? (
                <h2 className="overlay__title">{this.props.title}</h2>
            )
            : ''
            ;

        let text = this.props.text
            ? (
                <p className="overlay__text">{this.props.text}</p>
            )
            : ''
            ;

        let actionButtons = this.props.actions
            ? this.props.actions.map(action => {
                return (
                    <button className="overlay-action" onClick={() => action['action']()}>{action.text}</button>
                );
            })
            : ''
            ;

        let actions = actionButtons
            ? (
                <div className="overlay-actions">
                    {actionButtons}
                </div>
            )
            : ''
            ;

        return (
            <div className={this.getOverlayOuterClasses()}>
                <div className={this.getOverlayInnerClasses()}>
                    {title}
                    {text}
                    {this.props.children}
                    {actions}
                    {close}
                </div>
            </div>
        );
    }
}

export default Overlay;
