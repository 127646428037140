import React from 'react';

import ShopDeckList from './ShopDeckList';

class ShopSectionGeneral extends React.Component {

    constructor(props) {
        super();
        this.state = {
            decklistAction: () => {},
            showDeckList: false
        };
        this.performAction = this.performAction.bind(this);
        this.closeDeckList = this.closeDeckList.bind(this);
        this.shopDeleteCard = this.shopDeleteCard.bind(this);
        this.shopUpgradeCard = this.shopUpgradeCard.bind(this);
    }

    getTabClasses() {
        let classes = "shop-tab";
        if (this.props.isActiveTab === true) {
            classes += " shop-tab--active";
        }
        return classes;
    }

    showDeck(action, title)
    {
        this.setState({
            decklistAction: action,
            showDeckList: true,
            deckListTitle: title
        });
    }

    performAction(id)
    {
        this.state['decklistAction'](50, id);
    }

    closeDeckList()
    {
        this.setState({
            showDeckList: false
        });
    }

    shopDeleteCard(gold, id)
    {
        this.props.encounterAction('deleteCard', gold, id);
        this.closeDeckList();
    }

    shopUpgradeCard(gold, id)
    {
        this.props.encounterAction('upgradeCard', gold, id);
        this.closeDeckList();
    }

    render() {
        return (
            <div className={this.getTabClasses()}>
                <div className="shop__sections">
                    <a className={"shop__section " + (this.props.gold < 50 ? 'shop__section--unbuyable' : '')} href="#" onClick={() => this.props.encounterAction('increaseMaxHealth', 50)}>
                        Increase Max Health
                        <div className="shop-cost">
                            <img src="/images/roguequest/money.jpeg" />
                            50
                        </div>
                    </a>
                    <a className={"shop__section " + (this.props.gold < 50 ? 'shop__section--unbuyable' : '')} href="#" onClick={() => this.props.encounterAction('increaseHealth', 50)}>
                        Heal
                        <div className="shop-cost">
                            <img src="/images/roguequest/money.jpeg" />
                            50
                        </div>
                    </a>
                    <a className={"shop__section " + (this.props.gold < 50 ? 'shop__section--unbuyable' : '')} href="#" onClick={() => this.showDeck(this.shopDeleteCard, "Delete Card")}>
                        Delete Card
                        <div className="shop-cost">
                            <img src="/images/roguequest/money.jpeg" />
                            50
                        </div>
                    </a>
                    <a className={"shop__section " + (this.props.gold < 50 ? 'shop__section--unbuyable' : '')} href="#" onClick={() => this.showDeck(this.shopUpgradeCard, "Upgrade Card")}>
                        Update Card
                        <div className="shop-cost">
                            <img src="/images/roguequest/money.jpeg" />
                            50
                        </div>
                    </a>
                </div>
                <ShopDeckList
                    action={this.performAction}
                    getDeckData={this.props.getDeckData}
                    deck={this.props.deck}
                    isActive={this.state.showDeckList}
                    title={this.state.deckListTitle}
                    closeDeckList={this.closeDeckList}
                />
            </div>
        );
    }
}

export default ShopSectionGeneral;
