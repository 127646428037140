import React from 'react';
import Card from './Card';
import Mana from './Mana';
import Manas from './Manas';
import Statuses from './Statuses';

class Battle extends React.Component
{
    isPlayable(card)
    {
        let mana = this.props.mana;
        if (card.cost.blue !== undefined && mana.blue < card.cost.blue) {
            return false;
        }
        if (card.cost.green !== undefined && mana.green < card.cost.green) {
            return false;
        }
        if (card.cost.red !== undefined && mana.red < card.cost.red) {
            return false;
        }
        return true;
    }

    getPlayersCtasClasses()
    {
        let classes = "player_ctas ";
        if (this.props.interactionDisabled) {
            classes += "player_ctas--disabled";
        }
        return classes;
    }

    getHandClasses()
    {
        let classes = "hand ";
        if (this.props.interactionDisabled) {
            classes += "hand--uninterruptable";
        }
        return classes;
    }

    getPlayerImageClassName()
    {
        let className = "player__image ";

        if (this.props.animateHurt === true) {
            className += "player__image--hurt"
        }

        return className;
    }

    getEnemyImageClassName()
    {
        let className = "enemy__image ";

        if (this.props.enemyAnimateHurt === true) {
            className += "enemy__image--hurt ";
        }

        if (this.props.enemyName === "Doppelganger") {
            className += "enemy__image--doppelganger ";
        }

        return className;
    }

    getCardClasses(card)
    {
        let classes = "card--enemy-hand";
        if (card.aboutToPlay === false) {
            classes += " card--hidden"
        }
        return classes;
    }

    render()
    {
        let enemyStatusEffects = [...this.props.enemyStatusEffects];
        enemyStatusEffects.unshift({
            "icons": [
                {
                    "img": this.props.enemyImage,
                    "tooltip": this.props.enemyEffectDescription,
                    "name": "Special Enemy Effect",
                    "pulse": this.props.enemyShouldPulse
                }
            ]
        });

        let enemyNumbers = this.props.enemyNumbers;
        enemyNumbers = Object.values(enemyNumbers).map(function(number) {
            let className = "number number--" + number.type;
            return (
                <span className={className} key={number.id}>{number.val}</span>
            );
        });

        let playerNumbers = this.props.numbers;
        playerNumbers = Object.values(playerNumbers).map(function(number) {
            let className = "number number--" + number.type;
            return (
                <span className={className} key={number.id}>{number.val}</span>
            );
        });

        return (
            <div className="battle">
                <div className="enemy">
                    <div className="enemy-visual">
                        <div className={this.getEnemyImageClassName()}>
                            {enemyNumbers}
                            <img src={this.props.enemyImage} />
                        </div>
                        <div className="enemy-hand">
                            {this.props.enemyHand.map((card, handPos) => {
                                return (
                                    <div className="card-enemy-hand-wrapper">
                                        <Card
                                            cardClasses={this.getCardClasses(card)}
                                            id={card.id}
                                            key={card.uid}
                                            uid={card.uid}
                                            name={card.name}
                                            image={card.image}
                                            description={card.description}
                                            tier={card.tier}
                                            cost={card.cost}
                                            goldCost={card.goldCost}
                                            playCard={() => {}}
                                            addUnplayableClass={false}
                                            showBackFace={true}
                                            aboutToPlay={card.aboutToPlay}
                                            animationSpeed={this.props.animationSpeed}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="enemy-info">
                        <div className="enemy-health">
                            <span className="enemy-health-label">Life: </span>
                            <div className="progress-wrapper">
                                <progress className="progress" min="0" max={this.props.enemyMaxHp} value={this.props.enemyHp}></progress>
                                <span>{this.props.enemyHp}/{this.props.enemyMaxHp}</span>
                            </div>
                        </div>
                        <Manas
                            mana={this.props.enemyMana}
                            regen={this.props.enemyManaRegen}
                            />
                        <Statuses
                            statuses={enemyStatusEffects}
                            />
                    </div>
                </div>
                <div className="player">
                    <div className={this.getPlayerImageClassName()}>
                        {playerNumbers}
                        <img src={this.props.image} />
                    </div>
                    <div class="player__info">
                        <Statuses
                            statuses={this.props.statusEffects}
                            />
                        <div className={this.getPlayersCtasClasses()}>
                            <button disabled={this.props.interactionDisabled} onClick={() => this.props.endTurn()}>End Turn</button>
                        </div>
                    </div>
                </div>
                <div className={this.getHandClasses()}>
                    {this.props.hand.map((card, handPos) => {
                        let transformPos = handPos * 40;
                        let transform = {
                            transform: "translate(" + transformPos + "px, -100%)"
                        };
                        return (
                            <Card
                                styles={transform}
                                cardClasses="card--hand"
                                id={card.id}
                                key={card.uid}
                                uid={card.uid}
                                name={card.name}
                                image={card.image}
                                description={card.description}
                                tier={card.tier}
                                cost={card.cost}
                                goldCost={card.goldCost}
                                playCard={this.props.playCard}
                                addUnplayableClass={!this.isPlayable(card)}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default Battle;
