import AbstractEncounter from "./AbstractEncounter";

class NextLocationEncounter extends AbstractEncounter
{
    constructor()
    {
        super();
        this.name = 'New Location Discovered';
        this.buttonText = 'Continue...';
        this.image = '/images/new/encounters/next.png';
        this.ctas = ['next-location'];
    }

    startEncounter(game)
    {
        game.nextLocation();
        game.triggerSoundEffect('/sounds/effects/warp.mp3');
    }

    beforeEndEncounter()
    {

    }
}

export default NextLocationEncounter;
