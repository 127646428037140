import { GameFactory } from "../../Game";
import AbstractValue from "./AbstractValue";

class AttributeValue extends AbstractValue
{
    calculate(context)
    {
        return this.getPropertyValueFromContext(context, this.val);
    }

    getPropertyValueFromContext(context, property)
    {
        if (context.game === undefined) {
            context.game = GameFactory.getCurrentGame();
        }
        switch (property) {
            case 'goldGain':
                return context.goldGain;

            case 'gold':
                return context.game.gold;

            case 'playerMaxHp':
                if (context.player !== undefined) {
                    return context.player.maxHp;
                }
                if (context.game.battle !== undefined) {
                    return context.game.battle.player.maxHp;
                }
                return context.game.maxHp;

            case 'playerHp':
                if (context.player !== undefined) {
                    return context.player.hp;
                }
                if (context.game.battle !== undefined) {
                    return context.game.battle.player.hp;
                }
                return context.game.hp;

            case 'playerGreenMana':
                return context.player.mana.green;

            case 'playerRedMana':
                return context.player.mana.red;

            case 'playerBlueMana':
                return context.player.mana.blue;

            case 'enemyGreenMana':
                return context.enemy.mana.green;

            case 'enemyRedMana':
                return context.enemy.mana.red;

            case 'enemyBlueMana':
                return context.enemy.mana.blue;

            case 'cardCostBlue':
                return context?.card?.cost?.blue ?? 0;

            case 'cardCostGreen':
                return context?.card?.cost?.green ?? 0;

            case 'cardCostRed':
                return context?.card?.cost?.red ?? 0;

            case 'cardCost':
                if (context?.card?.cost !== undefined) {
                    return Object.values(context.card.cost).reduce((a, b) => a + b, 0);
                }
                return 0;

            case 'internalCounter':
                return context.internalCounter;

            case 'handCount':
                return context.player.deck.hand.length;

            case 'cardExiled':
                return context.card.deck.exile.filter(card => card.uid == context.card.uid).length > 0 ? 1 : 0;

            case 'playerDraw':
                return context.player.cardDraw;

            case 'playerToBeDiscarded':
                return context.player.toBeDiscarded;

            case 'enemyDraw':
                return context.enemy.cardDraw;

            case 'enemyToBeDiscarded':
                return context.enemy.toBeDiscarded;

            case 'selfInflictedDamage':
                return context.dealingDamage === context.receivingDamage && context.dealingDamage !== undefined;

            case 'turnCount':
                return context.battle.turnCount;

            case 'isBoss':
                return context.battle.enemy.boss;

            case 'artifactIsActive':
                if (context.artifact === undefined) {
                    return false;
                }
                return context.artifact.isActive;

            case 'contextKeys':
                return Object.keys(context);

            case 'cardCostTypes':
                if (context.card === undefined) {
                    return [];
                }
                return Object.keys(context.card.cost).filter(colour => context.card.cost[colour] > 0);

            case 'cardEffects':
                if (context.card === undefined) {
                    return [];
                }
                return Object.keys(context.card.effects);

            case 'numberOfDebuffs':
                if (context.player === undefined) {
                    return 0;
                }
                return Object.values(context.player.statusEffects).filter(statusEffect => statusEffect.isDebuff === true && statusEffect.val > 0).length;

            case 'enemyNumberOfDebuffs':
                if (context.enemy === undefined) {
                    return 0;
                }
                return Object.values(context.enemy.statusEffects).filter(statusEffect => statusEffect.isDebuff === true && statusEffect.val > 0).length;

            case 'numberOfBuffs':
                if (context.player === undefined) {
                    return 0;
                }
                return Object.values(context.player.statusEffects).filter(statusEffect => statusEffect.isBuff === true && statusEffect.val > 0).length;

            case 'enemyNumberOfBuffs':
                if (context.enemy === undefined) {
                    return 0;
                }
                return Object.values(context.enemy.statusEffects).filter(statusEffect => statusEffect.isBuff === true && statusEffect.val > 0).length;

            case 'numberOfArtifacts':
                return context.battle.game.artifacts.length;

            case 'numberOfTierThreeOrGreaterCardsInDeck':
                return context.game.deck.deck.filter(card => card.tier >= 3).length;

            case 'enemyId':
                return context.game.battle.enemy.id;

            case 'cardsInDeck':
                if (context.player !== undefined) {
                    return context.player.deck.deck.length;
                }
                return context.game.deck.deck.length;

            case 'cardsInGameDeck':
                console.log(context.game.deck.deck.length);
                return context.game.deck.deck.length;

            case 'startingMode':
                return context.game.modeId;

            default:
                if (context[property] !== undefined) {
                    return context[property];
                }

                if (context.game.battle !== undefined) {
                    let player;
                    let enemy;
                    if (context.player !== undefined) {
                        player = context.player;
                    } else {
                        player = context.game.battle.player;
                    }
                    if (context.enemy !== undefined) {
                        enemy = context.enemy;
                    } else {
                        enemy = player.isPlayer() ? context.game.battle.enemy : context.game.battle.player
                    }

                    if (property.slice(0, 5) === 'enemy') {
                        property = property.slice(5).charAt(0).toLowerCase() + property.slice(6);
                        return enemy.getStatusEffect(property).val
                    }
                    return player.getStatusEffect(property).val;
                }
                return 0;
                // throw new Error('Could not parse property value');
        }
    }
}

export default AttributeValue;
