import Achievement from "./Achievement";
import { achievementsData } from "./data/achievements";

class Achievements
{
    constructor()
    {
        this.achievementsUnlocked = [];

        this.achievements = [];

        this.loadAll();
    }

    static loadFromSession(sessionData)
    {
        let achievements = new Achievements();
        achievements.setAchievementsUnlocked(sessionData.achievements);
        achievements.loadAll();
        return achievements;
    }

    getSessionData()
    {
        return {
            achievements: this.achievementsUnlocked,
        };
    }

    getState()
    {
        return this.achievements.map(achievement => achievement.getState());
    }

    setAchievementsUnlocked(achievementsUnlocked)
    {
        this.achievementsUnlocked = achievementsUnlocked;
    }

    loadAll(game = null)
    {
        let achievements = [];
        let achievementsDataLoaded = {...achievementsData};
        Object.keys(achievementsDataLoaded).forEach(function (achievementId) {
            let achievement = Achievement.createFromAchievementId(achievementId);
            if (this.achievementsUnlocked.includes(parseInt(achievementId))) {
                achievement.unlocked = true;
            }
            achievement.game = game;
            achievement.achievements = this;
            achievements.push(achievement);
        }.bind(this));

        this.achievements = achievements;
    }

    registerTriggers(triggerName, triggerManager)
    {
        if (this.getListeners().length > 0) {
            this.getListeners().forEach(listener => {
                listener.registerTriggers(triggerName, triggerManager)
            });
        }
    }

    getListeners()
    {
        return this.achievements.filter(achievement => achievement.unlocked === false);
    }

    unlock(id)
    {
        this.achievementsUnlocked.push(id);
    }
}

export default Achievements;
