import React from 'react';

import { compressToBase64, decompressFromBase64 } from 'lz-string'

import Game, { GameFactory } from './Game';
import BattleScreen from './NewComponents/BattleScreen';
import Homepage from './NewComponents/Homepage';
import PlayScreen from './NewComponents/PlayScreen';
import Unpacker from './Unpacker';

import {modeData} from './data/startingModes';

import homeBackgroundMusic from './sounds/home.mp3';
import Cheats from './NewComponents/CheatsBar';
import Experience from './Experience';
import Achievements from './Achievements';
import Stats from './Stats';
import { cardsData } from './data/cards-new';
import { artifactsData } from './data/artifacts';
import { enemiesData } from './data/enemies-new';
import Card from './Card';
import Artifact from './Artifacts/Artifact';
import Player from './Player';
import Overlay from './NewComponents/Overlay';
import { compareVersions } from 'compare-versions';

class NewApp extends React.Component
{
	constructor(props) {
		super(props);
        var jsonVersion = require('../package.json').version;

		this.experience = new Experience();
		this.achievements = new Achievements();
		this.stats = new Stats();
		this.version = jsonVersion;
		this.state = {
			screen: 'home',
            backgroundMusicVol: 50,
			soundEffectVol: 50,
			animationSpeed: "normal",
			showCheatsBar: false,
			experience: this.experience.getState(),
			achievements: this.achievements.getState(),
			stats: this.stats.getState()
		};
		this.startNewGame = this.startNewGame.bind(this);
		this.continueGame = this.continueGame.bind(this);
		this.playCard = this.playCard.bind(this);
		this.endTurn = this.endTurn.bind(this);
		this.retire = this.retire.bind(this);
		this.surrender = this.surrender.bind(this);
		this.chooseCard = this.chooseCard.bind(this);
		this.startEncounter = this.startEncounter.bind(this);
		this.endEncounter = this.endEncounter.bind(this);
		this.closeEncounter = this.closeEncounter.bind(this);
		this.backToHome = this.backToHome.bind(this);
		this.setShopTab = this.setShopTab.bind(this);
		this.triggerEncounterAction = this.triggerEncounterAction.bind(this);
		this.leaveEncounter = this.leaveEncounter.bind(this);
		this.changeVolume = this.changeVolume.bind(this);
		this.changeSoundVolume = this.changeSoundVolume.bind(this);
		this.setAnimationSpeed = this.setAnimationSpeed.bind(this);
		this.chooseMode = this.chooseMode.bind(this);
		this.clickArtifact = this.clickArtifact.bind(this);
		this.updateState = this.updateState.bind(this);
		this.claimXp = this.claimXp.bind(this);
		this.testImportData = this.testImportData.bind(this);
		this.loadSessionFromStringAndVersion = this.loadSessionFromStringAndVersion.bind(this);
		this.loadGameFromStringAndVersion = this.loadGameFromStringAndVersion.bind(this);
		this.updateState = this.updateState.bind(this);
		this.resetAll = this.resetAll.bind(this);
		this.uninterruptable = false;
		this.soundEffects = {};

		this.backgroundMusicBattleUrl = '';
		this.backgroundMusicPlayUrl = '';

		this.homeBackgroundMusic = new Audio(homeBackgroundMusic);
		this.homeBackgroundMusic.loop = 1;
		this.homeBackgroundMusic.addEventListener('ended', function () {
		  this.currentTime = 0;
		  this.play();
		}, false);

		this.audioPlayer = this.homeBackgroundMusic;
	}

	componentDidMount()
	{
		this.loadGame();
		this.loadSession();
	}

	resetAll()
	{
		localStorage.clear();
		delete this.game;
		this.experience = new Experience();
		this.achievements = new Achievements();
		this.stats = new Stats();
		this.setState({
			screen: 'home',
            backgroundMusicVol: 50,
			soundEffectVol: 50,
			animationSpeed: "normal",
			showCheatsBar: false,
			experience: this.experience.getState(),
			achievements: this.achievements.getState(),
			stats: this.stats.getState(),
			game: null
		});
		// Simple POST request with a JSON body using fetch
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(
				{
					msg: "hi"
				}
			)
		};
		fetch('/.netlify/functions/send-data', requestOptions);
	}

	componentDidUpdate(prevProps, prevState) {
		this.playstate = this.state.playstate;
		this.gamestate = this.state.gamestate;
		// this.stats = this.state.stats;


		if (this.state.game_currentLocation && (this.state.game_currentLocation.backgroundMusicBattle !== this.backgroundMusicBattleUrl)) {
			this.backgroundMusicBattleUrl = this.state.game_currentLocation.backgroundMusicBattle;
			this.battleBackgroundMusic = new Audio(this.backgroundMusicBattleUrl);
			this.battleBackgroundMusic.addEventListener('ended', function () {
			  this.currentTime = 0;
			  this.play();
			}, false);
		}

		let newLocation = false;

		if (this.state.game_currentLocation && (this.state.game_currentLocation.backgroundMusicPlay !== this.backgroundMusicPlayUrl)) {
			this.backgroundMusicPlayUrl = this.state.game_currentLocation.backgroundMusicPlay;
			this.playBackgroundMusic = new Audio(this.backgroundMusicPlayUrl);
			this.playBackgroundMusic.addEventListener('ended', function () {
			  this.currentTime = 0;
			  this.play();
			}, false);
			newLocation = true;
		}

		if (this.state.screen !== prevState.screen
			|| (this.state.screen === 'game' && prevState.game_screen !== this.state.game_screen)
			|| newLocation
		) {
			this.audioPlayer.currentTime = 0;

			if (this.state.screen === 'home') {
				this.audioPlayer.pause();
				this.audioPlayer = this.homeBackgroundMusic;
				this.audioPlayer.volume = (this.state.backgroundMusicVol / 100);
				this.audioPlayer.play();
			} else if (this.state.screen === 'game' && this.state.game_screen === 'play') {
				this.audioPlayer.pause();
				this.audioPlayer = this.playBackgroundMusic;
				this.audioPlayer.volume = (this.state.backgroundMusicVol / 100);
				this.audioPlayer.play();
			} else if (this.state.screen === 'game' && this.state.game_screen === 'battle') {
				this.audioPlayer.pause();
				this.audioPlayer = this.battleBackgroundMusic;
				this.audioPlayer.volume = (this.state.backgroundMusicVol / 100);
				this.audioPlayer.play();
			}
		}

		if (this.state.player_animateHurt || this.state.enemy_animateHurt) {
			setTimeout(function() {
				this.updateState();
			}.bind(this), 550);
		}

		if (this.state.game_artifacts && this.state.game_artifacts.filter(artifact => artifact.shouldPulse).length > 0) {
			setTimeout(function() {
				this.updateState();
			}.bind(this), 300);
		}

		let currentSoundEffects = this.state.game_soundEffects instanceof Array ? this.state.game_soundEffects : [];
		let newSoundEffects = currentSoundEffects.filter(x => !Object.keys(this.soundEffects).includes(x));

		if (this.state.backgroundMusicVol !== prevState.backgroundMusicVol
			|| this.state.animationSpeed !== prevState.animationSpeed
			|| this.state.soundEffectVol !== prevState.soundEffectVol
		) {
			this.saveSession();
		}

		newSoundEffects.forEach(soundEffect => this.playSoundEffect(soundEffect));
	}

	toggleCheats()
	{
		this.setState({
			showCheatsBar: !this.state.showCheatsBar
		});
	}

	claimXp()
	{
		this.game.claimXp();
		this.saveSession();
		this.updateState();
	}

	playSoundEffect(soundEffect)
	{
		let audioPlayer = new Audio(soundEffect);
		audioPlayer.volume = (this.state.soundEffectVol / 100);
		this.soundEffects[soundEffect] = (audioPlayer);
		audioPlayer.play();
		audioPlayer.addEventListener("ended", function() {
			delete this.soundEffects[soundEffect];
	   	}.bind(this));
	}

	changeVolume(vol)
	{
		this.audioPlayer.volume = (vol / 100);
        this.setState({
            backgroundMusicVol: vol
        });
        this.audioPlayer.play();
	}

	changeSoundVolume(vol)
	{
        this.setState({
            soundEffectVol: vol
        });
	}

	loadGame()
	{
		if (localStorage.getItem("game") !== null) {
			let version = localStorage.getItem("version") ?? 'v0.3.3.2';
			let gameString = localStorage.getItem("game");
			this.loadGameFromStringAndVersion(gameString, version);
		}
	}

	loadGameFromStringAndVersion(string, version)
	{
		let unpacker = new Unpacker;
		if (compareVersions(version, '0.3.4') >= 0) {
			string = decompressFromBase64(string);
		}
		let game = unpacker.deserialize(string, version);
		game.version = this.version;
		GameFactory.setCurrentGame(game);
		this.game = game;
		this.updateState();
	}

	deleteGame()
	{
		localStorage.removeItem("game");
	}

	loadSession()
	{
		if (localStorage.getItem("session") !== null) {
			let sessionString = localStorage.getItem("session");
			let version = localStorage.getItem("version") ?? 'v0.3.3.2';
			this.loadSessionFromStringAndVersion(sessionString, version);
		}
	}

	loadSessionFromStringAndVersion(string, version)
	{
		if (compareVersions(version, '0.3.4') >= 0) {
			string = decompressFromBase64(string);
		}
		let session = JSON.parse(string);
		this.setState({
			backgroundMusicVol: session.backgroundMusicVol,
			soundEffectVol: session.soundEffectVol,
			animationSpeed: session.animationSpeed
		});
		let experience = session.experience;
		this.experience = Experience.loadFromSession(experience);
		let achievements = session.achievements;
		this.achievements = Achievements.loadFromSession(achievements);
		let stats = session.stats;
		this.stats = Stats.loadFromSessionData(stats);
		this.updateState();
	}

	saveSession()
	{
		let session = {
			backgroundMusicVol: this.state.backgroundMusicVol,
			soundEffectVol: this.state.soundEffectVol,
			animationSpeed: this.state.animationSpeed,
			experience: this.experience.getSessionData(),
			achievements: this.achievements.getSessionData(),
			stats: this.stats.getSessionData()
		};
		localStorage.setItem("session", compressToBase64(JSON.stringify(session)));
	}

	testImportData(object)
	{
		let backupGame = localStorage.getItem('game');
		let backupSession = localStorage.getItem('session');
		let backupVersion = localStorage.getItem('version');

		let session = object.sessionData;
		let version = object.versionData ?? 'v0.3.3.2';
		let game = object.gameData;

		try {
			if (session) {
				this.loadSessionFromStringAndVersion(session, version);
			}
			if (game) {
				this.loadGameFromStringAndVersion(game, version);
			}
			this.saveSession();
			this.game?.saveGame();
		} catch (e) {
			this.loadSessionFromStringAndVersion(backupSession, backupVersion);
			this.loadGameFromStringAndVersion(backupGame, backupVersion);
			return false;
		}

		return true;
	}

	continueGame()
	{
		this.updateState();
		this.setState({
			screen: "game"
		});
	}

	startNewGame(id)
	{
		this.game = new Game(id, this.achievements, this.experience, this.stats);
		this.updateState();
		this.setState({
			screen: "game"
		});
	}

	chooseMode(id)
	{
		this.startNewGame(id);
	}

	backToHome()
	{
		this.setState({
			screen: "home"
		});
	}

	clickArtifact(id)
	{
		this.game.clickArtifact(id);
		this.updateState();
	}

	startEncounter(id)
	{
		this.game.handleEncounter(id);
		this.uninterruptable = false;
		this.updateStateFromEnemyTurn();
	}

	leaveEncounter(id)
	{
		this.game.endEncounter(id);
		this.updateState();
	}

	closeEncounter()
	{
		this.game.closeEncounter();
		this.updateState();
	}

	setShopTab(tab)
	{
		this.game.currentEncounter.setShopTab(tab);
		this.updateState();
	}

	updateState()
	{
		if (this.game !== undefined) {
            let gamestate = this.game.getState();
            this.setState(gamestate);
        }
		let experiencestate = this.experience.getState();
		this.setState({
			"experience": experiencestate
		});
		let achievementsstate = this.achievements.getState();
		this.setState({
			"achievements": achievementsstate
		});
		let statsstate = this.stats.getState();
		this.setState({
			"stats": statsstate
		});
	}

	continueEnemyTurn()
	{
		if (this.game.battle !== undefined) {
			this.game.battle.continueEnemyTurn();
			this.updateStateFromEnemyTurn();
		}
	}

	updateStateFromEnemyTurn()
	{
		if (this.game?.battle?.enemyTurn === true && this.game?.battle?.showWinScreen === false && this.game?.battle?.showLoseScreen === false) {
			this.uninterruptable = true;
			setTimeout(this.continueEnemyTurn.bind(this), this.getPauseBetweenEnemyCards());
		} else {
			this.uninterruptable = false;
		}
		this.updateState();
	}

	getPauseBetweenEnemyCards()
	{
		let animationSpeedMapping = {
			"slow": 1500,
			"normal": 1000,
			"fast": 750,
			"vfast": 500
		};

		if (animationSpeedMapping[this.state.animationSpeed] === undefined) {
			this.setState({
				animationSpeed: "normal"
			});
			return 1000;
		}

		return animationSpeedMapping[this.state.animationSpeed];
	}

	endTurn() {
		if (this.uninterruptable !== true) {
			this.game.battle.endTurnAction();
			this.updateStateFromEnemyTurn();
		}
	}

	surrender()
	{
		this.game.battle.surrender();
		this.updateState();
		this.saveSession();
	}

	retire()
	{
		this.deleteGame();
		delete this.game;
		this.setState({
			screen: 'home'
		});
		this.saveSession();
	}

	chooseCard(id)
	{
		this.game.battle.chooseCard(id);
		this.game.endBattle();
		this.updateState();
	}

	endEncounter()
	{
		if (this.game.battle !== undefined) {
			this.game.endBattle();
		} else {
			this.game.endEncounter(this.game.currentEncounter.encounterId);
		}
		this.updateState();
	}

	playCard(uid)
	{
		if (this.uninterruptable !== true) {
			this.game.battle.playCard('player', uid);
			this.updateState();
		}
	}

	triggerEncounterAction(...params)
	{
		this.game.currentEncounter.handleAction(...params);
		this.updateState();
	}

	setAnimationSpeed(speed)
	{
		this.setState({
			animationSpeed: speed
		});
	}

	render()
	{
		window.addEventListener('click', (event) => {
			let isClickable = event.composedPath().filter(function(domNode) {
				return domNode.tagName === 'A' || domNode.tagName === 'BUTTON';
			}).length > 0;
			if (isClickable) {
				this.playSoundEffect('/sounds/effects/click.wav');
			}
			event.stopImmediatePropagation();
		});

		window.addEventListener('keydown', (event) => {
			if (event.key === '~') {
				this.toggleCheats();
			}
			event.stopImmediatePropagation();
		})

		let cheatsJsx = this.state.showCheatsBar
			? <Cheats game={this.game} updateState={this.updateState}></Cheats>
			: '';

        switch (this.state.screen) {
            case 'home':
				let compendiumCards = {...cardsData};
				let compendiumArtifacts = {...artifactsData};
				let compendiumEnemies = {...enemiesData};

				let cards = Object.keys(compendiumCards).map(id => Card.createFromCardId(id));
				let artifacts = Object.keys(compendiumArtifacts).map(id => Artifact.createFromId(id));
				let enemies = Object.keys(compendiumEnemies).map(id => compendiumEnemies[id]);
				cards.sort((a, b) => a.id < b.id ? 1 : -1).sort((a, b) => a.unlockLevel > b.unlockLevel ? 0 : -1);
				artifacts.sort((a, b) => a.id < b.id ? 1 : -1).sort((a, b) => a.unlockLevel > b.unlockLevel ? 0 : -1);
				enemies.sort((a, b) => a.level > b.level ? 1 : -1).sort((a, b) => a.unlockLevel > this.state.experience.level || b.unlockLevel > this.state.experience.level ? (a.unlockLevel > b.unlockLevel ? 1 : -1) : 0);


				return (
					<div>
						<Homepage
							chooseMode={this.chooseMode}
							canContinueGame={this.game instanceof Game}
							continueGame={this.continueGame}
							changeVolume={this.changeVolume}
							changeSoundVolume={this.changeSoundVolume}
							backgroundMusicVol={this.state.backgroundMusicVol}
							soundEffectVol={this.state.soundEffectVol}
							setAnimationSpeed={this.setAnimationSpeed}
							animationSpeed={this.state.animationSpeed}
							startingModes={modeData}
							achievements={this.state.achievements}
							experience={this.state.experience}
							stats={this.state.stats}
							compendiumCards={cards}
							compendiumArtifacts={artifacts}
							compendiumEnemies={enemies}
							testImportData={this.testImportData}
							resetAll={this.resetAll}
							/>
						{cheatsJsx}
					</div>
				)

			case 'game':
				switch (this.state.game_screen) {
					case 'play':
						return (
							<div>
								<PlayScreen
									encounters={this.state.game_encounters}
									gold={this.state.game_gold}
									hp={this.state.game_hp}
									maxHp={this.state.game_maxHp}
									deck={this.state.game_deck}
									mana={this.state.game_mana}
									manaRegen={this.state.game_manaRegen}
									currentLocation={this.state.game_currentLocation}
									startEncounter={this.startEncounter}
									leaveEncounter={this.leaveEncounter}
									endEncounter={this.endEncounter}
									chestCardRewards={this.state.game_currentEncounter?.cardRewards}
									blessingChoices={this.state.game_currentEncounter?.blessingChoices}
									pandoraChoices={this.state.game_currentEncounter?.pandoraChoices}
									displayChestCardChoice={this.state.game_currentEncounter?.cardRewards !== undefined}
									triggerEncounterAction={this.triggerEncounterAction}
									changeVolume={this.changeVolume}
									changeSoundVolume={this.changeSoundVolume}
									backgroundMusicVol={this.state.backgroundMusicVol}
									soundEffectVol={this.state.soundEffectVol}
									artifacts={this.state.game_artifacts}
									progress={this.state.game_progress}
									shouldDisplayShop={this.state.game_currentEncounter?.name === 'Shop'}
									shouldDisplayBlessing={this.state.game_currentEncounter?.name === 'Ancient Tome'}
									shouldDisplayPandora={this.state.game_currentEncounter?.name === 'Grimoire'}
									postChoiceMessage={this.state.game_currentEncounter?.postChoiceMessage}
									cardsForPurchase={this.state.game_currentEncounter?.cardsForPurchase}
									cardsPurchased={this.state.game_currentEncounter?.cardsPurchased}
									artifactsForPurchase={this.state.game_currentEncounter?.artifactsForPurchase}
									artifactsPurchased={this.state.game_currentEncounter?.artifactsPurchased}
									servicesPurchased={this.state.game_currentEncounter?.servicesPurchased}
									generalWareCost={this.state.game_currentEncounter?.generalWareCost}
									discountPercentage={this.state.game_currentEncounter?.discountPercentage}
									closeEncounter={this.closeEncounter}
									setAnimationSpeed={this.setAnimationSpeed}
									animationSpeed={this.state.animationSpeed}
									clickArtifact={this.clickArtifact}
									setShopTab={this.setShopTab}
									/>
								{cheatsJsx}
							</div>
						);

					case 'battle':
						return (
							<div>
								<BattleScreen
									currentLocation={this.state.game_currentLocation}
									gold={this.state.game_gold}
									playCard={this.playCard}
									surrender={this.surrender}
									endTurn={this.endTurn}
									hp={this.state.player_hp}
									maxHp={this.state.player_maxHp}
									mana={this.state.player_mana}
									manaRegen={this.state.player_manaRegen}
									statusEffects={this.state.player_statusEffects}
									artifacts={this.state.player_artifacts}
									name={this.state.player_name}
									image={this.state.player_image}
									hand={this.state.player_deck.hand}
									deck={this.state.player_deck.deck}
									discard={this.state.player_deck.discard}
									exile={this.state.player_deck.exile}
									animateHurt={this.state.player_animateHurt}
									shouldPulse={this.state.player_shouldPulse}
									numbers={this.state.player_numbers}
									enemyDeck={this.state.enemy_deck.deck}
									enemyHand={this.state.enemy_deck.hand}
									enemyDiscard={this.state.enemy_deck.discard}
									enemyExile={this.state.enemy_deck.exile}
									enemyHp={this.state.enemy_hp}
									enemyMaxHp={this.state.enemy_maxHp}
									enemyMana={this.state.enemy_mana}
									enemyManaRegen={this.state.enemy_manaRegen}
									enemyStatusEffects={this.state.enemy_statusEffects}
									enemyArtifacts={this.state.enemy_artifacts}
									enemyName={this.state.enemy_name}
									enemyImage={this.state.enemy_image}
									enemyEffectDescription={this.state.enemy_effectDescription}
									enemyAnimateHurt={this.state.enemy_animateHurt}
									enemyShouldPulse={this.state.enemy_shouldPulse}
									enemyNumbers={this.state.enemy_numbers}
									history={this.state.battle_history}
									isGameWon={this.state.battle_showWinScreen === true}
									isGameLost={this.state.battle_showLoseScreen === true}
									cardRewards={this.state.battle_cardRewards}
									chooseCard={this.chooseCard}
									retire={this.retire}
									endEncounter={this.endEncounter}
									isGameFinish={((this.state.battle_showWinScreen === true) && (this.state.game_futureLocationsCount === 0 && this.state.enemy_boss === true))}
									changeVolume={this.changeVolume}
									changeSoundVolume={this.changeSoundVolume}
									soundEffectVol={this.state.soundEffectVol}
									backgroundMusicVol={this.state.backgroundMusicVol}
									interactionDisabled={this.uninterruptable}
									setAnimationSpeed={this.setAnimationSpeed}
									animationSpeed={this.state.animationSpeed}
									clickArtifact={this.clickArtifact}
									xpBreakdown={this.state.game_xpBreakdown}
									currentXp={this.state.experience.xp}
									currentLevel={this.state.experience.level}
									currentXpRemaining={this.state.experience.xpRemaining}
									currentPercentProgress={this.state.experience.percentProgress}
									finishCardsUnlocked={this.state.game_finishCardsUnlocked}
									finishArtifactsUnlocked={this.state.game_finishArtifactsUnlocked}
									finishEnemiesUnlocked={this.state.game_finishEnemiesUnlocked}
									finishModesUnlocked={this.state.game_finishModesUnlocked}
									achievementsUnlocked={this.state.game_achievementsUnlocked}
									hasGainedLevel={this.state.game_hasGainedLevel}
									claimXp={this.claimXp}
									showXpScreen={this.state.game_showXpScreen === true}
									/>
								{cheatsJsx}
							</div>
						);
				}
        }
	}
}

export default NewApp;
