import AbstractEffect from "./AbstractEffect";

class DiscardEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            context.player.discard(this.getVal(context));

            context.battle.history.write(context.player.name + ' discards ' + this.getVal(context), context.historySourceImage);

            context.battle.game.triggerSoundEffect('/sounds/effects/generic.wav');
        }
    }

    generateDescription()
    {
        return 'Discard ' + parseInt(this.data) + ' ' + this.pluralise('card', parseInt(this.data)) + '.';
    }
}

export default DiscardEffect;
