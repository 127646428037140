export const locationsData = [
    {
      "type": "enemy",
      "eid": 1,
      "id": 1
    },
    {
      "type": "enemy",
      "eid": 2,
      "id": 2
    },
    {
      "type": "enemy",
      "eid": 3,
      "id": 3
    },
    {
      "type": "enemy",
      "eid": 4,
      "id": 4
    },
    {
      "type": "enemy",
      "eid": 5,
      "id": 5
    },
    {
      "type": "chest",
      "id": 6,
      "title": "Mysterious Chest"
    },
    {
      "type": "chest",
      "id": 7,
      "title": "Mysterious Chest"
    },
    {
      "type": "turnstile",
      "id": 8,
      "title": "Turnstile"
    },
    {
      "type": "turnstile",
      "id": 9,
      "title": "Turnstile"
    },
    {
      "type": "enemy",
      "eid": 6,
      "id": 10
    },
    {
      "type": "enemy",
      "eid": 7,
      "id": 11
    },
    {
      "type": "enemy",
      "eid": 8,
      "id": 12
    },
    {
      "type": "enemy",
      "eid": 9,
      "id": 13
    },
    {
      "type": "enemy",
      "eid": 10,
      "id": 14
    },
    {
      "type": "shop",
      "id": 15
    },
    {
      "type": "shop",
      "id": 16
    }
]
