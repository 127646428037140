import AbstractStatusEffect from "./AbstractStatusEffect";

class PoisonStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Poison';
        this.image = '/images/new/status/poison.png';
        this.soundEffect = '/sounds/effects/poison.mp3';
        this.isDebuff = true;
    }

    triggerStartPlayerTurn(context)
    {
        this.activate(context);
    }

    activate(context)
    {
        if (context.player === this.player) {
            if (this.val > 0) {
                let invulnerableStatusEffect = context.player.getStatusEffect('invulnerable');
                if (invulnerableStatusEffect.val > 0) {
                    invulnerableStatusEffect.pulse();
                    return;
                }

                let currentHp = context.player.hp;

                let newHp = Math.max(0, currentHp - this.val);
                context.player.hp = newHp;
                this.pulse();
                this.playSoundEffect(context);
                context.game.battle.history.write(this.player.name + ' takes ' + (this.val) + ' poison damage', this.image);
            }
        }
    }

    generateTooltip()
    {
        return 'At the start of the turn, take ' + this.val + ' damage.';
    }
}

export default PoisonStatusEffect;
