import ArrayValue from "./ArrayValue";
import AttributeValue from "./AttributeValue";
import CalcValue from "./CalcValue";
import ConditionValue from "./ConditionValue";
import FlatValue from "./FlatValue";
import PercentageValue from "./PercentageValue";
import RandomValue from "./RandomValue";

class ValueFactory
{
    static createValueFromJson(data)
    {
        switch (data.valType) {
            case 'flat':
                return new FlatValue(data);

            case 'calc':
                return new CalcValue(data);

            case 'attribute':
                return new AttributeValue(data);

            case 'percentage':
                return new PercentageValue(data);

            case 'condition':
                return new ConditionValue(data);

            case 'random':
                return new RandomValue(data);

            case 'array':
                return new ArrayValue(data);

            default:
                console.log(data);
                throw new Error('Cannot parse val JSON');
        }
    }
}

export default ValueFactory;
