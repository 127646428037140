import { v4 as uuidv4 } from 'uuid';

class AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        this.player = player;
        this.key = key;
        this.val = val;
        this.image = 'https://via.placeholder.com/150';
        this.uid = uuidv4();
        this.triggerPriorities = {};
        this.shouldPulse = false;
        this.soundEffect = '/sounds/effects/generic.wav';
        this.isBuff = false;
        this.isDebuff = false;
        this.name = 'Effect';
    }

    changeVal(val, context)
    {
        let oldVal = this.val;
        this.val = val;
        context.statusEffect = this;
        context.statusEffectOldVal = oldVal;

        context.game.trigger('afterChangeStatusEffect', context);
        if (context.player !== undefined) {
            let player = context.player;
            context.player = player.isPlayer() ? context.game.battle.enemy : context.game.battle.player;
            context.game.trigger('afterEnemyChangeStatusEffect', context);
            context.player = player;
        }

        this.playSoundEffect(context);
    }

    trigger(triggerName, context)
    {
        let methodName = 'trigger' + triggerName.charAt(0).toUpperCase() + triggerName.slice(1);
        if (this[methodName] !== undefined) {
            this[methodName](context);
        }
    }

    pulse()
    {
        this.shouldPulse = true;
    }

    playSoundEffect(context)
    {
        if (context !== undefined && context.battle !== undefined && context.battle.game !== undefined ) {
            context.battle.game.triggerSoundEffect(this.soundEffect);
        }
    }

    registerTriggers(triggerName, triggerManager)
    {
        let priority = 100;
        if (this.triggerPriorities[triggerName] !== undefined) {
            priority = this.triggerPriorities[triggerName];
        }
        triggerManager.registerListener(triggerName, priority, this.trigger.bind(this));
    }

    getStatusEffectState()
    {
        let state = {
            icons: [
                {
                    uid: this.name,
                    img: this.image,
                    val: this.val,
                    name: this.name,
                    tooltip: this.generateTooltip(),
                    pulse: this.shouldPulse
                }
            ]
        };

        setTimeout(function() {
            this.shouldPulse = false;
        }.bind(this), 250);

        return state;
    }

    generateTooltip()
    {
        return this.name + ' ' + this.val;
    }
}

export default AbstractStatusEffect;
