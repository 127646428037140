import AttackEffect from "../Effects/AttackEffect";
import CritAttackEffect from "../Effects/CritAttackEffect";
import AbstractStatusEffect from "./AbstractStatusEffect";

class StrengthenStatusEffect extends AbstractStatusEffect
{
    constructor(key, player, val = 0)
    {
        super(key, player, val);
        this.name = 'Strengthen';
        this.image = '/images/new/status/strengthen.png';
        this.isBuff = true;
    }

    triggerEndPlayerTurn(context)
    {
        if (context.player === this.player) {
            this.changeVal(Math.max(this.val - 1, 0), context);
        }
    }

    triggerBeforeTakeDamage(context)
    {
        if (this.val > 0 &&
            context.enemy === this.player &&
            context.source.effect !== undefined &&
            (
                (context.source.effect instanceof AttackEffect)
                || (context.source.effect instanceof CritAttackEffect)
            )
        ) {
            let multiplier = 1.5;
            if (context.strengthenMultiplier !== undefined) {
                multiplier = context.strengthenMultiplier;
            }
            context.damageVal = Math.ceil(multiplier * context.damageVal);
        }
    }

    generateTooltip()
    {
        return 'For the next ' + this.val + ' turns, whenever player would deal damage, deal 50% more.';
    }
}

export default StrengthenStatusEffect;
