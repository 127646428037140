import React from 'react';
import Card from './Card';

class WinScreen extends React.Component
{
    getClassNames()
    {
        let classNames = "results results--win";
        if (this.props.canDisplay) {
            classNames += ' results--display';
        }
        return classNames
    }

    render()
    {
        return (
            <div className={this.getClassNames()}>
                <h2 className="results__title">You Win!</h2>
                <p className="results__text">Please choose a card to add to your deck.</p>
                <div className="results__cards">
                    {this.props.cardRewards.map((card, handPos) => {
                        return (
                            <Card
                                playCard={this.props.chooseCard}
                                addUnplayableClass={false}
                                id={card.id}
                                key={card.uid}
                                uid={card.uid}
                                name={card.name}
                                image={card.image}
                                description={card.description}
                                tier={card.tier}
                                cost={card.cost}
                                goldCost={card.goldCost}
                            />
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default WinScreen;
