import AbstractEffect from "./AbstractEffect";

class ArmourEffect extends AbstractEffect
{
    handle(context)
    {
        if (this.data.context !== undefined) {
            context = {...context, ...this.data.context}
        }

        let multiEffect = 1;
        if (context.multiEffect !== undefined) {
            multiEffect = context.multiEffect;
        }

        delete context.multiEffect;

        for (let i = 0; i < multiEffect; i++) {
            let armourStatusEffect = context.player.getStatusEffect('armour');
            armourStatusEffect.changeVal(this.getVal(context) + armourStatusEffect.val, context);
            context.armourVal = this.getVal(context);

            context.game?.battle?.history.write(context.player.name + ' gains ' + this.getVal(context) + ' armour.', context.historySourceImage);

            context.game.triggerSoundEffect('/sounds/effects/armour.mp3');
        }
    }

    generateDescription()
    {
        return 'Gain ' + parseInt(this.data) + ' armour.';
    }
}

export default ArmourEffect;
