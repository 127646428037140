import React from 'react';

class ShopArtifacts extends React.Component
{
    performAction(artifact) {
        this.props.encounterAction('buyArtifact', this.getGoldCost(artifact), artifact.uid);
    }

    canPurchase(artifact)
    {
        return this.props.gold >= this.getGoldCost(artifact);
    }

    isPurchased(artifact)
    {
        return this.props.artifactsPurchased.filter(purchasedartifact => {
            return purchasedartifact.uid === artifact.uid;
        }).length > 0;
    }

    getGoldCost(artifact)
    {
        return Math.ceil(artifact.goldCost * ((100 - this.props.discountPercentage) / 100));
    }

    getGoldCostOriginal(artifact)
    {
        return artifact.goldCost;
    }

    render() {
        return (
            <div className="artifacts artifacts--shop">
                {this.props.artifactsForPurchase.map((artifact, handPos) => {
                    let buttonLabel = <span>Buy for {this.getGoldCost(artifact)} gold</span>;
                    if (this.getGoldCost(artifact) !== this.getGoldCostOriginal(artifact)) {
                        buttonLabel = <span>Buy for <span className="shop-discount-original-price">{this.getGoldCostOriginal(artifact)}</span> {this.getGoldCost(artifact)} gold</span>;
                    }

                    return (
                        <div key={handPos} class={"artifact artifact--shop " + (!this.canPurchase(artifact) ? 'artifact--unbuyable ' : '') + (this.isPurchased(artifact) ? 'artifact--purchased ' : '')}>
                            <span class="artifact__title">{artifact.name}</span>
                            <img class="artifact__image" src={artifact.image} />
                            <p class="artifact__description">
                                {artifact.description}
                            </p>
                            <button class="artifact__cta" onClick={() => (this.canPurchase(artifact) && !this.isPurchased(artifact)) ? this.performAction(artifact) : {}}>{buttonLabel}</button>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default ShopArtifacts;
