class AbstractCondition
{
    constructor(comparison, vals)
    {
        this.comparison = comparison;
        this.vals = vals;
    }

    isMet(context)
    {
        return true;
    }
}

export default AbstractCondition;
