import AttributeValue from "./AttributeValue";

class PercentageValue extends AttributeValue
{
    constructor(data)
    {
        super(data);
        this.property = data.valProperty;
    }

    calculate(context)
    {
        let propertyValue = this.getPropertyValueFromContext(context, this.property);
        let r = propertyValue * (this.val / 100);
        return r + this.valExtra;
    }
}

export default PercentageValue;
