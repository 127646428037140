    import React from 'react';
import Overlay from './Overlay';
import OverlayTabContent from './OverlayTabContent';
import OverlayTabs from './OverlayTabs';
import ShopArtifacts from './ShopArtifacts';
import ShopCards from './ShopCards';
import ShopGeneral from './ShopGeneral';

class Shop extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            activeTab: "general"
        }
        this.chooseTab = this.chooseTab.bind(this);
    }

    chooseTab(name)
    {
        this.props.chooseTab(name);
        this.setState({
            activeTab: name
        })
    }

    formatTabData()
    {
        return [
            {
                "name": "general",
                "title": "General Wares",
                "isActive": this.state.activeTab === 'general'
            },
            {
                "name": "cards",
                "title": "Cards For Sale",
                "isActive": this.state.activeTab === 'cards'
            },
            {
                "name": "artifacts",
                "title": "Artifacts For Sale",
                "isActive": this.state.activeTab === 'artifacts'
            }
        ];
    }

    render()
    {
        return (
            <div>
                <Overlay
                    overlayClasses="shop-overlay overlay--small"
                    title="Shop"
                    display={this.props.display}
                    closeOverlay={this.props.closeOverlay}
                    name='shop'
                    >
                    <div class="shop-overlay__gold">
                        <div class="icon icon--bottombar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g fill="none" stroke="#000" strokeWidth="14.552"><path d="M61.023 21.023l31.064 47.495s-178.49 175.26 40.016 169.56M203.183 21.023l-31.065 47.495s178.49 175.26-40.015 169.56"/><path d="M67.341 40.496s15.796-12.824 25.273-7.6c9.477 5.225 11.057 12.35 25.8 4.275 14.742-8.074 14.742-14.724 27.378-3.325 12.637 11.4 4.739 14.25 20.008 8.075 15.269-6.175 13.163-9.974 28.432-5.7"/></g></svg>
                            <span class="icon__label">{this.props.gold}</span>
                        </div>
                    </div>
                    <div class="shop-wrapper">
                        <OverlayTabs
                            chooseTab={this.chooseTab}
                            tabData={this.formatTabData()}
                            />
                        <OverlayTabContent
                            isActive={this.state.activeTab === 'artifacts'}
                            key="artifacts"
                            withBorder={true}
                        >
                            <ShopArtifacts
                                artifactsForPurchase={this.props.artifactsForPurchase}
                                artifactsPurchased={this.props.artifactsPurchased}
                                encounterAction={this.props.encounterAction}
                                discountPercentage={this.props.discountPercentage}
                                gold={this.props.gold}
                                />
                        </OverlayTabContent>
                        <OverlayTabContent
                            isActive={this.state.activeTab === 'cards'}
                            key="cards"
                            withBorder={true}
                        >
                            <ShopCards
                                cardsForPurchase={this.props.cardsForPurchase}
                                cardsPurchased={this.props.cardsPurchased}
                                encounterAction={this.props.encounterAction}
                                discountPercentage={this.props.discountPercentage}
                                gold={this.props.gold}
                                />
                        </OverlayTabContent>
                        <OverlayTabContent
                            isActive={this.state.activeTab === 'general'}
                            key="general"
                            withBorder={true}
                        >
                            <ShopGeneral
                                encounterAction={this.props.encounterAction}
                                servicesPurchased={this.props.servicesPurchased}
                                generalWareCost={this.props.generalWareCost}
                                discountPercentage={this.props.discountPercentage}
                                deck={this.props.deck}
                                gold={this.props.gold}
                                />
                        </OverlayTabContent>
                    </div>
                </Overlay>
            </div>
        );
    }
}

export default Shop;
