import { v4 as uuidv4 } from 'uuid';

import ConditionFactory from './Artifacts/Conditions/ConditionFactory';
import AbstractValue from './Artifacts/Values/AbstractValue';
import ValueFactory from './Artifacts/Values/ValueFactory';
import Card from './Card';
import {enemiesData} from './data/enemies-new';
import Deck from './Deck';
import EffectFactory from './Effects/EffectFactory';
import StatusEffectFactory from './StatusEffects/StatusEffectFactory';

class Player
{
    constructor(
        name,
        image,
        cardDraw,
        cardLimit,
        maxHp,
        hp,
        mana,
        manaRegen,
        artifacts,
        deck,
        effects = [],
        statusEffects = {},
        enemyId = false,
        gold = 0,
        level = null,
        backgroundMusic = null,
        description = "",
        effectDescription = "",
        boss = false,
        soundEffect = false,
        haste = false
    ) {
        this.name = name;
        this.image = image;
        this.cardDraw = cardDraw;
        this.cardLimit = cardLimit;
        this.hp = hp;
        this.maxHp = maxHp;
        this.mana = mana;
        this.manaRegen = manaRegen;
        this.artifacts = artifacts;
        this.deck = deck;
        deck.player = this;
        this.enemyId = enemyId;
        this.gold = gold;
        this.toBeDiscarded = 0;
        this.triggerPriorities = {
            "battleStart": 1000
        };
        this.effects = effects;
        this.statusEffects = {};
        this.level = level;
        this.backgroundMusic = backgroundMusic;
        this.description = description;
        this.effectDescription = effectDescription;
        this.boss = boss;
        this.internalCounter = 0;
        this.soundEffect = soundEffect;
        this.haste = haste;

        this.animateHurt = false;
        this.shouldPulse = false;
        this.numbers = {};

        Object.keys(statusEffects).forEach(function(key) {
            this.getStatusEffect(key, statusEffects[key]);
        }.bind(this));

        this.parseEffects(effects);
    }

    static createEnemyFromId(enemyId)
    {
        let data = enemiesData[enemyId];
        if (data === undefined) {
            throw new Error('Could not find enemy with ID ' + enemyId);
        }
        let deck = Deck.createFromDeckIds(data.deck);
        let effects = [];
        if (data.effects) {
            effects = JSON.parse(JSON.stringify(data.effects));
        }

        return new Player(
            data.name,
            data.image,
            data.cardDraw,
            data.cardLimit,
            data.hp,
            data.hp,
            {...data.mana},
            {...data.manaRegen},
            [],
            deck,
            effects,
            data.statusEffects,
            enemyId,
            data.gold,
            data.level,
            data.backgroundMusic,
            data.description,
            data.effectDescription,
            data.boss,
            data.effectSound,
            data.haste
        )
    }

    static createFromGame(game)
    {
        return new Player(
            game.playerName,
            game.playerImage,
            game.cardDraw,
            game.cardLimit,
            game.maxHp,
            game.hp,
            {...game.mana},
            {...game.manaRegen},
            [...game.artifacts],
            game.deck.clone(),
            [],
            {...game.statusEffects}
        );
    }

    setImage(img)
    {
        this.image = img;
    }

    getState(battle)
    {
        let deckState = this.deck.getDeckState();
        deckState.hand = deckState.hand.map(card => {
            card.isPlayable = this.canPlayCard(card);
            return card;
        });

        let playerStatusEffects = this.statusEffects;

        let statusEffects = Object.keys(playerStatusEffects)
            .filter(key => playerStatusEffects[key].val !== undefined && playerStatusEffects[key].val !== 0)
            .map(function(key, pos) {
                let statusEffectState = playerStatusEffects[key].getStatusEffectState();
                return statusEffectState;
            });

        let state = {
            name: this.name,
            image: this.image,
            cardDraw: this.cardDraw,
            cardLimit: this.cardLimit,
            hp: this.hp,
            maxHp: this.maxHp,
            mana: this.mana,
            manaRegen: this.manaRegen,
            artifacts: this.artifacts.filter(artifact => artifact.expired === false).map(artifact => artifact.getArtifactState()),
            deck: deckState,
            enemyId: this.enemyId,
            gold: this.gold,
            statusEffects: statusEffects,
            toBeDiscarded: this.toBeDiscarded,
            effectDescription: this.effectDescription,
            boss: this.boss,
            animateHurt: this.animateHurt,
            shouldPulse: this.shouldPulse,
            numbers: this.numbers,
        }

        if (this.animateHurt === true) {
            setTimeout(function() {
                this.animateHurt = false;
            }.bind(this), 500);
        }

        if (this.shouldPulse === true) {
            setTimeout(function() {
                this.shouldPulse = false;
            }.bind(this), 250);
        }

        return state;
    }

    getEnemyState(battle)
    {
        let enemyState = {};
        for (let key in this.getState(battle)) {
            enemyState['enemy_' + key] = this.getState(battle)[key];
        }
        return enemyState;
    }

    getPlayerState(battle)
    {
        let enemyState = {};
        for (var key in this.getState(battle)) {
            enemyState['player_' + key] = this.getState(battle)[key];
        }
        return enemyState;
    }

    pulse()
    {
        this.shouldPulse = true;
    }

    addNumber(val, type)
    {
        let number = {
            id: uuidv4(),
            val: val,
            type: type
        };

        this.numbers[number.id] = number;

        setTimeout(function() {
            this.unsetNumber(number.id);
        }.bind(this), 3000);
    }

    unsetNumber(uuid)
    {
        delete this.numbers[uuid];
    }

    canPlayCard(card)
    {
        if (card.unplayable !== null) {
            return false;
        }
        if (this.getStatusEffect('manaMastery').val > 0) {
            return Object.values(card.cost).reduce((a, b) => a + b) <= Object.values(this.mana).reduce((a, b) => a + b);
        }
        if (card.cost.red != undefined && card.cost.red > this.mana.red) {
            return false;
        }
        if (card.cost.blue != undefined && card.cost.blue > this.mana.blue) {
            return false;
        }
        if (card.cost.green != undefined && card.cost.green > this.mana.green) {
            return false;
        }
        return true;
    }

    registerTriggers(triggerName, triggerManager)
    {
        for (let statusEffect in this.statusEffects) {
            this.statusEffects[statusEffect].registerTriggers(triggerName, triggerManager);
        }

        let deckTypes = [
            'deck',
            'hand',
            'discard',
            'exile'
        ];

        deckTypes.forEach(deckType => {
            if (this.deck[deckType].length > 0) {
                this.deck[deckType].map(card => card.registerTriggers(triggerName, triggerManager));
            }
        });

        let priority = 100;
        if (this.triggerPriorities[triggerName] !== undefined) {
            priority = this.triggerPriorities[triggerName];
        }
        triggerManager.registerListener(triggerName, priority, this.trigger.bind(this));
    }

    trigger(triggerName, context)
    {
        let methodName = 'trigger' + triggerName.charAt(0).toUpperCase() + triggerName.slice(1);
        if (this[methodName] !== undefined) {
            this[methodName](context);
        }
        if (context.player === this || context.player === undefined) {
            let effects = this.effects.filter(effect => effect.trigger === triggerName);
            effects.forEach(effect => this.handleEffect(effect, context));
        }
    }

    triggerAfterPlayerDraw(context)
    {
        this.checkModularCards(context);
        if (context.player === this && this.isPlayer()) {
            context.battle.game.triggerSoundEffect('/sounds/effects/draw3.mp3');
        }
    }

    triggerAfterCardDraw(context)
    {
        this.checkModularCards(context);
        if (context.player === this && this.isPlayer()) {
            context.battle.game.triggerSoundEffect('/sounds/effects/draw3.mp3');
        }
    }

    checkModularCards(context)
    {
        let modularCardsToEquip = context.player.deck.hand.filter(card => {
            return (card.moduleSlots !== undefined) && (card.moduleSlots > 0);
        });
        modularCardsToEquip.forEach(modularCard => {
            let cardsToEquip = context.player.deck.hand.filter(card => ((card.module !== null) && (card.module == modularCard.id)));
            cardsToEquip.forEach(card => {
                this.equipModule(modularCard, card);
            })
        });
    }

    equipModule(modularCard, card)
    {
        if (modularCard.moduleSlots > 0
            && card.module !== null
            && card.module === modularCard.id
        ) {
            Object.keys(card.effects).forEach(effectKey => {
                if (modularCard.effects[effectKey] === undefined) {
                    modularCard.effects[effectKey] = 0;
                }
                modularCard.effects[effectKey] += card.effects[effectKey];
            });
            Object.keys(card.cost).forEach(manaType => {
                if (modularCard.cost[manaType] === undefined) {
                    modularCard.cost[manaType] = 0;
                }
                modularCard.cost[manaType] += card.cost[manaType];
            });
            modularCard.moduleSlots--;
            this.deck.hand = this.deck.hand.filter(cardInHand => cardInHand.uid !== card.uid);
        }
    }

    areConditionsMet(effectData, context)
    {
        if (effectData.conditions !== undefined) {
            return effectData.conditions.reduce((accum, condition) => accum && condition.isMet(context), true);
        }
        return true;
    }

    handleEffect(effectData, context)
    {
        context.internalCounter = this.internalCounter;

        if (!this.areConditionsMet(effectData, context)) {
            return;
        }

        if (context.enemy === undefined && context.battle !== undefined) {
            context.enemy = (this.isPlayer() ? context.battle.enemy : context.battle.player);
        }

        if (Array.isArray(effectData.effect)) {
            effectData.effect.forEach(function (effect) {
                let newEffectData = {...effectData};
                newEffectData.effect = effect;
                this.doEffect(newEffectData, context);
            }.bind(this));
            return;
        }

        this.doEffect(effectData, context);
    }

    doEffect(effectData, context)
    {
        let val;
        let newContext;
        let player;
        let enemy;

        context.historySourceImage = this.image;

        switch (effectData.effect) {
            case 'gainMaxHealth':
                context.game.maxHp += effectData.val.calculate(context);
                context.game.hp += effectData.val.calculate(context);
                break;

            case 'playerIncreaseHandSize':
                context.player.cardLimit += effectData.val.calculate(context);
                break;

            case 'playerGainShield':
                let shieldEffect = EffectFactory.createEffect('shield', effectData.val.calculate(context));
                shieldEffect.handle(context);
                break;

            case 'playerTimewalk':
                let timewalkEffect = EffectFactory.createEffect('timewalk', effectData.val.calculate(context));
                timewalkEffect.handle(context);
                break;

            case 'playerGainThorns':
                let thornEffect = EffectFactory.createEffect('thorn', effectData.val.calculate(context));
                thornEffect.handle(context);
                break;

            case 'playerGainInvulnerable':
                let invulnerableEffect = EffectFactory.createEffect('invulnerable', effectData.val.calculate(context));
                invulnerableEffect.handle(context);
                break;

            case 'playerGainImmune':
                let immuneEffect = EffectFactory.createEffect('immune', effectData.val.calculate(context));
                immuneEffect.handle(context);
                break;

            case 'playerHeal':
                let healEffect = EffectFactory.createEffect('heal', effectData.val.calculate(context));
                healEffect.handle(context);
                break;

            case 'playerMuster':
                let musterEffect = EffectFactory.createEffect('muster', effectData.val.calculate(context));
                musterEffect.handle(context);
                break;

            case 'playerReinforce':
                let reinforceEffect = EffectFactory.createEffect('reinforce', effectData.val.calculate(context));
                reinforceEffect.handle(context);
                break;

            case 'playerIncreaseArmour':
                let armourEffect = EffectFactory.createEffect('armour', effectData.val.calculate(context));
                armourEffect.handle(context);
                break;

            case 'playerDraw':
                let drawEffect = EffectFactory.createEffect('draw', effectData.val.calculate(context));
                drawEffect.handle(context);
                break;

            case 'playerFuture':
                let futureEffect = EffectFactory.createEffect('future', effectData.val.calculate(context));
                futureEffect.handle(context);
                break;

            case 'copyEnemyDeck':
                context.enemy.deck.deck.forEach(card => context.player.deck.addToDeck(card));
                break;

            case 'playerDiscard':
                let discardEffect = EffectFactory.createEffect('discard', effectData.val.calculate(context));
                discardEffect.handle(context);
                break;

            case 'enemyDiscard':
                let oppDiscardEffect = EffectFactory.createEffect('oppDiscard', effectData.val.calculate(context));
                oppDiscardEffect.handle(context);
                break;

            case 'enemyPoison':
                let poisonEffect = EffectFactory.createEffect('poison', effectData.val.calculate(context));
                poisonEffect.handle(context);
                break;

            case 'enemyBurn':
                let burnEffect = EffectFactory.createEffect('burn', effectData.val.calculate(context));
                burnEffect.handle(context);
                break;

            case 'enemyNegate':
                let negateEffect = EffectFactory.createEffect('negate', effectData.val.calculate(context));
                negateEffect.handle(context);
                break;

            case 'incrementCounter':
                this.internalCounter += effectData.val.calculate(context);
                break;

            case 'decrementCounter':
                this.internalCounter -= effectData.val.calculate(context);
                break;

            case 'increaseDamage':
                context.damageVal = parseInt(context.damageVal) + parseInt(effectData.val.calculate(context));
                break;

            case 'increaseHealEffect':
                context.healVal += effectData.val.calculate(context);
                break;

            case 'resetCounter':
                this.internalCounter = 0;
                break;

            case 'playerGainManaGreen':
                context.player.mana.green += effectData.val.calculate(context);
                break;

            case 'playerGainManaBlue':
                context.player.mana.blue += effectData.val.calculate(context);
                break;

            case 'playerGainManaRed':
                context.player.mana.red += effectData.val.calculate(context);
                break;

            case 'playerFocusGreen':
                context.player.manaRegen.green += effectData.val.calculate(context);
                break;

            case 'playerFocusBlue':
                context.player.manaRegen.blue += effectData.val.calculate(context);
                break;

            case 'playerFocusRed':
                context.player.manaRegen.red += effectData.val.calculate(context);
                break;

            case 'gainGold':
                context.game.gold += effectData.val.calculate(context);
                break;

            case 'dodgeDamage':
                context.damageVal = 0;
                context.game.battle.history.write(this.name + ' dodges the damage!', this.image);
                break;

            case 'mergeContext':
                Object.assign(context, effectData.val.calculate(context));
                break;

            case 'dealDamage':
                newContext = {...context};
                newContext.damageVal = effectData.val.calculate(context);
                newContext.enemy = this;
                newContext.player = (this.isPlayer() ? context.battle.enemy : context.battle.player)
                newContext.source = this;
                newContext.player.takeDamage(newContext);

                if (newContext.damageReceived < 1) {
                    if (newContext.damageMitigated > 0) {
                        newContext.battle.game.triggerSoundEffect('/sounds/effects/hit_shield.mp3');
                    }
                } else if (newContext.damageReceived < 6) {
                    newContext.battle.game.triggerSoundEffect('/sounds/effects/light_impact.mp3');
                } else if (newContext.damageReceived < 11) {
                    newContext.battle.game.triggerSoundEffect('/sounds/effects/medium_impact.mp3');
                } else {
                    newContext.battle.game.triggerSoundEffect('/sounds/effects/heavy_impact.mp3');
                }

                if (newContext.brokeShield) {
                    newContext.battle.game.triggerSoundEffect('/sounds/effects/shield_break_3.mp3');
                }
                break;

            case 'takeDamage':
                newContext = {...context};
                newContext.damageVal = effectData.val.calculate(context);
                newContext.source = this;
                newContext.player = this;
                newContext.player.takeDamage(newContext);
                context.battle.game.triggerSoundEffect('/sounds/effects/growl2.mp3');
                break;

            case 'limitDamage':
                context.damageVal = Math.min(context.damageVal, effectData.val.calculate(context));
                break;

            case 'takeCard':
                if (context.card.deck.deck.filter(card => card.uid === context.card.uid).length > 0) {
                    context.card.deck.deck = context.card.deck.deck.filter(card => card.uid !== context.card.uid);
                    this.deck.addToDeck(context.card);
                }
                if (context.card.deck.hand.filter(card => card.uid === context.card.uid).length > 0) {
                    context.card.deck.hand = context.card.deck.hand.filter(card => card.uid !== context.card.uid);
                    this.deck.addToDeck(context.card);
                }
                if (context.card.deck.exile.filter(card => card.uid === context.card.uid).length > 0) {
                    context.card.deck.exile = context.card.deck.exile.filter(card => card.uid !== context.card.uid);
                    this.deck.addToDeck(context.card);
                }
                break;

            case 'inflictBleed':
                delete context.multiEffect;
                let bleedEffect = EffectFactory.createEffect('bleed', effectData.val.calculate(context));
                bleedEffect.handle(context);
                break;

            case 'incrementAllCardsCostBlue':
                val = effectData.val.calculate(context);
                // hand, exile and discard should be empty
                context.battle.player.deck.deck.forEach(card => {
                    if (card.cost.blue !== undefined) {
                        card.cost.blue += parseInt(val);
                    } else {
                        card.cost.blue = 1;
                    }
                });
                // hand, exile and discard should be empty
                context.battle.enemy.deck.deck.forEach(card => {
                    if (card.cost.blue !== undefined) {
                        card.cost.blue += parseInt(val);
                    } else {
                        card.cost.blue = 1;
                    }
                });
                break;

            case 'setAllCardsCostBlue':
                val = effectData.val.calculate(context);
                // hand, exile and discard should be empty
                context.battle.player.deck.deck.forEach(card => {
                    card.cost.blue = val;
                });
                // hand, exile and discard should be empty
                context.battle.enemy.deck.deck.forEach(card => {
                    card.cost.blue = val;
                });
                break;

            case 'negateCard':
                context.card.negate = true;
                break;

            case 'stopCrit':
                context.isCrit = false;
                break;

            case 'forceCrit':
                context.isCrit = true;
                break;

            case 'loseGold':
                context.battle.game.gold = Math.max(0, context.battle.game.gold - effectData.val.calculate(context));
                break;

            case 'addCardToEnemyDeck':
                let cardId = effectData.val.calculate(context);
                let card = Card.createFromCardId(cardId);
                context.enemy.deck.addToDeck(card);
                break;

            case 'setCardCostRed':
                context.card.cost.red = effectData.val.calculate(context);
                break;

            case 'suicide':
                context.player.hp = 0;
                break;

            case 'disableArtifacts':
                context.battle.game.artifacts.forEach(artifact => artifact.disable(context));
                break;

            case 'enableArtifacts':
                console.log(this, 'enabling');
                context.battle.game.artifacts.forEach(artifact => artifact.enable(context));
                break;

            case 'syncBuffsAndDebuffs':
                if (context.statusEffect === undefined || context.statusEffectOldVal === undefined) {
                    console.log('This should be defined.');
                    break;
                }

                if (context.statusEffect.isBuff === false && context.statusEffect.isDebuff === false) {
                    break;
                }

                if (context.statusEffectOldVal === context.statusEffect.val) {
                    break;
                }

                if (context.isSyncing === true) {
                    break;
                }

                context.isSyncing = true;

                let enemy = this.isPlayer() ? context.battle.enemy : context.battle.player;
                let statusEffect;

                if (context.statusEffect.player === this) {
                    statusEffect = enemy.getStatusEffect(context.statusEffect.key);
                    statusEffect.changeVal(context.statusEffect.val, context);
                } else {
                    statusEffect = this.getStatusEffect(context.statusEffect.key);
                    statusEffect.changeVal(context.statusEffect.val, context);
                }

                context.isSyncing = false;
                break;
        }

        if (effectData.singleUse === true) {
            this.expired = true;
        }

        this.pulse();
        if (this.soundEffect) {
            context.battle.game.triggerSoundEffect(this.soundEffect);
        }
    }

    incrementMana()
    {
        for (var manaType in this.mana) {
            this.mana[manaType] += this.manaRegen[manaType];
        }
    }

    draw(context)
    {
        let cardsToDraw = this.cardDraw;
        cardsToDraw -= this.toBeDiscarded;
        if (cardsToDraw > 0) {
            this.deck.draw(cardsToDraw, context);
        }
        this.toBeDiscarded = 0;
    }

    discard(val)
    {
        this.toBeDiscarded += val;
    }

    getStatusEffect(statusEffectKey, val = 0)
    {
        if (this.statusEffects[statusEffectKey] === undefined) {
            this.statusEffects[statusEffectKey] = StatusEffectFactory.createStatusEffect(statusEffectKey, this, val);
        }
        return this.statusEffects[statusEffectKey];
    }

    isPlayer()
    {
        return this.enemyId === false;
    }

    payForCard(card)
    {
        if (this.getStatusEffect('manaMastery').val > 0) {
            this.getStatusEffect('manaMastery').pulse();
            let totalCost = Object.values(card.cost).reduce((a, b) => a + b);
            if (card.cost.green !== undefined) {
                this.totalCost -= Math.min(this.mana.green, card.cost.green);
                this.mana.green -= Math.min(this.mana.green, card.cost.green);
            }
            if (card.cost.red !== undefined) {
                this.totalCost -= Math.min(this.mana.red, card.cost.red);
                this.mana.red -= Math.min(this.mana.red, card.cost.red);
            }
            if (card.cost.blue !== undefined) {
                this.totalCost -= Math.min(this.mana.blue, card.cost.blue);
                this.mana.blue -= Math.min(this.mana.blue, card.cost.blue);
            }

            for (let i = 0; i < totalCost; i++) {
                if (this.mana.green > 0) {
                    this.mana.green--;
                } else if (this.mana.blue > 0) {
                    this.mana.blue--;
                } else {
                    this.mana.red--;
                }
            }
            return;
        }
        if (card.cost.green !== undefined) {
            this.mana.green -= card.cost.green;
        }
        if (card.cost.red !== undefined) {
            this.mana.red -= card.cost.red;
        }
        if (card.cost.blue !== undefined) {
            this.mana.blue -= card.cost.blue;
        }
    }

    parseEffects(effects)
    {
        this.effects = effects.map(effect => {
            if (effect.conditions !== undefined) {
                effect.conditions = effect.conditions.map(conditionData => {
                    return ConditionFactory.createConditionFromJson(conditionData);
                });
            }
            if (effect.val !== undefined && ((effect.val instanceof AbstractValue) === false)) {
                effect.val = ValueFactory.createValueFromJson(effect.val);
            }
            return effect;
        });
    }

    takeDamage(context, isCrit = false)
    {
        context.receivingDamage = context.player;
        if (context.source instanceof Player) {
            context.dealingDamage = context.source;
        } else {
            context.dealingDamage = context.source.player;
        }
        context.isCrit = isCrit;

        context.battle.game.trigger('beforeTakeDamage', context);
        context.player = context.dealingDamage;
        context.enemy = context.receivingDamage;
        context.battle.game.trigger('beforeDealDamage', context);
        context.player = context.receivingDamage;
        context.enemy = context.dealingDamage;

        let damageReceived = 0;
        let damageMitigated = 0;
        let damageVal = context.damageVal;

        context.brokeShield = false;

		if (context.isCrit === false) {
			let defenderShields = context.player.getStatusEffect('shield');
            defenderShields.pulse();
            if (damageVal > defenderShields.val) {
                damageMitigated += defenderShields.val;
                damageVal -= defenderShields.val;
                defenderShields.val = 0;
            } else {
                defenderShields.val -= damageVal;
                damageMitigated += damageVal;
                damageVal = 0;
            }

            if (defenderShields.val === 0 && damageMitigated > 0) {
                context.brokeShield = true;
            }
		}

        if (damageVal > 0) {
            context.player.hp = Math.max(0, context.player.hp - damageVal);
            damageReceived = damageVal;
            context.player.animateHurt = true;
            context.player.addNumber(damageReceived, 'hurt');
        }

        context.damageReceived = damageReceived;
        context.damageMitigated = damageMitigated;

        context.battle.history.write(context.player.name + ' takes ' + damageReceived + ' damage (' + context.damageMitigated + ' blocked by shield)', context.historySourceImage);

        context.battle.game.trigger('afterTakeDamage', context);
        context.player = context.dealingDamage;
        context.enemy = context.receivingDamage;
        context.battle.game.trigger('afterDealDamage', context);
        context.player = context.receivingDamage;
        context.enemy = context.dealingDamage;
    }
}

export default Player;
