import React from 'react';

import Achievements from './Achievements';
import Changelog from './Changelog';
import Compendium from './Compendium';
import Credits from './Credits';
import Settings from './Settings';
import SettingsConfirm from './SettingsConfirm';
import StartingModes from './StartingModes';
import Roadmap from './Roadmap';
import MyData from './MyData';

class Homepage extends React.Component
{
    constructor(props) {
        super();
        this.state = {
            dislayCompendium: false,
            displayAchievements: false,
            displayCredits: false,
            displayChangelog: false,
            displayRoadmap: false,
            displaySettings: false,
            displayStartingModes: false,
            displayMyData: false
        };
        this.closeOverlay = this.closeOverlay.bind(this);
    }

    showOverlay(overlay)
    {
        let stateProperty = 'display' + overlay.charAt(0).toUpperCase() + overlay.slice(1);
        let newStateObject = {};
        newStateObject[stateProperty] = true;

        this.setState(newStateObject);
    }

    closeOverlay(overlay)
    {
        let stateProperty = 'display' + overlay.charAt(0).toUpperCase() + overlay.slice(1);
        let newStateObject = {};
        newStateObject[stateProperty] = false;

        this.setState(newStateObject);
    }

    render()
    {
        let styles = {
            border:"0px",
            height:"36px"
        };

        let continueAdventure;

        if (this.props.canContinueGame) {
            continueAdventure =
            <div className="home-main-cta">
                <a href="#" onClick={() => this.props.continueGame()}>Continue Adventure</a>
            </div>
        }

        return (
            <div className="home">
                <div className="logo">
                    {/* <h1>RogueQuest</h1> */}
                    <img src="/images/new/logo.png" />
                </div>
                {continueAdventure}
                <div className="home-main-cta">
                    <a href="#" onClick={() => this.showOverlay('startingModes')}>Start New Adventure</a>
                </div>
                <ul className="home-menu">
                    <li className="home-menu__item">
                        <a href="#" onClick={() => this.showOverlay('compendium')}>Compendium</a>
                    </li>
                    <li className="home-menu__item">
                        <a href="#" onClick={() => this.showOverlay('achievements')}>Achievements</a>
                    </li>
                    <li className="home-menu__item">
                        <a href="#" onClick={() => this.showOverlay('myData')}>My Data</a>
                    </li>
                    <li className="home-menu__item">
                        <a href="#" onClick={() => this.showOverlay('credits')}>Credits</a>
                    </li>
                </ul>
                <div className="home-social">
                    <a href="#" className="home-social__reddit"></a>
                    <a href="#" className="home-social__discord"></a>
                </div>
                <div className="home-changelog">
                    <p className="home-changelog__version">v0.3.7</p>
                    <a href="#" onClick={() => this.showOverlay('changelog')} className="home-changelog__link">View Changelog</a>
                    <a href="#" onClick={() => this.showOverlay('roadmap')} className="home-changelog__link">View Roadmap</a>
                </div>
                <div className="settings">
                    <a href="#" onClick={() => this.showOverlay('settings')} className="icon icon--settings">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g stroke="#fff"><ellipse cx="132.859" cy="131.158" rx="85.041" ry="85.045" fill="none" strokeWidth="29.104" strokeLinejoin="round"/><path fill="#fff" d="M102.802 44.953L113.385 4.13l38.931.378L162.9 45.331zM162.908 217.362l-10.583 40.822-38.932-.378-10.583-40.821zM191.908 61.778l40.644-11.245 19.138 33.905-30.06 29.576zM72.65 200.037l-40.644 11.245-19.139-33.905 30.061-29.576zM223.486 147.1l30.06 29.575-19.793 33.527-40.644-11.245zM44.121 112.948l-30.06-29.576 19.793-33.527 40.644 11.246z" strokeWidth=".265"/></g></svg>
                    </a>
                </div>
                <div className="donate">
                    <a href='https://ko-fi.com/G2G56EIQG' target='_blank'>
                        <img height='36' style={styles} src='https://cdn.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' />
                    </a>
                </div>
                <Compendium
                    display={this.state.displayCompendium}
                    cards={this.props.compendiumCards}
                    enemies={this.props.compendiumEnemies}
                    artifacts={this.props.compendiumArtifacts}
                    experience={this.props.experience}
                    closeOverlay={this.closeOverlay} />
                <StartingModes
                    display={this.state.displayStartingModes}
                    startingModes={this.props.startingModes}
                    chooseMode={this.props.chooseMode}
                    closeOverlay={this.closeOverlay} />
                <Credits
                    display={this.state.displayCredits}
                    closeOverlay={this.closeOverlay} />
                <Achievements
                    display={this.state.displayAchievements}
                    achievements={this.props.achievements}
                    experience={this.props.experience}
                    stats={this.props.stats}
                    closeOverlay={this.closeOverlay} />
                <Changelog
                    display={this.state.displayChangelog}
                    closeOverlay={this.closeOverlay} />
                <MyData
                    display={this.state.displayMyData}
                    testImportData={this.props.testImportData}
                    resetAll={this.props.resetAll}
                    closeOverlay={this.closeOverlay} />
                <Roadmap
                    display={this.state.displayRoadmap}
                    closeOverlay={this.closeOverlay} />
                <Settings
                    display={this.state.displaySettings}
                    closeOverlay={this.closeOverlay}
                    volume={this.props.backgroundMusicVol}
                    changeVolume={this.props.changeVolume}
                    changeSoundVolume={this.props.changeSoundVolume}
                    soundEffectVol={this.props.soundEffectVol}
                    setAnimationSpeed={this.props.setAnimationSpeed}
                    animationSpeed={this.props.animationSpeed} />
            </div>
        );
    }
}

export default Homepage;
