import React from 'react';

import Card from './Card';

class ShopSectionCard extends React.Component {

    constructor(props) {
        super();
        this.performAction = this.performAction.bind(this);
    }

    getTabClasses() {
        let classes = "shop-tab";
        if (this.props.isActiveTab === true) {
            classes += " shop-tab--active";
        }
        return classes;
    }

    performAction(id) {
        this.props.encounterAction('buyCard', id);
    }

    isPurchased(card)
    {
        return this.props.cardsPurchased.filter(purchasedCard => {
            return purchasedCard.uid === card.uid;
        }).length > 0;
    }

    render() {
        return (
            <div className={this.getTabClasses()}>
                {this.props.cardsForPurchase.map((card, handPos) => {
                    return (
                        <div className={"shop-card " + (this.isPurchased(card) ? 'shop-card--purchased' : '')} onClick={() => !this.isPurchased(card) ? this.performAction(card.uid) : {}}>
                            <Card
                                id={card.id}
                                key={card.uid}
                                uid={card.uid}
                                name={card.name}
                                image={card.image}
                                description={card.description}
                                tier={card.tier}
                                cost={card.cost}
                                goldCost={card.goldCost}
                                playCard={() => {}}
                                addUnplayableClass={this.props.gold < card.goldCost}
                            />
                            <div className={"shop-card-cost " + (this.props.gold < card.goldCost ? 'shop-card-cost--unbuyable' : '')}>
                                <img src="/images/roguequest/money.jpeg" />
                                {card.goldCost}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default ShopSectionCard;
