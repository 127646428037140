import AbstractCondition from "./AbstractCondition";

class EqualToCondition extends AbstractCondition
{
    isMet(context)
    {
        let firstValue = this.vals[0].calculate(context);
        return this.vals.every((value) => value.calculate(context) == firstValue);
    }
}

export default EqualToCondition;
