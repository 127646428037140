import Player from './Player';
import History from './History';
import EffectFactory from './Effects/EffectFactory';
import BattleOverException from './Exceptions/BattleOverException';
import Card from './Card';

import {shuffle} from './Helper';

import {cardsData} from './data/cards-new';
import TriggerManager from './TriggerManager';

class Battle
{
    constructor(enemy, game)
    {
        this.game = game;
        this.enemy = enemy;
        this.player = Player.createFromGame(game);
        this.enemy.battle = this;
        this.player.battle = this;
        this.history = new History(this);
        this.turnCount = 0;

        this.showLoseScreen = false;
        this.showWinScreen = false;

        this.listeners = [
            this.player,
            this.enemy
        ];

        this.player.deck.reset();
        this.enemy.deck.reset();

        this.cardRewards = [];

        this.generateCardRewards();

        this.aboutToPlayCard = null;
        this.enemyTurn = false;
    }

    getBattleState()
    {
        let data = {
            'battle_showLoseScreen': this.showLoseScreen,
            'battle_showWinScreen': this.showWinScreen,
            'battle_history': this.history.messages,
            'battle_turnCount': this.turnCount,
            'battle_cardRewards': this.cardRewards.map(card => card.getCardState())
        }

        return {...data, ...this.player.getPlayerState(this), ...this.enemy.getEnemyState(this)}
    }

    startTurnAction()
    {
        this.turnCount++;
        this.history.setIndent(0);
        this.history.write('Turn ' + this.turnCount + ' starts', null, true);
        if (this.enemy.haste && this.turnCount === 1) {
            this.history.write(this.enemy.name + ' makes the first move!');
            this.endTurnAction();
            return;
        }
        this.startPlayerTurn(this.player);
        this.saveGame();
    }

    startPlayerTurn(player)
    {
        try {
            let enemy = (player.isPlayer() ? this.enemy : this.player);
            this.history.setIndent(0);
            this.history.write(player.name + ' starts their turn', player.image);
            this.game.trigger('startEnemyTurn', {
                player: enemy,
                battle: this,
                enemy: player,
                game: this.game
            });
            this.game.trigger('startPlayerTurn', {
                player: player,
                battle: this,
                enemy: enemy,
                game: this.game
            });
            this.checkWinLoseCondition();
            this.game.trigger('afterStartPlayerTurn', {
                player: player,
                battle: this,
                enemy: enemy,
                game: this.game
            });
            this.checkWinLoseCondition();
            player.draw({
                player: player,
                battle: this,
                enemy: enemy,
                game: this.game
            });
            this.game.trigger('afterPlayerDraw', {
                player: player,
                battle: this,
                enemy: enemy,
                game: this.game
            });
            player.incrementMana();
        } catch (e) {
            if (!(e instanceof BattleOverException)) {
                throw e;
            }
        }
    }

    playEnemyTurn()
    {
        let cardsToPlay = this.enemy.deck.hand.filter(card => this.enemy.canPlayCard(card));

        if (this.aboutToPlayCard !== null) {
            this.playCard('enemy', this.aboutToPlayCard.uid);
            this.aboutToPlayCard.aboutToPlay = false;
            this.aboutToPlayCard = null;
        } else if (cardsToPlay.length > 0) {
            this.aboutToPlayCard = cardsToPlay[0];
            this.aboutToPlayCard.aboutToPlay = true;
		} else {
            try {
                this.enemy.deck.discardHand();
                this.endPlayerTurn(this.enemy);
                this.enemyTurn = false;
                // this.history.write('<br />');
                this.startTurnAction();
            } catch (e) {
                if (!(e instanceof BattleOverException)) {
                    throw e;
                }
            }
        }
    }

    endTurnAction()
    {
        try {
            this.player.deck.discardHand();
            this.endPlayerTurn(this.player);
            this.enemyTurn = true;
            this.startPlayerTurn(this.enemy);
        } catch (e) {
            if (!(e instanceof BattleOverException)) {
                throw e;
            }
        }
    }

    continueEnemyTurn()
    {
        this.playEnemyTurn();
    }

    endPlayerTurn(player)
    {
        this.game.trigger('endPlayerTurn', {
            player: player,
            battle: this,
            enemy: (player.isPlayer() ? this.enemy : this.player),
            game: this.game
        });
        this.checkWinLoseCondition();
    }

    checkWinLoseCondition(context={})
    {
        if (this.enemy.hp <= 0) {
            this.handleWin(context);
        } else if (this.player.hp <= 0) {
            this.handleLose(context);
        }
    }

    playCard(playerType, uid)
    {
        try {
            let player = this[playerType];
            let enemy = (playerType === 'player' ? this.enemy : this.player);
            let card = player.deck.hand.filter(card => card.uid === uid)[0];

            let context = {
                battle: this,
                player: player,
                enemy: enemy,
                card: card,
                game: this.game
            };

            this.game.trigger('beforePayForCard', context);

            if (!player.canPlayCard(card)) {
                this.history.setIndent(0);
                this.history.write(player.name + ' doesn\'t have enough mana to play ' + card.name, player.image);
                return;
            }

            this.history.setIndent(0);
            this.history.write(player.name + ' plays ' + card.name, player.image);
            this.history.increaseIndent();

            player.payForCard(card);

            let negateStatusEffect = player.getStatusEffect('negate');
            if (negateStatusEffect.val > 0) {
                negateStatusEffect.pulse();
                negateStatusEffect.val--;
                this.history.write(card.name + ' is negated', negateStatusEffect.image);
                player.deck.move(card, 'hand', 'discard');
                negateStatusEffect.playSoundEffect(context);
                return;
            }

            context = Object.assign(context, card.context);

            this.game.trigger('beforePlayCard', context);
            this.game.trigger('beforeEnemyPlayCard', {
                card: card,
                player: enemy,
                enemy: player,
                battle: this,
                game: this.game
            });

            if (card.negate === true) {
                card.negate = false;
                player.deck.afterCardPlayed(card, {
                    battle: this,
                    player: player,
                    enemy: player.isPlayer() ? this.enemy : this.player,
                    game: this.game
                });
            }

            let doubleNextRed = player.getStatusEffect('doubleNextRed');
            let doubleNextGreen = player.getStatusEffect('doubleNextGreen');
            let playIterations = 1;

            if (card.cost.red !== undefined && doubleNextRed.val > 0) {
                playIterations += doubleNextRed.val;
                doubleNextRed.val = 0;
            }
            if (card.cost.green !== undefined && doubleNextGreen.val > 0) {
                playIterations += doubleNextGreen.val;
                doubleNextGreen.val = 0;
            }
            for (let i = 0; i < playIterations; i++) {
                if (card.effects.length === 0) {
                    this.triggerSoundEffect('/sounds/effects/generic.wav');
                }

                Object.keys(card.effects).forEach(function (key, pos) {
                    let contextInstance = {...context};
                    let effect = EffectFactory.createEffect(key, card.effects[key]);
                    contextInstance.effect = effect;
                    contextInstance.game = this.game;
                    contextInstance.historySourceImage = card.getHistorySourceImage();
                    effect.handle(contextInstance);
                }.bind(this));

                card.permEffects.forEach(function (effectData) {
                    let contextInstance = {...context};
                    contextInstance.historySourceImage = card.getHistorySourceImage();
                    card.handlePermEffect(effectData, contextInstance);
                });

                player.deck.afterCardPlayed(card, {
                    battle: this,
                    player: player,
                    enemy: player.isPlayer() ? this.enemy : this.player,
                    game: this.game
                });

                this.game.trigger('afterPlayCard', {
                    card: card,
                    player: player,
                    enemy: enemy,
                    battle: this,
                    game: this.game
                });

                this.game.trigger('afterEnemyPlayCard', {
                    card: card,
                    player: enemy,
                    enemy: player,
                    battle: this,
                    game: this.game
                });

                this.checkWinLoseCondition();
            }

            if (card.endTurn !== null) {
                player.deck.discardHand();
                // this.endTurnAction();
                return;
            }
        } catch (e) {
            if (!(e instanceof BattleOverException)) {
                throw e;
            }
        }
    }

    surrender()
    {
        try {
            this.handleLose();
        } catch (e) {
            if (!(e instanceof BattleOverException)) {
                throw e;
            }
        }
    }

    enemySurrender()
    {
        try {
            this.handleWin();
        } catch (e) {
            if (!(e instanceof BattleOverException)) {
                throw e;
            }
        }
    }

    handleWin(context={})
    {
        this.game.trigger('enemyLose', {...context,
            player: this.enemy,
            enemy: this.player,
            game: this.game,
            battle: this,
            checkWinLoseCondition: false
        }, false);
        this.game.trigger('playerWin', {...context,
            player: this.player,
            enemy: this.enemy,
            game: this.game,
            battle: this,
            checkWinLoseCondition: false
        }, false);

        this.game.gold += this.enemy.gold;
        this.game.trigger('gainGold', {...context,
            game: this.game,
            battle: this,
            player: this.player,
            enemy: this.enemy,
            goldGain: this.enemy.gold,
             Condition: false
        }, false);

        this.showWinScreen = true;
        if (this.game.isGameWon()) {
            this.game.trigger('gameWin', {...context,
                player: this.player,
                enemy: this.enemy,
                game: this.game,
                battle: this,
                checkWinLoseCondition: false
            }, false);
        }

        this.game.xpBreakdown.enemiesDefeated += this.enemy.level;
        if (this.enemy.boss) {
            // double the xp gain
            this.game.xpBreakdown.enemiesDefeated += this.enemy.level;
        }

        if (this.game.artifacts.filter(artifact => artifact.hasPassiveEffect('debuffsPersist')).length > 0) {
            let gameStatusEffects = {...this.player.statusEffects};
            Object.keys(this.player.statusEffects).forEach(function (key) {
                if (!gameStatusEffects[key].isDebuff) {
                delete gameStatusEffects[key];
                }
            });
            this.game.statusEffects = gameStatusEffects;
        }

        this.game.triggerSoundEffect('/sounds/effects/win.wav');
        this.game.triggerSoundEffect('/sounds/effects/growl4.mp3');
        throw new BattleOverException('playerWin');
    }

    handleLose(context={})
    {
        this.game.trigger('playerLose', {
            game: this.game,
            battle: this,
            player: this.player,
            enemy: this.enemy
        }, false);
        this.showLoseScreen = true;
        this.game.xpBreakdown.lossPenalty = (-1 * (Math.floor(this.game.xpBreakdown.enemiesDefeated / 2)));
        this.game.triggerSoundEffect('/sounds/effects/lose.wav');
        this.game.triggerSoundEffect('/sounds/effects/pain1.mp3');
        throw new BattleOverException('playerLose');
    }

    generateCardRewards()
    {
        let cardIds = {...cardsData};
        let possibleCardIds = Object.keys(cardIds)
            .filter(function (cardId) {
                if (cardIds[cardId].modes === undefined) {
                    return false;
                }
                return cardIds[cardId].tier > 0 && cardIds[cardId].modes.includes(this.game.modeId) && cardIds[cardId].unlockLevel <= this.game.experience.level;
            }.bind(this));

        if (this.game.artifacts.filter(artifact => artifact.hasPassiveEffect('noDuplicates')).length > 0) {
            possibleCardIds = possibleCardIds
                .filter(function (card) {
                    let cardNamesInGame = this.game.deck.deck.map(card => card.name);
                    return !cardNamesInGame.includes(card.name);
                }.bind(this));
        }


        let possibleCards = possibleCardIds.map((cardId, pos) => Card.createFromCardId(cardId));

        let possibleCommon = shuffle(possibleCards.filter(card => card.tier === 1));
        let possibleUncommon = shuffle(possibleCards.filter(card => card.tier === 2));
        let possibleRare = shuffle(possibleCards.filter(card => card.tier === 3));
        let cardRewards = [];

        if (this.enemy.boss) {
            for (let i = 0; i < 3; i++) {
                if (Math.random() < 0.75 && possibleRare.length > 0) {
                    cardRewards.push(possibleRare.pop());
                } else if (possibleUncommon.length > 0) {
                    cardRewards.push(possibleUncommon.pop());
                }
            }
        } else {
            for (let i = 0; i < 3; i++) {
                // 10% chance of rare
                if (Math.random() < 0.1 && possibleRare.length > 0) {
                    cardRewards.push(possibleRare.pop());
                } else if (Math.random() < 0.25 && possibleUncommon.length > 0) {
                    cardRewards.push(possibleUncommon.pop());
                } else if (possibleCommon.length > 0) {
                    cardRewards.push(possibleCommon.pop());
                }
            }
        }

        this.cardRewards = cardRewards;
    }

	chooseCard(cardUid)
	{
        let card = this.cardRewards.filter(reward => reward.uid === cardUid)[0];
        this.game.deck.addToDeck(card);
        this.game.triggerSoundEffect('/sounds/effects/gain_card.wav');

		// Simple POST request with a JSON body using fetch
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(
				{
					version: this.game.version,
                    internalId: parseInt(card.id),
                    name: card.name,
                    columnName: 'chosenFromEnemyReward'
				}
			)
		};
		fetch('/.netlify/functions/increase-card-pickrate-count', requestOptions);

        let unchosenCards = this.cardRewards.filter(reward => reward.uid !== cardUid);
        unchosenCards.forEach(function(card) {
            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        version: this.game.version,
                        internalId: parseInt(card.id),
                        name: card.name,
                        columnName: 'declinedFromEnemyReward'
                    }
                )
            };
            fetch('/.netlify/functions/increase-card-pickrate-count', requestOptions);
        }.bind(this));
	}

    registerTriggers(triggerName, triggerManager)
    {
        this.listeners.forEach(listener => {
            listener.registerTriggers(triggerName, triggerManager)
        });
    }

    saveGame()
    {
        this.game.saveGame();
    }
}

export default Battle;
