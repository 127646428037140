import React from 'react';

import Card from './Card';

class ShopDeckList extends React.Component
{
    getTabClasses() {
        let classes = "shop-deck-list";
        if (this.props.isActive === true) {
            classes += " shop-deck-list--active";
        }
        return classes;
    }

    render() {
        return (
            <div className={this.getTabClasses()}>
                <h2 className="shop-deck-list__title">{this.props.title}</h2>
                <a className="shop-deck-list__close" onClick={() => this.props.closeDeckList()}>Close</a>
                <div className="shop-deck-list__cards">
                    {this.props.deck.map((card, handPos) => {
                        return (
                            <Card
                                id={card.id}
                                key={card.uid}
                                uid={card.uid}
                                name={card.name}
                                image={card.image}
                                description={card.description}
                                tier={card.tier}
                                cost={card.cost}
                                goldCost={card.goldCost}
                                playCard={this.props.action}
                                addUnplayableClass={false} />
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default ShopDeckList;
