import React from 'react';
import TopBar from './TopBar';

class TopBarPlay extends React.Component
{
    getProgressClasses()
    {
        let classes = 'progress progress--encounter ';
        if (this.props.progress >= 100) {
            classes += 'progress--full';
        }
        return classes;
    }

    render()
    {
        return (
            <TopBar
                openOverlay={this.props.openOverlay}
                classes="topbar--play"
                >
                <div className="topbar-progress">
                    <h1 className="topbar-progress__title">{this.props.locationName}</h1>
                    <progress className={this.getProgressClasses()} min="0" max="100" value={this.props.progress}></progress>
                    <div className="progress-boss">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000" viewBox="0 0 264.583 264.583"><g transform="matrix(1.13883 0 0 1.13883 -15.425 3.056)"><ellipse cx="131.725" cy="142.346" rx="81.451" ry="81.488" stroke="red" strokeWidth=".271" strokeLinejoin="round"/><path d="M206.753 85.423c7.307-15.245 14.615-30.49 12.851-43.594-1.764-13.103-12.599-24.064-23.434-35.025 4.787 11.843 9.575 23.686 7.244 33.073-2.33 9.386-11.78 16.315-21.23 23.245M56.29 83.929c-7.308-15.245-14.615-30.49-12.851-43.594C45.203 27.232 56.038 16.271 66.873 5.31c-4.788 11.843-9.575 23.686-7.244 33.073 2.33 9.386 11.78 16.315 21.23 23.245" stroke="#000" strokeWidth=".265"/><path d="M89.958 96.762l45.357 26.08" fill="none" stroke="#000" strokeWidth=".265"/><path d="M175.759 105.833L145.52 127c5.543 3.402 11.087 6.804 17.639 6.93 6.552.125 14.111-3.024 16.379-8.253 2.268-5.228-.756-12.536-3.78-19.844zM91.812 106.182l30.238 21.166c-5.544 3.402-11.088 6.804-17.64 6.93-6.55.126-14.11-3.024-16.378-8.252-2.268-5.229.756-12.537 3.78-19.844zM80.131 153.836c17.135 5.796 34.27 11.592 51.846 11.844 17.576.251 35.592-5.04 53.61-10.332-9.45 11.717-18.9 23.435-31.499 29.23-12.599 5.796-28.348 5.67-41.199-.44-12.851-6.111-22.805-18.206-32.758-30.302z" fill="#feffff" stroke="#000" strokeWidth=".265"/><path d="M105.833 155.348l11.34 21.167 6.425-21.545 6.426 21.545 10.583-21.545 8.694 18.143 5.291-20.41 12.095 14.74 1.134-16.63 9.072 10.205-1.134-13.607-88.447.756 3.78 14.363 6.048-8.694 2.646 12.474z" stroke="#000" strokeWidth=".265"/></g></svg>
                    </div>
                </div>
            </TopBar>
        );
    }
}

export default TopBarPlay;
