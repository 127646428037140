import AbstractValue from "./AbstractValue";
import ValueFactory from "./ValueFactory";

class CalcValue extends AbstractValue
{
    constructor(data)
    {
        super(data);
        this.calc = data.valCalc;
        this.val = this.postConstructParseVal(this.val);
    }

    postConstructParseVal(val)
    {
        switch (this.calc) {
            case 'floor':
                return ValueFactory.createValueFromJson(val);

            case 'multiply':
                return val.map(value => ValueFactory.createValueFromJson(value));

            case 'divide':
                return val.map(value => ValueFactory.createValueFromJson(value));

            case 'remainder':
                return val.map(value => ValueFactory.createValueFromJson(value));

            case 'sum':
                return val.map(value => ValueFactory.createValueFromJson(value));

            case 'subtract':
                return val.map(value => ValueFactory.createValueFromJson(value));

            case 'max':
                return val.map(value => ValueFactory.createValueFromJson(value));

            case 'min':
                return val.map(value => ValueFactory.createValueFromJson(value));

            default:
                throw new Error('Could not parse val from JSON');
        }
    }

    calculate(context)
    {
        let firstVal;
        let valArray;
        let r;

        switch (this.calc) {
            case 'floor':
                r = Math.floor(this.val.calculate(context));
                break;
                break;

            case 'multiply':
                valArray = [...this.val];
                r = valArray.reduce((accumulator, value) => {
                    return accumulator * value.calculate(context);
                }, 1);
                break;

            case 'divide':
                valArray = [...this.val];
                firstVal = valArray.shift().calculate(context);
                r = valArray.reduce((accumulator, value) => {
                    return accumulator / value.calculate(context);
                }, firstVal);
                break;

            case 'remainder':
                valArray = [...this.val];
                firstVal = valArray.shift().calculate(context);
                r = valArray.reduce((accumulator, value) => {
                    return accumulator + firstVal%(value.calculate(context));
                }, 0);
                break;

            case 'sum':
                valArray = [...this.val];
                r = valArray.reduce((accumulator, value) => {
                    return value.calculate(context) + accumulator;
                }, 1);
                break;

            case 'subtract':
                valArray = [...this.val];
                firstVal = valArray.shift().calculate(context);
                r = valArray.reduce((accumulator, value) => {
                    return accumulator - value.calculate(context);
                }, firstVal);
                break;

            case 'max':
                valArray = [...this.val];
                firstVal = valArray.shift().calculate(context);
                r = valArray.reduce((accumulator, value) => {
                    return Math.max(value.calculate(context), accumulator);
                }, firstVal);
                break;

            case 'min':
                valArray = [...this.val];
                firstVal = valArray.shift().calculate(context);
                r = valArray.reduce((accumulator, value) => {
                    return Math.min(value.calculate(context), accumulator);
                }, firstVal);
                break;

            default:
                throw new Error('Could not calculate value');
        }

        if (typeof r === 'number') {
            r += this.valExtra;
        }

        return r;
    }
}

export default CalcValue;
